import { F } from 'ts-toolbelt';

// Thanks Stefan: https://fettblog.eu/typescript-array-includes/
export function includes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U,
): el is T {
  return coll.includes(el as T);
}

// https://stackoverflow.com/questions/70028907/narrowing-an-object-of-type-unknown
export function hasKey<ObjectType extends object, Key extends string>(
  obj: ObjectType,
  key: Key,
): obj is ObjectType & Record<Key, unknown> {
  return typeof obj === 'object' && key in obj;
}

export function isObjectWithKey<ObjectType, Key extends string>(
  obj: ObjectType,
  key: Key,
): obj is ObjectType & object & Record<Key, unknown> {
  return typeof obj === 'object' && obj !== null && key in obj;
}

export type WithNullableFields<T, Fields> = {
  [K in keyof T]: K extends Fields ? T[K] | null | undefined : T[K];
};

export type WithNonNullableFields<T, Fields> = {
  [K in keyof T]: K extends Fields ? Exclude<T[K], null> : T[K];
};

// https://www.youtube.com/watch?v=a_m7jxrTlaw
// eslint-disable-next-line @typescript-eslint/ban-types
export type LooseAutocomplete<T extends string> = T | (string & {});

export const satisfies =
  <TWide>() =>
  <TNarrow extends TWide>(narrow: F.Narrow<TNarrow>) =>
    narrow;

export type NonEmptyArray<T> = [T, ...T[]];
