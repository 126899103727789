import { useCallback } from 'react';
import { useRouter } from 'next/router';

/**
 * Returns the current length of the native browser history (or 1 if we're on the server)
 */
function getNativeHistoryLength() {
  return typeof window === 'undefined' ? 1 : window.history.length;
}

/**
 * Returns the length of the NextJS router history
 * This is different to the default `history.length` because it only counts client-side navigations
 */
let initialHistoryLength = getNativeHistoryLength();
export function getHistoryLength(): number {
  return 1 + (getNativeHistoryLength() - initialHistoryLength);
}

/**
 * Returns `true` if going "back" will result in a client-side navigation
 * Returns `false` if it will result in a full page reload or possibly the end of the browser history
 */
export function canGoBackAPage() {
  return getHistoryLength() > 1;
}

/**
 * Hook that returns a callback that can be used to navigate back a page
 *
 * @param fallback - URL to redirect to if the history length is 0
 */
export function useBackButtonWithFallback(fallback: string) {
  const router = useRouter();
  return useCallback(() => {
    if (canGoBackAPage()) {
      router.back();
    } else {
      router.replace(fallback);
    }
  }, [fallback, router]);
}
