import { Grid, GridItem, Icon } from '@chakra-ui/react';

import { PlayableVideo, Video } from '@arena-labs/shared-models';
import { CheckCircleIcon, CircleIcon } from '@arena-labs/strive2-ui';

import { Program } from '../../api';
import { VideoCard, VideoCardProps } from '../video/video-card';

export type ProgramVideoCardProps = {
  program: Program;
  video: Video;
  href: string;
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    video: PlayableVideo,
  ) => void;
  index: number;
  direction: VideoCardProps['direction'];
  thumbnailPosition?: string;
};

export function ProgramVideoCard({
  program,
  video,
  href,
  onClick,
  index,
  direction = 'row',
  thumbnailPosition,
}: ProgramVideoCardProps) {
  const watchIndicatorProps =
    direction === 'row'
      ? {
          borderRightRadius: 'sm',
          mr: 2,
        }
      : {
          borderLeftRadius: 'sm',
          mr: 1,
        };
  return (
    <>
      <Grid
        bg="strive.card"
        minW="10"
        height="full"
        shadow="md"
        {...watchIndicatorProps}
      >
        <GridItem
          gridArea="1/1"
          justifySelf="end"
          fontSize="xs"
          fontWeight="bold"
          color="strive.cardText"
          m="1"
        >
          {index}
        </GridItem>

        <Icon
          gridArea="1/1"
          placeSelf="center"
          boxSize={6}
          {...(video.watched
            ? {
                as: CheckCircleIcon,
                color: program.skill?.foreground_color,
              }
            : { as: CircleIcon, color: 'gray.400' })}
        />
      </Grid>
      <VideoCard
        video={video}
        href={href}
        onClick={onClick}
        thumbnailPx={direction === 'row' ? '110' : '180'}
        thumbnailRatio={5 / 3}
        direction={direction}
        flexGrow={direction === 'row' ? 1 : 0}
        disableLinkBox
        thumbnailPosition={thumbnailPosition}
      />
    </>
  );
}
