import { useEffect, useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';

import { Elevation, TEXT } from '@arena-labs/strive2-ui';

import { GameAndRulesExpectations } from '../information/competition-background';

export type CompetitionHubCountdownProps = {
  startDate: Date;
};
export function CompetitionHubCountdown({
  startDate,
}: CompetitionHubCountdownProps) {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const startTime = startDate.getTime();

  const timeDiff = startTime - currentTime;
  const minuteInMS = 1000 * 60;
  const hrInMS = minuteInMS * 60;
  const dayInMS = hrInMS * 24;

  const makeDoubleDigits = (value: string) => {
    if (value.length === 1) {
      value = '0' + value;
    }
    return value;
  };

  const daysLeft = makeDoubleDigits(
    Math.floor(timeDiff / dayInMS).toString(),
  ).split('');
  const msRemaining = timeDiff % dayInMS;
  const hoursLeft = makeDoubleDigits(
    Math.floor(msRemaining / hrInMS).toString(),
  ).split('');
  const msRemainingForMins = msRemaining % hrInMS;
  const minutesLeft = makeDoubleDigits(
    Math.floor(msRemainingForMins / minuteInMS).toString(),
  ).split('');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Flex direction={'column'} gap={4} my="auto">
        <TEXT.H2>Launch In</TEXT.H2>
        <Flex
          direction="row"
          gap="2"
          alignItems={'center'}
          w="full"
          color={'neutral.200'}
        >
          {daysLeft.map((value, idx) => {
            return <CountDownBlock key={idx} value={value} />;
          })}
          <TEXT.H1 color={'neutral.700'}>:</TEXT.H1>
          {hoursLeft.map((value, idx) => {
            return <CountDownBlock key={idx} value={value} />;
          })}
          <TEXT.H1 color={'neutral.700'}>:</TEXT.H1>
          {minutesLeft.map((value, idx) => {
            return <CountDownBlock key={idx} value={value} />;
          })}
        </Flex>
        <Flex justify={'space-around'} gap={8} px="1" mt={-2}>
          <TEXT.P3 w="full">Days</TEXT.P3>
          <TEXT.P3 w="full">Hours</TEXT.P3>
          <TEXT.P3 w="full">Minutes</TEXT.P3>
        </Flex>
      </Flex>
      <Divider mx={-6} px={6} mb={6} />
      <Box mb="auto">
        <GameAndRulesExpectations />
      </Box>
    </>
  );
}

function CountDownBlock({ value }: { value: string }) {
  return (
    <Elevation level={'24dp'} as={Flex} rounded="md" w="full">
      <TEXT.H2 textAlign={'center'} my="4" w="full">
        {value}
      </TEXT.H2>
    </Elevation>
  );
}
