import axios, { AxiosError, AxiosTransformer } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import type { AuthResponse } from '@arena-labs/shared-models';

import { getFreshToken } from '../auth/auth-api';
import {
  expireAuthSession,
  getAuthTokens,
  setAuthTokens,
} from '../auth/auth-service';
import { apiHost } from '../constants';
import { getAdditionalHeaders } from '../lib/utils';

type AuthClientConfig = {
  baseURL: string;
  getAuthTokens: () => AuthResponse | null;
  setAuthTokens: (tokens: AuthResponse) => void;
  onSessionExpired: () => void;
};

// Auth client backed by the auth service (store)
export const authClient = createAuthClient({
  baseURL: apiHost,
  getAuthTokens,
  setAuthTokens,
  onSessionExpired: expireAuthSession,
});

// Factory that creates standalone auth client which manages tokens locally
type StandaloneAuthClientOptions = {
  onSessionExpired?: () => void;
  onTokensUpdated?: (tokens: AuthResponse) => void;
  baseURL?: string;
};
export function createStandaloneAuthClient(
  tokens: AuthResponse,
  options: StandaloneAuthClientOptions = {},
) {
  let tokenStore: AuthResponse | null = tokens;

  return createAuthClient({
    baseURL: options.baseURL ?? apiHost,
    getAuthTokens: () => tokenStore,
    setAuthTokens: (tokens) => {
      tokenStore = tokens;
      options.onTokensUpdated?.(tokens);
    },
    onSessionExpired: () => {
      tokenStore = null;
      options.onSessionExpired?.();
    },
  });
}

/**
 * Factory that creates an Axios instance which automatically sets the `Authorization` header
 * and logs the user out if the API ever responds with a 401 status code
 */
export function createAuthClient(config: AuthClientConfig) {
  const client = axios.create({
    baseURL: config.baseURL,
    timeout: 10_000,
    transformRequest: [
      (data: unknown, headers: Record<string, string>) => {
        Object.assign(headers, getAdditionalHeaders());

        const tokens = config.getAuthTokens();
        if (tokens) {
          Object.assign(headers, getAuthHeaders(tokens.access));
        }
        return data;
      },
    ].concat(axios.defaults.transformRequest as AxiosTransformer[]),
  });

  createAuthRefreshInterceptor(client, async (failedRequest: AxiosError) => {
    const tokens = config.getAuthTokens();
    if (tokens) {
      console.warn('Refreshing auth');
      const newTokens = await getFreshToken(tokens.refresh);
      config.setAuthTokens(newTokens);
      Object.assign(
        failedRequest.response?.config.headers ?? {},
        getAuthHeaders(tokens.access),
        getAdditionalHeaders(),
      );
    } else {
      throw new Error('No refresh token');
    }
  });

  // Log the user out if the API responds with 401
  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        console.warn('Authentication failed, logging out');
        config.onSessionExpired();
      }
      return Promise.reject(error);
    },
  );

  return client;
}

/**
 * Helper to create a headers object with authorization token
 */
export function getAuthHeaders(authToken?: string) {
  if (authToken) {
    return { Authorization: `Bearer ${authToken}` };
  }
  return undefined;
}
