import { useEffect } from 'react';
import { useAnimate, usePresence } from 'framer-motion';

//if you use this hook in a component (lets call the component <TheUI/>), the component should be conditionally rendered
//directly inside of an <AnimatePresense/> to get both entry and exit animations ******
//ie:
// <AnimatePresense>
//     {shouldRenderTheUI ? <TheUI/> : undefined}
// </AnimatePresense>

export function useFadeEntryFadeExit(onExitAniEnd?: () => void) {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent && scope.current) {
      const enterAnimation = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 0.5 });
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, { opacity: 0 }, { duration: 0.5 });
        safeToRemove && safeToRemove();
      };
      scope.current &&
        exitAnimation().then(() => {
          onExitAniEnd && onExitAniEnd();
        });
    }
  }, [isPresent, animate, safeToRemove, scope, onExitAniEnd]);

  return { scope, animate };
}
