import { Config as DOMPurifyConfig, sanitize } from 'dompurify';

const ALLOWED_TAGS = [
  'p',
  'br',
  'u',
  'i',
  'em',
  'b',
  'strong',
  'ul',
  'ol',
  'li',
];

export function sanitizeMarkup(markup: string, config: DOMPurifyConfig = {}) {
  return sanitize(markup, {
    ...config,
    ALLOWED_TAGS,
    RETURN_DOM: false,
    RETURN_DOM_FRAGMENT: false,
  });
}
