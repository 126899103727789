import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { Box, Grid, Text } from '@chakra-ui/react';

import { LoomVideo, PlayableMedia } from '@arena-labs/shared-models';
import { ZStack } from '@arena-labs/strive2-ui';

import { LoomVideoEmbed } from '../loom/loom-video-embed';
import { EndPromptDisplay, EndPromptDisplayProps } from './end-prompt-display';

export type MediaQueueLoomProps = {
  loom: LoomVideo;
  mode: 'strict' | 'loose';
  nextMedia?: PlayableMedia;
  beforeContinue?: () => Promise<unknown>;
  onClose?: () => void;
  isActive?: boolean;
  showEndPrompt?: boolean;
  endPrompt?: EndPromptDisplayProps['render'];
};

export function MediaQueueLoom({ loom, ...props }: MediaQueueLoomProps) {
  // Create a new key every time the video is closed
  // This will cause any playing video to stop playing
  const [key, setKey] = useState(0);
  const wasActive = usePrevious(props.isActive);
  useEffect(() => {
    if (wasActive && !props.isActive) {
      setKey((prev) => prev + 1);
    }
  }, [wasActive, props.isActive]);
  const [isLoaded, setLoaded] = useState<boolean>(false);

  return (
    <Box px="6" h="full">
      <Grid
        templateRows="1fr auto"
        h="full"
        maxH="full"
        w="full"
        borderRadius="card"
        bg="gray.700"
        pt="4"
      >
        <ZStack justifySelf="center" h="full" w="full" placeItems="center">
          <Text
            aria-hidden
            textStyle="p2"
            color={isLoaded ? 'white' : 'transparent'}
          >
            {/* Render something for session replay which can't see the loom iframe */}
            Loom
          </Text>

          <LoomVideoEmbed
            key={key}
            loomID={loom.loom_id}
            styles={{
              aspectRatio: '9 / 16',
              width: 'auto',
            }}
            onLoad={() => setLoaded(true)}
          />
        </ZStack>
        {props.showEndPrompt && (
          <EndPromptDisplay
            show
            box={{ position: 'initial', width: '100%' }}
            beforeContinue={props.beforeContinue}
            onClose={props.onClose}
            media={loom}
            nextMedia={props.nextMedia}
            isActive={props.isActive}
            render={props.endPrompt}
          />
        )}
      </Grid>
    </Box>
  );
}
