import {
  BoxProps,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';

import { HelpCircleIcon } from '../icons';
import { Icon, IconProps } from './icon';

export type HelpButtonProps = {
  type?: 'popover' | 'modal';
  variant?: string;
  ariaLabel?: string;
  title?: React.ReactNode;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  iconProps?: Partial<IconProps>;
  contentProps?: BoxProps;
};

export function HelpButton({
  type = 'popover',
  variant = 'default',
  ariaLabel = 'Help',
  title,
  children,
  trigger,
  iconProps,
  contentProps,
}: HelpButtonProps) {
  const modal = useDisclosure();

  if (type === 'popover') {
    return (
      <Popover variant={variant} boundary="scrollParent" isLazy>
        <PopoverTrigger>
          {trigger ?? (
            <IconButton
              variant="unstyled"
              aria-label={ariaLabel}
              size="sm"
              display="inline-grid"
              placeItems="center"
            >
              <Icon as={HelpCircleIcon} boxSize="5" {...iconProps} />
            </IconButton>
          )}
        </PopoverTrigger>
        <Portal>
          <PopoverContent {...(contentProps as PopoverContentProps)}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverBody fontWeight="normal" fontSize="md">
              {children}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }

  return (
    <>
      {trigger ?? (
        <IconButton
          variant="unstyled"
          size="sm"
          aria-label={ariaLabel}
          onClick={modal.onOpen}
          display="inline-grid"
          placeItems="center"
        >
          <Icon as={HelpCircleIcon} boxSize="5" {...iconProps} />
        </IconButton>
      )}
      <Modal isCentered variant={variant} {...modal}>
        <ModalOverlay />
        <ModalContent {...contentProps}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0" mx="6" mb="4">
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
