import { z } from 'zod';

import { safeEnum, zIsoDateTime } from '@strive/api';
import { createDataStore } from '@strive/utils';

export type AciStoreSchema = z.infer<typeof aciStoreSchema>;
const aciStoreSchema = z.object({
  aci_start: z.date().or(zIsoDateTime).nullable(),
  skipped: z.boolean(),
  recharge_estimate: safeEnum(['above', 'below']),
  estimate_notes: z.string().nullable(),
  practice_review: safeEnum(['available', 'complete']),
});

export const initialAciState = {
  aci_start: null,
  recharge_estimate: null,
  estimate_notes: null,
  practice_review: 'available' as const,
  skipped: false,
};

export const aciStore = createDataStore({
  key: 'LatestAciEntry',
  schema: aciStoreSchema,
  fallback: initialAciState,
});
