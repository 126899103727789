import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError, AxiosInstance } from 'axios';

import {
  GetVideosResponse,
  GetWatchedVideosResponse,
  VideoQuery,
} from '@arena-labs/shared-models';
import { authClient } from '@strive/api';

import { useContentLibraryStore } from '../state';

export async function getWatchedVideos(
  query: VideoQuery | string,
  apiClient: AxiosInstance = authClient,
): Promise<GetWatchedVideosResponse> {
  const videosUrl = `/profile/history/`;
  const queryUrl =
    typeof query === 'string' ? query : `${videosUrl}?${query.toString()}`;
  return (await apiClient.get(queryUrl)).data;
}

export function useWatchedVideos() {
  const query = useContentLibraryStore((state) => state.query.WATCHED);
  return useInfiniteQuery<GetVideosResponse, AxiosError>(
    ['watched', query.toString()],
    async ({ pageParam }: { pageParam?: string }) => {
      const watchedResponse = await getWatchedVideos(pageParam || query);
      // convert "WatchedVideos" response to "Videos" response
      const videosResponse = {
        ...watchedResponse,
        results: watchedResponse.results.map(
          (watchedVideo) => watchedVideo.video,
        ),
      };
      return videosResponse;
    },

    {
      getNextPageParam: (lastPage) => lastPage.next,
      getPreviousPageParam: (lastPage) => lastPage.previous,
    },
  );
}
