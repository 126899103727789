import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useCallbackRef } from '@chakra-ui/react';
import { z } from 'zod';

import * as DataStore from './data-store/helpers';
import { FallbackFromSchema, Prettify } from './data-store/types';

type UseUrlStateOptions<
  Schema extends z.ZodTypeAny,
  Fallback = FallbackFromSchema<Schema>,
  Output = z.TypeOf<Schema>,
> = {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: Schema;
  fallback?: Fallback;
  serialize?: (value: Output) => string;
  deserialize?: (value: string) => Output;
  onChange?: (value: Output) => unknown;
};

type UseUrlStateReturn<
  Schema extends z.ZodTypeAny,
  Fallback extends Exclude<FallbackFromSchema<Schema>, undefined> | undefined,
  Output = Fallback extends undefined
    ? z.TypeOf<Schema> | undefined
    : z.TypeOf<Schema>,
> = {
  value: Output;
  set: (newValue: Output | null) => void;
  push: (newValue: Output | null) => void;
};

// Function overloads
// 1. Without a fallback, the returned value can include `undefined`
export function useUrlState<Schema extends z.ZodTypeAny>(
  options: UseUrlStateOptionsWithoutFallback<Schema>,
): Prettify<UseUrlStateReturn<Schema, undefined>>;

// 2. With a fallback, the returned value will exclude `undefined`
// (unless the schema includes `undefined` or .optional())
export function useUrlState<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema>,
>(
  options: UseUrlStateOptions<Schema, Fallback>,
): Prettify<UseUrlStateReturn<Schema, Fallback>>;

// Adjust function to handle conditional fallback types
export function useUrlState<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema>,
  Output = z.TypeOf<Schema>,
>(options: UseUrlStateOptions<Schema, Fallback>) {
  const {
    key,
    schema,
    fallback,
    serialize = getSerializer(schema),
    deserialize = getDeserializer(schema),
    onChange,
  } = options;
  const router = useRouter();
  const query = router.query;
  const value = query[key] as string | undefined;
  const routerRef = useRef(router);
  routerRef.current = router;

  /**
   * Creates a URL with the new query state value
   */
  const getStateUrl = useCallback(
    (newValue: Output | null) => {
      const updated = new URLSearchParams(window.location.search);
      if (newValue === null) {
        updated.delete(key);
      } else {
        const valueToSet = serialize(newValue);
        updated.set(key, valueToSet);
      }
      return `${window.location.pathname}?${updated.toString()}`;
    },
    [key, serialize],
  );

  // history.replaceState
  const set = useCallback(
    (newValue: Output | null) => {
      const newUrl = getStateUrl(newValue);
      routerRef.current.replace(newUrl, undefined, { shallow: true });
    },
    [getStateUrl],
  );

  // history.pushState
  const push = useCallback(
    (newValue: Output | null) => {
      const newUrl = getStateUrl(newValue);
      routerRef.current.push(newUrl, undefined, { shallow: true });
    },
    [getStateUrl],
  );

  const state = useMemo(() => {
    const rawValue = value ? deserialize(value) : null;
    const deserializedValue = rawValue !== null ? rawValue : fallback;
    const parsed = schema.safeParse(deserializedValue);

    return {
      /**
       * The value of the url state
       */
      value: parsed.success ? parsed.data ?? fallback : (fallback as Output),

      /**
       * Set the value of the url state (history.replaceState)
       */
      set,

      /**
       * Set the value of the url state (history.pushState)
       */
      push,
    };
  }, [value, deserialize, fallback, schema, set, push]);

  // Call the onChange callback when the value changes
  const handleChange = useCallbackRef(onChange);
  useEffect(() => {
    handleChange(state.value);
  }, [state.value, handleChange]);

  return state;
}

function getSerializer(schema: z.ZodTypeAny) {
  return schema instanceof z.ZodEnum ||
    schema instanceof z.ZodString ||
    schema instanceof z.ZodBoolean ||
    schema instanceof z.ZodNumber
    ? String
    : DataStore.serialize;
}

function getDeserializer(schema: z.ZodTypeAny) {
  return schema instanceof z.ZodEnum || schema instanceof z.ZodString
    ? String
    : schema instanceof z.ZodBoolean
    ? deserializeBoolean
    : schema instanceof z.ZodNumber
    ? Number
    : DataStore.deserialize;
}

function deserializeBoolean(value: string) {
  return value === 'true';
}

type UseUrlStateOptionsWithoutFallback<Schema extends z.ZodTypeAny> = {
  [K in keyof UseUrlStateOptions<Schema, undefined>]: K extends 'fallback'
    ? never // Exclude the 'fallback' key from the type
    : UseUrlStateOptions<Schema, undefined>[K]; // Include other keys from the Options
};
