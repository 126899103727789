import { apiClient } from './api-client';
import { authClient } from './auth-client';
import { backend } from './backend-client';
import { frontend } from './frontend-client';

export * from './auth-client';
export * from './backend-client';
export * from './frontend-client';
export * from './api-client';
export * from './resources-s3-client';

/**
 * Sets the baseURL for all clients to the given server and api URLs
 */
export function setClientEnvironment(server: string, api: string) {
  console.info('Setting Environment', { server, api });
  authClient.defaults.baseURL = api;
  backend.defaults.baseURL = api;
  frontend.defaults.baseURL = server;
  apiClient.axios.defaults.baseURL = api;
}
