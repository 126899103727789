import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';

const deviceSchema = z.object({
  type: z.enum(['ios', 'android', 'web']),
  registration_id: z.string(),
});

export const deviceApi = makeApi([
  {
    alias: 'registerDevice',
    description: 'Register a device for push notifications',
    method: 'post',
    path: '/notifications/device/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: deviceSchema,
      },
    ],
    response: z.object({
      id: z.number(),
      name: z.string().nullable(),
      registration_id: z.string(),
      device_id: z.string().nullable(),
      active: z.boolean(),
      date_created: zIsoDateTime,
      type: z.enum(['ios', 'android', 'web']).nullable(),
    }),
  },
]);
