import { Icon, IconProps, keyframes, Text, TextProps } from '@chakra-ui/react';

import { ArenaLogo } from '../icons';

export type BrandLogoProps = {
  iconColor?: string;
  iconSize?: IconProps['boxSize'];
  fontSize?: TextProps['fontSize'];
  spin?: boolean;
  noText?: boolean;
};

const animateSpin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export function BrandLogo({
  iconColor = 'brand.base',
  iconSize = [50, 75],
  fontSize = ['xl', '2xl'],
  spin,
  noText,
}: BrandLogoProps) {
  return (
    <>
      <Icon
        as={ArenaLogo}
        color={iconColor}
        boxSize={iconSize}
        animation={spin ? `${animateSpin} 5s linear infinite;` : undefined}
      ></Icon>
      {!noText && (
        <Text fontFamily="brand" fontSize={fontSize} ml="2">
          ARENA STRIVE
        </Text>
      )}
    </>
  );
}
