import { useDisclosure } from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';

export type UseModalDisclosureOptions = {
  onOpen?: () => void;
  onClose?: () => void;
  context?: Record<string, unknown>;
};

export function useModalDisclosure(
  modalName: string,
  options: UseModalDisclosureOptions = {},
) {
  const analytics = useAnalytics();

  return useDisclosure({
    onOpen: () => {
      analytics.logEvent(GeneralTrackingEvent.ModalOpened, {
        modalName,
        ...options.context,
      });
      options.onOpen?.();
    },
    onClose: () => {
      analytics.logEvent(GeneralTrackingEvent.ModalClosed, {
        modalName,
        ...options.context,
      });
      options.onClose?.();
    },
  });
}
