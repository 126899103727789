import { Box, Button, Center, SimpleGrid } from '@chakra-ui/react';

import { ACILocation } from '@arena-labs/shared-models';

import { ACILocations } from './aci-questions';

export type CheckInLocationButtonsProps = {
  compact?: boolean;
  value?: ACILocation | null;
  onClick: (location: ACILocation) => void;
};

export function CheckInLocationButtons({
  compact,
  value,
  onClick,
}: CheckInLocationButtonsProps) {
  return (
    <Box role="group" w="full" color="gray.100">
      <SimpleGrid
        columns={compact ? 2 : { base: 2, md: 4 }}
        autoRows="1fr"
        spacingX="3"
        spacingY="2"
      >
        {ACILocations.map((loc) => (
          <Button
            variant="solid"
            colorScheme="primary"
            type="button"
            key={loc.value}
            onClick={() => onClick(loc.value)}
          >
            <Center
              textAlign="center"
              fontWeight={value === loc.value ? 'bold' : undefined}
            >
              {loc.display_name}
            </Center>
          </Button>
        ))}
      </SimpleGrid>
    </Box>
  );
}
