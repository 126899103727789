import { useRef } from 'react';
import { shallowEqual } from '@xstate/react';
import { isEqual } from 'lodash-es';

type Comparator<T> = (a: T, b: T) => boolean;

/**
 * A custom hook that memoizes a value based on a custom comparator function.
 */
export function useCompareFnMemo<T>(value: T, comparator: Comparator<T>): T {
  const ref = useRef<T>(value);

  if (!comparator(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

/**
 * A custom hook that memoizes a value based on a deep comparison.
 */
export function useDeepCompareMemo<T>(value: T): T {
  return useCompareFnMemo(value, isEqual);
}

/**
 * A custom hook that memoizes a value based on a shallow comparison.
 */
export function useShallowCompareMemo<T>(value: T): T {
  return useCompareFnMemo(value, shallowEqual);
}
