import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';
import { appGatesSchema } from './app-gate';
import {
  pillarAudioSchema,
  pillarVideoSchema,
  playableAudioSchema,
  playableVideoSchema,
} from './media.schema';
import { pillarPracticeSchema, pillarSchema } from './pillars.schema';
import { playlistSchema } from './playlist.schema';
import { learningStateSchema, streakSchema } from './user-profile';

export const homepageSchema = z.object({
  score: z.number(),
  learning_state: learningStateSchema,
  learning_session: z
    .object({
      order: z.number(),
      start_time: zIsoDateTime,
      end_time: zIsoDateTime.nullable(),
      next_session_starts_at: zIsoDateTime.nullable().default(null),
      is_final_session: z.boolean().default(false),
      next_pillar_title: z.string().nullable(),
      is_final_session_within_pillar: z.boolean().default(false),
      state: z.enum([
        'in_progress',
        'complete',
        'select_pillar',
        'select_practice',
      ]),
      aci: z.object({
        force_aci: z.boolean(),
        image: z.string().nullable(),
      }),
      media_items: z.array(
        pillarAudioSchema.or(pillarVideoSchema).or(
          z.object({
            type: z.literal('loom'),
            loom_id: z.string(),
            watched: z.boolean(),
          }),
        ),
      ),
      practices: z.array(pillarPracticeSchema),
    })
    .optional()
    .nullable(),

  pillar_data: z
    .object({
      current_pillar: pillarSchema,
      session: z.number(),
    })
    .nullable(),
  onboard_data: z.object({
    welcome: z.object({
      title: z.string(),
      description: z.string(),
    }),
    welcome_video: playableVideoSchema.nullable(),
    goals: z.record(
      z.string().transform((id) => Number(id)),
      z.string(),
    ),
    slides: z.array(
      z.object({
        title: z.string(),
        content: z.string(),
        image: z.string(),
        alt_text: z.string(),
      }),
    ),
  }),
  streak: streakSchema.omit({
    days_since_learning_journey_started: true,
    total_acis: true,
  }),

  complete_data: z
    .object({
      explorations_audio: playableAudioSchema.nullable(),
      program_data: playlistSchema.nullable(),
    })
    .nullable(),
  app_gate: appGatesSchema.nullable().catch(null),
  can_submit_aci: z.boolean(),
  recent_aci_timestamp: zIsoDateTime.optional().nullable(),
  aci: z.object({
    force_aci: z.boolean(),
    image: z.string().nullable(),
  }),
  team_video_data: playableVideoSchema
    .extend({
      description: z.string(),
    })
    .nullable()
    .catch(null),
});

export const homepageApi = makeApi([
  {
    alias: 'getHomepage',
    description: 'Fetch the homepage',
    method: 'get',
    path: '/homepage/',
    response: homepageSchema,
  },
]);
