import { useForm, useWatch } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

import { useImpersonateMutation } from './admin-mutations';
import { ImpersonateData, impersonateSchema } from './admin-schema';

export type AdminImpersonateProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminImpersonate({
  formId,
  onSuccess,
  ...props
}: AdminImpersonateProps) {
  const impersonate = useImpersonateMutation();
  const { data: user } = useUserProfile();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ImpersonateData>({
    resolver: yupResolver(impersonateSchema),
    defaultValues: {
      type: 'myself',
    },
  });

  const type = useWatch({
    control,
    name: 'type',
  });

  const onSubmit = async ({ type, user_id, email }: ImpersonateData) => {
    try {
      if (type === 'myself' && user) {
        type = 'user_id';
        await impersonate.mutateAsync({ user_id: user.id });
      } else if (type === 'user_id' && user_id) {
        await impersonate.mutateAsync({ user_id: user_id });
      } else if (type === 'email' && email) {
        await impersonate.mutateAsync({ username: email });
      } else {
        throw new Error('Invalid impersonate data');
      }
      toast({ title: 'Impersonating user', status: 'success' });
      onSuccess();
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <Text fontStyle="italic">Who do you want to impersonate?</Text>
      <RadioGroup defaultValue={type} colorScheme="brand">
        <VStack spacing="2" flexWrap="wrap" align="start">
          <Radio value="myself" {...register('type')}>
            Myself
          </Radio>
          <Radio value="user_id" {...register('type')}>
            User id
          </Radio>
          <Radio value="email" {...register('type')}>
            Email
          </Radio>
        </VStack>
      </RadioGroup>

      {type === 'user_id' ? (
        <FormControl
          key="user_id"
          id="impersonate-user_id"
          isInvalid={Boolean(errors.user_id)}
        >
          <FormLabel fontWeight="bold" mb="0">
            USER ID
          </FormLabel>
          <Input
            type="text"
            inputMode="numeric"
            autoComplete="none"
            {...register('user_id')}
          />
          <FormErrorMessage>{errors.user_id?.message}</FormErrorMessage>
        </FormControl>
      ) : type === 'email' ? (
        <FormControl
          key="email"
          id="impersonate-email"
          isInvalid={Boolean(errors.email)}
        >
          <FormLabel fontWeight="bold" mb="0">
            EMAIL
          </FormLabel>
          <Input type="email" autoComplete="none" {...register('email')} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
      ) : null}
    </VStack>
  );
}
