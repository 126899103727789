import { createContext, useContext, useEffect } from 'react';
import { ZodiosBodyByAlias } from '@zodios/core';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  AppGateConfig,
  AppGateType,
  useHomepage,
} from '@arena-labs/strive2-coaching';
import { $API, striveAppApi } from '@strive/api';
import { useSuspendMedia } from '@strive/av';

import { ACIReminder } from '../aci/aci-reminder';
import { TypeformModal } from '../home/typeform-modal';
import { CompetitionCompletionModal } from '../incentives/competition/completion-modal';
import RewardGate from './reward-gate';

export type AppGateListenerProps = {
  children: React.ReactNode;
};

// Provide a context hook to know whether a gate or post-gate is open
const AppGateContext = createContext<boolean>(false);
export function useAppGateIsOpen() {
  return useContext(AppGateContext);
}

// AppGateViews are react components with these props:
export type AppGateProps<GateType extends AppGateType> = {
  appGate: AppGateConfig<GateType>;
  updateGates: (
    data: ZodiosBodyByAlias<typeof striveAppApi, 'updateAppGate'>,
  ) => Promise<unknown>;
};

export function AppGateListener({ children }: AppGateListenerProps) {
  // Get the app gate from the home screen data
  const [homepage, { refresh: refreshHomepage }] = useHomepage();
  const appGate = homepage?.app_gate;
  const analytics = useAnalytics();

  useEffect(() => {
    appGate &&
      analytics.logEvent(GeneralTrackingEvent.AppGateOpened, {
        appGate: appGate?.type,
        appGateSlug: appGate?.slug,
        appGateProperties: appGate?.properties,
      });
  }, [analytics, appGate]);

  const updateGate = $API.useUpdateAppGate(
    {},
    {
      onSettled: () => {
        analytics.logEvent(GeneralTrackingEvent.AppGateClosed, {
          appGate: appGate?.type,
          appGateSlug: appGate?.slug,
          appGateProperties: appGate?.properties,
        });
        refreshHomepage();
      },
    },
  );

  const skipCurrentGate = () => {
    updateGate.mutateAsync({ slug: appGate?.slug ?? '', skipped: true });
  };

  // Suspend media playback while any gate is present
  useSuspendMedia(Boolean(appGate?.type));

  return (
    <AppGateContext.Provider value={Boolean(appGate?.type)}>
      <>
        {children}
        {appGate?.type === 'typeform_gate' ? (
          <TypeformGate
            appGate={appGate}
            updateGates={updateGate.mutateAsync}
          />
        ) : appGate?.type === 'reminder_prefs' ? (
          <ACIReminder
            isOpen
            canSkip={appGate.can_skip}
            isLoading={updateGate.isLoading}
            isError={updateGate.isError}
            onSkip={skipCurrentGate}
            onSubmit={(selected_time) =>
              updateGate.mutateAsync({
                type: appGate.type,
                slug: appGate.slug,
                preferences: { selected_time },
              })
            }
          />
        ) : appGate?.type === 'reward_gate' ? (
          <RewardGate
            key={appGate.slug}
            appGate={appGate}
            updateGates={updateGate.mutateAsync}
          />
        ) : appGate?.type === 'competition_completed' ? (
          <CompetitionCompletionModal
            isOpen
            onClose={() =>
              updateGate.mutateAsync({ slug: appGate.slug, skipped: true })
            }
            stats={appGate.properties}
          />
        ) : null}
      </>
    </AppGateContext.Provider>
  );
}

function TypeformGate({ appGate, updateGates }: AppGateProps<'typeform_gate'>) {
  const saveResponseId = (responseID: string) => {
    updateGates({
      type: 'typeform_gate',
      slug: appGate.slug,
      response_id: responseID,
    });
  };

  return (
    <TypeformModal
      autoClose={1}
      allowClose={false}
      formId={
        appGate.properties?.assessment_id
          ? appGate.properties.assessment_id
          : ''
      }
      isOpen={appGate.type === 'typeform_gate'}
      onSubmitted={({ responseId }) => saveResponseId(responseId)}
      onClose={() => {
        return null;
      }}
      source={'appGate'}
    />
  );
}
