import { useCallback } from 'react';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';

import { LooseAutocomplete } from '@arena-labs/shared-models';
import { assert } from '@strive/utils';

type AppName = keyof typeof appUrls;

const platform = Capacitor.getPlatform();

const appUrls = {
  whoop: {
    scheme: 'whoop',
    packageName: 'com.whoop.android',
    launchUrl: 'https://app.whoop.com/OverviewPillar',
    appStore:
      'https://apps.apple.com/us/app/whoop-performance-optimization/id933944389',
    playStore:
      'https://play.google.com/store/apps/details?id=com.whoop.android',
  },
  oura: {
    scheme: 'oura',
    packageName: 'com.ouraring.oura',
    launchUrl: 'oura://v1/route/home',
    appStore: 'https://apps.apple.com/us/app/oura/id1043837948',
    playStore:
      'https://play.google.com/store/apps/details?id=com.ouraring.oura',
  },
};

function isAppName(name: string): name is AppName {
  return name in appUrls;
}

/**
 * Checks if the specified app is installed on the device
 */
export async function isAppInstalled(
  appName: LooseAutocomplete<AppName>,
): Promise<boolean> {
  if (platform === 'web' || !isAppName(appName)) return false;

  const urls = appUrls[appName];
  const url = platform === 'ios' ? `${urls.scheme}://` : urls.packageName;

  const { value: hasApp } = await AppLauncher.canOpenUrl({ url });
  console.debug('isAppInstalled', { appName, url, hasApp });
  return hasApp;
}

/**
 * Try to open the specified app, or redirect to the app store if not installed
 */
export async function openApp(name: LooseAutocomplete<AppName>) {
  assert(isAppName(name), `Invalid app name: ${name}`);

  const urls = appUrls[name];
  const hasApp = await isAppInstalled(name);
  if (hasApp || (!urls.scheme && urls.launchUrl.startsWith('http'))) {
    const url = appUrls[name].launchUrl;
    return AppLauncher.openUrl({ url });
  }
  openAppStore(name);
}

/**
 * Open the app store link for the specified app
 */
export function openAppStore(name: LooseAutocomplete<AppName>) {
  assert(isAppName(name), `Invalid app name: ${name}`);

  const storeUrl =
    platform === 'android' ? appUrls[name].playStore : appUrls[name].appStore;
  window.open(storeUrl, '_blank');
}

/**
 * Hook to intercept link clicks and attempt to open apps
 */
export function useAppLinkInterceptor() {
  const handleAppLinkClick = useCallback((event: React.MouseEvent) => {
    let el = event.target as HTMLElement | null;

    // Traverse up the DOM tree to find the closest <a> tag
    while (el && el.tagName !== 'A') {
      el = el.parentElement;
    }

    // If we found an <a> tag
    if (el && el.tagName === 'A') {
      const anchorEl = el as HTMLAnchorElement;
      const appName = getAppNameFromUrl(anchorEl.href);
      if (appName) {
        event.preventDefault();
        openApp(appName);
      }
    }
  }, []);

  return handleAppLinkClick;
}

/**
 * Check if the given URL corresponds to one of the known apps
 */
function getAppNameFromUrl(url: string) {
  const parsedUrl = new URL(url);
  return (Object.keys(appUrls) as AppName[]).find((appName) => {
    const app = appUrls[appName];
    const appUrl = new URL(app.launchUrl);
    // If the protocol is http(s), then check if the hostname matches
    // Otherwise, check if the protocol matches
    return parsedUrl.protocol.match(/^https?:/)
      ? parsedUrl.hostname === appUrl.hostname
      : parsedUrl.protocol === appUrl.protocol;
  });
}
