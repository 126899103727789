import { ReactEventHandler, useEffect, useState } from 'react';
import {
  BoxProps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { VideoJsPlayer } from 'video.js';

import { Icon, IconProps, SliderThumbIcon } from '@arena-labs/strive2-ui';
import { haptics } from '@strive/device';

import { useMediaScrubberValue } from './media-scrubber.machine';

type MediaScrubberProps = {
  player: VideoJsPlayer;
  color?: string;
  thumbSize?: BoxProps['boxSize'];
  thumbIconProps?: Partial<IconProps>;
};

export function MediaScrubber({
  player,
  color,
  thumbSize = 4,
  thumbIconProps,
}: MediaScrubberProps) {
  const buffered = useBufferedTime(player);
  const currentTime = player.currentTime();
  let duration = player.duration();
  let value = currentTime / duration;
  if (Number.isNaN(duration) || duration <= 0) {
    duration = 0;
    value = 0;
  }

  const [sliderValue, changeHandlers] = useMediaScrubberValue(
    value,
    (value: number) => {
      player.currentTime(value * player.duration());
    },
  );

  const percentBuffered = Math.floor((buffered / duration) * 100);

  return (
    <Slider
      className="swiper-no-swiping"
      step={0.005}
      value={sliderValue}
      max={1}
      focusThumbOnChange={false}
      onChangeStart={() => {
        haptics.selectionStart();
        haptics.impact('LIGHT');
      }}
      onChange={(val) => {
        changeHandlers.onChange(val);
        const getStep = (val: number) => Math.floor((100 * val) / 5);
        if (getStep(val) !== getStep(sliderValue)) {
          haptics.selectionChanged();
        }
      }}
      onChangeEnd={(val) => {
        changeHandlers.onChangeEnd(val);
        haptics.selectionEnd();
      }}
    >
      <SliderTrack
        bgGradient="linear(to right, elevation.24dp 0 50%, neutral.400 50% 100%)"
        bgSize="200%"
        bgPosition={`-${percentBuffered}%`}
        transition="background-position 0.2s"
      >
        <SliderFilledTrack bg={color} />
      </SliderTrack>
      <SliderThumb boxSize={thumbSize} bg="transparent">
        <Icon
          as={SliderThumbIcon}
          size="full"
          color={color}
          {...thumbIconProps}
        />
      </SliderThumb>
    </Slider>
  );
}

/**
 * For more information about the `buffered` property, and the returned timeranges object,
 * See https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/buffering_seeking_time_ranges
 *
 * Essentially here we're looking for the time range which encloses the "currentTime" from the player
 * We return the "end" of that range, and can use that to display how much is buffered.
 */
function useBufferedTime(player: VideoJsPlayer) {
  const [bufferedEnd, setBufferedEnd] = useState(0);

  useEffect(() => {
    const onProgress: ReactEventHandler<HTMLAudioElement | HTMLVideoElement> = (
      event,
    ) => {
      const currentTime = player.currentTime();
      const buffered = player.buffered();

      // find the most recent range that contains the currentTime
      let rangeEnd = 0;
      for (let i = 0; i < buffered.length; i++) {
        if (
          buffered.start(i) <= currentTime &&
          buffered.end(i) >= currentTime
        ) {
          rangeEnd = buffered.end(i);
          break;
        }
      }
      setBufferedEnd(rangeEnd);
    };

    player.on('progress', onProgress);
    return () => {
      player.off('progress', onProgress);
    };
  }, [player, setBufferedEnd]);

  return bufferedEnd;
}
