import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { pillarListItemSchema, pillarSchema } from './pillars.schema';

export const pillarsApi = makeApi([
  {
    alias: 'getPillars',
    description: 'Fetch the pillars',
    method: 'get',
    path: '/content/pillars/',
    response: z.array(pillarListItemSchema),
  },
  {
    alias: 'getPillar',
    description: 'Fetch a pillar',
    method: 'get',
    path: '/content/pillars/:slug/',
    parameters: [
      {
        type: 'Path',
        name: 'slug',
        description: 'The slug of the pillar',
        schema: z.string(),
      },
    ],
    response: pillarSchema,
  },
  {
    alias: 'selectPillar',
    description: 'Set the next pillar/practice in the learning journey',
    method: 'post',
    path: '/content/pillars/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ selected_pillar_slug: z.string() }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: 'getScorecard',
    description: 'Fetch the scorecard',
    method: 'get',
    path: '/perfect_day/',
    response: z.object({
      aci: z.object({
        completed: z.number(),
        target: z.number(),
      }),
      learnings: z.object({
        completed: z.number(),
        target: z.number(),
      }),
      practice: z.object({
        completed: z.number(),
        target: z.number(),
      }),
    }),
  },
]);
