import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { getDataStoreProvider } from './providers';
import {
  DataStore,
  DataStoreMutation,
  DataStoreQuery,
  FallbackFromSchema,
  Prettify,
  StoredDataConfig,
  UndefinedToNull,
  WithoutFallback,
} from './types';

/**
 * Create a data store. You can use this directly or pass it to
 * useDataStore()
 *
 * Data is automatically serialized and deserialized, and a zod schema
 * is used to validate the data.
 * You can supply a `fallback` value in case the preference is not set,
 * or there is an error reading it.
 *
 * @example
 *   const store = createDataStore({
 *     key: 'Test',
 *     schema: z.string(),
 *     fallback: 'Hello',
 *   })
 *   await store.set('Hello')
 *   await store.get() // 'Hello'
 */
export function createDataStore<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema> = Prettify<
    FallbackFromSchema<Schema>
  >,
>(params: StoredDataConfig<Schema, Fallback>): DataStore<Schema, Fallback> {
  const { key, schema, fallback, provider } = params;

  return {
    key,
    schema,
    fallback,
    provider,
    staleTime: params.staleTime ?? Infinity,
    async get(): Promise<z.infer<Schema> | Fallback> {
      let value: unknown;
      const store = getDataStoreProvider(provider);
      try {
        // Load & parse the store data
        const stored = await store.getItem(key);

        if (
          stored?.timestamp &&
          stored.timestamp + this.staleTime < Date.now()
        ) {
          // If the data is stale, remove it
          store.removeItem(key);
          value = undefined;
        } else {
          value = stored?.value;
        }
      } catch (e) {
        console.error(`Error getting preference ${key}`, e);
      }

      // Validate it
      const parsed = schema.safeParse(value);
      if (parsed.success) {
        return parsed.data;
      } else {
        if (params.persistFallback) {
          this.set(fallback);
        }
        return fallback;
      }
    },

    async set(value?: z.infer<Schema>) {
      schema.parse(value);
      const store = getDataStoreProvider(provider);
      await store.setItem(key, value);
    },

    async remove() {
      const store = getDataStoreProvider(provider);
      try {
        await store.removeItem(key);
      } catch (e) {
        console.error(`Error removing preference ${key}`, e);
      }
    },
  };
}

/**
 * Returns a react query query and mutation for a preference.
 *
 * @example
 *  useDataStore({ key: 'Test', schema: z.unknown(), fallback: 'Hello' })
 *
 * @example
 *  const store = createDataStore({ ... })
 *  useDataStore(store)
 */
export function useDataStoreQuery<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema>,
>(params: StoredDataConfig<Schema, Fallback> | DataStore<Schema, Fallback>) {
  let store: DataStore<Schema, Fallback>;
  const { key, schema, fallback, provider = 'default', ...rest } = params;

  if ('get' in params) {
    store = params;
  } else {
    store = createDataStore({ key, schema, fallback, ...rest });
  }

  const queryClient = useQueryClient();
  const queryKey = ['DataStore', provider, key];

  // Query
  // the queryfn needs to return null instead of undefined
  const query = useQuery<UndefinedToNull<z.infer<Schema> | Fallback>>({
    queryKey,
    queryFn: async () => (await store.get()) ?? null,
  });

  // Mutation
  const mutation = useMutation({
    mutationFn: (value) => {
      return value == null ? store.remove() : store.set(value);
    },
    onMutate: async (value: z.infer<Schema> | null | undefined) => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, value ?? null);
    },
    onError: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return [query, mutation] as const;
}

/**
 * A `useState`-like interface to the data store queries
 *
 * We have 2 overloads here, so we can provide good type inference of the value
 * based on whether there is a fallback provided
 */
export function useDataStore<Schema extends z.ZodTypeAny>(
  params: WithoutFallback<Schema>,
): [
  UndefinedToNull<undefined | z.TypeOf<Schema>> | undefined,
  DataStoreMutation<Schema>['mutate'],
  DataStoreQueries<Schema, undefined>,
];

export function useDataStore<
  Schema extends z.ZodTypeAny,
  Fallback extends Exclude<FallbackFromSchema<Schema>, undefined>,
>(
  params: StoredDataConfig<Schema, Fallback> | DataStore<Schema, Fallback>,
): [
  Fallback | z.TypeOf<Schema>,
  DataStoreMutation<Schema>['mutate'],
  DataStoreQueries<Schema, Fallback>,
];

export function useDataStore<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema>,
>(params: StoredDataConfig<Schema, Fallback> | DataStore<Schema, Fallback>) {
  const [query, update] = useDataStoreQuery(params);
  return [
    query.data ?? params.fallback,
    update.mutate,
    { query, update },
  ] as const;
}

type DataStoreQueries<
  Schema extends z.ZodTypeAny,
  Fallback extends FallbackFromSchema<Schema>,
> = {
  query: DataStoreQuery<Schema, Fallback>;
  update: DataStoreMutation<Schema>;
};
