import axios from 'axios';

import { apiHost } from '../constants';

/**
 * A raw API client to connect to the Strive API
 * For an authenticated client, see `authClient`
 */
export const backend = axios.create({
  baseURL: apiHost,
});
