import { forwardRef } from 'react';
import {
  Badge,
  Box,
  BoxProps,
  Fade,
  Grid,
  Heading,
  IconButton,
} from '@chakra-ui/react';

import { PlayableAudio, PlayableMedia } from '@arena-labs/shared-models';
import { CloseIcon, Icon } from '@arena-labs/strive2-ui';

import { AudioPlayer } from '../audio-player';
import { MediaHandleRef } from '../media-handle';
import { EndPromptDisplay, EndPromptDisplayProps } from './end-prompt-display';

type MediaQueueAudioProps = {
  mode: 'strict' | 'loose';
  audio: PlayableAudio;
  thumbnailWidth?: number;
  isFullScreen?: boolean;
  isActive?: boolean;
  autoPlay?: boolean;
  onEnded?: () => void;
  onClose?: () => void;
  boxProps?: BoxProps;
  beforeContinue?: () => Promise<unknown>;
  nextMedia?: PlayableMedia;
  showEndPrompt?: boolean;
  endPrompt?: EndPromptDisplayProps['render'];
};

export const MediaQueueAudio = forwardRef<MediaHandleRef, MediaQueueAudioProps>(
  function MediaQueueAudio({ audio, boxProps, ...props }, ref) {
    return (
      <Grid
        gap="4"
        templateRows="auto 1fr"
        position="relative"
        height="100%"
        p={props.isFullScreen ? 4 : 0}
        pt={props.isFullScreen ? 'max(8px, env(safe-area-inset-top))' : 0}
        {...boxProps}
      >
        <Grid templateColumns="1fr 60px" gap="2">
          <Box>
            <Heading as="h2" fontSize="xl">
              {audio.title}
            </Heading>
            <Fade in={audio.played}>
              <Badge colorScheme="whiteAlpha">Played</Badge>
            </Fade>
          </Box>

          {props.mode === 'loose' && props.onClose ? (
            <IconButton
              variant="unstyled"
              aria-label="Close"
              onClick={() => props.onClose?.()}
            >
              <Icon as={CloseIcon} size="6" />
            </IconButton>
          ) : null}
        </Grid>
        <AudioPlayer
          ref={ref}
          key={audio.slug}
          duration={audio.duration}
          playBackID={audio.playback_id}
          transcript={audio.transcript}
          onEnded={props.onEnded}
          autoPlay={props.autoPlay}
        >
          {(player) =>
            props.showEndPrompt && (
              <EndPromptDisplay
                player={player}
                media={audio}
                nextMedia={props.nextMedia}
                isActive={props.isActive}
                beforeContinue={props.beforeContinue}
                onClose={props.onClose}
                render={props.endPrompt}
              />
            )
          }
        </AudioPlayer>
      </Grid>
    );
  },
);
