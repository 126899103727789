/**
 * Return the correct singular/plural form of a word based on the count
 */
export function pluralize(
  count: number,
  singular: string,
  plural?: string,
): string {
  return count === 1 ? singular : plural || singular + 's';
}
