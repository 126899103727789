import { MouseEventHandler } from 'react';
import NextLink from 'next/link';
import { Icon, Link, VisuallyHidden } from '@chakra-ui/react';

import { ChevronLeftIcon } from '../icons';
import { useBackButtonWithFallback } from '../utils/router-history';

type BackLinkProps = {
  href: string;
  color: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export function BackLink({ href, color, onClick }: BackLinkProps) {
  const goBack = useBackButtonWithFallback(href);

  return (
    <Link
      href={href}
      as={NextLink}
      p="2"
      ml="-2"
      display="inline-flex"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        if (e.defaultPrevented) {
          return;
        }

        e.preventDefault();
        goBack();
      }}
    >
      <VisuallyHidden>Back to previous page</VisuallyHidden>
      <Icon as={ChevronLeftIcon} boxSize={4} color={color} />
    </Link>
  );
}
