import { useRef, useState } from 'react';
import { Dialog } from '@capacitor/dialog';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  chakra,
  Code,
  Divider,
  Flex,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  MutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import color from 'color';

import {
  formatRelativeTime,
  Markdown,
  SettingsIcon,
  toast,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $RESOURCES, getResourcesS3Url, resourcesS3Client } from '@strive/api';
import { TimeoutError, withTimeout } from '@strive/utils';

import { useAppInfo } from '../../lib/use-app-info';

const channels: Array<{ id: string; name?: string; description: string }> = [
  {
    id: 'production',
    description: 'The most recent _live update_ available to users',
  },
  {
    id: 'prerelease',
    description: 'The release candidate for pre-release testing',
  },
  {
    id: 'staging',
    description: 'The latest code for the *staging* environment',
  },
  {
    id: 'be-compat',
    description:
      '_Test what happens for current users when we release the backend._',
  },
  {
    id: 'fe-compat',
    description: '_Test what happens if we only release the frontend._',
  },
  { id: 'dev', description: 'Ad-hoc development channel' },
];

export function AdminCapgoChannel() {
  const openPRs = $RESOURCES.useGetOpenPRs();

  const currentChannel = useCapgoChannel();
  const isProduction =
    (currentChannel.isSuccess && currentChannel.data === 'production') ||
    currentChannel.data === undefined;

  const [switchProgress, setSwitchProgress] = useState<string>();
  const switchChannel = useCapgoChannelMutation({
    onProgress: setSwitchProgress,
  });

  const handleSubmit = (channel: string) => async () => {
    if (switchChannel.isLoading && switchChannel.variables === channel) return;

    try {
      await switchChannel.mutateAsync(channel);
      toast({ title: 'Channel switched', status: 'success' });
    } catch (e) {
      console.error('🔀 Error switching channel', e);
      toast({ title: 'Channel switch failed', status: 'error' });
    }
  };

  const confirmReset = async () => {
    const { value } = await Dialog.confirm({
      title: 'Reset to app store version?',
      message:
        'This will reset the app to the version in the app store. Are you sure?',
    });
    if (value) {
      CapacitorUpdater.reset();
    }
  };

  const app = useAppInfo();

  return (
    <VStack spacing="4" align="stretch" layerStyle="24dp">
      <Flex
        direction="column"
        position="sticky"
        top="0"
        bg="inherit"
        boxShadow="0dp"
        py="2"
        gap="2"
      >
        <Text>
          Current version:{' '}
          <Text as="span" color="white" fontWeight="bold">
            {app?.capgoVersion || process.env.VERSION}
          </Text>
        </Text>
        <Text>
          Current channel:{' '}
          <Text as="span" color="white" fontWeight="bold">
            <WaitForQuery
              query={currentChannel}
              loading="Checking..."
              error="Unknown!"
            >
              {(channel) => String(channel)}
            </WaitForQuery>
          </Text>
        </Text>
        {switchProgress && (
          <Text
            px="3"
            py="2"
            fontStyle="italic"
            bg="gray.800"
            border="1px solid"
            borderColor="gray.700"
            borderRadius="md"
          >
            {switchProgress}
          </Text>
        )}
      </Flex>

      <Flex direction="column" gap="4" align="stretch">
        <Flex direction="column" gap="2" align="stretch">
          {channels.map((channel) => (
            <ChannelButton
              key={channel.id}
              channel={channel.id}
              onSelect={handleSubmit(channel.id)}
              description={channel.description}
              isCurrent={
                currentChannel.data === channel.id ||
                (isProduction && channel.id === 'production')
              }
            />
          ))}
          <WaitForQuery query={openPRs}>
            {(prs) =>
              prs.map((pr) => {
                const prChannel = `preview-${pr.number}`;
                return (
                  <ChannelButton
                    key={pr.number}
                    onSelect={handleSubmit(prChannel)}
                    channel={prChannel}
                    description={pr.title}
                    isCurrent={currentChannel.data === prChannel}
                  />
                );
              })
            }
          </WaitForQuery>
        </Flex>

        <Accordion
          allowToggle
          position="sticky"
          bottom="0"
          layerStyle="24dp"
          pt="2"
        >
          <AccordionItem layerStyle="8dp">
            <AccordionButton gap="2">
              <Icon as={SettingsIcon} />{' '}
              <Text textStyle="p2" fontWeight="bold">
                Advanced
              </Text>
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <AccordionPanel>
              <Button
                colorScheme="danger"
                alignSelf="start"
                onClick={() => confirmReset()}
              >
                Reset to built-in ({app?.nativeVersion})
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </VStack>
  );
}

type ChannelButtonProps = {
  channel: string;
  description: string;
  isCurrent?: boolean;
  onSelect: () => void;
};

function ChannelButton({
  channel,
  description,
  isCurrent,
  onSelect,
}: ChannelButtonProps) {
  const channelLatest = $RESOURCES.useGetReleaseChannel({
    params: { name: channel },
  });

  const fallback = (
    <Flex direction="column" gap="2" w="full" textStyle="p3">
      <Text gridArea="channel" textStyle="p1_bold" color="secondary.700">
        {channel}
      </Text>
      <Markdown fontSize="p1">{description}</Markdown>
    </Flex>
  );

  return (
    <chakra.button
      type="button"
      borderRadius="button"
      borderWidth={isCurrent ? '2px' : '1px'}
      borderColor={isCurrent ? 'secondary.700' : 'neutral.200'}
      bg="elevation.8dp"
      display="flex"
      p="2"
      textAlign="left"
      onClick={onSelect}
    >
      <WaitForQuery query={channelLatest} error={fallback} loading={fallback}>
        {(latest) => {
          const channelTitle =
            latest.type === 'pull_request'
              ? latest.title
              : latest.type === 'release'
              ? latest.name
              : latest.commit.title;
          return (
            <Flex direction="column" gap="2" w="full" textStyle="p3">
              <Flex gap="2" justify="space-between">
                <Text
                  gridArea="channel"
                  textStyle="p1_bold"
                  color="secondary.700"
                >
                  {channel}
                </Text>
                <Text>{formatRelativeTime(latest.date)}</Text>
              </Flex>

              <Markdown fontSize="p1">{channelTitle}</Markdown>

              {latest.type === 'pull_request' && latest.labels.length ? (
                <Flex gap="2" wrap="wrap">
                  {latest.labels.map((label, id) => {
                    const labelColor = color(`#${label.color}`);
                    const bg = labelColor.alpha(0.18);
                    const fg = labelColor.lightness(60);
                    const border = fg.alpha(0.3);
                    return (
                      <Badge
                        key={id}
                        bg={bg.toString()}
                        color={fg.toString()}
                        borderColor={border.toString()}
                        borderWidth="1px"
                        borderRadius="full"
                        py="1"
                        px="2"
                        fontSize="p3"
                        variant="outline"
                      >
                        {label.name}
                      </Badge>
                    );
                  })}
                </Flex>
              ) : null}

              <Link
                href={latest.url}
                variant="underline"
                color="tertiary.300"
                isExternal
                onClick={(e) => e.stopPropagation()}
              >
                {latest.url}
              </Link>

              {latest.type !== 'pull_request' && (
                <Markdown fontSize="p2">{description}</Markdown>
              )}
              <Divider />

              {latest.type === 'pull_request' && <Text>{latest.branch}</Text>}
              <Flex gap="2">
                <Text>{latest.commit.sha.slice(0, 8)}</Text>
                <Text>[{latest.commit.author}]</Text>
                <Text isTruncated>{latest.commit.title}</Text>
              </Flex>

              <Code
                as="span"
                alignSelf="end"
                sx={{ textWrap: 'balance' }}
                colorScheme="primary"
                fontSize="p2"
                isTruncated
              >
                {latest.version}
              </Code>
            </Flex>
          );
        }}
      </WaitForQuery>
    </chakra.button>
  );
}

type UseCapgoChannelMutationOptions = MutationOptions<void, unknown, string> & {
  onProgress?: (update: string) => void;
};

const CapgoChannelQueryKey = ['capgo', 'channel'];
export function useCapgoChannel() {
  return useQuery({
    queryKey: CapgoChannelQueryKey,
    queryFn: () => withTimeout(5_000, CapacitorUpdater.getChannel()),
    select: (res) => res.channel,
    retry: 5,
  });
}

export function useCapgoChannelMutation({
  onProgress,
  ...options
}: UseCapgoChannelMutationOptions = {}) {
  const queryClient = useQueryClient();

  // Keep track of the most recent channel the user requested
  const mostRecentChannel = useRef<string>();

  const setChannel = useMutation({
    mutationFn: async (channel: string) => {
      onProgress?.('Setting Channel...');
      await withTimeout(10_000, CapacitorUpdater.setChannel({ channel }));
    },
    onMutate: (channel) => {
      queryClient.setQueryData(CapgoChannelQueryKey, { channel });
    },
    onError: () => {
      queryClient.invalidateQueries(CapgoChannelQueryKey);
    },
  });

  const getS3Latest = async (channel: string) => {
    onProgress?.(`Querying S3 latest version from in ${channel} channel...`);
    const latest = await resourcesS3Client.getReleaseChannel({
      params: { name: channel },
    });
    // Prerelease is a special case - the bundles are in the production channel
    const bundleChannel = channel === 'prerelease' ? 'production' : channel;
    const url = getResourcesS3Url(
      `/release/channel/${bundleChannel}/${latest.version}.zip`,
    );
    return {
      version: latest.version,
      url,
    };
  };

  const getCapgoLatest = async (channel: string) => {
    onProgress?.(`Querying Capgo latest version in ${channel} channel...`);
    const latest = await withTimeout(10_000, CapacitorUpdater.getLatest());
    return {
      version: latest.version,
      url: latest.url ?? '',
    };
  };

  const downloadAndInstall = async (
    latest: Awaited<ReturnType<typeof getS3Latest>>,
    channel: string,
  ) => {
    onProgress?.(`Downloading ${latest.version}...`);
    const dl = await CapacitorUpdater.download(latest);

    if (mostRecentChannel.current !== channel) return; // Abort: User switched channels again

    CapacitorUpdater.set({ id: dl.id });
  };

  return useMutation(
    async function mutateEnv(channel: string) {
      mostRecentChannel.current = channel;

      try {
        await setChannel.mutateAsync(channel);
      } catch (error) {
        console.warn(`🔀 Switchin to ${channel} channel failed:`, error);
        // Continue anyway, Capgo might be down, but we can still try to update from S3
      }

      if (mostRecentChannel.current !== channel) return; // Abort: User switched channels again

      try {
        const latest = await getS3Latest(channel).catch(() =>
          getCapgoLatest(channel),
        );

        if (mostRecentChannel.current !== channel) return; // Abort: User switched channels again
        await downloadAndInstall(latest, channel);
      } catch (error) {
        onProgress?.(String(error));
        throw error;
      }
    },
    {
      ...options,
      retry: (failureCount, error) =>
        failureCount < 5 && error instanceof TimeoutError,
      retryDelay: 1000,
    },
  );
}
