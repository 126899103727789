import { Capacitor } from '@capacitor/core';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import {
  useModalDisclosure,
  UseModalDisclosureOptions,
} from '@arena-labs/strive2-ui';

import { openSettingsPage } from './utils';

DeclinedNotificationPermissionModal.useDisclosure = (
  options?: UseModalDisclosureOptions,
) => useModalDisclosure('Declined Notification Permission', options);

export type DeclinedNotificationPermissionModalProps = Pick<
  ModalProps,
  'isOpen' | 'onClose' | 'closeOnEsc' | 'closeOnOverlayClick'
> & {
  onContinue: () => void;
};

export function DeclinedNotificationPermissionModal({
  onContinue,
  ...props
}: DeclinedNotificationPermissionModalProps) {
  const handleContinue = () => {
    props.onClose();
    onContinue();
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      variant="bottom-sheet"
      motionPreset="slideInBottom"
      {...props}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Declined Notification Permission</ModalHeader>

        <ModalBody>
          <p>
            Are you sure you want to turn off notifications? You can enable them
            in your phone settings if needed!
          </p>
        </ModalBody>

        <ModalFooter
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap="4"
        >
          <Button variant="secondary" w="full" onClick={handleContinue}>
            Continue
          </Button>
          {Capacitor.isPluginAvailable('NativeSettings') ? (
            <Button
              variant="primary"
              w="full"
              onClick={() => openSettingsPage()}
            >
              Open Settings
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
