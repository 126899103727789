import { Directory, Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import type { WritableKeys } from 'utility-types';
import { z } from 'zod';

import { assert } from '../assert';
import { deserialize, serialize } from './helpers';

// Define a type for the metadata
export type DataStoreMetadata = {
  version?: string;
  readonly timestamp?: number;
  readonly size: number;
};

// Update the DataStoreProvider interface
export type DataStoreProvider = {
  getItem: (
    key: string,
  ) => Promise<({ value: unknown | null } & DataStoreMetadata) | undefined>;
  setItem: (
    key: string,
    value: unknown,
    metadata?: Pick<DataStoreMetadata, WritableKeys<DataStoreMetadata>>,
  ) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  keys: () => Promise<string[] | undefined>;
  clear: () => Promise<void>;
};

const metadataSchema = z.object({
  value: z.any(),
  metadata: z.object({
    timestamp: z.number().optional(),
    version: z.string().optional(),
  }),
});

// Preferences -----------------------------------------

export class PreferencesDataStore implements DataStoreProvider {
  constructor(private namespace?: string) {}

  #key(key: string) {
    return this.namespace ? `${this.namespace}.${key}` : key;
  }

  async getItem(key: string) {
    const val = (await Preferences.get({ key: this.#key(key) })).value;
    if (val == null) return;

    const unpacked = deserialize(val);

    // Check if there's metadata
    const parsed = metadataSchema.safeParse(unpacked);
    if (parsed.success)
      return {
        ...parsed.data.metadata,
        value: parsed.data.value,
        size: val.length,
      };

    // Otherwise, just return the value
    return {
      value: unpacked,
      size: val.length,
    };
  }
  async setItem(
    key: string,
    value: unknown,
    metadata?: Pick<DataStoreMetadata, WritableKeys<DataStoreMetadata>>,
  ) {
    const encoded = serialize({
      value,
      metadata: { ...metadata, timestamp: Date.now() },
    });
    return await Preferences.set({ key: this.#key(key), value: encoded });
  }
  async removeItem(key: string) {
    return await Preferences.remove({ key: this.#key(key) });
  }
  async keys() {
    return (await Preferences.keys()).keys.map((key) =>
      this.namespace ? key.replace(`${this.namespace}.`, '') : key,
    );
  }
  async clear() {
    return await Preferences.clear();
  }
}

// SQLite -----------------------------------------

const sqliteGetValue = z.object({
  values: z
    .array(z.object({ value: z.string(), updated_at: z.number() }))
    .optional(),
});
const sqliteGetKeys = z.object({
  values: z.array(z.object({ keys: z.array(z.string()) })).optional(),
});
export class SQLiteDataStore implements DataStoreProvider {
  constructor(private db: SQLiteDBConnection, public table: string) {}

  async getItem(key: string) {
    const res = await this.db.query(
      `SELECT value, updated_at FROM ${this.table} WHERE key = ?`,
      [key],
    );

    const rowData = sqliteGetValue.parse(res).values?.[0];
    if (!rowData) return;

    return {
      value: deserialize(rowData.value),
      timestamp: rowData?.updated_at,
      size: rowData.value.length,
    };
  }
  async setItem(key: string, value: unknown) {
    await this.db.run(
      `INSERT OR REPLACE INTO ${this.table} (key, value) VALUES (?, ?)`,
      [key, serialize(value)],
    );
  }
  async removeItem(key: string) {
    await this.db.run(`DELETE FROM ${this.table} WHERE key = ?`, [key]);
  }
  async keys() {
    const res = await this.db.query(`SELECT key FROM ${this.table}`);
    return sqliteGetKeys.parse(res).values?.[0]?.keys;
  }
  async clear() {
    await this.db.run(`DELETE FROM ${this.table}`);
  }
}

// Filesystem -----------------------------------------
export class FilesystemDataStore implements DataStoreProvider {
  #initialized: Promise<boolean> | null = null;

  constructor(
    private namespace = 'store',
    private directory = Directory.Data,
  ) {}

  #initialize() {
    if (!this.#initialized) {
      // Initialise the directory if it doesn't exist
      // we do this only once
      this.#initialized = Filesystem.stat({
        path: this.namespace,
        directory: this.directory,
      })
        .catch(() =>
          Filesystem.mkdir({
            path: this.namespace,
            directory: this.directory,
            recursive: true,
          }),
        )
        .then(() => true);
    }

    return this.#initialized;
  }

  #filePath(key: string) {
    return `${this.namespace}/${key}`;
  }

  getConfig() {
    return {
      path: this.namespace,
      directory: this.directory,
    };
  }

  async getItem(key: string) {
    await this.#initialize();

    const fsOpts = {
      path: this.#filePath(key),
      directory: this.directory,
    };
    const [stat, data] = await Promise.all([
      Filesystem.stat(fsOpts),
      Filesystem.readFile(fsOpts),
    ]);

    const contents = Buffer.from(data.data as string, 'base64').toString(
      'utf-8',
    );
    const unpacked = deserialize(contents);

    const metadata = {
      timestamp: stat.mtime,
      size: contents.length,
    };

    // Check if there's additional metadata
    const parsed = metadataSchema.safeParse(unpacked);
    if (parsed.success) Object.assign(metadata, parsed.data.metadata);

    return {
      value: unpacked,
      ...metadata,
    };
  }
  async setItem(
    key: string,
    value: unknown,
    metadata?: Pick<DataStoreMetadata, WritableKeys<DataStoreMetadata>>,
  ) {
    await this.#initialize();

    const encoded = serialize(metadata ? { value, metadata } : value);

    await Filesystem.writeFile({
      path: this.#filePath(key),
      directory: this.directory,
      data: Buffer.from(encoded, 'utf-8').toString('base64'),
    });
  }
  async removeItem(key: string) {
    await this.#initialize();
    await Filesystem.deleteFile({
      path: this.#filePath(key),
      directory: this.directory,
    });
  }
  async keys() {
    await this.#initialize();
    const { files } = await Filesystem.readdir({
      path: this.namespace,
      directory: this.directory,
    });
    return files.map((file) => file.name.replace(`${this.namespace}/`, ''));
  }
  async clear() {
    await this.#initialize();
    await Filesystem.rmdir({
      path: this.namespace,
      directory: this.directory,
      recursive: true,
    });
    this.#initialized = null;
  }
}

// ------------------------

const providers = new Map<string, DataStoreProvider>([
  ['default', new PreferencesDataStore()],
]);

/**
 * Set/remove data store
 */
export function setDataStoreProvider(
  name: string,
  provider?: DataStoreProvider,
) {
  if (provider) {
    providers.set(name, provider);
  } else {
    providers.delete(name);
  }
}

export function getDataStoreProvider(name = 'default') {
  const provider = providers.get(name);
  assert(provider, `No data store provider named ${name}`);
  return provider;
}
