import { $API } from '@strive/api';

/**
 * Returns the number of biometrics that need attention
 */
export function useBiometricAlerts() {
  const biometrics = $API.useGetBiometrics();
  if (biometrics.status !== 'success') return;

  const { wearable, hrv, rhr, hours_of_sleep } = biometrics.data;
  if (wearable?.find((w) => Boolean(w.errors))) {
    return 1;
  }
  const actionsAvailable = [hrv, rhr, hours_of_sleep].filter(
    (biometric) =>
      biometric?.value_status === 'not ok' &&
      biometric?.action_status === 'ready',
  );

  return actionsAvailable.length;
}
