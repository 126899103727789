import 'swiper/css';
import 'swiper/css/effect-fade';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useReducedMotion } from 'framer-motion';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { $API, AppGateProps } from '@strive/api';

import { CompetitionCompletionReward } from './competition-completion-reward';
import { CompletionCompletionScreen } from './completion-screen';
import { CompetitionCompletionStats } from './completion-stats';

export type CompetitionCompletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  stats: AppGateProps<'competition_completed'>;
};

export function CompetitionCompletionModal({
  isOpen,
  onClose,
  stats,
}: CompetitionCompletionModalProps) {
  const prefersReducedMotion = useReducedMotion();
  const { data: incentives } = $API.useGetIncentives();
  const incentive = incentives?.[0];

  return (
    <Drawer isOpen={isOpen} size="full" placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody h="full" pt="env(safe-area-inset-top)" px="0">
          <Swiper
            style={{ width: '100%', height: '100%' }}
            allowTouchMove={false}
            effect={prefersReducedMotion ? 'fade' : 'slide'}
            modules={prefersReducedMotion ? [EffectFade] : undefined}
          >
            <SwiperSlide>
              {stats.is_winner ? (
                <CompetitionWinner />
              ) : (
                <CompetitionFinisher />
              )}
            </SwiperSlide>
            <SwiperSlide>
              <CompetitionCompletionStats onClose={onClose} stats={stats} />
            </SwiperSlide>
            {stats.is_winner &&
              incentive?.slug.includes('competition_gifts') && (
                <SwiperSlide>
                  <CompetitionCompletionReward onClose={onClose} />
                </SwiperSlide>
              )}
          </Swiper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function CompetitionWinner() {
  return (
    <CompletionCompletionScreen
      lottie={import('./winners.lottie.json')}
      messages={[
        {
          title: 'Congratulations!',
          body: "You're among the top five achievers in our Practice Excellence Program!",
        },
        {
          title: 'Event Details Await!',
          body: 'Expect a message about the event soon. Exciting times ahead! Stay tuned!',
        },
      ]}
    />
  );
}

function CompetitionFinisher() {
  return (
    <CompletionCompletionScreen
      lottie={import('./finishers.lottie.json')}
      messages={[
        {
          title: 'Well Done!',
          body: "We're grateful for your participation and dedication in the Strive Leaderboard!",
        },
      ]}
    />
  );
}
