import { Collapse, FormLabel, Switch, Text, VStack } from '@chakra-ui/react';

import { Card, HelpButton, useAppStateChange } from '@arena-labs/strive2-ui';

import { isPushEnabled } from '..';
import { usePushPermissions, usePushRequestPermissions } from './use-push';

export type TogglePushNotificationsProps = {
  isEnabled: boolean;
  onChange: (value: boolean) => void;
};

export function TogglePushNotifications({
  isEnabled,
  onChange,
}: TogglePushNotificationsProps) {
  const pushPermissions = usePushPermissions();
  const pushRequest = usePushRequestPermissions();

  const handleToggle = () => {
    const nextValue = !isEnabled;
    if (nextValue && pushPermissions.data === 'prompt') {
      pushRequest.mutate();
    }
    onChange(nextValue);
  };

  useAppStateChange((active) => {
    if (active && !pushPermissions.isLoading) {
      pushPermissions.refetch();
    }
  });

  return (
    <Card colorScheme="dark" w="full">
      <Card.Heading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormLabel fontWeight="bold" fontSize="lg" m="0" verticalAlign="middle">
          Push Notifications
          <HelpButton title="What are push notifications?">
            <Text>
              Push notifications will help you stay on top of your progress and
              never miss a coaching session, so you can achieve your performance
              goals faster. We promise not to bombard you with messages -
              we&apos;ll only send a few notifications per week to keep you
              up-to-date.
            </Text>
          </HelpButton>
        </FormLabel>
        <Switch
          isChecked={isEnabled}
          disabled={pushPermissions.data === 'denied'}
          onChange={handleToggle}
        />
      </Card.Heading>

      <Collapse in={!isEnabled}>
        <Text>
          Status:{' '}
          <Text as="span" fontWeight="bold">
            OFF
          </Text>
        </Text>
      </Collapse>

      {/* Toggle is enabled, but permission is denied */}
      <Collapse in={isEnabled && pushPermissions.data === 'denied'}>
        <VStack spacing="3" align="stretch">
          <Text>
            Status:{' '}
            <Text as="span" color="red.500" fontWeight="bold">
              OFF
            </Text>
          </Text>
          {pushRequest.isSuccess ? (
            // User has just denied permission
            <Text>
              We respect your decision not to grant permission for push
              notifications. You can always change your mind and grant
              permission later in the app settings.
            </Text>
          ) : (
            // User has previously denied permission
            <Text>
              We cannot send you push notifications to this device without your
              permission. You previously denied permission, but you can change
              this by going to your device settings and enabling notifications
              for Strive.
            </Text>
          )}
        </VStack>
      </Collapse>

      {/* Toggle is enabled & user granted permission */}
      <Collapse in={isEnabled && pushPermissions.data === 'granted'}>
        <VStack spacing="3" align="stretch">
          <Text>
            Status:{' '}
            <Text as="span" color="green.500" fontWeight="bold">
              READY
            </Text>
          </Text>
          {pushRequest.isSuccess && (
            // User has just granted permission
            <>
              <Text>
                Great, we can now send you push notifications to help you stay
                connected with your coach and on track with your program.
              </Text>
              <Text>Thank you for your permission!</Text>
            </>
          )}
        </VStack>
      </Collapse>

      <Collapse in={isEnabled && !isPushEnabled}>
        <VStack spacing="3" align="stretch">
          <Text>
            Push notifications are not currently supported on this device.
          </Text>
          <Text>
            Leave this enabled if you intend to receive push notifications on a
            supported mobile device.
          </Text>
        </VStack>
      </Collapse>
    </Card>
  );
}
