import { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid } from '@chakra-ui/react';

import { PillarPractice } from '@arena-labs/shared-models';
import {
  PracticeIcon,
  TEXT,
  useFadeEntryFadeExit,
  useResponsive,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

export function PracticeReview({
  onReviewComplete,
  submissionError,
  logPracticeCard,
}: {
  submissionError: boolean;
  onReviewComplete: () => void;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const pillars = $API.useGetPillars();
  const rs = useResponsive();
  const { scope, animate } = useFadeEntryFadeExit();

  const [logMore, setLogMore] = useState(false);

  useEffect(() => {
    animate(
      '#logMoreButton',
      { opacity: 1, y: ['200px', '0px'] },
      { duration: 0.33, delay: 0.5 },
    );
  }, [animate]);

  return (
    <Flex ref={scope} flexGrow={1}>
      <Flex direction={'column'} h={'full'}>
        <TEXT.P1_SEMIBOLD color={'logo'} mt={10} mb={4}>
          3 of 3
        </TEXT.P1_SEMIBOLD>

        <TEXT.H2 mb={10}>Over the last 24 hours you&apos;ve recorded: </TEXT.H2>

        <Flex
          direction={'column'}
          gap={6}
          w={'full'}
          layerStyle={'1dp'}
          p={4}
          mb={'auto'}
          rounded={'card'}
          maxH={rs({ xs: '200px', base: '400px' })}
          overflow={'auto'}
        >
          <WaitForQuery query={pillars}>
            {(Pillars) => {
              const practicesAvailable = Pillars.flatMap((pillar) =>
                pillar.practices.filter((practice) => practice.log_count > 0),
              );
              const practicesComplete = practicesAvailable.filter(
                (pract) => pract.completed_today,
              );
              const practicesIncomplete = practicesAvailable.filter(
                (pract) => !pract.completed_today,
              );
              if (logMore && practicesIncomplete.length > 0) {
                return practicesIncomplete.map((incompletePractice) =>
                  logPracticeCard(incompletePractice),
                );
              } else if (!logMore && practicesComplete.length > 0) {
                return practicesComplete.map((practiceComplete) => (
                  <Grid
                    templateColumns={'40px 1fr'}
                    columnGap={4}
                    alignItems={'center'}
                    key={practiceComplete.slug}
                  >
                    <PracticeIcon slug={practiceComplete.slug} />

                    <TEXT.P1_BOLD key={practiceComplete.slug}>
                      {practiceComplete.short_title}
                    </TEXT.P1_BOLD>
                  </Grid>
                ));
              } else {
                return (
                  <TEXT.P2 mx={'auto'} textAlign={'center'}>
                    {logMore
                      ? 'There are no other practices to record right now.'
                      : 'There were no practices recorded yesterday.'}
                  </TEXT.P2>
                );
              }
            }}
          </WaitForQuery>
        </Flex>

        <Button
          variant={'underline-link'}
          color={'neutral.white'}
          mt={'auto'}
          mb={'6'}
          onClick={() => setLogMore(!logMore)}
          opacity={0}
          id={'logMoreButton'}
        >
          {logMore ? 'Return' : 'Log More'}
        </Button>
        {submissionError && (
          <Box
            px={3}
            mx={'auto'}
            mb={3}
            rounded={'sm'}
            transition={'all 0.35s ease'}
            w="auto"
            bg={'error.500'}
            textAlign={'center'}
            color={'neutral.300'}
          >
            An error occured, please submit again!
          </Box>
        )}
        <Button
          variant={'primary'}
          w={'full'}
          onClick={() => onReviewComplete()}
        >
          {' '}
          Generate Insights
        </Button>
      </Flex>
    </Flex>
  );
}
