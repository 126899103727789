import { useEffect } from 'react';
import router from 'next/router';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { z } from 'zod';

import { NotificationTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { $API } from '@strive/api';

import { openApp } from '../../lib/open-app';

const isPushAvailable = Capacitor.isPluginAvailable('PushNotifications');

const streamData = z.object({
  sender: z.literal('stream.chat'),
  channel_id: z.string().optional(),
  message_id: z.string().optional(),
});

const striveData = z.object({
  sender: z.literal('strive.api'),
  uuid: z.string(),
  event: z.string(),
  provider: z.string().optional(),
});

const notificationData = z.discriminatedUnion('sender', [
  streamData,
  striveData,
]);

export function usePushNotificationAction() {
  const analytics = useAnalytics();
  const sendNotificationViewed = $API.useSendNotificationViewed();

  useEffect(() => {
    if (!isPushAvailable) {
      return undefined;
    }

    const listener = PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (event) => {
        console.info('🔔 Push notification action', event);

        const analyticsData: Record<string, unknown> = {};

        const parse = notificationData.safeParse(event.notification.data);
        if (parse.success) {
          const data = parse.data;
          if (data.sender === 'stream.chat') {
            // Redirect stream chat notifications to the chat page
            router.push('/chat');
            Object.assign(analyticsData, {
              notificationType: 'chat',
              streamChannel: data.channel_id,
              streamMessageId: data.message_id,
            });
          } else if (data.sender === 'strive.api') {
            Object.assign(analyticsData, {
              notificationType: data.event,
              uuid: data.uuid,
            });
            sendNotificationViewed.mutate({ uuid: data.uuid });

            if (data.event === 'device_connection_lost' && data.provider) {
              analyticsData.provider = data.provider;
              openApp(data.provider);
            }
          }

          // Analytics
          analytics.logEvent(
            NotificationTrackingEvent.PushNotificationClick,
            analyticsData,
          );
        } else {
          console.warn(
            '🔔 Unrecognized push notification',
            event.notification.data,
          );
        }
      },
    );

    return () => {
      listener.then((_) => _.remove());
    };
  }, [analytics, sendNotificationViewed]);
}
