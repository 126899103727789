import * as React from 'react';

export function UserHighRecharge(props: React.SVGProps<SVGSVGElement>) {
  const gradientId1 = React.useId();
  const gradientId2 = React.useId();
  const gradientId3 = React.useId();
  const gradientId4 = React.useId();
  const gradientId5 = React.useId();
  const gradientId6 = React.useId();
  const gradientId7 = React.useId();
  const gradientId8 = React.useId();

  return (
    <svg viewBox="0 0 115 112" fill="none" {...props}>
      <path
        d="M27.302 40.285l19.816-18.45 23.791 23.233m-3.975-4.1l21.865 22.549 23.233-23.233"
        stroke={`url(#${gradientId1})`}
        strokeWidth={5.952}
      />
      <path
        d="M55.317 52.584l21.183 20.5-15.462 15.46"
        stroke={`url(#${gradientId2})`}
        strokeWidth={5.952}
      />
      <path
        d="M17.05 95.632L71.66 40.23"
        stroke={`url(#${gradientId3})`}
        strokeWidth={5.952}
      />
      <circle
        cx={84.7}
        cy={35.934}
        r={9.323}
        stroke={`url(#${gradientId4})`}
        strokeWidth={5.952}
      />
      <path
        d="M8.603 66.934h24.599"
        stroke={`url(#${gradientId5})`}
        strokeWidth={4.592}
      />
      <path
        d="M16.802 58.734h24.6"
        stroke={`url(#${gradientId6})`}
        strokeWidth={4.592}
      />
      <path
        d="M113.398 94.266H88.799"
        stroke={`url(#${gradientId7})`}
        strokeWidth={4.592}
      />
      <path
        d="M105.199 86.066h-24.6"
        stroke={`url(#${gradientId8})`}
        strokeWidth={4.592}
      />
      <defs>
        <linearGradient
          id={gradientId1}
          x1={120.232}
          y1={42.334}
          x2={27.523}
          y2={46.868}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={0.531} stopColor="#95FDFD" stopOpacity={0} />
          <stop offset={1} stopColor="#95FDFD" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={58.734}
          y1={53.267}
          x2={71.135}
          y2={90.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" stopOpacity={0} />
          <stop offset={1} stopColor="#95FDFD" />
        </linearGradient>
        <linearGradient
          id={gradientId3}
          x1={73.082}
          y1={40.284}
          x2={46.052}
          y2={65.153}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" stopOpacity={0} />
          <stop offset={1} stopColor="#95FDFD" />
        </linearGradient>
        <linearGradient
          id={gradientId4}
          x1={96.015}
          y1={27.222}
          x2={71.248}
          y2={55.953}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={1} stopColor="#95FDFD" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId5}
          x1={8.603}
          y1={66.934}
          x2={33.202}
          y2={66.918}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={1} stopColor="#95FDFD" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId6}
          x1={16.802}
          y1={58.734}
          x2={41.402}
          y2={58.719}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={1} stopColor="#95FDFD" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId7}
          x1={113.398}
          y1={94.266}
          x2={88.799}
          y2={94.251}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={1} stopColor="#95FDFD" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId8}
          x1={105.199}
          y1={86.066}
          x2={80.6}
          y2={86.051}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" />
          <stop offset={1} stopColor="#95FDFD" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoUserHighRecharge = React.memo(UserHighRecharge);
export default MemoUserHighRecharge;
