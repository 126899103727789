import { Icon, IconProps } from '@chakra-ui/react';

import {
  AdaptabilitySkillIcon,
  ArenaLogo,
  CommunicationSkillIcon,
  EnergyManagementSkillIcon,
  HumilitySkillIcon,
  InnovationSkillIcon,
  IntentionalitySkillIcon,
  PsychologicalSafetySkillIcon,
  SelfAwarenessSkillIcon,
  SituationalAwarenessSkillIcon,
  StewardshipSkillIcon,
  TransformationSkillIcon,
  TribeSkillIcon,
} from '@arena-labs/strive2-ui';

import { Program } from '../../api/programs';

export type ProgramIconProps = {
  program: Program;
} & IconProps;

const ProgramIcons = {
  introduction: ArenaLogo,
  adaptability: AdaptabilitySkillIcon,
  communication: CommunicationSkillIcon,
  'energy-management': EnergyManagementSkillIcon,
  humility: HumilitySkillIcon,
  innovation: InnovationSkillIcon,
  intentionality: IntentionalitySkillIcon,
  'psychological-safety': PsychologicalSafetySkillIcon,
  'self-awareness': SelfAwarenessSkillIcon,
  'situational-awareness': SituationalAwarenessSkillIcon,
  stewardship: StewardshipSkillIcon,
  transformation: TransformationSkillIcon,
  tribe: TribeSkillIcon,
};

function hasIcon(skill: string): skill is keyof typeof ProgramIcons {
  return skill in ProgramIcons;
}

export function ProgramIcon({ program, ...iconProps }: ProgramIconProps) {
  const skillSlug = program.skill?.slug ?? '';
  const icon = hasIcon(skillSlug)
    ? ProgramIcons[skillSlug]
    : ProgramIcons.introduction;
  return <Icon color={'tertiary.700'} {...iconProps} as={icon} />;
}
