import { assign, createMachine, sendParent } from 'xstate';

import {
  AuthResponse,
  isAuthToken,
  isObjectWithKey,
} from '@arena-labs/shared-models';

import { isOfflineError } from '../lib/network-error';
import { initializeEnvironment } from '../lib/strive-environment';
import { getFreshToken } from './auth-api';
import { AuthTokenStore, LegacyTokenStore } from './auth-tokens';

export type AuthEvents =
  | { type: 'Log in' }
  | { type: 'Log out' }
  | { type: 'Sign up' }
  | { type: 'Revalidate' }
  | { type: 'Refresh'; data: AuthResponse }
  | { type: 'Complete Auth'; data: AuthResponse }
  | { type: 'Setup Profile'; data: AuthResponse }
  | { type: 'Finalize Profile' };

type AuthServices = {
  restoreSavedSession: { data: AuthResponse };
};

type AuthContextType = {
  accessToken: string | null;
  refreshToken: string | null;
  userId: number | null;
};

const initialContext: AuthContextType = {
  accessToken: null,
  refreshToken: null,
  userId: null,
};

export const authMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEECuAXAFmAduglgMYCGBA9jgHQCSO+BxANvgF745QDEA2gAwC6iUAAcysevgpCQAD0QBGAOy9KvRQCYAnAGZFm3gFZFB3poBsAGhABPRABZ1Zyne1njZu27OaHAX19WaFi4BCTkVLQSTKzsXNzygkggouLh0nIISipqWrr6RibmVrYIBup2qgAcmpW86ryVBkbq-oEY2HhEpJJUADJkUDAQAARkGJzIAKoAKgASAKIActPUAMLI0-MAInyJImISUkkZ8uqKTnby2poGntrqSop2xYjlTrxmJmZmlb8G8k0zK0QEEOqFuhRKP1BpBhuxOAAlMAAMwATnBMLtpClDjh0q8GupKJoSVcNPJKh5LDZXpdKAZ9HZKrpvHYTMzgaCQl1wlCBkM4ThONDRhgsUkcWljgpNGdKHprjptAZtGyPi9MuoDJRPuptIZVZpFNp5DpOe1uWEepQkbB0GRUbFhrA4OIKJwIBQwJR2AA3MgAa29XM6Vshtvtjo4ztdPQQfrIYZwu3F+1SPXxCGUFVO5V48hcdgainkGqamko2kqZ2rFKUDhaARBFtDEKoEYdTpdsDdQrAqNRDsowkYpGRDoAtpQQ+DeR2o1AYz24wmkymBNiDlLQBls5Rc0WC6ri6WaQhHNpKA8fpUAWoDNVtObgq253BI13Y+7+4PUcPR+g46olOM48ta86fsuFDxjg-prgI8R7MkW4ZtKWa8Dm6h5keRaVCWGpVvI+5suyx4PmYT5NqBSaUAA8siyLMDgYCImAvrRBApBgKmyHpkcO6IJo8hOOYpgmqcpy3poGoPJe1wFhSRrKrcALPmCYGQtCApjOgnAAMrTMgemzAA+tMtEANJLDxkqoQJmrnM4Vw3HcDyKE8GqXNqWGOHqygYbwlFtC+s7WlpTrwlMcxLCs6ybDsG4Sih-GyAojQVI0zm8KYvxnNonkAleWEMreBg-MJmj+E2OBkBAcDSNRbabnxeJoQAtNSJQdao2XZUYlKKI0phlWplptjQdAMMwbAcM1uKZg4ZaFQy+beJ8HwMqNr5hfysI6XN26pZkhgVDU1YfLUWEaJUBH6s4dQBaYxrKvIW2hZpu0jOwB12Ud9S3PK3zreJdg3ARPzOHo5xYaqoNaG9GlUHp-a+v2P0pRk2j3FeDT3LUZRGucnnlJWZQfJ8QlmIFLgIzREHRt2vbo619lY0S9TMuo+NaO5nX2NWlCVJc5I+GyriUrT430Yx7BgMzmbyCdxK5RddRnFzMlalU+oYXhjJ2INku8uF0bfUlLUK58TjGjcJgKjo6gEUJxLGHrPxqJSlVVUAA */
  createMachine(
    {
      context: initialContext,
      tsTypes: {} as import('./auth-service.machine.typegen').Typegen0,
      schema: {
        context: {} as AuthContextType,
        events: {} as AuthEvents,
        services: {} as AuthServices,
      },
      id: 'Authentication',
      initial: 'Initializing',
      description:
        'Auth machine handling signup, login, and restoring saved session',
      predictableActionArguments: true,

      states: {
        Initializing: {
          always: [
            {
              target: 'Server',
              cond: 'isServer',
            },
            {
              target: 'Restoring session',
            },
          ],
        },

        'Logged out': {
          entry: ['resetContext', 'clearSession', sendParent('LOGGED_OUT')],
          on: {
            'Complete Auth': {
              target: 'Logged in',
              description:
                'Received from sub-machine:\nany authenticated event from login, signup',
            },

            'Setup Profile': 'Setting up profile',
          },
        },

        'Logged in': {
          entry: ['saveTokens', 'persistSession', sendParent('LOGGED_IN')],
          on: {
            Refresh: {
              actions: ['saveTokens', 'persistSession'],
            },
            'Log out': {
              target: 'Logged out',
            },
          },
        },

        Server: {},

        'Restoring session': {
          description: 'Load saved token from storage, and re-validate it',
          invoke: {
            src: 'restoreSavedSession',
            onDone: [
              {
                target: 'Logged in',
              },
            ],
            onError: [
              {
                target: 'Offline',
                cond: 'isOffline',
              },
              {
                target: 'Logged out',
              },
            ],
          },
        },

        Offline: {
          entry: sendParent('OFFLINE'),
          on: {
            Revalidate: {
              target: 'Restoring session',
            },
          },
        },

        'Setting up profile': {
          entry: 'saveTokens',

          on: {
            'Finalize Profile': 'Logged in',
          },
        },
      },
    },
    {
      guards: {
        isServer: () => typeof window === 'undefined',
        isOffline: (ctx, event) => isOfflineError(event.data),
      },
      actions: {
        resetContext: assign((ctx) => initialContext),
        saveTokens: assign({
          userId: (ctx, evt) =>
            hasToken(evt) ? pluckUserIdFromJwt(evt.data.access) : ctx.userId,
          accessToken: (ctx, evt) =>
            hasToken(evt) ? evt.data.access : ctx.accessToken,
          refreshToken: (ctx, evt) =>
            hasToken(evt) ? evt.data.refresh : ctx.refreshToken,
        }),
        persistSession: (context, event) => {
          if (hasToken(event)) {
            AuthTokenStore.set(event.data);
          } else if (context.accessToken && context.refreshToken) {
            AuthTokenStore.set({
              access: context.accessToken,
              refresh: context.refreshToken,
            });
          } else {
            AuthTokenStore.remove();
          }
        },
        clearSession: (ctx, evt) => AuthTokenStore.remove(),
      },
      services: {
        restoreSavedSession: async (context, event) => {
          // Load any saved token and ensure we're connected to the correct environment
          const [tokens] = await Promise.all([
            getSavedToken(),
            initializeEnvironment(),
          ]);

          if (!tokens) {
            throw new Error('No saved session');
          }

          return tokens;
        },
      },
    },
  );

async function getSavedToken() {
  let token = await AuthTokenStore.get();
  if (!token) {
    const refresh = await LegacyTokenStore.get();
    if (refresh) {
      // Migrate from legacy token
      LegacyTokenStore.remove();
      token = await getFreshToken(refresh);
      AuthTokenStore.set(token);
    }
  }
  return token;
}

function pluckUserIdFromJwt(accessToken: string) {
  let userId: number | null = null;
  if (accessToken !== undefined) {
    const [, payload] = accessToken.split('.');
    if (payload) {
      const parsed = JSON.parse(atob(payload));
      userId = parsed.user_id as number;
    }
  }

  return userId ?? -1;
}

function hasToken(event: unknown): event is { data: AuthResponse } {
  return isObjectWithKey(event, 'data') && isAuthToken(event.data);
}
