import {
  BoxProps,
  Center,
  Flex,
  RadioProps,
  useRadioGroup,
} from '@chakra-ui/react';

import { ACILocation } from '@arena-labs/shared-models';
import { RadioButton } from '@arena-labs/strive2-ui';

import { ACILocations } from './aci-questions';

export type CheckInLocationProps = {
  value?: ACILocation | null;
  onChange: (location: ACILocation) => void;
};

export function CheckInLocation({ value, onChange }: CheckInLocationProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'location',
    value: value ?? undefined,
    onChange,
  });

  return (
    <Flex
      direction="column"
      gap="6"
      as="fieldset"
      w="full"
      {...(getRootProps() as BoxProps)}
    >
      {ACILocations.map((loc) => (
        <RadioButton
          size="md"
          key={loc.value}
          {...(getRadioProps({ value: loc.value }) as RadioProps)}
          variant="outline-selected"
        >
          <Center
            textAlign="center"
            fontWeight={value === loc.value ? 'bold' : undefined}
          >
            {loc.display_name}
          </Center>
        </RadioButton>
      ))}
    </Flex>
  );
}
