import { useRouter } from 'next/router';

import { useAnalytics } from '.';
import { TrackingEventContext } from './analytics-client';

export function useRouterAnalytics(context?: Partial<TrackingEventContext>) {
  const router = useRouter();
  const analytics = useAnalytics({
    ...context,
    ...router.query,
    route: router.pathname,
    url: router.asPath,
  });

  return analytics;
}
