import { Duration } from 'date-fns';

export function formatSeconds(seconds: number) {
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
}

export function formatHoursAsHM(hours: number) {
  const h = Math.floor(hours);
  const m = Math.floor((hours - h) * 60);
  return `${String(h)}h ${String(m).padStart(2, '0')}m`;
}

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;
const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30; // Note: approximation
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365; // Note: approximation

export function durationToMs(duration: Duration): number {
  let seconds = 0;
  seconds += duration.seconds ?? 0;
  seconds += (duration.minutes ?? 0) * SECONDS_IN_MINUTE;
  seconds += (duration.hours ?? 0) * SECONDS_IN_HOUR;
  seconds += (duration.days ?? 0) * SECONDS_IN_DAY;
  seconds += (duration.weeks ?? 0) * SECONDS_IN_WEEK;
  seconds += (duration.months ?? 0) * SECONDS_IN_MONTH;
  seconds += (duration.years ?? 0) * SECONDS_IN_YEAR;
  return Math.round(seconds * 1000);
}
