import { Box, BoxProps, TabPanel, TabPanels } from '@chakra-ui/react';

import { useTabsContext } from './tabs-provider';

export function TabsContent({ children, ...props }: BoxProps) {
  const { tabs, currentTab } = useTabsContext();
  if (tabs && tabs.length) {
    return (
      <TabPanels height="full" overflowY="auto" {...props}>
        {tabs.map((tabId) => (
          <TabPanel key={tabId} p="0">
            {tabId === currentTab && children}
          </TabPanel>
        ))}
      </TabPanels>
    );
  }
  return (
    <Box height="full" overflowY="auto" {...props}>
      {children}
    </Box>
  );
}
