import { Button, chakra, useClipboard, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';

import { useHomeScreenData } from '@arena-labs/strive2-coaching';
import {
  AndroidOsIcon,
  ArenaLogo,
  Card,
  Icon,
  IosIcon,
  SettingsIcon,
  useDeviceInfo,
  UserProfileIcon,
} from '@arena-labs/strive2-ui';
import { useStriveEnvironments, useUserProfile } from '@strive/api';

import { useAppInfo } from '../../lib/use-app-info';

const DL = chakra('dl', {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: '6rem 1fr',
    fontSize: 'sm',
    columnGap: 4,
    rowGap: 1,
    bg: 'strive.card.dark.bg',
  },
});
const DT = chakra('dt', {
  baseStyle: {
    gridColumnStart: 1,
    fontWeight: 'bold',
    textAlign: 'right',
  },
});
const DD = chakra('dd', {
  baseStyle: { gridColumnStart: 2 },
});

export function AdminInfo() {
  const { data: user } = useUserProfile();
  const [homepage] = useHomeScreenData();
  const app = useAppInfo();
  const device = useDeviceInfo();
  const env = useStriveEnvironments((state) => state.getEnvironment());
  const sentryReplayId = Sentry.getReplay()?.getReplayId();
  const sentryReplayUrl = useClipboard(
    `https://arena-labs.sentry.io/replays/${sentryReplayId}`,
  );

  const { hasCopied, onCopy } = useClipboard(
    JSON.stringify(
      {
        user,
        learningState: homepage?.learning_state,
        app,
        env,
        device,
        sentry: { replayId: sentryReplayId, replayUrl: sentryReplayUrl.value },
      },
      null,
      2,
    ),
  );

  return (
    <VStack spacing="4">
      <Button
        size="sm"
        alignSelf="flex-end"
        variant="outline"
        onClick={onCopy}
        bg={hasCopied ? 'green.600' : undefined}
      >
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
      {user && (
        <Card colorScheme="dark" width="full">
          <Card.Heading
            color="strive.primary"
            mb="2"
            display="flex"
            alignItems="center"
          >
            <Icon as={UserProfileIcon} size={4} mr="4" />
            User
          </Card.Heading>
          <DL>
            <DT>Name:</DT>
            <DD>
              {user.first_name} {user.last_name}{' '}
              {user.profile.impersonated_by && <b>(Impersonated)</b>}
            </DD>
            <DT>Username:</DT>
            <DD>{user.username}</DD>
            <DT>User id:</DT>
            <DD>{user.id}</DD>
            <DT>Team:</DT>
            <DD>{user.profile.team}</DD>
            {homepage && (
              <>
                <DT>Learning state:</DT>
                <DD>{homepage.learning_state}</DD>
              </>
            )}
            <DT>Sentry Replay Id:</DT>
            <DD display="flex">
              {sentryReplayId ?? '-'}{' '}
              <Button
                ml="auto"
                size="xs"
                variant="outline"
                onClick={sentryReplayUrl.onCopy}
                bg={sentryReplayUrl.hasCopied ? 'success.500' : undefined}
              >
                {sentryReplayUrl.hasCopied ? 'Copied ✔️' : 'Copy URL'}
              </Button>
            </DD>
          </DL>
        </Card>
      )}

      {app && (
        <Card colorScheme="dark" width="full">
          <Card.Heading
            color="strive.primary"
            mb="2"
            display="flex"
            alignItems="center"
          >
            <Icon as={ArenaLogo} size={4} mr="4" />
            Strive
          </Card.Heading>
          <DL>
            <DT>Release:</DT>
            <DD>{app.version}</DD>
            <DT>Capgo:</DT>
            <DD>{app.capgoVersion}</DD>
            {app.nativeVersion && (
              <>
                <DT>App Store:</DT>
                <DD>
                  {app.nativeVersion}{' '}
                  {app.build !== app.nativeVersion ? `(${app.build})` : null}
                </DD>
              </>
            )}
            {app.sha && (
              <>
                <DT>Commit:</DT>
                <DD>{app.sha}</DD>
              </>
            )}
            {env && (
              <>
                <DT>Environment:</DT>
                <DD>{env.name ?? 'Default'}</DD>
                <DT>API:</DT>
                <DD>{env.api}</DD>
                <DT>Server:</DT>
                <DD>{env.server}</DD>
              </>
            )}
          </DL>
        </Card>
      )}

      {device && (
        <Card colorScheme="dark" width="full">
          <Card.Heading
            color="strive.primary"
            mb="2"
            display="flex"
            alignItems="center"
          >
            <Icon
              as={
                device.platform === 'ios'
                  ? IosIcon
                  : device.platform === 'android'
                  ? AndroidOsIcon
                  : SettingsIcon
              }
              size={4}
              mr="4"
            />
            Device
          </Card.Heading>
          <DL>
            <DT>Platform:</DT>
            <DD>{device.platform}</DD>
            <DT>OS:</DT>
            <DD>
              {device.operatingSystem} / {device.osVersion}
            </DD>
            <DT>Model:</DT>
            <DD>
              {device.model} / {device.manufacturer}
            </DD>
            {device.name && (
              <>
                <DT>Name:</DT>
                <DD>{device.name}</DD>
              </>
            )}
            {device.webViewVersion && (
              <>
                <DT>Webview:</DT>
                <DD>{device.webViewVersion}</DD>
              </>
            )}
            {device.memUsed && (
              <>
                <DT>Mem used:</DT>
                <DD>{(device.memUsed / 1024 ** 2).toFixed(1)}MB</DD>
              </>
            )}
            {device?.uuid && (
              <>
                <DT>UUID:</DT>
                <DD fontFamily="monospace">{device?.uuid}</DD>
              </>
            )}
            {device?.updaterId && (
              <>
                <DT>Capgo ID:</DT>
                <DD fontFamily="monospace">{device?.updaterId}</DD>
              </>
            )}
          </DL>
        </Card>
      )}
    </VStack>
  );
}
