import { useMutation } from '@tanstack/react-query';

import { UserUpdateBody } from '@arena-labs/shared-models';

import { useAuthState } from '../auth/auth-service';
import { $API, apiClient } from '../clients';
import { queryClient } from './query-client';

export function useIsLoggedIn() {
  const [authState] = useAuthState();
  return (
    authState.matches('Logged in') || authState.matches('Setting up profile')
  );
}

export function useUserProfile() {
  const isLoggedIn = useIsLoggedIn();
  return $API.useGetUserProfile({}, { enabled: isLoggedIn });
}

export function invalidateUserProfile() {
  queryClient.invalidateQueries($API.getKeyByAlias('getUserProfile'));
}

export function useInvalidateUserProfile() {
  return invalidateUserProfile;
}

export function useUpdateUserProfileMutation() {
  const invalidateCachedProfile = useInvalidateUserProfile();
  return useMutation(
    (update: UserUpdateBody) =>
      Promise.all([
        update.profile && apiClient.updateUserProfile(update.profile),
        update.user && apiClient.updateUserData(update.user),
      ]),
    { onSettled: invalidateCachedProfile },
  );
}
