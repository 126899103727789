import { useAsyncFn } from 'react-use';
import { Button, Circle, Divider, Flex, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon, Icon, TEXT } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../layout/app-banner';

export function CompetitionCompletionReward({
  onClose,
}: {
  onClose: () => unknown;
}) {
  const swiper = useSwiper();

  // Show a loading state after the user clicks the "Keep Practicing" button
  const [loadingState, startLoading] = useAsyncFn(
    (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),
  );
  const handleClose = () => {
    if (loadingState.loading) return;
    onClose();
    startLoading(3000); // submit appgate & refresh homepage
  };

  const premierRewards = [
    { path: '/images/competition/sun-light.webp', item: 'Day Bright Light' },
    { path: '/images/competition/theragun.webp', item: 'Theragun Mini' },
    { path: '/images/competition/smart-goggle.webp', item: 'Smart Goggles' },
  ];

  const secondaryRewards = [
    {
      path: '/images/competition/stanley-quencher.webp',
      item: 'Stanley Quencher',
    },
    {
      path: '/images/competition/grat-journal.webp',
      item: 'Gratitude Journal',
    },
  ];

  return (
    <Flex h="full" direction={'column'}>
      <AppBanner>
        <Button
          onClick={() => swiper.slidePrev()}
          variant={'unstyled'}
          size={'xs'}
        >
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Button>

        <TEXT.H3 textAlign={'center'} w="full">
          Strive Performance Bundle
        </TEXT.H3>
      </AppBanner>
      <Flex
        direction="column"
        gap="4"
        px="6"
        pb="4"
        h="full"
        color="neutral.400"
        bg="bg.primary"
      >
        <Flex
          mt={'auto'}
          rounded={'sm'}
          py={3}
          direction={'column'}
          textAlign={'center'}
          gap={3}
          px={4}
          border={'1px solid'}
          borderColor={'neutral.900'}
        >
          <TEXT.P1_BOLD>Premier Prize</TEXT.P1_BOLD>
          <TEXT.P2>Select 1 out of 3</TEXT.P2>
          <Divider />
          <Flex direction={'row'} justify={'space-between'}>
            {premierRewards.map((reward) => {
              return (
                <Circle
                  size="72px"
                  bg="neutral.white"
                  alignSelf={'center'}
                  key={reward.item}
                >
                  <Image
                    src={reward.path}
                    alt={reward.item}
                    w={reward.path.includes('light') ? '50px' : '70px'}
                    alignSelf={'center'}
                  />
                </Circle>
              );
            })}
          </Flex>
        </Flex>

        <Flex gap={4} textAlign={'center'} mb={'auto'}>
          {secondaryRewards.map((reward) => {
            return (
              <Flex
                key={reward.item}
                direction="column"
                w="full"
                gap={6}
                py="4"
                border={'1px solid'}
                borderColor={'neutral.900'}
              >
                <TEXT.P1_BOLD>{reward.item}</TEXT.P1_BOLD>
                <Circle size="72px" bg="neutral.white" alignSelf={'center'}>
                  <Image ml={2} src={reward.path} alt={reward.item} w="35px" />
                </Circle>
              </Flex>
            );
          })}
        </Flex>

        <Divider mx={-6} px={6} />

        <Flex
          direction="column"
          gap="2"
          textAlign="center"
          align="center"
          mt={2}
          mb={'auto'}
        >
          <TEXT.H2>Strive Performance Bundle</TEXT.H2>
          <TEXT.P1>
            We’ll be in touch for the premier prize selection. The Strive
            Performance Bundle will be delivered to your door soon!
          </TEXT.P1>
        </Flex>

        <Button
          variant="primary"
          width="full"
          mt="auto"
          isLoading={loadingState.loading}
          onClick={handleClose}
        >
          Keep Practicing
        </Button>
      </Flex>
    </Flex>
  );
}
