import { Wearable } from '@strive/api';

export type WearableMode = keyof WearableType;

export type StrivewareWearable = Wearable & { provider: 'striveware' };
export type PartnerWearable = Wearable & {
  provider: Exclude<Wearable['provider'], 'striveware'>;
};

export type WearableType = {
  striveware: StrivewareWearable;
  partner: PartnerWearable;
  sensorless: undefined;
};

/**
 * Type predicates to determine the type of wearable
 * Returns mode along with the wearable object if available.
 */
export const isWearable = {
  Striveware(wearable?: Wearable): wearable is StrivewareWearable {
    return wearable?.provider === 'striveware';
  },
  Partner(wearable?: Wearable): wearable is PartnerWearable {
    return !!wearable && wearable.provider !== 'striveware';
  },
  Sensorless(wearable?: Wearable): wearable is undefined {
    return !wearable;
  },
};
