import { useEffect } from 'react';
import { Box, Image } from '@chakra-ui/react';

import {
  TEXT,
  useFadeEntryFadeExit,
  useResponsive,
  ZStack,
} from '@arena-labs/strive2-ui';

export function GeneratingInsights() {
  const { scope, animate } = useFadeEntryFadeExit();

  const rs = useResponsive();
  // ideally the image would be width:full and height:auto, but using a ref a waiting for the height to calc causes wierd issues with rendering the scanner bit
  const imageHeight = rs({ xs: '400px', base: '450px' });

  useEffect(() => {
    animate(
      '#scanner',
      { y: [`-${imageHeight}px`, '0px'], opacity: [0, 1, 1, 0] },
      { duration: 3, repeat: Infinity },
    );
  }, [animate, imageHeight]);

  return (
    <Box my={'auto'} w={'full'} textAlign={'center'} ref={scope}>
      <ZStack
        position={'relative'}
        overflow={'hidden'}
        rounded={'card'}
        zIndex={0}
      >
        <Image
          src={'./images/check-in/humanoid.webp'}
          alt={"1980's vision of a future body scan"}
          w={'auto'}
          h={imageHeight}
        />

        <Box
          px={2}
          id={'scanner'}
          borderBottom={'5px solid'}
          borderColor={'#95FDFD'}
          background={`linear-gradient(179.99deg, rgba(44, 44, 46, 0.232) -0.12%, rgba(149, 253, 253, 0.348) 99.99%)`}
          height={imageHeight}
        ></Box>
      </ZStack>

      <TEXT.P1_SEMIBOLD mt={6}>Generating insights...</TEXT.P1_SEMIBOLD>
    </Box>
  );
}
