import SuperJSON from 'superjson';
import { SuperJSONResult } from 'superjson/dist/types';

/**
 * Helper to stringify a JS object. Uses SuperJSON to handle complex
 * types like Dates, Sets, Maps, etc
 */
export function serialize(value: unknown) {
  let data: unknown;
  const serialized = SuperJSON.serialize(value);

  // If SuperJSON is requred to parse the object:
  if (serialized.meta) {
    data = serialized;
  }

  // If the object can be parsed with JSON.parse without
  // losing fidelity
  else {
    data = serialized.json;
  }

  // Stringify
  return JSON.stringify(data);
}

/**
 * Decodes a stringified stored value.
 * If `data` requires SuperJSON to parse, it takes that extra step
 */
export function deserialize(value: string) {
  let obj: unknown;
  try {
    obj = JSON.parse(value);
  } catch (error) {
    // Data might have been saved as a plain string
    obj = value;
  }

  if (typeof obj === 'object' && isSuperJSONResult(obj)) {
    // If the data is wrapped in SuperJSON
    return SuperJSON.deserialize(obj);
  }

  // If the data is unwrapped
  return obj;
}

function isSuperJSONResult(data: unknown): data is SuperJSONResult {
  return Boolean(data && typeof data === 'object' && 'json' in data);
}
