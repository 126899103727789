import { captureException } from '@sentry/nextjs';
import { ZodiosResponseByAlias } from '@zodios/core';
import axios from 'axios';
import { pick } from 'lodash-es';
import { assign, createMachine } from 'xstate';

import { ACILocation, ACIQuestionId } from '@arena-labs/shared-models';
import { apiClient, striveAppApi } from '@strive/api';

import {
  ACIQuestion,
  ACIResponses,
  getACIQuestionIds,
  getACIQuestions,
} from './aci-questions';

export type ACIPostResponse = ZodiosResponseByAlias<
  typeof striveAppApi,
  'submitACI'
>;
export type ACIScoreData = NonNullable<ACIPostResponse['current']>;

export type ACIFormContextType = {
  location: ACILocation | null;
  questions: ACIQuestion[];
  availableQuestions: ACIQuestion[];
  responses: Partial<ACIResponses>;
};

export type ACIFormEvents =
  | {
      type: 'setLocation';
      location: ACILocation;
    }
  | {
      type: 'setResponse';
      questionId: ACIQuestionId;
      value: number;
    }
  | { type: 'submit' };

export const aciFormMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5SwC4CcCWA3MBBAxhgGID2aAtgHQYB2ADgK4oDEsYKASnHSTWwNoAGALqJQPWBhQZeYkAA9EARkEB2ADQgAnogBsADiWUlugMwAWJfoPmAnPoBMpgL7PNqTDgLEyVWoxY2Tm5eASVRJBAJKRkaOUUEAFZE3UpE81VzfUzTJWSMzR0EJVtbSlUHW1VSlMSnB3NzV3d0bDxCUgpqeiZWdgAZEnwAQ2leIQjxEkkxuMiEpSUHQsRk-UpGiv1EwTrBfVtmkA82706-HpRKfAALMHwAa1ooZgheMG6sEgePk68O3zdALXO6PZ4IWhfEazCYTOTRWbxRCqQTmSjWczpUzVVS6VQWFYIXQqSimfYmUz6XZKfG6I5-do+Lr+Jgg+5PGgvMBoNBkSh0AA2owAZoCGWdASyrrd2eDIUNRrFYSJ4dMYrJ5sjUejdJjzNiqniCdpVmpSQ4zLpEvi7EsXG5jq1-kyLsC0GBhhAtKwGAAjchSOGRBGxJHFByqVQbQSmdJWqz7fTmQm6fbotSJCx5Ha2LL0p2M85A1nc3loH3+wMq4NqxGa8OR6Ox8zxqn6JOE2OpNQOC3YhyCWx5JoO8UA5mXSilsjMABUQamM1D9fMZtxJnspnsJVspkJGVShhRqbskdMDiUI5ankLYr9AZQ0k5r3en2+vwLEq6sHvUifUAhGgoUVcYRAXKJa2XUAFkSJNKDxa1BBUXRbAHKxO13UlLwabZiUHcwHHzG8vyoH9K0fZ5mGnNB+SFFBRW-T9x1I38KM5QDgJhMDq0XdU5mg5RYLRBCUWQ1CkP0TtMXRBxElsa0SkESo1CI05mMoMiH3-KieT5QURTFJiXQ01j-w4hUuOEfhwlVJcNQEpJlhNBBDA2XD7EsKxTBQxJXAdGgSAgOA5DHF1bL4sNk2cgBaRJKFKBLEqS+1rzU4ypXCusHLkztXKHa1HCU3FVCpOlRyMospTZMFOUyqCFEQFsowaWkqiHMkh0JBS0l1DJbEEJTLF1VTnUqyd3U9IpeKyhqEF3NFvKqNR8RpWouuyShBGJSxUXyTYRtvCdgQAd2GGJaprOz+Nm8SNgaVctscbY1H3OC1FsYknG2Lyr0dYj1Kq6i6vs2aWvRbFdEhi85NXJR9xQ6M8l2fFYItMrUtGu9yP-YHroWLdBEoC1DH7SoGmNIpTFjNJ12yMwTCUFK-rSosf3wfA4HgS6Ivra1CUWEwdQtXZdjqRoDpI65eGFAUMHwFBcbDPF+dF9FqjqOSHG2SkR1cIA */
  createMachine(
    {
      context: {
        location: null,
        questions: [],
        availableQuestions: [],
        responses: {},
      },
      tsTypes: {} as import('./aci-form.machine.typegen').Typegen0,
      schema: {
        context: {} as ACIFormContextType,
        events: {} as ACIFormEvents,
        services: {} as {
          isReadyToSubmit: { data: boolean };
          submitACI: { data: ACIPostResponse };
        },
      },
      id: 'striveAciForm',
      initial: 'input',
      predictableActionArguments: true,

      states: {
        input: {
          initial: 'checking',
          states: {
            checking: {
              invoke: {
                src: 'isReadyToSubmit',
                onDone: [
                  {
                    target: 'ready',
                  },
                ],
                onError: [
                  {
                    target: 'waiting',
                  },
                ],
              },
            },
            ready: {
              description: 'The user has filled in all the responses',
              on: {
                submit: {
                  target: '#striveAciForm.submitting',
                },
              },
            },
            waiting: {
              description: 'The user has 1 or more questions to answer',
            },
            error: {
              on: {
                submit: {
                  target: '#striveAciForm.submitting',
                },
                '*': {
                  target: 'checking',
                },
              },
            },
          },
          on: {
            setResponse: [
              {
                actions: ['updateResponse', 'updateAvailableQuestions'],
                cond: 'hasUnanswered',
                target: '.checking',
              },
              {
                actions: 'updateResponse',
              },
            ],
            setLocation: {
              actions: ['updateLocation', 'updateAvailableQuestions'],
              target: '.checking',
            },
          },
        },
        submitting: {
          invoke: {
            src: 'submitACI',
            onDone: [
              {
                target: 'success',
              },
            ],
            onError: [
              {
                cond: 'isConflictErrorResponse',
                target: 'conflict',
              },
              {
                target: '#striveAciForm.input.error',
              },
            ],
          },
        },
        success: {
          type: 'final',
          entry: 'onSuccess',
        },
        conflict: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        updateLocation: assign({
          location: (ctx, event) => event.location,
          questions: (ctx, event) => getACIQuestions(event.location),
          responses: (ctx, event) =>
            pick(ctx.responses, getACIQuestionIds(event.location)),
        }),
        updateResponse: assign({
          responses: (ctx, event) => ({
            ...ctx.responses,
            [event.questionId]: event.value,
          }),
        }),
        updateAvailableQuestions: assign({
          availableQuestions: (ctx, event) => {
            let unanswered = false;
            const available = ctx.questions.filter((question) => {
              if (Number.isFinite(ctx.responses[question.id])) {
                return true;
              } else {
                if (!unanswered) {
                  unanswered = true;
                  return true;
                }
              }
              return false;
            });

            return available;
          },
        }),
        onSuccess: (ctx, event) => {
          // noop
        },
      },
      guards: {
        hasUnanswered: (ctx) => {
          return hasQuestions(ctx) && hasUnansweredQuestions(ctx);
        },
        isConflictErrorResponse: (ctx, evt) =>
          axios.isAxiosError(evt.data) && evt.data.response?.status === 409,
      },
      services: {
        isReadyToSubmit: (ctx) => {
          const isReady = hasQuestions(ctx) && !hasUnansweredQuestions(ctx);
          return isReady ? Promise.resolve(isReady) : Promise.reject();
        },
        submitACI: async (ctx) => {
          if (ctx.location) {
            const data = await apiClient
              .submitACI({
                offset: new Date().getTimezoneOffset(),
                arena_location: ctx.location,
                ...ctx.responses,
              })
              .catch((error) => {
                captureException(error);
                console.error(error);
                throw error;
              });
            return data;
          } else {
            throw Error('Location is not selected');
          }
        },
      },
    },
  );

function hasQuestions(ctx: ACIFormContextType) {
  return Boolean(ctx.questions.length);
}

function hasUnansweredQuestions(ctx: ACIFormContextType) {
  return ctx.questions.length > Object.keys(ctx.responses).length;
}
