import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const notificationsApi = makeApi([
  {
    alias: 'sendNotificationViewed',
    description: 'Send a read receipt for a notification',
    method: 'post',
    path: 'notifications/view/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          uuid: z.string().optional(),
        }),
      },
    ],
    response: z.unknown(),
  },
]);
