import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import videojs, { VideoJsPlayer } from 'video.js';

import { LooseAutocomplete } from '@arena-labs/shared-models';

export type VideoPlayerComponentProps = {
  player?: VideoJsPlayer;
  parent?: LooseAutocomplete<
    | 'controlBar'
    | 'controlBar.progressControl'
    | 'touchOverlay'
    | 'poster'
    | 'loadingSpinner'
  >;
  className?: string;
  styles?: Record<string, string | number>;
  children?: React.ReactNode;
  overlayActive?: boolean;
  overlayPaused?: boolean;
  overlayInitial?: boolean;
  overlayInactive?: boolean;
};

export function VideoPlayerComponent({
  player,
  parent,
  children = <div />,
  ...props
}: VideoPlayerComponentProps) {
  const vjsComponent = React.useRef<videojs.Component>();
  const className = clsx('vjs-strive', props.className, {
    'vjs-strive-overlay-active': props.overlayActive,
    'vjs-strive-overlay-paused': props.overlayPaused,
    'vjs-strive-overlay-initial': props.overlayInitial,
    'vjs-strive-overlay-inactive': props.overlayInactive,
  });
  useEffect(() => {
    // Where do we attach our overlay
    const root = getChildFromPath(player, parent);

    // Create a VJS component
    vjsComponent.current = root?.addChild('Component', { className });

    // Cleanup
    return () =>
      vjsComponent.current && root?.removeChild(vjsComponent.current);
  }, [parent, player, className]);

  const el = vjsComponent.current?.el() as HTMLElement | undefined;
  if (el) {
    Object.assign(el.style, props.styles);
  }

  // Put our "overlay" in the VJS component
  return vjsComponent.current && vjsComponent.current.el()
    ? ReactDOM.createPortal(children, vjsComponent.current.el())
    : null;
}

function getChildFromPath(
  player: VideoJsPlayer | undefined,
  path: string | undefined,
) {
  if (!path) return player; // If the path is undefined, return the player itself.

  const parts = path.split('.');
  let root: videojs.Component | undefined = player;
  for (const part of parts) {
    root = root?.getChild(part);
    if (!root) break;
  }
  return root;
}
