import { createStandaloneToast } from '@chakra-ui/toast';

import { theme } from '../theme/theme';

const { toast, ToastContainer } = createStandaloneToast({
  theme,
  defaultOptions: { variant: 'toast', isClosable: true },
});

export { toast, ToastContainer };
