import { useEffect } from 'react';
import Head from 'next/head';
import { Box } from '@chakra-ui/react';

import {
  AnalyticsContext,
  GeneralTrackingEvent,
  useAnalytics,
} from '@arena-labs/analytics';
import {
  Coach,
  isVideoDetail,
  Video,
  VideoPageMetadata,
} from '@arena-labs/shared-models';
import { LoadingOrError } from '@arena-labs/strive2-ui';

import { Program, ProgramDetail, useVideo } from '../api';
import { VideoDescription } from '../components/video/video-description';
import { VideoLibraryPlayer } from '../components/video/video-library-player';
import { getVideoTrackingContext } from '../lib/tracking-helpers';
import { ProgramDetail as ProgramsDetails } from './program-detail';

export type VideoDetailProps = {
  slug: string;
  program?: ProgramDetail | undefined;
  getCoachPage: (coach: Coach) => string;
  getVideoPage: (video: Video | string, meta?: VideoPageMetadata) => string;
  getProgramPage: (program: Program) => string;
  variant?: 'default' | 'whoop';
  onPlayerClosed?: () => void;
};

export function VideoDetail({
  variant = 'default',
  slug,
  program,
  getCoachPage,
  getVideoPage,
  getProgramPage,
  onPlayerClosed,
}: VideoDetailProps) {
  const { data: video, status, error, refetch } = useVideo(slug);

  const analytics = useAnalytics(
    video
      ? getVideoTrackingContext(video, {
          program,
          programTitle: program?.title,
        })
      : {},
  );
  const isFullyLoaded = isVideoDetail(video);
  const videoNotFound = error?.response?.status === 404;

  useEffect(() => {
    if (videoNotFound) {
      analytics.logEvent(GeneralTrackingEvent.NotFound);
    }
  }, [analytics, videoNotFound]);

  useEffect(() => {
    if (isFullyLoaded) {
      analytics.logEvent(GeneralTrackingEvent.PageLoad);
    }
  }, [analytics, isFullyLoaded]);

  return (
    <Box h="100%" pb="4">
      {status !== 'success' ? (
        <LoadingOrError
          status={status}
          errorTitle={'Error'}
          errorMessage="There was a problem loading this video."
          retry={refetch}
        />
      ) : video ? (
        <AnalyticsContext context={analytics.context}>
          <Head>
            <title>{`Arena Strive | Videos | ${video.title}`}</title>
          </Head>
          <VideoLibraryPlayer
            key={video.slug}
            video={video}
            getProgramHref={getProgramPage}
            getVideoHref={getVideoPage}
            disableUpNext={variant === 'whoop'}
            onPlayerClosed={onPlayerClosed}
            options={{ fluid: true }}
          />
          <Box px={8} pt={8}>
            <VideoDescription
              variant={variant}
              video={video}
              coachUrl={getCoachPage(video.coach)}
            />
          </Box>
          {program ? (
            <Box px={2} w="full">
              <ProgramsDetails
                program={program}
                getVideoHref={getVideoPage}
                showDescription={false}
              />
            </Box>
          ) : null}
        </AnalyticsContext>
      ) : null}
    </Box>
  );
}
