import { Tab, TabList, TabListProps } from '@chakra-ui/react';

import { useTabsContext } from './tabs-provider';

export function TabsList(props: TabListProps) {
  const { tabs, tabLabels, name } = useTabsContext();

  return (
    <TabList
      as="nav"
      aria-label={`${name} tabs list`}
      width="100%"
      px="4"
      pb="2"
      pt="0"
      border="none"
      {...props}
    >
      {tabs.map((tabId) => (
        <Tab key={tabId}>{tabLabels[tabId]}</Tab>
      ))}
    </TabList>
  );
}
