import {
  differenceInMinutes,
  formatDistanceToNowStrict,
  Locale,
} from 'date-fns';
import { enUS } from 'date-fns/locale';

type FormatRelativeTimeOptions = {
  roundTo?: number;
};

export function formatRelativeTime(
  date: number | Date,
  options: FormatRelativeTimeOptions = {},
) {
  const minutes = Math.abs(differenceInMinutes(new Date(), date));
  if (minutes >= 60) {
    return formatDistanceToNowStrict(date, { addSuffix: true });
  } else if (minutes < 15) {
    return 'just now';
  } else {
    const { roundTo = 5 } = options;
    const rounded = Math.round(minutes / roundTo) * roundTo;
    return rounded === 60 ? `1 hour ago` : `${rounded} minutes ago`;
  }
}

export function mergeDateLocale(locale: Locale, baseLocale = enUS) {
  return {
    ...baseLocale,
    ...locale,
  };
}

/**
 * This function takes a duration and rounds it to the nearest value of the largest unit.
 * For example, if the duration is 3 months and 2 weeks, the result will be 3 months.
 * If the duration is 11 months and 3 weeks, the result will be 1 year.
 * Note that the result may be in years, months, weeks, days, hours, minutes, or seconds.
 * This function assumes that duration is always positive.
 * The input is an object with optional fields years, months, weeks, days, hours, minutes, and seconds.
 * The output is an object with exactly one field, years, months, weeks, days, hours, minutes, or seconds.
 */
export function roundDuration(duration: Duration): Duration {
  if (duration.years) {
    const { months = 0 } = duration;
    const years = Math.round(duration.years + months / 12);
    return { years };
  } else if (duration.months) {
    const { weeks = 0 } = duration;
    const months = Math.round(duration.months + weeks / 4);
    return months === 12 ? { years: 1 } : { months };
  } else if (duration.weeks || (duration?.days ?? 0) >= 7) {
    const { days = 0 } = duration;
    let weeks = duration.weeks ?? 0;
    weeks = Math.round(weeks + days / 7);
    return weeks === 4 ? { months: 1 } : { weeks };
  } else if (duration.days) {
    const { hours = 0 } = duration;
    const days = Math.round(duration.days + hours / 24);
    return days === 7 ? { weeks: 1 } : { days };
  } else if (duration.hours) {
    const { minutes = 0 } = duration;
    const hours = Math.round(duration.hours + minutes / 60);
    return hours === 24 ? { days: 1 } : { hours };
  } else if (duration.minutes) {
    const { seconds = 0 } = duration;
    const minutes = Math.round(duration.minutes + seconds / 60);
    return minutes === 60 ? { hours: 1 } : { minutes };
  } else {
    const seconds = duration.seconds ?? 0;
    return { seconds };
  }
}
