import {
  BoxProps,
  Heading as ChakraHeading,
  Text,
  VStack,
} from '@chakra-ui/react';

export type HeadingProps = {
  as?: string;
  small?: string | React.ReactNode;
} & BoxProps;

export function Title({
  as = 'h1',
  small,
  fontSize = as,
  children,
  ...props
}: HeadingProps) {
  return (
    <VStack align="flex-start" spacing={0} {...props}>
      {small ? (
        <Text fontSize="p2" fontWeight="bold" textTransform="uppercase">
          {small}
        </Text>
      ) : null}
      <ChakraHeading as={as} fontSize={fontSize}>
        {children}
      </ChakraHeading>
    </VStack>
  );
}
