import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useCallbackRef } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';

/**
 * A React hook to call a callback when the output of a selector changes.
 * @param input The input to the selector.
 * @param selector A function to select an output value from the input.
 * @param onChange A callback to call when the output changes.
 */
export function useChangeEffect<InputType, OutputType>(
  input: InputType,
  selector: (input: InputType) => OutputType,
  onChange: (output: OutputType, previous: OutputType | undefined) => void,
) {
  const current = selector(input);
  const previous = usePrevious(current);
  const handleChange = useCallbackRef(onChange);

  useEffect(() => {
    if (!isEqual(current, previous)) {
      handleChange(current, previous);
    }
  }, [current, previous, handleChange]);
}
