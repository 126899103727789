import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';
import { streakSchema } from './user-profile';

type Incentive = z.infer<typeof IncentiveSchema>[number];
export type IncentiveType<T extends Incentive['type'] = Incentive['type']> =
  Extract<Incentive, { type: T }>;

export type AchievementIncentiveGroup =
  IncentiveType<'achievements'>['groups'][number];

const dataPointSchema = z.object({
  value: z.number(),
  trend: z.enum(['up', 'down', 'stable']),
});

const AchievementsSchema = z.object({
  type: z.literal('achievements'),
  slug: z.string(),
  active: z.boolean(),
  groups: z.array(
    z.object({
      type_id: z.number(),
      type_text: z.string(),
      incentives: z.array(
        z.object({
          title: z.string(),
          reward: z.string(),
          earned: zIsoDateTime.nullable(),
          icon: z.string(),
          icon_alternative: z.string(),
          alt_text: z.string(),
          count_data: z
            .object({
              target: z.number(),
              current: z.number(),
            })
            .optional()
            .nullable(),
        }),
      ),
    }),
  ),
});

const CompetitionSchema = z.object({
  start_date: zIsoDateTime,
  end_date: zIsoDateTime,
  latest_entry_date: zIsoDateTime,
  slug: z.string(),
  active: z.boolean(),
  type: z.literal('competition'),
  position: z.number().nullable(),
  score: dataPointSchema,
  hrv_above_avg: dataPointSchema,
  consistency: dataPointSchema,
  leaderboard: z
    .array(
      z.object({
        score: z.number(),
        rank: z.number(),
        name: z.string(),
        me: z.boolean().optional(),
      }),
    )
    .nullable(),
});

const IncentiveSchema = z.array(
  z.discriminatedUnion('type', [CompetitionSchema, AchievementsSchema]),
);

export const incentivesApi = makeApi([
  {
    alias: 'getStreakData',
    description: 'Fetch the Streaks Data',
    method: 'get',
    path: '/streaks/',
    response: z.object({
      levels: z.record(z.string(), z.string()),
      streak: streakSchema,
    }),
  },
  {
    alias: 'getIncentives',
    description: 'Fetch the Incentives',
    method: 'get',
    path: '/incentives/',
    response: IncentiveSchema,
  },
]);
