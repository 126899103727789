import React, { createContext, useContext } from 'react';
import { useRouter } from 'next/router';
import { Tabs } from '@chakra-ui/react';
import { once } from 'lodash-es';

/**
 * Tabs react context
 */
type TabIdObject<TabId extends string> = {
  [id in TabId]: string;
};
type TabsContextType<TabId extends string> =
  | {
      tabs: readonly TabId[];
      tabLabels: TabIdObject<TabId>;
      tabUrls: TabIdObject<TabId>;
      currentTab: TabId;
      name: string;
    }
  | Record<string, never>;

/**
 * Create a new context on the fly, and memoize it.
 * There's only going to be one context, but this will help us maintain types across different sections
 */
const createTabsContext = once(
  <TabId extends string>() =>
    createContext({}) as React.Context<TabsContextType<TabId>>,
);

export function useTabsContext<TabId extends string = string>() {
  return useContext(createTabsContext<TabId>());
}

/**
 * Provider component
 */
type TabsProviderProps<TabId extends string> = TabsContextType<TabId> & {
  children: React.ReactNode;
};

export function TabsProvider<TabId extends string>({
  children,
  ...props
}: TabsProviderProps<TabId>) {
  const router = useRouter();

  const TabsContext = createTabsContext<TabId>();
  const changeTab = (tabIndex: number) => {
    if (props.tabs) {
      const tabId = props.tabs[tabIndex];
      if (tabId) {
        router.push(props.tabUrls[tabId]);
      }
    }
  };

  return (
    <TabsContext.Provider value={props}>
      {props.tabs ? (
        <Tabs
          isLazy
          index={props.tabs.indexOf(props.currentTab)}
          variant="secondary"
          onChange={changeTab}
          size="md"
          sx={{
            '.chakra-tabs__tablist': {
              // I've tried putting this in the theme, but it keeps getting overwritten with 0px
              paddingTop: 2,
            },
          }}
        >
          {children}
        </Tabs>
      ) : (
        children
      )}
    </TabsContext.Provider>
  );
}
