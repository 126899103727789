import { useEffect } from 'react';
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';

import { SVGIconComponentType } from './icon';
import { Title } from './title';

export type ExplainerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
  body: string | JSX.Element;
  analyticsDescription: string;
  icon?: SVGIconComponentType;
  gradient?: string;
};
export function ExplainerModal({
  isOpen,
  onClose,
  title,
  body,
  icon,
  gradient,
  analyticsDescription,
}: ExplainerModalProps) {
  const analytics = useAnalytics({
    context: title,
  });

  useEffect(() => {
    if (!isOpen) return;
    analytics.logEvent(GeneralTrackingEvent.ExplainerOpened, {
      explainer: analyticsDescription,
    });
  }, [isOpen, analytics, analyticsDescription]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgGradient={gradient}>
        <ModalHeader>
          <HStack mt="2">
            {icon && <Icon as={icon} mr={2} boxSize={'35px'} />}
            {title && (
              <>
                {typeof title === 'string' ? (
                  <Title pt="1" fontSize="h3">
                    {title}
                  </Title>
                ) : (
                  title
                )}
              </>
            )}
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button size="sm" variant="primary" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
