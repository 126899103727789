import { Text } from '@chakra-ui/react';

import {
  VideoPlayerTimedComponent,
  VideoPlayerTimedComponentProps,
} from './video-player-timed-component';

export function VideoPlayerAnnotation({
  children,
  ...props
}: VideoPlayerTimedComponentProps) {
  return (
    <VideoPlayerTimedComponent
      position="absolute"
      top="0"
      right={[0, 10]}
      bg="gray.200"
      color="gray.600"
      p="2"
      fontSize={['xs', 'sm', 'md']}
      boxShadow="md"
      {...props}
    >
      <Text noOfLines={1}>{children}</Text>
    </VideoPlayerTimedComponent>
  );
}
