import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export type NotificationModalProps = {
  title: string;
  body: string;
  onClose: () => void;
  closeText?: string;
};

export function NotificationModal({
  title,
  body,
  onClose,
  closeText = 'Close',
}: NotificationModalProps) {
  return (
    <Modal isOpen isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{closeText}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
