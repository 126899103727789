import { useState } from 'react';
import {
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { z } from 'zod';

import {
  StatusText,
  TEXT,
  Title,
  useModalDisclosure,
} from '@arena-labs/strive2-ui';
import { $API, queryClient, useUserProfile } from '@strive/api';
import { PushPermissionsRequired } from '@strive/notifications';

ACIReminder.useDisclosure = function () {
  return useModalDisclosure('Aci Reminder');
};

export type ACIReminderProps = {
  isOpen: boolean;
  canSkip?: boolean;
  onSkip?: () => unknown;
  onSubmit: (selected_time: string) => Promise<unknown>;
  isLoading?: boolean;
  isError?: boolean;
};

export function ACIReminder({
  isOpen,
  canSkip,
  onSkip,
  onSubmit,
  isLoading,
  isError,
}: ACIReminderProps) {
  const onClose = (canSkip && onSkip) || (() => null);
  const { data: user } = useUserProfile();
  const displayableTime = (timeString: string) => {
    let timePieces = timeString.split(':');
    if (timePieces.length == 3) {
      timePieces.pop();
    }
    let isAM = true;
    if (timePieces[0] && Number(timePieces[0]) > 12) {
      timePieces[0] = (Number(timePieces[0]) - 12).toString();
      isAM = false;
    }
    let rejoinedTime = timePieces.join(':');

    return rejoinedTime + (isAM ? ' AM' : ' PM');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={0} my={0}>
        <ModalHeader zIndex={3} display="flex" w="full" justifyContent="center">
          <Heading>Reminders</Heading>
          {canSkip && <ModalCloseButton />}
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap="4">
            <Title fontSize="lg" fontWeight={'bold'} textAlign={'center'}>
              Would You like to set a Daily Reminder to Check-In?
            </Title>
            {user?.profile.selected_notification_time && (
              <Collapse
                in={Boolean(user?.profile.selected_notification_time)}
                animateOpacity
              >
                <Flex direction={'column'} gap={'2'}>
                  <Divider />
                  <Flex direction={'row'} justify={'space-between'}>
                    <TEXT.H3>Current Reminder: </TEXT.H3>
                    <TEXT.H3>
                      {displayableTime(user.profile.selected_notification_time)}
                    </TEXT.H3>
                  </Flex>
                  <Divider />
                </Flex>
              </Collapse>
            )}
            <Text fontSize="sm">
              Stay on track with your goals by signing up to receive gentle
              reminders to check in daily, ensuring you never miss an
              opportunity to make progress and maintain your streak
            </Text>

            <PushPermissionsRequired>
              <ACIReminderForm
                isError={isError}
                isLoading={isLoading}
                onSubmit={(selectedTime) =>
                  onSubmit(selectedTime).then(() =>
                    queryClient.invalidateQueries(
                      $API.getKeyByAlias('getUserProfile'),
                    ),
                  )
                }
              />
            </PushPermissionsRequired>
          </Flex>
        </ModalBody>
        <ModalFooter
          alignItems="center"
          gap="2"
          px="4"
          justifyContent="flex-start"
        >
          {canSkip && onClose ? (
            <Button
              variant="link"
              borderRadius="button"
              isLoading={isLoading}
              onClick={() => onClose()}
            >
              No Thanks
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

type ACIReminderFormProps = Pick<
  ACIReminderProps,
  'isLoading' | 'isError' | 'onSubmit'
>;
function ACIReminderForm({
  isLoading,
  isError,
  onSubmit,
}: ACIReminderFormProps) {
  const [selectedTime, setSelectedTime] = useState<string>(
    format(new Date(), 'HH:mm'),
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const validated = z
    .string()
    .regex(new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$'))
    .safeParse(selectedTime);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedTime(e.target.value);

  return (
    <>
      <VStack
        py="4"
        px="3"
        spacing="3"
        borderRadius={'card'}
        bgGradient="linear(to-r, primary.900 0%, primaryAlpha.2 120%)"
        w="full"
      >
        <Input
          value={selectedTime}
          onChange={handleChange}
          border={'none'}
          bg="gray.50"
          size="md"
          type="time"
          position="relative"
          color="strive.background"
        />
        <Button
          variant="primary"
          isActive={!!selectedTime}
          isLoading={isLoading}
          onClick={() => {
            setSubmitted(true);
            if (validated.success) {
              onSubmit(selectedTime);
            }
          }}
        >
          {!selectedTime ? 'Select a time above..' : 'Add Daily Reminder'}
        </Button>
      </VStack>

      {isError ? (
        <StatusText status="error" mt="0">
          An error occurred
        </StatusText>
      ) : (
        isSubmitted &&
        !validated.success && (
          <StatusText status="error" mt="0">
            {validated.error.format()._errors[0]}
          </StatusText>
        )
      )}
    </>
  );
}
