import { useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Flex,
  Grid,
  Icon,
  VStack,
} from '@chakra-ui/react';

import {
  SVGIconComponentType,
  TEXT,
  useFadeEntryFadeExit,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { UserHighRecharge } from '../icons/user-high-recharge';
import { UserLowRecharge } from '../icons/user-low-recharge';

type ACIEstimateValue = 'below' | 'above';

export function Estimate({
  estimate,
  persistSelection,
}: {
  estimate?: ACIEstimateValue;
  persistSelection: (selection: ACIEstimateValue) => void;
}) {
  const [selection, setSelection] = useState<ACIEstimateValue | null>(
    estimate ?? null,
  );

  const handleSelectOption = (selection: ACIEstimateValue) => {
    setSelection(selection);
  };

  const handleSubmitSelection = (selection: ACIEstimateValue) => {
    persistSelection(selection);
  };

  const { scope } = useFadeEntryFadeExit();

  return (
    <Flex ref={scope} flexGrow={1}>
      <Flex direction={'column'} h={'full'}>
        <TEXT.P1_SEMIBOLD color={'logo'} mt={10}>
          1 of 3
        </TEXT.P1_SEMIBOLD>

        <TEXT.H2 mt={4} mb={10}>
          Reflect on your recharge status over the last 24 hours.
        </TEXT.H2>

        <VStack spacing={4} mb={'auto'}>
          <RechargeEstimateOption
            onClick={() => handleSelectOption('below')}
            key={'Less than most days'}
            label={'Less than most days'}
            icon={UserLowRecharge}
            selected={selection === 'below'}
            selectedBg={{
              border: 'linear-gradient(254.14deg, #632D0F 0.68%, #FF5B00 100%)',
              background:
                'linear-gradient(74.15deg, rgba(44, 44, 46, 0.232) 21.7%, rgba(255, 92, 0, 0.284) 97.96%)',
            }}
          />
          <RechargeEstimateOption
            onClick={() => handleSelectOption('above')}
            key={'More than most days'}
            label={'More than most days'}
            icon={UserHighRecharge}
            selected={selection === 'above'}
            selectedBg={{
              border: 'linear-gradient(254.14deg, #146B6B 0.68%, #95FDFD 100%)',
              background:
                'linear-gradient(74.15deg, rgba(44, 44, 46, 0.232) 21.7%, rgba(149, 253, 253, 0.284) 97.96%)',
            }}
          />
        </VStack>

        <Button
          mt={'auto'}
          isDisabled={!selection}
          variant={'primary'}
          w={'full'}
          onClick={() => {
            selection && handleSubmitSelection(selection);
          }}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

function RechargeEstimateOption({
  label,
  icon,
  selected,
  selectedBg,
  onClick,
}: {
  label: string;
  icon: SVGIconComponentType;
  selected: boolean;
  selectedBg: { border: string; background: string };
  onClick: ButtonProps['onClick'];
}) {
  const rs = useResponsive();
  const border = selected ? selectedBg.border : 'none';
  const background = selected ? selectedBg.background : 'none';
  return (
    <Box rounded={'card'} p={'1px'} bg={border}>
      <Box rounded={'card'} layerStyle={'4dp'}>
        <chakra.button onClick={onClick} aria-pressed={selected}>
          <Grid
            alignItems={'center'}
            gap={8}
            templateColumns={'auto 1fr'}
            p="6"
            bgGradient={background}
            rounded={'card'}
          >
            <Box>
              <Icon as={icon} boxSize={rs({ xs: '85px', base: '112px' })} />
            </Box>
            <TEXT.H3 gridColumn={2}>{label}</TEXT.H3>
          </Grid>
        </chakra.button>
      </Box>
    </Box>
  );
}
