import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { PillarPractice } from '@arena-labs/shared-models';
import { $API } from '@strive/api';

export function usePracticeCache() {
  const queryClient = useQueryClient();
  return useCallback(
    (practice: PillarPractice) => {
      const practiceDetailKey = $API.getKeyByAlias('practiceDetail');
      queryClient.setQueryData(practiceDetailKey, practice);
    },
    [queryClient],
  );
}
