export type ContentSectionType = keyof typeof ContentSection;

export const ContentSection = {
  VIDEOS: 'Videos',
  SAVED: 'Saved',
  WATCHED: 'Watched',
} as const;

// The section list appears in this order
export const ContentSectionList = ['VIDEOS', 'SAVED', 'WATCHED'] as const;
