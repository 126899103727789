import { isPast } from 'date-fns';
import * as yup from 'yup';

import { LearningState } from '@arena-labs/strive2-coaching';

// Impersonate a user
export const impersonateSchema = yup.object({
  type: yup.string().oneOf(['myself', 'user_id', 'email']).required(),
  user_id: yup.number().when('type', {
    is: 'user_id',
    then: yup.number().required(),
    otherwise: yup.mixed().cast(undefined),
  }),
  email: yup.string().when('type', {
    is: 'email',
    then: yup.string().email().required(),
    otherwise: yup.mixed().cast(undefined),
  }),
});

export type ImpersonateData = yup.Asserts<typeof impersonateSchema>;

// Relocate on user journey

const learningStates: LearningState[] = [
  'onboarding',
  'in_progress',
  'complete',
];
export const relocateSchema = yup.object({
  learning_state: yup.string().oneOf(learningStates),
  learning_session: yup.number().when('learning_state', {
    is: (learning_state: LearningState) => {
      return learning_state === 'onboarding' || learning_state === 'complete';
    },
    then: yup.mixed().cast(undefined),
    otherwise: yup.number().integer().positive().required(),
  }),
});
export type RelocateData = yup.Asserts<typeof relocateSchema>;

// Delete data
const optionalDate = yup
  .date()
  .nullable()
  .transform((curr, orig) => (orig === '' ? null : curr))
  .test('is-past-date', 'Date must be in the past', (d) =>
    d ? isPast(d) : true,
  );
export const deleteDataSchema = yup.object({
  after: optionalDate,
  before: optionalDate.when('after', (after, schema) =>
    after ? schema.min(after, 'End date should be after from date') : schema,
  ),
  check_ins: yup.boolean().optional(),
  practices: yup.boolean().optional(),
  audio_plays: yup.boolean().optional(),
  video_views: yup.boolean().optional(),
  sensor_data: yup.boolean().optional(),
  pre_assessment: yup.boolean().optional(),
  post_assessment: yup.boolean().optional(),
  onboard: yup.boolean().optional(),
  app_gate: yup.boolean().optional(),
  incentives: yup.boolean().optional(),
  push_tokens: yup.boolean().optional(),
  local: yup.boolean().optional(),
});
export type DeleteDataData = yup.Asserts<typeof deleteDataSchema>;

// Generate data
export const generateDataSchema = yup.object({
  generate_whoop_data: yup.boolean().optional(),
  generate_aci_data: yup.boolean().optional(),
  generate_practice_data: yup.boolean().optional(),
});
export type GenerateDataData = yup.Asserts<typeof generateDataSchema>;

export const environmentSchema = yup.object({
  environment: yup.string().required(),
  server: yup.string().when('environment', {
    is: -1,
    then: yup.string().url().required(),
    otherwise: yup.mixed().cast(undefined),
  }),
  api: yup.string().when('environment', {
    is: -1,
    then: yup.string().url().required(),
    otherwise: yup.mixed().cast(undefined),
  }),
});
export type EnvironmentData = yup.Asserts<typeof environmentSchema>;
