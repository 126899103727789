export class AssertError extends Error {}

export function assert(
  condition: unknown,
  message: string | (() => string) = 'Assertion failed',
  reportMessage?: (message: string) => void,
): asserts condition {
  if (condition) return;

  const errorMessage = message instanceof Function ? message() : message;
  reportMessage?.(errorMessage);

  const assertErr = new AssertError(errorMessage);

  // Remove the constructor and assert function calls from the stack trace
  // in V8 environments
  if (typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(assertErr, assert);
  } else {
    // For other environments, attempt to clean up the stack trace by
    // removing the first 2 lines
    const stack = new Error().stack?.split('\n').slice(2).join('\n');
    assertErr.stack = stack;
  }
  throw assertErr;
}
