import { Ref } from 'react';
import {
  BoxProps,
  forwardRef,
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
  keyframes,
} from '@chakra-ui/react';

export type SVGIconComponentType = React.FC<React.SVGProps<SVGSVGElement>> & {
  title?: string;
  titleId?: string;
};

export type IconProps = {
  as: BoxProps['as'];
  animate?: boolean;
  sx?: ChakraIconProps['sx'];
  size?: ChakraIconProps['boxSize'];
} & ChakraIconProps;

export const Icon = forwardRef(function Icon(
  { animate, sx = {}, size, ...props }: IconProps,
  ref: Ref<SVGSVGElement>,
) {
  const animationStyles = animate
    ? {
        '[data-animate-draw]': {
          strokeDasharray: 'var(--stroke-dasharray)',
          strokeDashoffset: 'var(--stroke-dasharray)',
          animation: `${animateDraw} var(--animate-duration, 1s) ease-in-out forwards`,
          animationDelay: 'var(--animate-delay, 0s)',
        },
        '[data-animate-draw="reverse"]': {
          strokeDashoffset: 'calc(-1 * var(--stroke-dasharray))',
        },
      }
    : {};

  const sizeProps = size ? { width: 'auto', height: size } : {};
  return (
    <ChakraIcon
      ref={ref}
      sx={{ ...sx, ...animationStyles }}
      {...props}
      {...sizeProps}
    />
  );
});

const animateDraw = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;
