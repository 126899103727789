import * as Sentry from '@sentry/nextjs';

export function identifySentry(
  id: string,
  attributes: Record<string, string | number | boolean | null>,
) {
  const { email, ...rest } = attributes;

  // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
  Sentry.setUser({ id, email: email as string });

  // https://docs.sentry.io/platforms/javascript/enriching-events/context/
  Sentry.setContext('strive', rest);

  // https://docs.sentry.io/platforms/javascript/enriching-events/tags/
  for (const [key, value] of Object.entries(rest)) {
    Sentry.setTag(`strive.${key}`, value);
  }
}

export function resetSentry() {
  Sentry.getCurrentScope().clear();
}

export function reportErrorSentry(error: unknown) {
  Sentry.captureException(error);
}
