import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const adminToolsApi = makeApi([
  {
    alias: 'sendTestNotification',
    description: 'Send a test push notification',
    method: 'post',
    path: '/notifications/test/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          token: z.string().optional(),
          type: z.string().optional(),
        }),
      },
    ],
    response: z.unknown(),
  },
]);
