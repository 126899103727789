import { z } from 'zod';

import { useDataStore } from '@strive/utils';

export function useEducationPopoverViewed(popoverID: string) {
  const [educationPopoverViewed, setEducationPopover] = useDataStore({
    key: `Product_Education.${popoverID}`,
    schema: z.boolean(),
    fallback: false,
  });

  const toggle = () => {
    if (educationPopoverViewed) {
      setEducationPopover(false);
    } else {
      setEducationPopover(true);
    }
  };
  return [educationPopoverViewed, toggle] as const;
}
