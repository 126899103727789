import * as yup from 'yup';

// Form schema
export const contactFormSchema = yup.object({
  category: yup.string().required('Please select a category'),
  message: yup
    .string()
    .required()
    .trim()
    .min(10, 'Please provide more information'),
  contactPreference: yup
    .string()
    .oneOf(['email', 'sms'])
    .nullable()
    .required('Please select a contact preference'),
});

export type ContactFormData = yup.Asserts<typeof contactFormSchema>;
