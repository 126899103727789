import { useCallback, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Token } from '@capacitor/push-notifications';
import { useCallbackRef } from '@chakra-ui/react';
import { useActor } from '@xstate/react';
import invariant from 'tiny-invariant';
import { interpret, InterpreterFrom } from 'xstate';
import create from 'zustand';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { apiClient, useUserProfile } from '@strive/api';
import { usePushToken } from '@strive/notifications';

import {
  chatConnectionMachine,
  ChatConnectionProps,
} from './chat-connection.machine';

type ChatService = InterpreterFrom<typeof chatConnectionMachine>;

type ChatStore = {
  service: ChatService;
  actions: {
    connect: (config: ChatConnectionProps) => void;
    disconnect: () => void;
    setPushToken: (token: string | undefined, enable: boolean) => void;
  };
};

/**
 * A data store for the Auth service.
 */
const useStore = create<ChatStore>()((set, get) => ({
  service: interpret(chatConnectionMachine).start(),
  actions: {
    connect(data) {
      const service = get().service;
      service.send({ type: 'Connect', data });
    },
    disconnect: () => {
      const { service } = get();
      service.send({ type: 'Disconnect' });
    },
    setPushToken: (token, enable) => {
      const { service } = get();
      service.send({ type: 'Set push token', token, enable });
    },
  },
}));

export const useStriveChatContext = () => {
  const service = useStore((store) => store.service);
  const [state] = useActor(service);

  return {
    state,
    status: state.value,
    client: state.context.client,
    channel: state.context.channel,
  };
};

export function useStriveChatActions() {
  const actions = useStore((store) => store.actions);
  const { data: user } = useUserProfile();

  const [homepage] = useHomepage();

  let channel = user?.profile.channel_name_with_coach;
  let channelType = 'messaging';
  if (homepage?.learning_state === 'launchpad') {
    channel = user?.profile.channel_name_with_launchpad;
    channelType = 'launchpad';
  }

  const getUserData = useCallbackRef(
    () =>
      user && {
        userToken: user.profile.stream_token,
        userId: user.profile.stream_username,
        userProps: {
          name: user.first_name + ' ' + user.last_name,
          striveUser: user.id,
          team: user.profile.team,
          teamId: user.profile.team_id,
        },
      },
  );

  const connect = useCallback(
    function () {
      const userData = getUserData();
      if (!userData) return;

      invariant(channel, 'We need a channel to connect to');
      actions.connect({
        channel,
        channelType,
        ...userData,
      });
    },
    // `connect()` changes identity when channel/channelType change
    // `getUserData` and `actions` are stable references
    [channel, channelType, getUserData, actions],
  );

  return {
    ...actions,
    connect,
  };
}

export const ChatContextProvider = ({ children }: React.PropsWithChildren) => {
  const { connect, setPushToken } = useStriveChatActions();

  const { data: user } = useUserProfile();

  // TODO: Move this to the state machine (useEffect is a code-smell and best avoided).
  // There's a lot of fragility, and hidden complexity in the chain of events that lead
  // this effect to run
  useEffect(() => connect(), [connect]);

  const registerTokenToStrive = (token?: Token | undefined) => {
    if (!token) return;

    apiClient.registerDevice({
      registration_id: token.value,
      type: Capacitor.getPlatform() as 'ios' | 'android' | 'web',
    });
  };

  // Register device token with Stream
  const token = usePushToken(registerTokenToStrive)?.value;
  const pushEnabled = user?.profile.send_push;
  useEffect(() => {
    setPushToken(token, !!pushEnabled);
  }, [pushEnabled, setPushToken, token]);

  return children;
};
