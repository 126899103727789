import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { looseEnum, zIsoDateTime } from '../lib/schema-utils';

const commonMetadata = {
  version: z.string(),
  url: z.string().url(),
  date: zIsoDateTime,
  commit: z.object({
    sha: z.string(),
    author: z.string(),
    title: z.string(),
    date: zIsoDateTime,
  }),
} as const;

export const resourcesS3Api = makeApi([
  {
    method: 'get',
    alias: 'getOpenPRs',
    path: '/release/channel/open_prs.json',
    parameters: [],
    response: z.array(
      z.object({
        number: z.number(),
        title: z.string(),
        updated_at: zIsoDateTime,
        author: z.string(),
        url: z.string(),
      }),
    ),
  },
  {
    method: 'get',
    alias: 'getReleaseChannel',
    path: '/release/channel/:name/latest.json',
    parameters: [
      {
        name: 'name',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.discriminatedUnion('type', [
      z.object({
        type: z.literal('pull_request'),
        ...commonMetadata,
        number: z.number(),
        title: z.string(),
        author: z.string(),
        created_at: zIsoDateTime,
        updated_at: zIsoDateTime,
        branch: z.string(),
        base_branch: z.string(),
        state: z.string(),
        merged: z.boolean().nullable(),
        labels: z.array(
          z.object({
            color: z.string(),
            name: z.string(),
          }),
        ),
        files_changed: z.number(),
        comments: z.number(),
        review_comments: z.number(),
      }),
      z.object({
        type: z.literal('release'),
        ...commonMetadata,
        tag: z.string(),
        name: z.string(),
        author: z.string(),
        published_at: zIsoDateTime,
        draft: z.boolean(),
        prerelease: z.boolean(),
        body: z.string(),
      }),
      z.object({
        type: z.literal('commit'),
        ...commonMetadata,
        branch: z.string(),
        pusher: z.string(),
      }),
    ]),
  },
  {
    alias: 'getServiceStatus',
    description: 'Get the status of the service',
    method: 'get',
    path: '/service-status/:env/latest.json',
    parameters: [
      {
        name: 'env',
        type: 'Path',
        schema: looseEnum(['development', 'staging', 'production']),
      },
    ],
    response: z.discriminatedUnion('status', [
      z.object({
        status: z.literal('ok'),
      }),
      z.object({
        status: z.literal('disrupted'),
        issue: z
          .object({
            number: z.number(),
            url: z.string(),
            published: zIsoDateTime,
            updated: zIsoDateTime,
            status: z.enum(['open', 'closed']),
            title: z.string(),
            description: z.string(),
            comments: z
              .array(
                z.object({
                  id: z.number(),
                  updated_at: zIsoDateTime,
                  body: z.string(),
                }),
              )
              .catch([]),
          })
          .nullable()
          .catch(null),
      }),
    ]),
  },
]);
