import { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Flex,
  FormControl,
  Input,
  useCallbackRef,
} from '@chakra-ui/react';
import { useActor } from '@xstate/react';
import { ActorRefFrom } from 'xstate';

import { TEXT } from '@arena-labs/strive2-ui';

import { createVerifySmsMachine } from '../lib/verify-sms.machine';

// We do not care here about the type of `meta` stored in the context
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const machine = createVerifySmsMachine<any>();

export type OtpFormProps = {
  service: ActorRefFrom<typeof machine>;
  buttonLabel: string;
  label?: string;
};

export function OtpForm({
  service,
  buttonLabel,
  label = 'Enter Code',
}: OtpFormProps) {
  const [state, send] = useActor(service);
  const { recipient } = state.context;
  const isValidating = state.matches('Verifying SMS.Verifying');
  const isInvalid = state.matches('Verifying SMS.Waiting.Failed');
  const onRetry = () => send('Resend');

  const [otp, setOtp] = useState('');

  // WebOTP: See https://web.dev/web-otp/
  // Also: https://www.youtube.com/watch?v=sU4MpWYrGSI
  const onSubmit = useCallbackRef(
    (code: string) => send({ type: 'Submit', code }),
    // send('SMS Code Verified'),
  );
  useEffect(() => {
    let ac: AbortController;
    if ('OTPCredential' in window) {
      ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        } as CredentialRequestOptions)
        .then((otp) => {
          if (isOtpCredential(otp)) {
            setOtp(otp.code);
            onSubmit(otp.code);
          }
        })
        .catch((error) => console.error(error));
    }

    return () => ac?.abort('OTP Autofill Aborted');
  }, [onSubmit]);

  useEffect(() => {
    if (otp.length === 8) onSubmit(otp);
  }, [otp, onSubmit]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(otp);
  };

  const renderAuxLinkButtons = () => (
    <Flex justifyContent="space-between" w="full">
      <Button
        variant="link"
        color="strive.primary"
        onClick={() => onRetry()}
        alignSelf="center"
        fontSize={'p2'}
      >
        <u> Resend Code</u>
      </Button>
      <Button
        variant="link"
        color="strive.primary"
        onClick={() => send('Start again')}
        alignSelf="center"
        fontSize={'p2'}
      >
        <u>Change Phone</u>
      </Button>
    </Flex>
  );

  return (
    <chakra.form
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      style={{ height: '100%' }}
    >
      <Flex gap={3} direction={'column'} align="flex-start" h={'full'}>
        <TEXT.H2 mt={10} color={'neutral.white'} transition={'all 1s linear'}>
          Enter Code
        </TEXT.H2>
        <TEXT.P1 color={'neutral.400'}>
          We sent it to <strong>{recipient ?? 'you'}</strong>.
        </TEXT.P1>
        <FormControl id="otp-input" isInvalid={isInvalid}>
          <Input
            type="tel"
            color="white"
            fontWeight="bold"
            maxLength={8}
            isInvalid={isInvalid}
            onChange={(event) => setOtp(event.target.value)}
            value={otp}
            autoComplete="one-time-code"
            fontSize="p1"
            height="auto"
            py={'3'}
            placeholder="Enter code"
          />
        </FormControl>
        {renderAuxLinkButtons()}
        {isInvalid && (
          <Alert
            status="error"
            flexDirection="column"
            alignItems="flex-start"
            variant="left-accent"
          >
            <Flex>
              <AlertIcon />
              <AlertTitle>Invalid code</AlertTitle>
            </Flex>
            <AlertDescription>
              Your code may have expired. Please check that you have typed it
              correctly and try again.
            </AlertDescription>
          </Alert>
        )}
        <Button
          type="submit"
          variant="primary"
          isLoading={isValidating}
          w="full"
          mt={'auto'}
        >
          {buttonLabel}
        </Button>
      </Flex>
    </chakra.form>
  );
}

function isOtpCredential(otp: unknown): otp is { type: 'otp'; code: string } {
  return (
    typeof otp === 'object' &&
    otp !== null &&
    'type' in otp &&
    'code' in otp &&
    (otp as { type: string }).type === 'otp'
  );
}
