import { useMemo } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import debouncePromise from 'debounce-promise';
import { debounce } from 'lodash-es';

import { useShallowCompareMemo } from './use-compare-fn-memo';

/**
 * A custom hook that creates a debounced callback function.
 */
export function useDebounceCallbackRef<TArgs extends unknown[], TReturn>(
  fn: (...args: TArgs) => TReturn,
  delay: number,
  options: Parameters<typeof debounce>[2] = {},
) {
  const fnRef = useCallbackRef(fn);
  const debounceOptions = useShallowCompareMemo(options);
  return useMemo(
    () => debounce(fnRef, delay, debounceOptions),
    [fnRef, delay, debounceOptions],
  );
}

/**
 * A custom hook that creates a debounced **async** callback function.
 */
export function useDebounceAsyncCallbackRef<
  TArgs extends unknown[],
  TReturn extends Promise<unknown>,
>(
  fn: (...args: TArgs) => TReturn,
  delay: number,
  options: Parameters<typeof debouncePromise>[2] = {},
) {
  const fnRef = useCallbackRef(fn);
  const debounceOptions = useShallowCompareMemo(options);
  return useMemo(
    () => debouncePromise(fnRef, delay, debounceOptions),
    [fnRef, delay, debounceOptions],
  );
}
