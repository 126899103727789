import { useMemo, useState } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import produce from 'immer';
import { z } from 'zod';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { PlayableAudio, PlayableVideo } from '@arena-labs/shared-models';
import { createZustandDataStore, useThrottleCallbackRef } from '@strive/utils';

const progressValueSchema = z.tuple([z.number(), z.number()]);
const progressStoreSchema = z.object({
  audio: z.record(z.string(), progressValueSchema),
  video: z.record(z.string(), progressValueSchema),
});

type MediaProgressValue = z.infer<typeof progressValueSchema>;
type MediaProgressActions = {
  update: (type: 'video' | 'audio', slug: string, progress: number) => void;
  getValue: (
    type: 'video' | 'audio',
    slug: string,
  ) => MediaProgressValue | undefined;
  reset: () => void;
};
type MediaProgressStore = z.infer<typeof progressStoreSchema> & {
  actions: MediaProgressActions;
};

// A simple Zustand store that keeps track of the progress of media
export const useMediaProgressStore = create<MediaProgressStore>()(
  persist(
    (set, get) => ({
      video: {},
      audio: {},
      actions: {
        update: (type, slug, progress) => {
          set(
            produce((draft) => {
              draft[type][slug] = [progress, Date.now()];
            }),
          );
        },
        getValue: (type, slug) => get()[type][slug],
        reset: () => {
          set({
            audio: {},
            video: {},
          });
        },
      },
    }),
    {
      ...createZustandDataStore<MediaProgressStore>({
        key: 'Media.Progress',
        schema: progressStoreSchema,
        partialize: (state) => ({
          audio: state.audio,
          video: state.video,
        }),
      }),
      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            console.error('Hydration error', error);
          }
        };
      },
    },
  ),
);

export function useMediaProgressActions() {
  return useMediaProgressStore((store) => store.actions);
}

type UseInitialMediaStartTimeProps = {
  rewind?: number;
  finalSecondsLimit?: number;
  staleTime?: number;
};

/**
 * Creates a `loadedmetadata` event handler that seeks to the last known
 * progress of the media, rewinding by `rewind` seconds.
 */
export function useInitialMediaStartTime(
  media: PlayableVideo | PlayableAudio,
  {
    rewind = 10,
    finalSecondsLimit = 5,
    staleTime = Infinity,
  }: UseInitialMediaStartTimeProps = {},
) {
  const getProgressValue = useMediaProgressStore(
    (store) => store.actions.getValue,
  );
  const { slug, type: mediaType } = media;

  // Get the progress value from the store
  const percent = useMemo(() => {
    const [percent = 0, played = 0] = getProgressValue(mediaType, slug) ?? [];
    if (played < Date.now() - staleTime) {
      // If the progress is stale, don't use it
      return 0;
    }
    return percent;
  }, [getProgressValue, mediaType, slug, staleTime]);

  // Calculate the start time
  const getStartTime = (duration = mediaDuration) => {
    const timeCode = (percent / 100) * duration;
    const timeRemaining = duration - timeCode;
    return timeRemaining < finalSecondsLimit
      ? // Treat the media as finished if it's near the end
        duration
      : // Otherwise rewind by `rewind` seconds
        Math.max(0, timeCode - rewind);
  };

  const mediaDuration = media.duration ? Math.round(Number(media.duration)) : 0;
  const [startTime, setStartTime] = useState(getStartTime(mediaDuration));

  // Create a mediaevent handler that seeks to the start time
  const seekToStartTime = useCallbackRef<
    React.ReactEventHandler<HTMLMediaElement>
  >((event) => {
    const target = event.target;
    if (target instanceof HTMLMediaElement) {
      // Just in case the `media.duration` is missing or invalid, we use the
      // actual duration of the media element
      const actualStartTime = getStartTime(target.duration);
      setStartTime(actualStartTime);
      if (actualStartTime) target.currentTime = actualStartTime;
    }
  });

  return [startTime, seekToStartTime] as const;
}

/**
 * Creates a throttled `timeupdate` event handler that records the media progress
 * in the store every second
 */
export function useMediaProgressHandler(media: PlayableVideo | PlayableAudio) {
  // Create a throttled update function
  const updateProgress = useMediaProgressStore((store) => store.actions.update);
  const throttledUpdate = useThrottleCallbackRef(updateProgress, 1000, {
    leading: true,
  });

  // Return a callback that calculates the progress and calls the throttled update
  return useCallbackRef<React.ReactEventHandler<HTMLMediaElement>>((event) => {
    const target = event.target;
    if (
      target instanceof HTMLMediaElement ||
      target instanceof HTMLAudioElement
    ) {
      if (Number.isNaN(target.duration)) return;
      throttledUpdate(
        media.type,
        media.slug,
        (target.currentTime / target.duration) * 100,
      );
    }
  });
}
