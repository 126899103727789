import { z } from 'zod';

export const coachDetailSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  suffix: z.string(),
  slug: z.string(),
  url: z.string(),
  headshot_url: z.string(),
  detail_title: z.string(),
  group: z.string(),
  bio: z.string(),
  coach_links: z.array(
    z.object({
      link: z.string(),
      title: z.string(),
      description: z.string(),
    }),
  ),
});

export const coachListItemSchema = coachDetailSchema
  .pick({
    first_name: true,
    last_name: true,
    suffix: true,
    group: true,
    headshot_url: true,
    url: true,
    slug: true,
  })
  .extend({
    list_title: z.string(),
  });

export const coachMinimumDetailSchema = coachDetailSchema.pick({
  first_name: true,
  last_name: true,
  suffix: true,
  headshot_url: true,
  slug: true,
});
