import { useMemo } from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/react';

export type PersonIconProps = AvatarProps & {
  person: {
    first_name: string;
    last_name: string;
    headshot_url?: string;
  };
};

export function PersonIcon({ person, ...props }: PersonIconProps) {
  const { headshot_url } = person;
  const src = useMemo(() => {
    if (!headshot_url) {
      return;
    }

    // add imagekit params to resize and crop the image around the face
    // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations
    const srcUrl = new URL(headshot_url);
    srcUrl.searchParams.set('tr', 'w-100,h-100,fo-face');
    return srcUrl.toString();
  }, [headshot_url]);

  return <Avatar src={src} {...props} />;
}
