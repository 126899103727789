import { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { ACILocation } from '@arena-labs/shared-models';
import { useModalDisclosure, ZStack } from '@arena-labs/strive2-ui';

import { ACIForm } from './aci-form';
import { ACIPostResponse } from './aci-form.machine';
import { useACIGate } from './aci-gate';
import { ACIScore } from './aci-score';

LegacyACIModal.useDisclosure = function () {
  return useModalDisclosure('ACI');
};

export type LegacyACIModalProps = {
  location?: ACILocation;
  onSuccess?: (results: ACIPostResponse) => void;
} & Omit<DrawerProps, 'children'>;

export function LegacyACIModal({
  location,
  onSuccess,
  ...props
}: LegacyACIModalProps) {
  // Analytics & reset the form when the drawer opens
  const analytics = useAnalytics();

  const [results, setResults] = useState<ACIPostResponse>();

  // Handle success message
  const handleSuccess = (results: ACIPostResponse) => {
    setResults(results);
    onSuccess?.(results);
    analytics.logEvent(DataTrackingEvent.ACISubmitted);
  };

  const aciGate = useACIGate();
  const handleClose = () => {
    setResults(undefined);
    props.onClose();
    aciGate.actions.reset();
  };

  return (
    <Drawer placement="bottom" size="full" {...props} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody mt="8" p="0">
          <DrawerCloseButton top="12" zIndex="1" />
          <ZStack h="full" overflow="hidden" variant="position">
            <motion.div
              style={{ height: '100%' }}
              animate={results ? { y: '-100%' } : {}}
              transition={{ duration: 1.5, ease: [0.43, 0.13, 0.23, 0.96] }}
              className="form"
            >
              <ACIForm
                location={location}
                onSuccess={handleSuccess}
                wrapper={{ overflowY: 'auto' }}
              />
            </motion.div>
            <motion.div
              style={{ height: '100%', inset: 'initial' }}
              initial={{ y: '100%', opacity: 0 }}
              animate={
                results ? { y: 0, opacity: 1, inset: 0, width: '100%' } : {}
              }
              transition={{ duration: 1.5, ease: [0.43, 0.13, 0.23, 0.96] }}
              className="results"
            >
              {results ? (
                <ACIScore
                  score={results.current}
                  streak={results.streak}
                  onProceed={() => handleClose()}
                />
              ) : null}
            </motion.div>
          </ZStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
