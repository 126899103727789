/// <reference types="@types/dom-screen-wake-lock" />
import { useMemo, useRef } from 'react';

// Screen Wake Lock (https://developer.mozilla.org/en-US/docs/Web/API/WakeLock)
// Not supported on iOS or Safari, allows us to request that the screen does not go to sleep
export function useWakeLock() {
  const lockSentinel = useRef<WakeLockSentinel>();
  const lockPending = useRef<boolean>(false);
  const expireTimeout = useRef<number>();

  const wakeLock = useMemo(() => {
    return {
      async lock(expire = 15 * 60) {
        if ('wakeLock' in navigator) {
          // First reset/re-initialize the cleanup timeout
          if (Number.isFinite(expire) && expire > 0) {
            window.clearTimeout(expireTimeout.current);
            expireTimeout.current = window.setTimeout(() => {
              if (lockSentinel.current) {
                lockSentinel.current.release();
              }
            }, expire * 1000);
          }

          if (lockSentinel.current || lockPending.current) {
            // reuse existing lock;
            return;
          }

          // Set up a new wakelock
          try {
            lockPending.current = true;
            lockSentinel.current = await navigator.wakeLock.request('screen');
            lockSentinel.current.addEventListener('release', () => {
              // clean up
              lockSentinel.current = undefined;
              window.clearTimeout(expireTimeout.current);
              expireTimeout.current = undefined;
            });
          } catch (error) {
            console.error('Could not acquire wake lock', error);
          } finally {
            lockPending.current = false;
          }
        }
      },
      release() {
        if (lockSentinel.current) {
          lockSentinel.current.release();
        }
      },
    };
  }, []);

  return wakeLock;
}
