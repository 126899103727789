import NextLink from 'next/link';
import {
  Box,
  Flex,
  HStack,
  Link,
  StackProps,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';

import {
  getFromVideo,
  isVideoDetail,
  PlayableVideo,
  Video,
} from '@arena-labs/shared-models';
import {
  formatCoachName,
  PersonIcon,
  SkillTag,
  TEXT,
} from '@arena-labs/strive2-ui';

import { useCoachCache } from '../../api/coaches';

function CoachName({
  coach,
  ...props
}: StackProps & { coach: Video['coach'] }) {
  const coachName = formatCoachName(coach);
  return (
    <HStack {...props}>
      <PersonIcon person={coach} size="xs" />
      <TEXT.P1 pl="2" color="neutral.400">
        {coachName}
      </TEXT.P1>
    </HStack>
  );
}

export type VideoDescriptionProps = {
  variant?: 'default' | 'whoop';
  video: Video | PlayableVideo;
  coachUrl?: string;
  mediaType?: 'audio' | 'video';
  withReactions?: boolean;
};

export function VideoDescription({
  variant = 'default',
  mediaType = 'video',
  withReactions = false,
  video,
  coachUrl,
}: VideoDescriptionProps) {
  const setCoachCache = useCoachCache();

  //round the duration here
  const duration = Math.round(
    Number(getFromVideo(video, 'duration', '0')) / 60,
  );

  return (
    <VStack spacing={4} align="stretch" mb="4">
      {variant === 'default' && 'skill' in video && video.skill && (
        <Box>
          <SkillTag skill={video.skill} />
        </Box>
      )}

      <Flex justifyContent="space-between" alignItems="flex-start">
        <TEXT.H2>{video.title}</TEXT.H2>
        <TEXT.P1 color="neutral.400">
          <TEXT.P1 as="span">
            <span aria-hidden>{duration}m</span>
            <VisuallyHidden>{duration} minutes.</VisuallyHidden>
          </TEXT.P1>
        </TEXT.P1>
      </Flex>
      {video.coach ? (
        <>
          {variant === 'default' && coachUrl ? (
            <Link
              as={NextLink}
              href={coachUrl}
              onClick={() =>
                isVideoDetail(video) ? setCoachCache(video.coach) : null
              }
            >
              <CoachName coach={video.coach} />
            </Link>
          ) : (
            <CoachName coach={video.coach} />
          )}
        </>
      ) : null}
      {withReactions && 'liked' in video && (
        <Flex justifyContent="space-between" alignItems="center" mt={4}>
          <>
            <HStack spacing="4">
              {video.watched && (
                <TEXT.P3 fontWeight="bold">
                  {mediaType === 'audio' ? 'Listened' : 'Watched'}
                </TEXT.P3>
              )}
            </HStack>
          </>
        </Flex>
      )}
      <TEXT.P2 lineHeight={'taller'} color={'neutral.400'}>
        {getFromVideo(video, 'description')}
      </TEXT.P2>
    </VStack>
  );
}
