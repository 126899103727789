import { z } from 'zod';

import { safeEnum } from '../lib/schema-utils';
import {
  playableAudioSchema,
  playableVideoSchema,
  videoListItemSchema,
} from './media.schema';

export type PillarPracticeSchema = z.infer<typeof pillarPracticeSchema>;

export const pillarPracticeSchema = z.object({
  pillar_order: z.number().optional(),
  order: z.number(),
  slug: z.string(),
  title: z.string(),
  short_title: z.string().nullable(),
  prompt: z.string().nullable(),
  coach_me: playableVideoSchema.or(playableAudioSchema),
  intro: playableVideoSchema.or(playableAudioSchema),
  log_count: z.number(),
  related_videos: z.array(videoListItemSchema),
  completed_today: z.boolean().default(false),
  completion_count: z.number().default(0),
  streak: z.number().default(0),
  favorite: z.boolean(),
  status: safeEnum(['not_started', 'skipped', 'completed']),
  background_image: z.string().nullable().catch(null),
});
export const pillarSchema = z.object({
  url: z.string(),
  order: z.number(),
  slug: z.string(),
  title: z.string(),
  description: z.string(),
  intro: playableVideoSchema.or(playableAudioSchema).nullable().optional(),
  practices: z.array(pillarPracticeSchema),
  icon: z.string(),
  short_title: z.string(),
  state: z
    .enum(['not_started', 'in_progress', 'completed'])
    .nullable()
    .catch(null),
});

export const pillarListItemSchema = pillarSchema.pick({
  title: true,
  description: true,
  slug: true,
  order: true,
  practices: true,
  icon: true,
  short_title: true,
  state: true,
});
