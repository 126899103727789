import React, { useCallback, useEffect, useRef } from 'react';
import { useCallbackRef } from '@chakra-ui/hooks';
import produce from 'immer';
import create from 'zustand';

type ScrollStateType = {
  positions: Record<string, { x: number; y: number } | undefined>;
  setScrollPosition: (id: string, x: number, y: number) => void;
};
const useScrollState = create<ScrollStateType>()((set) => ({
  positions: {},
  setScrollPosition: (id: string, x: number, y: number) => {
    set(
      produce((draft) => {
        draft.positions[id] = { x, y };
      }),
    );
  },
  reset: () => {
    set({ positions: {} });
  },
}));

export function useRestoredScroll<
  ElementType extends HTMLElement = HTMLDivElement,
>(id: string) {
  const scrollRef = useRef<ElementType>(null);
  const setScrollPosition = useScrollState(
    useCallback((state) => state.setScrollPosition, []),
  );
  const scrollPosition = useScrollState(
    useCallback((state) => state.positions[id], [id]),
  );
  const getCurrentPosition = useCallbackRef(() => scrollPosition);

  useEffect(() => {
    const element = scrollRef.current;

    const onScroll = (event: Event) => {
      const evt = event as unknown as React.UIEvent<ElementType>;
      setScrollPosition(
        id,
        evt.currentTarget.scrollLeft,
        evt.currentTarget.scrollTop,
      );
    };

    const initialPosition = getCurrentPosition();
    if (element) {
      // Restore previous scroll position
      element.scrollLeft = initialPosition?.x ?? 0;
      element.scrollTop = initialPosition?.y ?? 0;

      // Listen to scroll events
      element.addEventListener('scroll', onScroll);
    }

    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll);
      }
    };
  }, [id, getCurrentPosition, setScrollPosition]);

  return { ref: scrollRef };
}
