export { ReactComponent as NotoThumbsUp } from './NotoThumbsUp.svg';
export { ReactComponent as NotoThumbsUpDarkSkinTone } from './NotoThumbsUpDarkSkinTone.svg';
export { ReactComponent as NotoThumbsUpLightSkinTone } from './NotoThumbsUpLightSkinTone.svg';
export { ReactComponent as NotoThumbsUpMediumDarkSkinTone } from './NotoThumbsUpMediumDarkSkinTone.svg';
export { ReactComponent as NotoThumbsUpMediumLightSkinTone } from './NotoThumbsUpMediumLightSkinTone.svg';
export { ReactComponent as NotoThumbsUpMediumSkinTone } from './NotoThumbsUpDarkSkinTone.svg';
export { ReactComponent as NotoPartyPopper } from './NotoPartyPopper.svg';
export { ReactComponent as NotoBrain } from './NotoBrain.svg';
export { ReactComponent as NotoCheckMarkButton } from './NotoCheckMarkButton.svg';
export { ReactComponent as NotoFlexBiceps } from './NotoFlexBiceps.svg';
