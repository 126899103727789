import { useForm } from 'react-hook-form';
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HTMLChakraProps,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContactFormData, contactFormSchema } from './contact-form-schema';

export type ContactFormProps = {
  context: ReturnType<typeof useContactForm>;
} & HTMLChakraProps<'form'>;

export function useContactForm() {
  return useForm<ContactFormData>({
    resolver: yupResolver(contactFormSchema),
  });
}

export function ContactForm({
  context,
  onSubmit,
  ...formProps
}: ContactFormProps) {
  const {
    register,
    formState: { errors },
  } = context;

  return (
    <chakra.form {...formProps} onSubmit={onSubmit}>
      <Stack direction="column" w="full" spacing="6">
        <FormControl
          id="help-form-category"
          isInvalid={Boolean(errors.category)}
        >
          <FormLabel fontWeight="bold" mb="1">
            I have feedback or a question about
          </FormLabel>
          <Select {...register('category')}>
            <option value="">Select a category</option>
            <option>Data</option>
            <option>Content</option>
            <option>Coaching</option>
            <option>WHOOP</option>
            <option>It&apos;s something else...</option>
          </Select>
          <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="help-form-message" isInvalid={Boolean(errors.message)}>
          <FormLabel fontWeight="bold" mb="1">
            MESSAGE
          </FormLabel>
          <Textarea
            {...register('message')}
            placeholder="Your message"
            rows={6}
          />
          <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="help-form-contactPreference"
          isInvalid={Boolean(errors.contactPreference)}
        >
          <RadioGroup
            defaultValue="sms"
            aria-labelledby="help-form-contactPreference-label"
          >
            <Text fontWeight="bold" mb="2">
              We will contact you via:
            </Text>
            <Stack spacing={2} direction="column">
              <Radio
                {...register('contactPreference')}
                value="sms"
                fontSize="sm"
              >
                SMS
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>
            {errors.contactPreference?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </chakra.form>
  );
}
