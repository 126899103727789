import * as yup from 'yup';

import { emailSchema, stringSchema } from '@arena-labs/shared-models';

// Form schema
export const guestContactFormSchema = yup.object({
  category: yup.string().required('Please select a category'),
  message: yup
    .string()
    .required()
    .trim()
    .min(10, 'Please provide more information'),
  name: stringSchema.required().trim().min(4, 'Please provide your name'),
  email: emailSchema.required().trim().min(4, 'Please provide your email'),
});

export type GuestContactFormData = yup.Asserts<typeof guestContactFormSchema>;
