import { useEffect } from 'react';
import Head from 'next/head';
import { Box, Heading } from '@chakra-ui/react';

import {
  AnalyticsContext,
  GeneralTrackingEvent,
  useAnalytics,
} from '@arena-labs/analytics';
import {
  Coach,
  isVideoDetail,
  Video,
  VideoPageMetadata,
} from '@arena-labs/shared-models';
import { PageStack } from '@arena-labs/strive2-ui';
import { AudioPlayer, useVideoViewMutation } from '@strive/av';

import { Program } from '../api';
import { useVideo } from '../api/videos';
import { VideoDescription } from '../components/video/video-description';
import { getVideoTrackingContext } from '../lib/tracking-helpers';

export type AudioDetailProps = {
  slug: string;
  programSlug?: string;
  getCoachPage: (coach: Coach) => string;
  getVideoPage?: (video: Video, meta?: VideoPageMetadata) => string;
  getProgramPage?: (program: Program) => string;
  variant?: 'default' | 'whoop';
  allowSeek?: boolean;
  onListened?: () => void;
};

export function AudioDetail({
  slug,
  variant,
  getCoachPage,
  allowSeek,
  onListened,
}: AudioDetailProps) {
  const { data: audio, error } = useVideo(slug);
  const analytics = useAnalytics(audio ? getVideoTrackingContext(audio) : {});

  const isFullyLoaded = isVideoDetail(audio);
  const saveAudioPlayed = useVideoViewMutation(); // This is actually video as far as the API is concerned

  const audioNotFound = error?.response?.status === 404;
  useEffect(() => {
    if (audioNotFound) {
      analytics.logEvent(GeneralTrackingEvent.NotFound);
    }
  }, [analytics, audioNotFound]);

  useEffect(() => {
    if (isFullyLoaded) {
      analytics.logEvent(GeneralTrackingEvent.PageLoad);
    }
  }, [analytics, isFullyLoaded]);

  return (
    <Box pb="4">
      {error ? (
        <Heading m={4}>
          {error.response?.status === 404
            ? 'Sorry, we could not find the video you were looking for'
            : 'Sorry, there was a problem loading this video, please try later'}
        </Heading>
      ) : audio ? (
        <AnalyticsContext context={analytics.context}>
          <Head>
            <title>{`Arena Strive | Audios | ${audio.title}`}</title>
          </Head>
          <PageStack spacing={6} alignItems="stretch">
            <Box alignSelf="center" p="4">
              <AudioPlayer
                playBackID={audio.playback_id}
                duration={audio.duration}
                onPlayerClosed={(progress) =>
                  saveAudioPlayed.mutateAsync([slug, progress]).then(onListened)
                }
                allowSeek={allowSeek}
              />
            </Box>
            <VideoDescription
              variant={variant}
              mediaType="audio"
              video={audio}
              coachUrl={getCoachPage(audio.coach)}
            />
          </PageStack>
        </AnalyticsContext>
      ) : null}
    </Box>
  );
}
