export default function GaugeArc() {
  return (
    <svg viewBox="0 0 36 25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 24a16.55 16 0 1 1 30 0"
        stroke="#313131"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M3 24a16.55 16 0 1 1 30 0"
        className="scoreIndicator"
        stroke="url(#competition-gauge-gradient)"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <defs>
        <linearGradient
          id="competition-gauge-gradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#46646B" />
          <stop offset="100%" stopColor="#7EA7B1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
