import {
  makeApi,
  ZodiosBodyByAlias,
  ZodiosResponseByAlias,
} from '@zodios/core';

import { aciApi } from './aci';
import { adminToolsApi } from './admin-tools';
import { appGateApi } from './app-gate';
import { biometricsApi } from './biometrics';
import { coachDashboardApi } from './coach-dashboard';
import { contentAPI, playlistApi } from './content';
import { deviceApi } from './device';
import { homepageApi } from './homepage';
import { incentivesApi } from './incentives';
import { launchpadApi } from './launchpad';
import { notificationsApi } from './notifications';
import { pillarsApi } from './pillars';
import { practicesApi } from './practices';
import { resourcesS3Api } from './resources-s3';
import { userProfileApi } from './user-profile';

export * from './coach-dashboard';
export type { BiometricsResponse } from './biometrics';
export type { AppGates, AppGateProps } from './app-gate';
export type { IncentiveType, AchievementIncentiveGroup } from './incentives';
export { homepageSchema } from './homepage';
export { learningStateSchema } from './user-profile';
export { resourcesS3Api };
export { addressSchema } from './launchpad';

export const striveAppApi = makeApi([
  ...aciApi,
  ...adminToolsApi,
  ...appGateApi,
  ...biometricsApi,
  ...contentAPI,
  ...deviceApi,
  ...homepageApi,
  ...incentivesApi,
  ...notificationsApi,
  ...pillarsApi,
  ...practicesApi,
  ...userProfileApi,
  ...launchpadApi,
]);
export const coachPortalApi = makeApi([
  ...userProfileApi,
  ...coachDashboardApi,
  ...playlistApi,
]);

export type StriveApiResponse<
  Alias extends (typeof striveAppApi)[number]['alias'],
> = ZodiosResponseByAlias<typeof striveAppApi, Alias>;

export type StriveApiRequest<
  Alias extends (typeof striveAppApi)[number]['alias'],
> = ZodiosBodyByAlias<typeof striveAppApi, Alias>;

export type CoachApiResponse<
  Alias extends (typeof coachPortalApi)[number]['alias'],
> = ZodiosResponseByAlias<typeof coachPortalApi, Alias>;

export type CoachApiRequest<
  Alias extends (typeof coachPortalApi)[number]['alias'],
> = ZodiosBodyByAlias<typeof coachPortalApi, Alias>;

export type ResourceApiResponse<
  Alias extends (typeof resourcesS3Api)[number]['alias'],
> = ZodiosResponseByAlias<typeof resourcesS3Api, Alias>;
