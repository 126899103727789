import { useEffect } from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Heading,
} from '@chakra-ui/react';

import {
  AnalyticsContext,
  MediaTrackingEvent,
  MediaTrackingTag,
  useAnalytics,
} from '@arena-labs/analytics';
import { PlayableAudio } from '@arena-labs/shared-models';
import { SwipeableDrawerBody } from '@arena-labs/strive2-ui';

import { AudioPlayer } from '../audio-player';
import { useAudioPlayMutation } from './media-played';

export type AudioModalProps = {
  audio: PlayableAudio;
  onPlayerClosed?: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
} & Omit<DrawerProps, 'children' | 'orientation'>;

export function AudioModal({
  audio,
  onPlayerClosed,
  title,
  children,
  ...props
}: AudioModalProps) {
  const saveAudioPlayed = useAudioPlayMutation();

  const analytics = useAnalytics({
    tags: [MediaTrackingTag.AudioPlayer],
    videoSlug: audio.slug, // to delete at some point in the future
    audioSlug: audio.slug,
  });
  useEffect(() => {
    if (props.isOpen) {
      analytics.logEvent(MediaTrackingEvent.AudioModalOpen);
    }
  }, [analytics, props.isOpen]);

  if (!audio.slug) {
    return null;
  }

  return (
    <AnalyticsContext context={analytics.context}>
      <Drawer placement="bottom" size="full" {...props}>
        <DrawerOverlay />
        <DrawerContent bg="modal.bg">
          <DrawerHeader paddingRight="48px">
            {title ?? (
              <Heading as="h2" fontSize="xl">
                {audio.title}
              </Heading>
            )}
            <DrawerCloseButton />
          </DrawerHeader>

          <SwipeableDrawerBody onSwipe={props.onClose} px="6">
            <Flex
              flexDirection="column"
              gap="4"
              align="stretch"
              minHeight="full"
            >
              <AudioPlayer
                duration={audio.duration}
                playBackID={audio.playback_id}
                onPlayerClosed={(progress) =>
                  saveAudioPlayed
                    .mutateAsync([audio.slug, progress])
                    .then(onPlayerClosed)
                }
                transcript={audio.transcript}
              />
              {children}
            </Flex>
          </SwipeableDrawerBody>
        </DrawerContent>
      </Drawer>
    </AnalyticsContext>
  );
}
