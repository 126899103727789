import { useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import pMemoize from 'p-memoize';

export type AppInfo = {
  platform: string;
  version: string | undefined;
  nativeVersion: string | undefined;
  capgoVersion: string | undefined;
  build: string | undefined;
  sha: string | undefined;
  environment: string | undefined;
  channel: string | undefined;
};

export function useAppInfo() {
  const [appInfo, setAppInfo] = useState<AppInfo>();
  useEffect(() => {
    getAppInfo().then(setAppInfo);
  }, []);
  return appInfo;
}

export const getAppInfo = pMemoize(async function getAppInfo() {
  const platform = Capacitor.getPlatform();
  let capgoVersion;
  let nativeVersion;
  let build;
  let channel;
  try {
    await Promise.allSettled([
      App.getInfo().then((info) => {
        build = info.build;
        nativeVersion = info.version;
      }),
      ...(Capacitor.isNativePlatform()
        ? [
            CapacitorUpdater.current().then(
              (res) => (capgoVersion = res.bundle.version),
            ),
            CapacitorUpdater.getChannel().then(
              (res) => (channel = res.channel),
            ),
          ]
        : []),
    ]);
  } catch (error) {
    // ignore, this fails on web
  }
  const version =
    (capgoVersion === 'builtin' ? nativeVersion : capgoVersion) ||
    nativeVersion ||
    process.env.VERSION;
  const appInfo = {
    platform,
    version,
    nativeVersion,
    capgoVersion,
    sha: process.env.STRIVE_GIT_SHA,
    build,
    channel,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  };

  console.log('App Info', appInfo);
  return appInfo;
});
