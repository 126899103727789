import { useState } from 'react';
import { useToken } from '@chakra-ui/react';

import { TEXT, useDeviceInfo, ZStack } from '@arena-labs/strive2-ui';

export type CompetitionStarProps = {
  size: 'list' | 'gauge' | 'info';
  positionValue: number | string | null;
  showOutline?: boolean;
};
export function CompetitionStar({
  size,
  positionValue,
  showOutline = true,
}: CompetitionStarProps) {
  const [gold, silver, bronze] = useToken('colors', [
    'warning.300',
    'neutral.100',
    'tertiary.600',
  ]);
  const device = useDeviceInfo();
  const [positionColors] = useState([
    gold,
    silver,
    bronze,
    '#7EA7B1',
    '#7EA7B1',
  ]);
  let highlightColor: string | undefined;
  let textColor = 'neutral.white';
  if (typeof positionValue === 'number') {
    highlightColor = positionColors[positionValue - 1];
    if (positionValue <= 3) textColor = highlightColor as string;
  }

  if (typeof positionValue === 'string') {
    highlightColor = positionColors[4];
  }

  return (
    <ZStack boxSize={size === 'gauge' ? 12 : size === 'info' ? 120 : 10}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 41" fill="none">
        <g>
          <path
            d="M20.4157 6.85254C20.6971 6.44201 21.3029 6.44201 21.5843 6.85254L26.5003 14.0257C26.5924 14.1601 26.7281 14.2586 26.8843 14.3047L35.2255 16.7635C35.7029 16.9042 35.8901 17.4804 35.5866 17.8749L30.2837 24.7669C30.1844 24.8961 30.1326 25.0555 30.1371 25.2184L30.3761 33.9111C30.3898 34.4086 29.8997 34.7647 29.4308 34.598L21.2373 31.6844C21.0838 31.6298 20.9162 31.6298 20.7627 31.6844L12.5692 34.598C12.1003 34.7647 11.6102 34.4086 11.6239 33.9111L11.8629 25.2184C11.8674 25.0555 11.8156 24.8961 11.7163 24.7669L6.41336 17.8749C6.10986 17.4804 6.29708 16.9042 6.77446 16.7635L15.1157 14.3047C15.2719 14.2586 15.4076 14.1601 15.4997 14.0257L20.4157 6.85254Z"
            fill={'url(#star)'}
            filter={
              highlightColor
                ? `drop-shadow(0px 0px 0.147599995136261px ${highlightColor})  drop-shadow(0px 0px 0.9332000255584717px ${highlightColor}) drop-shadow(0px 0px 1.9px ${highlightColor})`
                : 'none'
            }
          />
          {device?.platform !== 'ios' && showOutline ? (
            <path
              d="M20.644 31.3507L20.7627 31.6844L20.644 31.3507L12.4506 34.2643C12.2161 34.3477 11.971 34.1696 11.9779 33.9209L12.217 25.2281C12.2237 24.9838 12.146 24.7446 11.997 24.551L6.69405 17.6589C6.5423 17.4617 6.63591 17.1736 6.8746 17.1032L15.2158 14.6444C15.4502 14.5753 15.6536 14.4275 15.7918 14.2259L20.7079 7.05276C20.8485 6.84749 21.1515 6.84749 21.2921 7.05276L26.2082 14.2259C26.3464 14.4275 26.5498 14.5753 26.7842 14.6444L35.1254 17.1032C35.3641 17.1736 35.4577 17.4617 35.3059 17.6589L30.003 24.551C29.854 24.7446 29.7763 24.9838 29.783 25.2281L30.0221 33.9209C30.029 34.1696 29.7839 34.3477 29.5494 34.2643L21.356 31.3507C21.1257 31.2688 20.8743 31.2688 20.644 31.3507Z"
              stroke={highlightColor}
              strokeWidth="0.6"
            />
          ) : null}
        </g>
        <defs>
          <linearGradient
            id="star"
            x1="21"
            y1="6"
            x2="21"
            y2="38"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#315B66" />
            <stop offset="1" stopColor="#10272D" />
          </linearGradient>
        </defs>
      </svg>

      <TEXT.P1
        color={textColor}
        pt={'1px'}
        my="auto"
        mx="auto"
        fontSize={size === 'gauge' ? 'auto' : size === 'info' ? '40px' : '13px'}
        fontFamily={'RacingSansOne-Regular'}
        textShadow={
          highlightColor ? `0px 0px 1.2px ${highlightColor}` : ' none'
        }
      >
        {positionValue || '-'}
      </TEXT.P1>
    </ZStack>
  );
}
