import Link from 'next/link';
import {
  AspectRatio,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { VideoJsPlayer } from 'video.js';

import { ContentTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  Video,
  VideoListItem,
  VideoPageMetadata,
} from '@arena-labs/shared-models';
import {
  VideoPlayerAnnotation,
  VideoPlayerTimedComponent,
} from '@arena-labs/strive2-ui';

import { Program } from '../../api';

export type VideoPlayerOverlaysProps = {
  player?: VideoJsPlayer | null;
  video: Video;
  upNextVideo?: VideoListItem | null;
  getVideoHref: (video: Video, meta?: VideoPageMetadata) => string;
  getProgramHref: (program: Program) => string;
  disableUpNext?: boolean;
};

/**
 * Renders components overlaid on the video player.
 * 1. The "Next video" is promoted in a small annotation during the last 15 seconds of playback
 * 2. The "Next video" is promoted in the "end card"
 *
 * On _desktop_, the user can also like/dislike the video in the end card
 */
export function VideoPlayerOverlays({
  player,
  video,
  upNextVideo,
  getVideoHref,
  getProgramHref,
  disableUpNext,
}: VideoPlayerOverlaysProps) {
  const analytics = useAnalytics();

  if (!player) {
    return null;
  }

  const getThumbail = (vid: VideoListItem) =>
    `https://image.mux.com/${vid.playback_id}/thumbnail.png?width=500&time=${vid.thumbnail_time_code}`;
  const getUpNextVideoHref = (vid: VideoListItem) => getVideoHref(vid);

  return (
    <>
      {/* "Up next" annotation in last 15 seconds of video */}
      {upNextVideo && !disableUpNext && (
        <VideoPlayerAnnotation
          as={LinkBox}
          player={player}
          start={-15}
          end={-1}
        >
          Up next:{' '}
          <Link href={getUpNextVideoHref(upNextVideo)} passHref legacyBehavior>
            <LinkOverlay
              textDecoration="underline"
              onClick={() =>
                analytics.logEvent(
                  ContentTrackingEvent.FollowUpNextAnnotation,
                  {
                    upNextVideoSlug: upNextVideo.slug,
                    upNextVideoTitle: upNextVideo.title,
                  },
                )
              }
            >
              {upNextVideo.title}
            </LinkOverlay>
          </Link>
        </VideoPlayerAnnotation>
      )}

      {/* Like buttons + "Up next" at end of video */}
      <VideoPlayerTimedComponent
        player={player}
        start={-0.5}
        end={99999}
        inset="0"
        position="absolute"
        display="grid"
        placeItems="center"
        pointerEvents="none"
      >
        <HStack
          w="full"
          p={['4', '8']}
          spacing="8"
          align="center"
          justify="center"
          bg="rgba(0 0 0 / 0.9)"
          borderRadius="sm"
        >
          {upNextVideo && !disableUpNext ? ( // "Up next" if there is one in the same program
            <VStack
              as={LinkBox}
              maxW="50vw"
              pointerEvents="fill"
              flex="1"
              spacing="2"
              align="flex-start"
              w={['fit-content', 'min-content']}
            >
              <VStack spacing="2" align="stretch" width={{ sm: 'full' }}>
                <Heading as="h2" fontSize={['md', 'xl', '2xl']}>
                  UP NEXT
                </Heading>
                <AspectRatio
                  ratio={16 / 9}
                  minWidth={['150px']}
                  maxWidth="50vw"
                >
                  <Image
                    src={getThumbail(upNextVideo)}
                    alt=""
                    objectFit="cover"
                    loading="eager"
                    flexShrink={0}
                  />
                </AspectRatio>
              </VStack>
              <Link
                href={getUpNextVideoHref(upNextVideo)}
                passHref
                legacyBehavior
              >
                <LinkOverlay
                  fontSize={['xs', 'md']}
                  onClick={() =>
                    analytics.logEvent(
                      ContentTrackingEvent.FollowUpNextVideoEndCard,
                      {
                        upNextVideoSlug: upNextVideo.slug,
                        upNextVideoTitle: upNextVideo.title,
                      },
                    )
                  }
                >
                  <Text noOfLines={1}>{upNextVideo.title}</Text>
                </LinkOverlay>
              </Link>
            </VStack>
          ) : null}
        </HStack>
      </VideoPlayerTimedComponent>
    </>
  );
}
