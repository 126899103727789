import { useState } from 'react';
import { BoxProps, chakra, Fade } from '@chakra-ui/react';
import { oembed, validate } from '@loomhq/loom-embed';
import { OEmbedInterface } from '@loomhq/loom-embed/dist/.types/oembed';
import { useQuery } from '@tanstack/react-query';

import { WaitForQuery } from '@arena-labs/strive2-ui';

import { getLoomEmbedUrl, getLoomUrl } from './loom-url';

export type LoomVideoEmbedProps = {
  loomID: string;
  onLoad?: (meta: OEmbedInterface) => void;
  styles?: BoxProps['sx'];
};

export function LoomVideoEmbed({
  loomID,
  styles,
  onLoad,
}: LoomVideoEmbedProps) {
  const loomMeta = useLoomVideoData(getLoomUrl(loomID));
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <WaitForQuery query={loomMeta}>
      {(loomMetaData) => (
        <Fade in={isLoaded} style={{ height: '100%', maxHeight: '100%' }}>
          <chakra.iframe
            title={loomMetaData.title}
            onLoad={() => {
              setIsLoaded(true);
              onLoad?.(loomMetaData);
            }}
            allowFullScreen
            src={getLoomEmbedUrl(loomID)}
            sx={{
              border: 'none',
              borderRadius: '10px',
              width: 'auto',
              height: '100%',
              maxHeight: '100%',
              ...styles,
            }}
          />
        </Fade>
      )}
    </WaitForQuery>
  );
}

export function useLoomVideoData(loomUrl: string) {
  return useQuery({
    queryKey: ['loom', loomUrl],
    queryFn: () => oembed(loomUrl),
    enabled: validate.isLoomUrl(loomUrl),
  });
}
