import { assert } from './assert';

type ImageToBlobOptions = {
  url: string;
  type?: string;
};

export function imageToBlob(options: ImageToBlobOptions) {
  return new Promise<Blob>((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    assert(ctx, 'Canvas context is null');
    const img = new Image();

    img.crossOrigin = 'Anonymous'; // Attempt to address CORS issues, might not work for all images
    img.src = options.url;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject('Canvas.toBlob() returned null');
          return;
        }
        resolve(blob);
      }, options.type ?? 'image/jpeg');
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}
