import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { isObjectWithKey } from '@arena-labs/shared-models';
import { apiClient, StriveApiResponse } from '@strive/api';

export type ProgramListItem =
  StriveApiResponse<'getPlaylists'>['groups'][number];
export type ProgramDetail = StriveApiResponse<'getPlaylist'>;

export type Program = ProgramDetail | ProgramListItem;

export function useProgramCache() {
  const queryClient = useQueryClient();
  return useCallback(
    (program: Program, stale = true) => {
      const programQuery = ['program', program.slug];
      if (!queryClient.getQueryData(programQuery)) {
        queryClient.setQueryData(programQuery, program);
      }
      if (stale) {
        queryClient.invalidateQueries(programQuery);
      }
    },
    [queryClient],
  );
}

// Can return either ProgramDetail or ProgramListItem since `useProgramCache` accepts both
export function useProgram(slug: string) {
  return useQuery({
    queryKey: ['program', slug],
    queryFn: () => apiClient.getPlaylist({ params: { slug } }),
    enabled: !!slug,
  });
}

export function isProgramDetail(program: unknown): program is ProgramDetail {
  return (
    isProgram(program) && 'videos' in program && Array.isArray(program.videos)
  );
}

export function isProgram(program: unknown): program is Program {
  return (
    isObjectWithKey(program, 'video_count') &&
    typeof program.video_count === 'number'
  );
}
