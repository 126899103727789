import { Capacitor } from '@capacitor/core';
import * as yup from 'yup';

import { marketingSchema, phoneSchema, stringSchema } from './schema';
import { UserProfile, UserUpdateBody } from './types';

/**
 * Validation schema for the edit user profile form
 */
export type EditProfileData = yup.Asserts<typeof editProfileSchema>;
export const editProfileSchema = yup.object({
  firstName: stringSchema.label('First name').max(50),
  lastName: stringSchema.label('Last name').max(50),
  preferred_name: stringSchema.min(1).max(25),
  phone: phoneSchema.nullable(),
  marketing: marketingSchema,
  pushPermissions: yup.mixed().when('marketing.push', {
    is: true,
    then: yup
      .string()
      [
        Capacitor.isPluginAvailable('PushNotifications')
          ? 'required'
          : 'optional'
      ]('Please activate push notifications, or toggle it off')
      .oneOf(
        ['granted', 'denied'],
        'Please activate push notifications, or toggle it off',
      ),
    otherwise: yup.string().nullable(),
  }),
});

export function createProfileUpdateRequest(
  data: EditProfileData,
  user: UserProfile,
): UserUpdateBody {
  return {
    user: {
      username: user.username,
      email: user.email,
      first_name: data.firstName,
      last_name: data.lastName,
    },
    profile: {
      phone_number: data.phone,
      send_email: data.marketing.email ?? false,
      send_sms: data.marketing.sms ?? false,
      send_push: data.marketing.push ?? false,
      goal: user.profile.goal ?? null,
    },
  };
}

export function createEmailUpdateRequest(
  data: { email: string },
  user: UserProfile,
): UserUpdateBody {
  return {
    user: {
      username: data.email,
      email: data.email,
      first_name: user.first_name,
      last_name: user.last_name,
    },
  };
}
