import 'swiper/css';
import 'swiper/css/pagination';

import { useRef } from 'react';
import { useMount, useUnmount } from 'react-use';
import {
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Portal,
  useBreakpointValue,
  useCallbackRef,
} from '@chakra-ui/react';
import { merge } from 'lodash-es';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  AnalyticsContext,
  MediaTrackingEvent,
  useAnalytics,
} from '@arena-labs/analytics';
import {
  isMediaPlayedThisSession,
  LoomVideo,
  PlayableAudio,
  PlayableMedia,
  PlayableVideo,
} from '@arena-labs/shared-models';
import { formatCoachName } from '@arena-labs/strive2-ui';
import { useDebounceAsyncCallbackRef } from '@strive/utils';

import { useMediaPlayMutation } from '../media/media-played';
import { MediaHandleRef } from '../media-handle';
import { EndPromptDisplayProps, EndPromptProps } from './end-prompt-display';
import { MediaQueueAudio } from './media-queue-audio';
import { MediaQueueLoom } from './media-queue-loom';
import { MediaQueueVideo } from './media-queue-video';

export type MediaQueueEndPromptProps = EndPromptProps;

export type MediaQueueProps = {
  items: Array<PlayableVideo | PlayableAudio | LoomVideo>;
  initialIndex?: number;
  mode?: 'strict' | 'loose';
  onClose?: () => void;
  isFullScreen?: boolean;
  swipeToClose?: boolean;
  boxProps?: BoxProps;
  videoProps?: React.ComponentProps<typeof MediaQueueVideo>['videoProps'];
  endPrompt?: EndPromptDisplayProps['render'];
};

export function MediaQueue({
  items,
  mode = 'strict',
  initialIndex = 0,
  onClose,
  boxProps,
  ...props
}: MediaQueueProps) {
  const mediaHandles = useRef<Map<string, MediaHandleRef>>(new Map());
  const mediaRefCallback = useMediaCallback<MediaHandleRef>((slug, ref) => {
    if (ref) {
      mediaHandles.current.set(slug, ref);
    }
  });
  const currentIndex = useRef(initialIndex);
  const contentPlayed = useMediaPlayMutation();
  const thumbnailWidth = useBreakpointValue({ base: 480, sm: 768, md: 992 });
  const getHandle = (idx: number) => {
    const media = items[idx];
    if (media) {
      return mediaHandles.current.get(
        media.type === 'loom' ? media.loom_id : media.slug,
      );
    }
  };

  // Report progress for all media items that have been played
  // Keep track of recorded progress to avoid reporting the same progress multiple times
  const reportedProgress = useRef<Map<string, number>>(new Map());
  const reportProgress = useDebounceAsyncCallbackRef(
    async (item: PlayableMedia, percent?: number) => {
      const slug = item.type === 'loom' ? item.loom_id : item.slug;
      const previous = reportedProgress.current.get(slug) ?? 0;
      // We don't know loom progress so we just pretend it's 100% to make it consistent
      const current =
        item.type === 'loom'
          ? 100
          : percent ??
            mediaHandles.current.get(slug)?.getContext().currentPercent;
      if (item && current && current !== previous) {
        reportedProgress.current.set(slug, current);
        await contentPlayed.mutateAsync([item.type, slug, current], {
          onError: () => {
            reportedProgress.current.set(slug, previous);
          },
        });
      }
    },
    200,
    { leading: true },
  );

  const analytics = useAnalytics({
    queueLength: items.length,
    activeIndex: currentIndex.current,
  });

  useMount(() => {
    analytics.logEvent(MediaTrackingEvent.MediaQueueOpened);
  });

  useUnmount(() => {
    const idx = currentIndex.current;
    const media = items[idx];
    if (media) {
      reportProgress(media);
    }
    analytics.logEvent(MediaTrackingEvent.MediaQueueClosed);
  });

  // Get the max progress for the current media item
  const getMediaProgress = (item: PlayableMedia) => {
    if (item.type === 'loom') {
      return 100;
    }
    const handle = mediaHandles.current.get(item.slug);
    const player = handle?.player;
    const playerProgress = player
      ? (player.currentTime() * 100) / player.duration()
      : 0;
    const progress = Math.max(
      handle?.getContext().maxPercent ?? 0,
      playerProgress,
    );
    return progress;
  };

  const handleClose = onClose
    ? () => {
        if (mode === 'strict' && currentIndex.current !== items.length - 1) {
          // If we're in strict mode, we only close after the final media item
          return;
        }
        onClose();
      }
    : undefined;

  return (
    <AnalyticsContext parent={analytics}>
      <Swiper
        style={{ height: '100%', width: '100%' }}
        initialSlide={initialIndex}
        modules={[Pagination]}
        pagination={mode === 'loose' && { el: '.pagination', type: 'bullets' }}
        slidesPerView={1}
        spaceBetween={0}
        allowTouchMove={mode !== 'strict'}
        noSwipingSelector=".swiper-no-swiping, input, .vjs-control-bar"
        onSlideNextTransitionStart={(controller) => {
          // if `mode` is 'strict', we need to check if the user has played the media
          // when the user swipes to the next slide.
          // If not, we need to go back to the previous slide
          const previous = items[controller.previousIndex];
          if (
            previous &&
            mode === 'strict' &&
            !isMediaPlayedThisSession(previous)
          ) {
            const progress = getMediaProgress(previous);
            if (progress < 70) {
              controller.slideTo(controller.previousIndex);
            }
          }
        }}
        onReachEnd={() => {
          if (props.swipeToClose) {
            // This is a hack to make sure the video is paused before closing
            setTimeout(() => onClose?.(), 300);
          }
        }}
        onActiveIndexChange={(controller) => {
          currentIndex.current = controller.activeIndex;
          const previousItem = items[controller.previousIndex];
          const previous = getHandle(controller.previousIndex);
          const current = getHandle(controller.activeIndex);

          analytics.logEvent(MediaTrackingEvent.MediaQueueChanged, {
            previousIndex: controller.previousIndex,
            activeIndex: controller.activeIndex,
          });

          if (previous) {
            previous.player?.pause();
            previous.hide?.();
          }
          if (previousItem) {
            reportProgress(previousItem);
          }
          if (current) {
            // Play the new video
            const readyState = current.player?.readyState() ?? 0;
            if (readyState > 1) {
              current.player?.play();
            }
          }
        }}
      >
        {items.map((media, idx) =>
          media.type === 'video' ? (
            <SwiperSlide key={media.slug}>
              <AnalyticsContext
                context={{
                  videoSlug: media.slug,
                  videoTitle: media.title,
                  videoDuration: media.duration && parseFloat(media.duration),
                  coach: media.coach && formatCoachName(media.coach),
                  coachSlug: media.coach?.slug,
                  watched: media.watched,
                  watchedThisSession: media.watched_within_current_session,
                }}
              >
                <MediaQueueVideo
                  ref={mediaRefCallback(media.slug)}
                  key={media.slug}
                  mode={mode}
                  video={media}
                  videoProps={props.videoProps}
                  autoPlay={idx === initialIndex}
                  beforeContinue={() => reportProgress(media, 100)}
                  onEnded={() => reportProgress(media, 100)}
                  onClose={handleClose}
                  isFullScreen={props.isFullScreen}
                  thumbnailWidth={thumbnailWidth}
                  boxProps={merge({}, boxProps, {
                    sx: {
                      '--footer-offset': mode === 'loose' ? '35px' : '0px',
                    },
                  })}
                  isActive={idx === currentIndex.current}
                  nextMedia={items[idx + 1]}
                  showEndPrompt={mode === 'strict'}
                  endPrompt={props.endPrompt}
                  {...props}
                />
              </AnalyticsContext>
            </SwiperSlide>
          ) : media.type === 'audio' ? (
            <SwiperSlide key={media.slug}>
              <AnalyticsContext
                context={{
                  audioSlug: media.slug,
                  audioTitle: media.title,
                  audioDuration: parseFloat(media.duration),
                  played: media.played,
                  playedThisSession: media.played_within_current_session,
                }}
              >
                <MediaQueueAudio
                  ref={mediaRefCallback(media.slug)}
                  key={media.slug}
                  mode={mode}
                  audio={media}
                  autoPlay={idx === initialIndex}
                  onEnded={() => reportProgress(media)}
                  onClose={handleClose}
                  isActive={idx === currentIndex.current}
                  boxProps={boxProps}
                  isFullScreen={props.isFullScreen}
                  nextMedia={items[idx + 1]}
                  showEndPrompt={mode === 'strict'}
                  endPrompt={props.endPrompt}
                />
              </AnalyticsContext>
            </SwiperSlide>
          ) : media.type === 'loom' ? (
            <SwiperSlide
              key={media.loom_id}
              style={{ height: '100%', overflowY: 'auto' }}
            >
              <AnalyticsContext
                context={{ mediaType: 'loom', loomID: media.loom_id }}
              >
                <MediaQueueLoom
                  key={media.loom_id}
                  loom={media}
                  mode={mode}
                  nextMedia={items[idx + 1]}
                  beforeContinue={() => reportProgress(media)}
                  isActive={idx === currentIndex.current}
                  showEndPrompt={mode === 'strict'}
                  endPrompt={props.endPrompt}
                  onClose={handleClose}
                />
              </AnalyticsContext>
            </SwiperSlide>
          ) : null,
        )}
        {props.swipeToClose ? (
          <SwiperSlide key="media-queue-swipe-to-close" />
        ) : null}

        {mode === 'loose' && items.length > 1 && (
          <Flex
            slot="container-end"
            justifyContent="space-around"
            w="full"
            position="absolute"
            bottom="calc(70px + env(safe-area-inset-bottom) / 2)"
            zIndex="1"
          >
            <Flex
              w="auto !important"
              className="pagination"
              wrap="wrap"
              justifyContent="center"
              gap="3"
              px="4"
              py="2"
              bg="#EEEEEE40"
              borderRadius="20px"
              sx={{
                '.swiper-pagination-bullet': {
                  background: 'gray.250',
                  margin: '0 !important',
                },
                '.swiper-pagination-bullet-active': {
                  background: 'white',
                },
              }}
            />
          </Flex>
        )}
      </Swiper>
    </AnalyticsContext>
  );
}

MediaQueue.Modal = function MediaQueueModal({
  isOpen,
  onClose,
  placement = 'right',
  ...props
}: Omit<MediaQueueProps, 'isFullScreen'> & {
  isOpen: boolean;
  onClose: () => void;
  placement?: DrawerProps['placement'];
}) {
  return (
    <Drawer placement={placement} size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="modal.bg">
        <MediaQueue
          {...props}
          onClose={onClose}
          mode={props.mode}
          isFullScreen
        />
      </DrawerContent>
    </Drawer>
  );
};

MediaQueue.FullScreen = function MediaQueueFullScreen(
  props: Omit<MediaQueueProps, 'isFullScreen'>,
) {
  return (
    <Portal>
      <Box position="fixed" inset="0">
        <MediaQueue {...props} isFullScreen />
      </Box>
    </Portal>
  );
};

function useMediaCallback<T>(callback: (key: string, value: T) => unknown) {
  const ref = useRef<Record<string, (value: T) => unknown>>({});
  const callbackRef = useCallbackRef((key: string, value: T) => {
    callback(key, value);
  });

  return (key: string) => {
    if (!ref.current[key]) {
      ref.current[key] = (value: T) => callbackRef(key, value);
    }
    return ref.current[key];
  };
}
