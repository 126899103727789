import { Box, Divider, Flex, Image, VStack } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

export default function CompetitionIncentive() {
  return (
    <Flex direction="column" h="full" justifyContent={'space-evenly'}>
      <Box py="2" w="full" bg="beige" rounded={'md'}>
        <Image
          mx="auto"
          src="./images/competition/carne-mare.png"
          alt="Carne Mare Logo, Pier 17 NYC"
          w={'186px'}
        />
      </Box>

      <Flex direction={'row'} px={2} justify={'space-between'}>
        <ExperienceGuest
          name={'Brian Ferguson'}
          bio={
            <TEXT.P3
              color="neutral.300"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <span>Founder of Arena Labs,</span>
              <span>Student of Human</span>
              <span>Performance</span>
            </TEXT.P3>
          }
          photoSource={'brian-circle.webp'}
        />
        <ExperienceGuest
          name={'Jurgen Heitman'}
          bio={
            <TEXT.P3
              color="neutral.300"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <span>A career as a Navy SEAL</span>
              <span>spanning 3 decades</span>
              <span></span>
            </TEXT.P3>
          }
          photoSource={'jurgen-circle.webp'}
        />
      </Flex>

      <Divider px={6} ml={-6} />

      <Flex direction="column" gap={4}>
        <TEXT.H3>Exclusive Experience Unveiled!</TEXT.H3>

        <TEXT.P2 color="neutral.400">
          Dine with HSS high achievers, Arena Labs&apos; founder, and a
          legendary Naval Special Warfare Leader.
        </TEXT.P2>

        <Flex direction="row" justify={'space-between'} px="10">
          <Flex direction={'column'} textAlign={'center'} rowGap={1}>
            <TEXT.P2 color="neutral.300">Location</TEXT.P2>
            <TEXT.P1_SEMIBOLD
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <span> Carne Mare </span>
              <span> NYC</span>
            </TEXT.P1_SEMIBOLD>
          </Flex>
          <Flex direction={'column'} textAlign={'center'} rowGap={1}>
            <TEXT.P2 color="neutral.300">Date &amp; Time</TEXT.P2>

            <TEXT.P1_SEMIBOLD
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <span>Nov 10, 2023 </span>
              <span> 8:30 pm</span>
            </TEXT.P1_SEMIBOLD>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

type ExperienceGuestProps = {
  name: string;
  bio: React.ReactNode;
  photoSource: string;
};

function ExperienceGuest({ name, bio, photoSource }: ExperienceGuestProps) {
  return (
    <Flex direction="column" gap="2" textAlign={'center'} px={2}>
      <Image
        src={`./images/competition/${photoSource}`}
        w={'118px'}
        alignSelf={'center'}
        alt=""
      />
      <VStack>
        <TEXT.P2>{name}</TEXT.P2>
        {bio}
      </VStack>
    </Flex>
  );
}
