import type { ValueOf } from 'type-fest';

export const AppTrackingEvent = {
  AppUpdateAvailable: 'AppUpdateAvailable',
  AppUpdatePrompt: 'AppUpdatePrompt',
  AppUpdateNow: 'AppUpdateNow',
  AppUpdateLater: 'AppUpdateLater',
  AppUpdated: 'AppUpdated',
  AppUpdateVisitAppStore: 'AppUpdateVisitAppStore',
  AppStartupMetrics: 'AppStartupMetrics',
};

/**
 * General tracking
 */
export const GeneralTrackingEvent = {
  PageLoad: 'PageLoad',
  NotFound: 'NotFound',
  AccessDenied: 'AccessDenied',
  LoginRequired: 'LoginRequired',
  HelpModalOpen: 'HelpModalOpen',
  HelpRequested: 'HelpRequested',
  TypeformModalOpen: 'TypeformModalOpen',
  TypeformSubmitted: 'TypeformSubmitted',
  ExplainerOpened: 'ExplainerOpened',

  ModalOpened: 'ModalOpened',
  ModalClosed: 'ModalClosed',

  AppGateOpened: 'AppGateOpen',
  AppGateClosed: 'AppGateClosed',
  RewardHubOpen: 'RewardHubOpen',
  RewardHubClosed: 'RewardHubClosed',
  StreaksHubOpen: 'StreaksHubOpen',
  StreaksHubClosed: 'StreakHubClosed',
  CompetitionHubOpen: 'CompetitionHubOpen',
  CompetitionHubClosed: 'CompetitionHubClosed',
  ServiceDisruptionModalOpen: 'ServiceDisruptionModalOpen',
  ServiceDisruptionModalClosed: 'ServiceDisruptionModalClosed',
} as const;

/**
 * General Page tracking
 */
export const GeneralTrackingTag = {
  Home: 'Home',
  ArenaContactDetails: 'ArenaContactDetails',
} as const;

/**
 * Launchpad tracking
 */
export const LaunchpadTrackingEvent = {} as const;

export const LaunchpadTrackingTag = {
  Launchpad: 'Launchpad',
  LaunchpadPlaylist: 'LaunchpadPlaylist',
  LaunchpadVideo: 'LaunchpadVideo',
  LaunchpadProgress: 'LaunchpadProgress',
};

/**
 * Onboarding tracking
 */
export const OnboardingTrackingEvent = {
  OnboardingTransition: 'OnboardingTransition',
  OnboardingFinished: 'OnboardingFinished',
} as const;

/**
 * Onboarding Page tracking
 */
export const OnboardingTrackingTag = {
  Onboarding: 'Onboarding',
  Slides: 'OnboardingSlides',
} as const;

/**
 * Authentication tracking
 */
export const AuthTrackingTag = {
  Auth: 'Auth',
  LogIn: 'LogIn',
  SignUp: 'SignUp',
  WhoopLogin: 'WhoopLogin',
  PreSignUp: 'PreSignUp',
  ProfileDetails: 'ProfileDetails',
} as const;

export const AuthTrackingEvent = {
  ChangeEmail: 'ChangeEmail',
} as const;

/**
 * Chat
 */
export const ChatTrackingTag = {
  Chat: 'Chat',
  UserChat: 'UserChat',
  OnboardGuide: 'OnboardGuide',
} as const;

export const ChatTrackingEvent = {
  Messages: 'MessagesLoaded',
  MessageSent: 'MessageSent',
  ChatLinkClicked: 'ChatLinkClicked',
} as const;

/**
 * Coaching tracking
 */
export const CoachingTrackingTag = {
  Coaching: 'Coaching',
  Syllabus: 'Syllabus',
  Ambassadors: 'Ambassadors',
  AmbassadorList: 'AmbassadorList',
  AmbassadorDetail: 'AmbassadorDetail',
  Coaches: 'Coaches',
  CoachList: 'CoachList',
  CoachDetail: 'CoachDetail',
  Programs: 'Programs',
  ProgramDetail: 'ProgramDetail',
} as const;

export const CoachingTrackingEvent = {
  PillarListenNext: 'PillarListenNext',
} as const;

/**
 * Practice Tracking
 */

export const PracticesTrackingTag = {
  PracticeTab: 'PracticeTab',
  AllPractices: 'AllPractices',
  PracticeDetail: 'PracticeDetail',
};

export const PracticesTrackingEvent = {
  PracticeCompleted: 'PracticeCompleted',
  PracticeSkipped: 'PracticeSkipped',
  PracticeCardExtraInfoOpened: 'PracticeCardExtraInfoOpened',
  PracticeGuideMe: 'PracticeGuideMe',
  PracticeIntro: 'PracticeIntro',
  PracticeFavorited: 'PracticeFavorited',
  AllPracticesInfoOpen: 'AllPracticesInfoOpen',
  AllPracticesInfoClosed: 'AllPracticesInfoClosed',
};

/**
 * Content library tracking
 */
export const ContentTrackingTag = {
  ContentLibrary: 'ContentLibrary',
  PlayLists: 'Playlists',
  Videos: 'Videos',
  VideoDetail: 'VideoDetail',
  AudioDetail: 'AudioDetail',
  Saved: 'Saved',
  Watched: 'Watched',
  VideosAll: 'VideosAll',
  StandaloneVideo: 'StandaloneVideo',
  HCA: 'HCA',
} as const;

export const ContentTrackingEvent = {
  ShowProgramCompletion: 'ShowProgramCompletion',
  ShowMoreConfetti: 'ShowMoreConfetti',
  FollowUpNextAnnotation: 'FollowUpNextAnnotation',
  FollowUpNextVideoEndCard: 'FollowUpNextVideoEndCard',
  FollowUpNextProgramEndCard: 'FollowUpNextProgramEndCard',
  OpenVideoFilters: 'OpenVideoFilters',
  ApplyVideoFilters: 'ApplyVideoFilters',
  OpenSorting: 'OpenSorting',
  ApplySorting: 'ApplySorting',
  LoadMoreVideos: 'LoadMoreVideos',
} as const;

/**
 * Audio/Video tracking
 */
export const MediaTrackingEvent = {
  MediaPlayed: 'MediaPlayed',
  MediaProgress: 'MediaProgress',
  VideoModalOpen: 'VideoModalOpen',
  VideoModalClosed: 'VideoModalClosed',
  AudioModalOpen: 'AudioModalOpen',
  PlaybackRate: 'MediaPlaybackRateUpdated',
  MediaQueueOpened: 'MediaQueueOpened',
  MediaQueueClosed: 'MediaQueueClosed',
  MediaQueueChanged: 'MediaQueueChanged',
  MediaQueueContinueClicked: 'MediaQueueContinueClicked',
  MediaQueueReplayClicked: 'MediaQueueReplayClicked',
} as const;

export const MediaTrackingTag = {
  AudioPlayer: 'AudioPlayer',
};

/**
 * Data tracking
 */
export const DataTrackingTag = {
  Data: 'Data',
  ACI: 'ACI',
  ACITrends: 'ACITrends',
  ACIForm: 'ACIForm',
  DataHub: 'DataHub',
  Snapshot: 'Snapshot',
  SnapshotSummary: 'SnapshotSummary',
} as const;

export const DataTrackingEvent = {
  StartCheckIn: 'StartCheckIn',
  ACIModalOpened: 'ACIModal',
  ACIModalClosed: 'ACIModalClosed',
  ACISubmitted: 'ACISubmitted',
  ACIExplanationOpened: 'ACIExplanationOpened',
  ACIExplanationClosed: 'ACIExplanationClosed',
  ACIScoreExplanationOpened: 'ACIScoreExplanationOpened',
  ACIScoreExplanationClosed: 'ACIScoreExplanationClosed',
  BiometricsSummary: 'BiometricsSummary',
} as const;

/**
 * Notification tracking
 */

export const NotificationTrackingEvent = {
  PromptClick: 'PromptClick',
  LocalNotificationClick: 'LocalNotificationClick',
  LocalNotificationPermission: 'LocalNotificationPermission',
  PushNotificationClick: 'PushNotificationClick',
  AllowNotificationsClicked: 'AllowNotificationsClicked',
  NotificationPermissionGranted: 'NotificationPermissionGranted',
  NotificationPermissionDenied: 'NotificationPermissionGranted',
} as const;

export const NotificationTrackingTag = {
  Notification: 'Notification',
} as const;

/**
 * Coach/Leadership Dashboard events
 */
export const DashboardTrackingEvent = {} as const;

export const DashboardTrackingTag = {
  Dashboard: 'Dashboard',
  CoachDashboard: 'CoachDashboard',
  LeadershipDashboard: 'LeadershipDashboard',
} as const;

/**
 * Wearable tracking
 */

export const WearableTrackingTag = {
  WearablePage: 'WearablePage',
  PartnerWearable: 'PartnerWearable',
  StriveWareWearable: 'StriveWareWearable',
} as const;

export const WearableTrackingEvent = {} as const;

export type TrackingTag =
  | ValueOf<typeof AuthTrackingTag>
  | ValueOf<typeof CoachingTrackingTag>
  | ValueOf<typeof ContentTrackingTag>
  | ValueOf<typeof DataTrackingTag>
  | ValueOf<typeof GeneralTrackingTag>
  | ValueOf<typeof NotificationTrackingTag>
  | ValueOf<typeof WearableTrackingTag>
  | ValueOf<typeof ChatTrackingTag>
  | ValueOf<typeof DashboardTrackingTag>
  | ValueOf<typeof MediaTrackingTag>
  | ValueOf<typeof PracticesTrackingTag>
  | ValueOf<typeof LaunchpadTrackingTag>;

export type TrackingEvent =
  | ValueOf<typeof AppTrackingEvent>
  | ValueOf<typeof GeneralTrackingEvent>
  | ValueOf<typeof AuthTrackingEvent>
  | ValueOf<typeof CoachingTrackingEvent>
  | ValueOf<typeof ContentTrackingEvent>
  | ValueOf<typeof DataTrackingEvent>
  | ValueOf<typeof NotificationTrackingEvent>
  | ValueOf<typeof WearableTrackingEvent>
  | ValueOf<typeof OnboardingTrackingEvent>
  | ValueOf<typeof ChatTrackingEvent>
  | ValueOf<typeof DashboardTrackingEvent>
  | ValueOf<typeof MediaTrackingEvent>
  | ValueOf<typeof LaunchpadTrackingEvent>;
