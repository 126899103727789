/**
 * Wrap a value in a Promise if it's not already a Promise.
 * If the value is a function, execute it and return the result as a Promise.
 */
export function toPromise<T>(
  input: T | Promise<T> | (() => T | Promise<T>),
): Promise<T> {
  if (typeof input === 'function') {
    return toPromise((input as () => T | Promise<T>)());
  }
  return Promise.resolve(input as T | Promise<T>);
}
