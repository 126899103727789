import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';
import axios from 'axios';

import { resourcesS3Api } from '../endpoints/resources-s3';

const bucketName = 'strive-resources';
const resourcesUrl = `https://${bucketName}.s3.amazonaws.com`;

export const resourcesS3 = axios.create({
  baseURL: resourcesUrl,
  timeout: 10_000,
});

export const resourcesS3Client = new Zodios(resourcesS3Api, {
  axiosConfig: resourcesS3.defaults,
});

export const $RESOURCES = new ZodiosHooks(bucketName, resourcesS3Client);

export function getResourcesS3Url(path?: string) {
  if (!path) {
    return resourcesUrl;
  }
  return `${resourcesUrl}/${path.replace(/^\//, '')}`;
}
