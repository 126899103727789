import { PlayableVideo, Video } from '@arena-labs/shared-models';

type VideoUrlOptions = {
  width?: number;
  animated?: boolean;
  startTime?: number;
};

export function getVideoUrls(
  video: PlayableVideo | Video,
  options: VideoUrlOptions = {},
) {
  const width = options.width ?? 450;

  if (!video.playback_id) {
    return { videoUrl: '', imageUrl: '' };
  }
  const thumbnailTimeCode =
    options.startTime || video.thumbnail_time_code || '45';
  const videoUrl = `https://stream.mux.com/${video.playback_id}.m3u8`;
  const imageUrl = `https://image.mux.com/${video.playback_id}/thumbnail.webp?width=${width}&time=${thumbnailTimeCode}`;
  const animatedUrl = `https://image.mux.com/${
    video.playback_id
  }/animated.webp?width=${Math.min(640, width)}&start=${thumbnailTimeCode}`;
  return { videoUrl, imageUrl: options.animated ? animatedUrl : imageUrl };
}

export function getAudioUrl(playbackID: string) {
  return `https://stream.mux.com/${playbackID}.m3u8?add_audio_only=true`;
}
