import { Fragment, useEffect } from 'react';
import { JSONTree } from 'react-json-tree';
import { Capacitor } from '@capacitor/core';
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Message } from 'console-feed/lib/definitions/Console';
import Hook from 'console-feed/lib/Hook';
import Unhook from 'console-feed/lib/Unhook';
import create from 'zustand';

import { TEXT } from '@arena-labs/strive2-ui';
import { saveAs, serialize } from '@strive/utils';

const maxLogs = 1000;

/**
 * Note: The `Console` component provided by `console-feed` is great,
 * but a dependency (react-inspector) breaks our CSP rules by executing
 * an unsafe `eval`.
 *
 * We really don't need anything fancy, so we continue to use console-feed
 * to capture console logs, and then render them simply using plain chakra
 * and `react-json-tree` for non-strings.
 */

type ConsoleStore = {
  logs: Message[];
  actions: {
    clear: () => void;
  };
};

const useConsoleStore = create<ConsoleStore>((set, get) => ({
  logs: [],
  actions: {
    clear: () => {
      if (!Capacitor.isNativePlatform()) {
        console.clear?.();
      }
      set({ logs: [] });
    },
  },
}));

function addLog(log: Message) {
  useConsoleStore.setState(({ logs }) => ({
    logs: [...logs, log].slice(-maxLogs),
  }));
}

export function useConsoleLogSetup() {
  useEffect(() => {
    const hooked = Hook(
      window.console,
      (log) => addLog({ timestamp: new Date().toISOString(), ...log }),
      false,
    );
    return () => void Unhook(hooked);
  }, []);
}

export function useConsoleLogs() {
  return useConsoleStore((state) => state.logs);
}

export function useConsoleLogActions() {
  return useConsoleStore((state) => state.actions);
}

export function AdminConsoleLogs() {
  const logs = useConsoleLogs();
  const { clear } = useConsoleLogActions();
  return (
    <VStack spacing="4">
      <Flex
        justifyContent="space-between"
        w="100vw"
        px="4"
        mt="-4"
        py="2"
        bg="bg.secondary"
        position="sticky"
        top="0"
      >
        <Button size="sm" variant="outline" onClick={() => clear()}>
          Clear
        </Button>
        <Button
          size="sm"
          variant="solid"
          onClick={() =>
            saveAs({
              data: serialize(logs),
              filename: 'console-log.json',
              type: 'application/json',
            }).catch(() => alert('Export cancelled'))
          }
        >
          Export Logs
        </Button>
      </Flex>

      <Grid templateColumns="auto 1fr" columnGap="2" rowGap="1" w="full">
        {logs.map((log, idx) => (
          <Fragment key={idx}>
            <Tooltip label={log.timestamp} placement="right" hasArrow>
              <Text
                textStyle="p3"
                borderRadius="md"
                tabIndex={0}
                bg={
                  log.method === 'error'
                    ? 'error.700'
                    : log.method === 'info'
                    ? 'primary.700'
                    : log.method === 'warn'
                    ? 'warning.700'
                    : 'gray.700'
                }
                color="white"
                p="1"
                pt="1.5"
                textAlign="center"
              >
                {log.method.toUpperCase()}
              </Text>
            </Tooltip>
            <Box>
              {stripFormatting(log.data ?? []).map((datum, index) => {
                // If datum is an object
                if (typeof datum === 'object') {
                  return (
                    <JSONTree
                      key={index}
                      data={datum}
                      hideRoot
                      sortObjectKeys
                    />
                  );
                }

                // Other cases
                else {
                  return (
                    <TEXT.P2 key={index} as="span" wordBreak="break-all">
                      {datum}
                    </TEXT.P2>
                  );
                }
              })}
            </Box>
          </Fragment>
        ))}
      </Grid>
    </VStack>
  );
}

// Remove fancy formatting (https://developer.chrome.com/docs/devtools/console/format-style/)
function stripFormatting(params: Parameters<(typeof console)['log']>) {
  let [message, ...rest] = params;
  if (typeof message !== 'string') return params;

  const splitMessage = message.split(/(%c|%s|%O)/g);
  let newParams = [];
  let restIndex = 0;
  for (let i = 0; i < splitMessage.length; i++) {
    if (splitMessage[i] === '%c') {
      restIndex++; // Skip the format string for %c
    } else if (/^%\w$/.test(splitMessage[i] ?? '')) {
      newParams.push(rest[restIndex++]); // Add string or object to newParams
    } else {
      newParams.push(splitMessage[i]); // Add the part of the message to newParams
    }
  }

  newParams.push(...rest.slice(restIndex));

  return newParams;
}
