import { createActorContext } from '@xstate5/react';

import { aciMachine } from './aci2.machine';

export const AciContext = createActorContext(aciMachine);

export function useAciMachine() {
  const actor = AciContext.useActorRef();
  const state = AciContext.useSelector((state) => state);
  return [state, actor.send, actor] as const;
}
