import { z } from 'zod';

import { createDataStore } from '@strive/utils';

export const AuthTokenStore = createDataStore({
  key: 'AuthTokens',
  schema: z.object({
    access: z.string(),
    refresh: z.string(),
  }),
});

export const LegacyTokenStore = createDataStore({
  key: 'strive_refresh_token',
  schema: z.string(),
});
