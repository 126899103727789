import { useMount } from 'react-use';
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';

import { useHomepageState } from '@arena-labs/strive2-coaching';
import {
  Icon,
  MotionBox,
  ProductEducationPopover,
  TEXT,
  ZStack,
} from '@arena-labs/strive2-ui';
import { StriveApiResponse } from '@strive/api';
import { haptics } from '@strive/device';

import { AciBadge } from './aci-badge';
import { ACIScoreData } from './aci-form.machine';

export type ACIScoreProps = {
  score: ACIScoreData;
  streak: StriveApiResponse<'submitACI'>['streak'];
  onProceed: () => void;
};

export function ACIScore({ score, streak, onProceed }: ACIScoreProps) {
  const [homepageState] = useHomepageState();
  const hasLessonContent = homepageState.matches('Media Queue.Pending');

  useMount(() => {
    setTimeout(() => {
      haptics.notification('SUCCESS');
    }, 1000);
  });

  return (
    <Flex direction="column" h="full" px="6" py="4" gap="8">
      <Text as="h2" textStyle="h2">
        Arena Check-In
      </Text>

      <Grid
        templateRows="auto auto 1fr"
        justifyItems="center"
        gap="clamp(0.5rem, 1.5vh, 1rem)"
        h="full"
      >
        <ScoreBadge count={streak.current} />
        <Flex w="full" gap={2} sx={{ '> *': { flex: 1 } }}>
          <ACIStat
            label="Days on Strive"
            value={streak.days_since_learning_journey_started}
            helpText="Days"
          />
          <ACIStat label="Total ACIs" value={streak.total_acis} />
          <ACIStat label="Longest Streak" value={streak.max} helpText="Days" />
        </Flex>
        <ScoreExplanationCard
          image={score.image}
          heading={score.heading}
          copy={score.copy}
        />
      </Grid>

      <ProductEducationPopover
        id={'ACI_Score'}
        placement={'top'}
        shouldRender={true}
        title="Today's Lesson"
        body="Once you have finished reflecting, explore a new lesson for the day."
      >
        <Button variant={'primary'} w="full" onClick={onProceed}>
          {hasLessonContent ? 'Start Lesson' : 'Continue'}
        </Button>
      </ProductEducationPopover>
    </Flex>
  );
}

type ScoreBadgeProps = {
  count: number;
};

function ScoreBadge({ count }: ScoreBadgeProps) {
  return (
    <ZStack color="white" placeItems="center" boxSize={48} maxHeight="25vh">
      <Icon as={AciBadge} score={count} size="full" />

      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.25 }}
      >
        <Flex direction="column" align="center">
          <Text textStyle="p2" />
          <div>
            <TEXT.H0 textShadow={'md'}>{count}</TEXT.H0>
          </div>
          <Text textStyle="p2">day streak</Text>
        </Flex>
      </MotionBox>
    </ZStack>
  );
}

type ACIStatProps = {
  label: string;
  value: number;
  helpText?: string;
};
function ACIStat({ label, value, helpText }: ACIStatProps) {
  return (
    <Flex
      direction="column"
      as="dl"
      layerStyle="4dp"
      textAlign="center"
      borderRadius="card"
      py="2"
      px="3"
      gap="2"
    >
      <Text as="dt" textStyle="p3" color="neutral.200">
        {label}
      </Text>
      <Text as="dd" textStyle="p1_semibold" color="white">
        {value}{' '}
        {helpText ? (
          <Text as="span" textStyle="p3" color="neutral.200">
            {helpText}
          </Text>
        ) : null}
      </Text>
    </Flex>
  );
}

type ScoreExplanationCardProps = {
  image: string;
  heading: string;
  copy: string;
};

function ScoreExplanationCard({
  image,
  heading,
  copy,
}: ScoreExplanationCardProps) {
  const [h2, h3, p2, p3] = useToken('fontSizes', ['h2', 'h3', 'p2', 'p3']);

  return (
    <Box
      w="full"
      display="grid"
      placeItems="center"
      backgroundImage={image}
      bgSize="cover"
      borderRadius="card"
      p={4}
    >
      <VStack w="full" textAlign="center" spacing="clamp(0.25rem, 2vh, 1.5rem)">
        <TEXT.H2 fontSize={`clamp(${h3}, 3vh, ${h2})`}>{heading}</TEXT.H2>
        <TEXT.P2 fontSize={`clamp(${p3}, 1.8vh, ${p2})`} lineHeight={'normal'}>
          {copy}
        </TEXT.P2>
      </VStack>
    </Box>
  );
}
