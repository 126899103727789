import { SVGProps, useId, useState } from 'react';

export type AciBadgeProps = SVGProps<SVGSVGElement> & {
  title?: string;
  titleId?: string;
  desc?: string;
  descId?: string;
  score: number;
  animate?: boolean;
};

export function AciBadge({
  title,
  titleId,
  desc,
  descId,
  score,
  animate = true,
  ...props
}: AciBadgeProps) {
  const id = useId();

  const borderOpacity = 1;

  // The diamond background pattern is composed of two overlapping gradients
  // 1. A linear gradient that fades out from the inner corner to the outer corner
  // 2. A very subtle radial gradient emanating from the inner corner
  const stopColor = '#1e5766';
  const diamondLinearStops = [
    { offset: 0, stopColor, stopOpacity: 1 },
    { offset: '12%', stopColor, stopOpacity: 0.8 },
    { offset: '28%', stopColor, stopOpacity: 0.4 },
    { offset: '38%', stopColor, stopOpacity: 0.24 },
    { offset: '53%', stopColor, stopOpacity: 0 },
  ];

  const diamondRadialStops = [
    { offset: '40%', stopColor, stopOpacity: 0.05 },
    { offset: '55%', stopColor, stopOpacity: 0.1 },
    { offset: '70%', stopColor, stopOpacity: 0.0 },
  ];

  const makeStops = (stops: SVGProps<SVGStopElement>[]) =>
    stops.map((stop, i) => <stop key={i} {...stop} />);

  const diamondLinearGradientStops = makeStops(diamondLinearStops);
  const diamondRadialGradientStops = makeStops(diamondRadialStops);

  // The diamond background is composed of four quadrants, each with its own
  // pair of gradients pointing in different directions
  const rectNW = { x: 0, y: 0, width: 90, height: 100 };
  const rectNE = { x: 90, y: 0, width: 90, height: 100 };
  const rectSW = { x: 0, y: 100, width: 90, height: 100 };
  const rectSE = { x: 90, y: 100, width: 90, height: 100 };

  const [hexagonPathLength, setHexagonPathLength] = useState(0);

  return (
    <svg
      width={150}
      height={174}
      viewBox="14 14 150 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}
    >
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <path
          className="hexagon"
          filter={`url(#${id}hexagonShadow)`}
          d="M89 19.9167L159 60.1667V141.833L89 182.083L19 141.833V60.1667L89 19.9167Z"
          stroke={`url(#${id}hexagonStroke)`}
          strokeWidth={6}
          shapeRendering="crispEdges"
          opacity={animate ? 0 : 1}
          strokeDasharray={hexagonPathLength}
          strokeDashoffset={animate ? hexagonPathLength : 0}
          strokeLinecap="round"
          ref={(el) => {
            if (el) {
              setHexagonPathLength(el.getTotalLength());
            }
          }}
        >
          {animate ? (
            <>
              <animate
                attributeName="opacity"
                from={0}
                to={1}
                begin="1s"
                dur="0.1s"
                fill="freeze"
              />
              <animate
                attributeName="stroke-dashoffset"
                from={hexagonPathLength}
                to={0}
                begin="1s"
                dur="0.5s"
                fill="freeze"
              />
            </>
          ) : null}
        </path>

        <g
          clipPath={`url(#${id}hexagonClip`}
          transform="translate(0)"
          opacity={animate ? 0 : 1}
        >
          {animate && (
            <animate
              attributeName="opacity"
              from="0"
              to="1"
              begin="1s"
              dur="0.5s"
              fill="freeze"
            />
          )}

          {/* Add linear & radial gradient to each quadrant */}
          <rect {...rectNW} fill={`url(#${id}linearNW)`} />
          <rect {...rectNW} fill={`url(#${id}radialNW)`} />

          <rect {...rectNE} fill={`url(#${id}linearNE)`} />
          <rect {...rectNE} fill={`url(#${id}radialNE)`} />

          <rect {...rectSW} fill={`url(#${id}linearSW)`} />
          <rect {...rectSW} fill={`url(#${id}radialSW)`} />

          <rect {...rectSE} fill={`url(#${id}linearSE)`} />
          <rect {...rectSE} fill={`url(#${id}radialSE)`} />
        </g>
      </g>
      <defs>
        {/*
            This clip path is applied to make sure the diamond pattern does not
            bleed out of the hexagon shape
        */}
        <clipPath id={`${id}hexagonClip`}>
          <path d="M159 60.1667L89 19.9167L19 60.4885V141.833L89 182.083L159 141.833V60.1667Z" />
        </clipPath>

        {/*
          The filter applied to the hexagon shape is a shadow filter that
          creates a drop shadow effect
        */}
        <filter
          id={`${id}hexagonShadow`}
          x={0}
          y={0.452637}
          width={178}
          height={201.091}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="soft-light"
            in2="BackgroundImageFix"
            result={`${id}effect1_dropShadow_545_1073`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`${id}effect1_dropShadow_545_1073`}
            result="shape"
          />
        </filter>

        {/* The gradient used for the hexagon outline */}
        <linearGradient
          id={`${id}hexagonStroke`}
          x1={89}
          y1={19.9167}
          x2={89}
          y2={182.083}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#95FDFD" stopOpacity={borderOpacity} />
          <stop offset={1} stopColor="#81D1E5" stopOpacity={borderOpacity} />
        </linearGradient>

        {/* The linear & radial gradients used for the diamond pattern */}
        <linearGradient id={`${id}linearNW`} x1={1} y1={1} x2={0} y2={0}>
          {diamondLinearGradientStops}
        </linearGradient>
        <radialGradient id={`${id}radialNW`} cx="100%" cy="100%" r="100%">
          {diamondRadialGradientStops}
        </radialGradient>

        <linearGradient id={`${id}linearNE`} x1={0} y1={1} x2={1} y2={0}>
          {diamondLinearGradientStops}
        </linearGradient>
        <radialGradient id={`${id}radialNE`} cx={0} cy="100%" r="100%">
          {diamondRadialGradientStops}
        </radialGradient>

        <linearGradient id={`${id}linearSW`} x1={1} y1={0} x2={0} y2={1}>
          {diamondLinearGradientStops}
        </linearGradient>
        <radialGradient id={`${id}radialSW`} cx="100%" cy={0} r="100%">
          {diamondRadialGradientStops}
        </radialGradient>

        <linearGradient id={`${id}linearSE`} x1={0} y1={0} x2={1} y2={1}>
          {diamondLinearGradientStops}
        </linearGradient>
        <radialGradient id={`${id}radialSE`} cx={0} cy={0} r="100%">
          {diamondRadialGradientStops}
        </radialGradient>
      </defs>
    </svg>
  );
}
