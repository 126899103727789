import { useMemo } from 'react';
import { useBreakpoint, useMediaQuery } from '@chakra-ui/react';

import { breakpoints } from '@strive/theme/tokens';

type Breakpoint = 'xs' | keyof typeof breakpoints;
type BreakpointStyle<T> = Partial<Record<Breakpoint, T>>;

const breakpointsOrder = Object.keys(breakpoints).reverse() as Breakpoint[];

/**
 * A custom hook to provide responsive style values based on the current breakpoint.
 *
 * This hook is designed to work with Chakra UI's breakpoint system and provides
 * a utility function to easily define styles for different breakpoints. Additionally,
 * it introduces a custom `xs` breakpoint to cater to specific design requirements
 * outside of Chakra's default breakpoints.
 *
 * @example
 * const rs = useResponsive();
 *
 * // Using direct style values based on breakpoints:
 * <Text textStyle={rs({ base: 'p2', sm: 'p3' })} />
 *
 * // Using nested style objects based on breakpoints:
 * const boxStyles = rs({
 *   base: { marginTop: 2, color: 'red' },
 *   xs: { marginTop: 4, color: 'blue' }
 * });
 * <Box {...boxStyles} />
 *
 * @returns {function} - A function to retrieve the style value or style object for the current breakpoint.
 */
export function useResponsive() {
  const [isCompact] = useMediaQuery('(max-height: 750px)');
  const breakpoint = useBreakpoint();

  return useMemo(() => {
    function responsive<T>(values: BreakpointStyle<T>): T | BreakpointStyle<T>;
    function responsive(
      values: BreakpointStyle<
        string | number | Record<PropertyKey, string | number>
      >,
    ): string | number | Record<PropertyKey, string | number>;
    function responsive<T>(values: BreakpointStyle<T>): T | BreakpointStyle<T> {
      const bp =
        isCompact && typeof values.xs !== 'undefined' ? 'xs' : breakpoint;

      if (bp in values) {
        return values[bp as Breakpoint] as T;
      }

      // Find the nearest defined value in the values object only
      // if the current breakpoint value doesn't exist
      const fallbackValue = breakpointsOrder
        .slice(breakpointsOrder.indexOf(bp as Breakpoint))
        .find((b) => values[b]);

      return values[fallbackValue as Breakpoint] || values.xs || {};
    }
    return responsive;
  }, [isCompact, breakpoint]);
}
