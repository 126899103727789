import { useEffect } from 'react';
import { useLatest } from 'react-use';
import { Center } from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { BrandLogo } from '@arena-labs/strive2-ui';
import { $API, useAuthState } from '@strive/api';

import { AppInfo } from '../../lib/use-app-info';
import { GeneralError } from '../error/general-error';
import { UserTelemetry } from '../utils/user-telemetry';
import { usePassportService } from './passport-service';

export type PassportManagerProps = {
  allowGuest?: boolean;
  children: React.ReactNode;
  onDenyAccess?: () => void;
  trackPageLoad?: boolean;
  appInfo?: AppInfo;
};

export function PassportManager({
  allowGuest = false,
  children,
  onDenyAccess,
  trackPageLoad = true,
  appInfo,
}: PassportManagerProps) {
  const [state, send] = usePassportService();
  const [authState, authSend] = useAuthState();

  const { data: user } = $API.useGetUserProfile(
    {},
    { enabled: authState.matches('Logged in') },
  );

  // Initialize the auth machine
  useEffect(() => {
    send({ type: 'START' });
  }, [send]);

  // TODO: pull `state` more directly from the machine
  const hasAccess = allowGuest || state.matches('Authenticated.Ready');
  const hasTimeout = state.matches('Authenticated.Timeout');
  const isLoading =
    state.matches('Pending') ||
    state.matches('Starting') ||
    state.matches('Authenticated.Waiting for Homepage');
  const isOffline = state.matches('Offline');
  const status = hasAccess
    ? 'ready'
    : isLoading
    ? 'loading'
    : hasTimeout
    ? 'timeout'
    : isOffline
    ? 'offline'
    : 'undefined';

  // Handle access denied
  const handleDenyAccess = useLatest(onDenyAccess);
  const accessIsDenied = !isLoading && !hasTimeout && !hasAccess && !isOffline;
  const analytics = useAnalytics();
  useEffect(() => {
    if (accessIsDenied) {
      analytics.logEvent(GeneralTrackingEvent.LoginRequired);
      handleDenyAccess.current?.();
    }
  }, [analytics, handleDenyAccess, accessIsDenied]);

  // Render the page, if the user is allowed access, otherwise nothing (while pending) or access denied
  return (
    <UserTelemetry
      status={status}
      trackPageLoad={trackPageLoad}
      appInfo={appInfo}
      user={user}
      userId={authState.context.userId}
    >
      {hasAccess ? (
        children
      ) : isLoading ? (
        <Center h="100vh">
          <BrandLogo spin noText iconSize={24} />
        </Center>
      ) : state.matches('Authenticated.Timeout') ? (
        <GeneralError title="This is taking longer than usual" />
      ) : isOffline ? (
        <GeneralError
          title="You appear to be offline"
          onReload={() => authSend('Revalidate')}
        />
      ) : null}
    </UserTelemetry>
  );
}
