import { ZodiosHooks } from '@zodios/react';

import {
  expireAuthSession,
  getAuthTokens,
  setAuthTokens,
} from '../auth/auth-service';
import { apiHost } from '../constants';
import { striveAppApi } from '../endpoints';
import { createApiClient } from '../zodios';

export const apiClient = createApiClient<typeof striveAppApi>(striveAppApi, {
  baseURL: apiHost,
  getAuthTokens,
  setAuthTokens,
  onSessionExpired: () => {
    expireAuthSession();
  },
});

export const $API = new ZodiosHooks('strive-api', apiClient);
