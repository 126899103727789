import React from 'react';

import { AnalyticsContext, TrackingEventContext } from '.';

/**
 * Higher Order Component (HOC) to wrap an analytics context around a component
 */
export function withAnalyticsContext<ComponentProps extends object>(
  mapFn: (props: ComponentProps) => Partial<TrackingEventContext>,
  InnerComponent: React.ComponentType<ComponentProps>,
): React.ComponentType<ComponentProps> {
  const wrappedComponent = (props: ComponentProps) => (
    <AnalyticsContext context={mapFn(props)}>
      <InnerComponent {...props} />
    </AnalyticsContext>
  );
  wrappedComponent.displayName = `withAnalyticsContext(${
    InnerComponent.displayName ?? InnerComponent.name ?? 'unknown'
  })`;
  return wrappedComponent;
}
