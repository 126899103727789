import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@strive/api';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

export function AdminReactQuery() {
  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={null}>
        <ReactQueryDevtoolsProduction
          // incorrect types in @tanstack/react-query-devtools
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          panelProps={{ showCloseButton: false } as any}
          initialIsOpen
        />
      </React.Suspense>
    </QueryClientProvider>
  );
}
