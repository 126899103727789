import { AxiosInstance } from 'axios';
import { merge } from 'lodash-es';

import { authClient } from './auth-client';

const location =
  typeof window !== 'undefined' ? window.location : { origin: '' };
const nextJsServer = process.env['NEXT_PUBLIC_HOST'] || location.origin;

/**
 * A pseudo-axios client configured to connect to the NextJS backend
 * Here we are wrapping the `authClient` because NextJS will forward auth headers for some endpoints
 */
export const frontend: Pick<
  AxiosInstance,
  'defaults' | 'get' | 'delete' | 'put' | 'post' | 'patch' | 'getUri'
> = {
  defaults: {
    baseURL: nextJsServer,
  },
  get(url, config) {
    return authClient.get(url, merge(this.defaults, config));
  },
  delete(url, config) {
    return authClient.delete(url, merge(this.defaults, config));
  },
  put(url, data, config) {
    return authClient.put(url, data, merge(this.defaults, config));
  },
  post(url, data, config) {
    return authClient.post(url, data, merge(this.defaults, config));
  },
  patch(url, data, config) {
    return authClient.patch(url, data, merge(this.defaults, config));
  },
  getUri(config) {
    return authClient.getUri(merge(this.defaults, config));
  },
};
