import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { zIsoDateTime } from '../lib/schema-utils';
import { wearableProviderSchema } from './user-profile';

export type Address = z.infer<typeof addressSchema>;

export const addressSchema = z.object({
  full_name: z.string().max(50).min(1, 'Shipping Recipient is required'),
  shipping_email: z
    .string()
    .email(`Email address not properly formed`)
    .min(1, 'Email is required'),
  address: z.string().min(1, 'Address is required '),
  apartment: z.string().nullable(),
  company: z.string().nullable(),
  city: z.string().min(1, 'City is required'),
  state: z.string().min(1, 'State is required'),
  zip_code: z.string().min(1, 'Postal Code is required'),
  country: z.string(),
});

const wearableOrderUpdateSchema = z.object({
  address: addressSchema,
  size: z.number(),
  style: z.string().nullable().optional(),
});

const launchpadChecklistState = z.enum([
  'locked',
  'active',
  'skipped',
  'completed',
]);

export const launchpadApi = makeApi([
  {
    alias: 'getLaunchpad',
    description: "Fetches the current user's launchpad data",
    method: 'get',
    parameters: [],
    path: '/launchpad/',
    response: z.object({
      cohort_launch_at: zIsoDateTime,
      launch_events: z.string(), // free-form markdown

      wearable: z
        .object({
          provider: wearableProviderSchema,
          status: z.enum([
            'not_ordered',
            'ordered',
            'shipped',
            'delivered',
            'complete',
          ]),
          tracking_url: z.string().url().optional().nullable(),
        })
        .nullable(),

      checklist: z.array(
        z
          .union([
            makeChecklist(
              'setup_wearable',
              z.array(
                z.union([
                  makeChecklistItem('wearable_order'),
                  makeChecklistItem('add_wearable_size'),
                  makeChecklistItem('confirm_ring_delivery'),
                  makeChecklistItem('sync_wearable'),
                ]),
              ),
            ),
            makeTypeformChecklistItem('pfi_questionnaire'),
            makeTypeformChecklistItem('build_profile_questionnaire'),
            makePlaylistChecklistItem('intro_playlist'),
            makePlaylistChecklistItem('data_playlist'),
            makeChecklistItem('commitment_statement'),
            makeChecklistItem('email_verification'),
          ])
          .nullable(),
        // TODO: leaving this commented for now so we can easily spot errors in development
        // But we might consider removing this for release. There are pros and cons though
        // .catch(null),
      ),
    }),
  },
  {
    alias: 'setWearableOrder',
    description: 'Post the address the user has entered for shipping purposes',
    method: 'patch',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: wearableOrderUpdateSchema,
      },
    ],
    path: '/launchpad/wearable-order/',
    response: wearableOrderUpdateSchema,
  },
  {
    alias: 'getWearableOrder',
    description: 'Get the wearable order object: address, ring size',
    method: 'get',
    parameters: [],
    path: '/launchpad/wearable-order/',
    response: wearableOrderUpdateSchema,
  },
  {
    alias: 'setLaunchpadChecklistItemStatus',
    description: 'Post the slug of a checklist item and its status',
    method: 'patch',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ slug: z.string(), status: launchpadChecklistState }),
      },
    ],
    path: '/launchpad/',
    response: z.object({
      slug: z.string(),
      status: launchpadChecklistState,
    }),
  },
  {
    alias: 'emailVerification',
    description: 'Have the BE send a validation email to the user ',
    method: 'post',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ email: z.string() }),
      },
    ],
    path: '/auth/email/verify',
    response: z.unknown(),
  },
]);

// Basic checklist item
function makeChecklistItem<Slug extends string>(slug: Slug) {
  return z.object({
    slug: z.literal(slug),
    label: z.string(),
    completed_at: zIsoDateTime.optional().nullable(),
    status: launchpadChecklistState,
  });
}

function makeChecklist<Slug extends string, Children extends z.ZodSchema>(
  slug: Slug,
  children: Children,
) {
  return makeChecklistItem(slug).extend({ children });
}

// Typeform checklist requires a form_id
function makeTypeformChecklistItem<Slug extends string>(slug: Slug) {
  return makeChecklistItem(slug).extend({
    typeform_id: z.string(),
  });
}

// playlist checklist requires playlist slug
function makePlaylistChecklistItem<Slug extends string>(slug: Slug) {
  return makeChecklistItem(slug).extend({
    playlist: z.string(),
  });
}
