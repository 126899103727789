import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from '@arena-labs/strive2-ui';

import { useDeleteDataMutation } from './admin-mutations';
import { DeleteDataData, deleteDataSchema } from './admin-schema';

export type AdminDeleteDataProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminDeleteData({ formId, ...props }: AdminDeleteDataProps) {
  const deleteData = useDeleteDataMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DeleteDataData>({
    resolver: yupResolver(deleteDataSchema),
    defaultValues: {},
  });

  const onSubmit = async (values: DeleteDataData) => {
    try {
      await deleteData.mutateAsync(values);
      props.onSuccess();
      toast({ title: 'Deleting data', status: 'success' });
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <FormControl id="delete-data__after" isInvalid={Boolean(errors.after)}>
        <FormLabel fontWeight="bold" mb="0">
          FROM
        </FormLabel>
        <Input
          type="datetime-local"
          autoComplete="none"
          {...register('after')}
        />
        <FormErrorMessage>{errors.after?.message}</FormErrorMessage>
      </FormControl>

      <FormControl id="delete-data__before" isInvalid={Boolean(errors.before)}>
        <FormLabel fontWeight="bold" mb="0">
          UNTIL
        </FormLabel>
        <Input
          type="datetime-local"
          autoComplete="none"
          {...register('before')}
        />
        <FormErrorMessage>{errors.before?.message}</FormErrorMessage>
      </FormControl>

      <Box as="fieldset">
        <Heading as="legend" fontWeight="bold" fontSize="lg" py={2}>
          DATA TO DELETE
        </Heading>

        <SimpleGrid columns={2} spacing="2">
          <Checkbox size="lg" colorScheme="brand" {...register('check_ins')}>
            ACI
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('practices')}>
            Practices
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('audio_plays')}>
            Audio
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('video_views')}>
            Video
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('sensor_data')}>
            Sensor
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('onboard')}>
            Onboard
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('push_tokens')}>
            Push Tokens
          </Checkbox>

          <Checkbox
            size="lg"
            colorScheme="brand"
            {...register('pre_assessment')}
          >
            Pre assessment
          </Checkbox>

          <Checkbox
            size="lg"
            colorScheme="brand"
            {...register('post_assessment')}
          >
            Post assessment
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('app_gate')}>
            App gate
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('incentives')}>
            Incentives
          </Checkbox>

          <Checkbox size="lg" colorScheme="brand" {...register('local')}>
            Local storage
          </Checkbox>
        </SimpleGrid>
      </Box>
    </VStack>
  );
}
