import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Drawer, DrawerProps } from '@chakra-ui/react';
import { useMachine } from '@xstate/react/fsm';

import { getHistoryLength } from '../utils/router-history';
import { navigationDisclosureMachine } from './navigation-disclosure-machine';

// TODO This needs a bit of work & testing. For now, disable this
// Before re-enabling this - verify it doesn't clash with `PageLoad` analytics event
// due to router manipulation
const DisableNavigationMachine = true;

export function NavigationDrawer(props: DrawerProps) {
  const { push, back } = useRouter();

  // Initialise the state machine
  const [state, send] = useMachine(navigationDisclosureMachine, {
    actions: {
      onClose: props.onClose,
      pushHistory: () =>
        DisableNavigationMachine
          ? null
          : push(`${window.location.pathname}#`, undefined, { shallow: true }),
      popHistory: () => (DisableNavigationMachine ? null : back()),
    },
  });

  // Tell the state machine top open/close based on the current
  // value of the `isOpen` prop which was passed in
  const stateVal = useRef<string>();
  stateVal.current = state.value;
  useEffect(() => {
    if (props.isOpen) {
      send('OPEN');
    } else {
      send('CLOSE');
    }
  }, [props.isOpen, send]);

  // Let the state machine know whenever there is a navigation
  const current = getHistoryLength();
  useEffect(() => {
    send('NAVIGATION');
  }, [current, send]);

  // Render the drawer, and override `isOpen` based on
  // the state machine
  return (
    <Drawer
      {...props}
      isOpen={DisableNavigationMachine ? props.isOpen : state.value === 'open'}
    />
  );
}
