import { useEffect, useState } from 'react';

export function useViewportHeightUnit() {
  function getViewportHeightUnit() {
    return typeof window === 'undefined' ? 0 : window.innerHeight * 0.01;
  }
  const [vh, setVH] = useState<number>(getViewportHeightUnit);

  useEffect(() => {
    const onChange = () => setVH(getViewportHeightUnit);
    window.addEventListener('resize', onChange);
    return () => window.removeEventListener('resize', onChange);
  }, []);

  return vh;
}
