/**
 * Executes an asynchronous operation that should complete within
 * a specified timeout period.
 *
 * If the operation does not complete within the timeout period,
 * this function rejects with an error.
 */
export function withTimeout<Result>(
  timeout: number,
  promise: Promise<Result> | (() => Promise<Result>),
): Promise<Result> {
  const timeoutPromise = new Promise<never>((_, reject) =>
    setTimeout(
      () => reject(new TimeoutError(`Operation timed out after ${timeout}ms`)),
      timeout,
    ),
  );

  const futureResult = typeof promise === 'function' ? promise() : promise;

  return Promise.race([futureResult, timeoutPromise]);
}

export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}
