import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { useQuery } from '@tanstack/react-query';

type DeviceInfoResult = DeviceInfo & { updaterId?: string; uuid?: string };

export function useDeviceInfo() {
  const { data: deviceInfo, isLoading } = useQuery(
    ['deviceInfo'],
    () =>
      Promise.all([
        Device.getInfo(),
        Device.getId(),
        Capacitor.isNativePlatform() ? CapacitorUpdater.getDeviceId() : null,
      ]).then(
        ([deviceInfo, device, updater]) =>
          ({
            ...deviceInfo,
            uuid: device.identifier,
            ...(updater && {
              updaterId: updater.deviceId,
            }),
          } as DeviceInfoResult),
      ),
    {
      cacheTime: Infinity,

      // This query is not dependent on the network, so we can use offlineFirst
      networkMode: 'offlineFirst',
    },
  );

  return isLoading ? undefined : ((deviceInfo ?? {}) as DeviceInfoResult);
}
