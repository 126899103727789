import { z } from 'zod';

import { coachMinimumDetailSchema } from './coach.schema';

const contentLabelBase = z.object({
  name: z.string(),
  slug: z.string(),
});

export const contentLabelCategorySchema = contentLabelBase.extend({
  label_type: z.literal('Category'),
});

export const contentLabelTagSchema = contentLabelBase.extend({
  label_type: z.literal('Tag'),
});

export const contentLabelSkillSchema = contentLabelBase.extend({
  label_type: z.literal('Skill'),
  category: z.string(),
  foreground_color: z.string(),
  background_color: z.string(),
});

export const mediaCategory = z.enum([
  'pillar_intro',
  'practice_intro',
  'practice_coach_me',
]);

export const playableAudioSchema = z.object({
  type: z.literal('audio'),
  title: z.string(),
  slug: z.string(),
  playback_id: z.string(),
  duration: z.string(),
  transcript: z.string(),
  played: z.boolean(),
  played_within_current_session: z.boolean().nullable(),
  category: mediaCategory.optional().nullable(),
});

export const pillarAudioSchema = playableAudioSchema
  .omit({ category: true })
  .extend({ category: mediaCategory });

export const playableVideoSchema = z.object({
  type: z.literal('video'),
  slug: z.string(),
  title: z.string(),
  description: z.string().optional(),
  playback_id: z.string(),
  duration: z.string().optional(),
  thumbnail_time_code: z.string(),
  aspect_ratio: z.string().optional(),
  watched: z.boolean(),
  watched_within_current_session: z.boolean().nullable().default(false),
  coach: coachMinimumDetailSchema.optional().nullable(),
  category: mediaCategory.optional().nullable(),
});

export const pillarVideoSchema = playableVideoSchema
  .omit({ category: true })
  .extend({ category: mediaCategory });

export const videoListItemSchema = z.object({
  type: z.literal('video'),
  title: z.string(),
  description: z.string(),
  url: z.string(),
  coach: coachMinimumDetailSchema,
  slug: z.string(),
  skill: contentLabelSkillSchema,
  asset_id: z.string(),
  playback_id: z.string(),
  duration: z.string(),
  thumbnail_time_code: z.string(),
  favorited: z.boolean(),
  watched: z.boolean(),
  watched_within_current_session: z.boolean().nullable().default(false),
  length: z.enum(['short', 'medium', 'long']),
});

export const programListItemSchema = z.object({
  title: z.string(),
  url: z.string(),
  slug: z.string(),
  skill: contentLabelSkillSchema,
  description: z.string(),
  data: z.object({
    duration: z.number().nullable(),
    progress: z.number().nullable(),
    status: z.enum(['unenrolled', 'enrolled', 'completed']).nullable(),
    num_videos: z.number(),
  }),
  next_video: videoListItemSchema.optional().nullable(),
});

export const programDetailSchema = programListItemSchema.extend({
  video_data: z.object({
    next_video_slug: z.string().nullable(),
    videos: z.array(videoListItemSchema),
  }),
});

export const relatedProgramSchema = programListItemSchema.extend({
  next_video: videoListItemSchema,
});

export const videoDetailSchema = videoListItemSchema
  .extend({
    created_at: z.string(),
    liked: z.boolean().optional(),
    related_programs: z.array(relatedProgramSchema),
  })
  .omit({
    length: true,
  });
