import { Button, ButtonProps } from '@chakra-ui/react';

import { useStyles } from './card';

export type CardButtonProps = ButtonProps;

export function CardButton({ ...props }: CardButtonProps) {
  const styles = useStyles();
  const colorScheme = styles.props?.colorScheme
    ? `${styles.props.colorScheme}-inverted`
    : '';
  return <Button colorScheme={colorScheme} {...props} />;
}
