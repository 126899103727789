import { useMemo } from 'react';
import { BoxProps, Flex } from '@chakra-ui/react';
import { formatDuration } from 'date-fns';

import { TEXT } from '@arena-labs/strive2-ui';

import { Program } from '../../api/programs';

export type ProgramStatsProps = {
  program: Program;
} & BoxProps;

export function ProgramStats({ program, ...rest }: ProgramStatsProps) {
  const duration = useMemo(() => {
    if (!program.total_duration) return null;
    const minutes = Math.round(program.total_duration / 60);
    return formatDuration({
      hours: Math.floor(minutes / 60),
      minutes: minutes % 60,
    });
  }, [program.total_duration]);

  return (
    <Flex
      w="full"
      as="dl"
      aria-label="Program details"
      justifyContent="space-between"
      {...rest}
    >
      <div>
        <TEXT.P3 as="dt" color="neutral.400" pb={2}>
          TOTAL LENGTH
        </TEXT.P3>
        <TEXT.P2 as="dd">{duration}</TEXT.P2>
      </div>
      <div>
        <TEXT.P3 as="dt" color="neutral.400" pb={2}>
          YOUR PROGRESS
        </TEXT.P3>
        <TEXT.P2 as="dd">
          {program.percent_complete
            ? `${Math.round(program.percent_complete)}% Complete`
            : 'Not started'}
        </TEXT.P2>
      </div>
    </Flex>
  );
}
