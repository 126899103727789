import { Box, Button, Divider, Flex, HStack } from '@chakra-ui/react';
import { addDays } from 'date-fns';

import { Elevation, pluralize, TEXT } from '@arena-labs/strive2-ui';
import { IncentiveType } from '@strive/api';

import { CompetitionLeaders } from './competition-leaders';
import CompetitionScoreGauge from './competition-score-gauge';

export type CompetitionHubLeaderboardProps = {
  competitionData: IncentiveType<'competition'>;
  onClickedDetails: () => void;
};

export function CompetitionHubLeaderboard({
  competitionData,
  onClickedDetails,
}: CompetitionHubLeaderboardProps) {
  const daysUntilCompEnds = Math.round(
    (competitionData.end_date.getTime() - addDays(new Date(), 0).getTime()) /
      (1000 * 60 * 60 * 24),
  );

  return (
    <>
      <CompetitionScoreGauge
        showConnectorTree={false}
        hrv_above_avg={competitionData.hrv_above_avg}
        consistency={competitionData.consistency}
        score={competitionData.score}
        position={competitionData.position}
        linkStats
      />

      <Box position={'relative'} w="full">
        <Divider
          position={'absolute'}
          top={'50%'}
          left={0}
          zIndex={-1}
          mx={-6}
          px={6}
        />

        {competitionData.end_date > new Date() && (
          <Elevation
            level={'1dp'}
            px={4}
            py={3}
            border={'1px solid'}
            borderColor={'logo'}
            rounded={'md'}
            as={HStack}
            zIndex={1}
          >
            <Flex gap={1} direction="column" w="full">
              <TEXT.P2>Exclusive Experience Awaits You!</TEXT.P2>

              <TEXT.P3>
                {daysUntilCompEnds === 0
                  ? 'Less than 1 day'
                  : `${daysUntilCompEnds} ${pluralize(
                      daysUntilCompEnds,
                      ' day',
                    )}`}{' '}
                till program ends
              </TEXT.P3>
            </Flex>
            <Button
              fontSize={'p3'}
              variant={'underline-link'}
              onClick={onClickedDetails}
            >
              Details
            </Button>
          </Elevation>
        )}
      </Box>

      <CompetitionLeaders leaders={competitionData.leaderboard} />
    </>
  );
}
