import { UseQueryResult } from '@tanstack/react-query';

import { useChangeEffect } from './use-change-effect';

/**
 * Runs a callback when a react query first transitions to `success`.
 */
export function useQueryLoaded<DataType>(
  query: UseQueryResult<DataType>,
  onLoaded: (value: DataType) => void,
) {
  useChangeEffect(
    query,
    (_) => _.status,
    (current, previous) => {
      if (
        current === 'success' &&
        previous !== 'success' &&
        query.data !== undefined
      ) {
        onLoaded(query.data);
      }
    },
  );
}
