import { Capacitor } from '@capacitor/core';

export * from './push-notifications/push-notifications.machine';
export * from './push-notifications/use-push-notifications';
export * from './push-notifications/use-push';
export * from './push-notifications/use-enable-push-notifications';
export * from './push-notifications/push-permissions-required';
export * from './push-notifications/toggle-push-notifications';
export * from './push-notifications/declined-notification-permission-modal';
export * from './push-notifications/fear-of-missing-out';
export * from './push-notifications/success-metrics';
export * from './push-notifications/utils';

export const isPushEnabled = Capacitor.isPluginAvailable('PushNotifications');
