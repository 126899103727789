import { useFormContext } from 'react-hook-form';
import {
  chakra,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  SimpleGrid,
  TextProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export type DateOfBirthInputProps = {
  variant?: InputProps['variant'];
};

function FieldError(props: TextProps) {
  const styles = useMultiStyleConfig('FormError', props);
  return (
    <chakra.div
      id={props.id}
      gridColumn="1 / -1"
      justifySelf="start"
      __css={styles.text}
    >
      {props.children}
    </chakra.div>
  );
}

export function DateOfBirthInput({ variant }: DateOfBirthInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ dob: { month: number; day: number; year: number } }>();

  // This grid is following the technique described here:
  // https://css-tricks.com/expandable-sections-within-a-css-grid/
  // So that the errors can be displayed full-width below the 3 columns

  return (
    <SimpleGrid columns={3} columnGap="4" rowGap="1" autoFlow="dense">
      <FormControl id="dob-month" isInvalid={Boolean(errors.dob?.month)}>
        <FormLabel
          color="strive.cardTextSecondary"
          fontWeight="bold"
          fontSize="sm"
          mb="0"
        >
          MM
        </FormLabel>
        <Input
          {...register('dob.month')}
          maxLength={2}
          autoComplete="bday-month"
          type="tel"
        />
      </FormControl>
      {errors.dob?.month?.message && (
        <FieldError id="dob-month-feedback">
          {errors.dob?.month?.message}
        </FieldError>
      )}

      <FormControl id="dob-day" isInvalid={Boolean(errors.dob?.day)}>
        <FormLabel
          color="strive.cardTextSecondary"
          fontWeight="bold"
          fontSize="sm"
          mb="0"
        >
          DD
        </FormLabel>

        <Input
          {...register('dob.day')}
          maxLength={2}
          autoComplete="bday-day"
          variant={variant}
          type="tel"
        />
      </FormControl>
      {errors.dob?.day?.message && (
        <FieldError id="dob-day-feedback">
          {errors.dob?.day?.message}
        </FieldError>
      )}

      <FormControl id="dob-year" isInvalid={Boolean(errors.dob?.year)}>
        <FormLabel
          color="strive.cardTextSecondary"
          fontWeight="bold"
          fontSize="sm"
          mb="0"
        >
          YYYY
        </FormLabel>
        <Input
          {...register('dob.year')}
          maxLength={4}
          autoComplete="bday-year"
          variant={variant}
          type="tel"
        />
      </FormControl>
      {errors.dob?.year?.message && (
        <FieldError id="dob-year-feedback">
          {errors.dob?.year?.message}
        </FieldError>
      )}
    </SimpleGrid>
  );
}
