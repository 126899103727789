import Head from 'next/head';
import { Flex, Grid } from '@chakra-ui/react';

import { AppContainer, BrandLogo } from '@arena-labs/strive2-ui';

export type ErrorLayoutProps = {
  title?: string;
  children: React.ReactNode;
};

export function ErrorLayout({ title, children }: ErrorLayoutProps) {
  return (
    <AppContainer height="full" flexDirection="column">
      {title && (
        <Head>
          <title>{`Arena Strive | ${title}`}</title>
        </Head>
      )}
      <Grid
        templateRows="auto 1fr"
        mx="8"
        height="full"
        pb="env(safe-area-inset-bottom)"
      >
        <Flex
          as="header"
          my="10"
          alignItems="center"
          justifyContent={['center', 'flex-start']}
        >
          <BrandLogo />
        </Flex>
        <main>{children}</main>
      </Grid>
    </AppContainer>
  );
}
