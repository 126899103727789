import { BoxProps, chakra } from '@chakra-ui/react';

import { Video } from '@arena-labs/shared-models';
import { HorizontalList } from '@arena-labs/strive2-ui';

import { VideoCard, VideoCardProps } from './video-card';

export interface VideoGroupProps {
  videos: Video[];
  getHref: (video: Video) => string;
  groupId: string;
  box?: BoxProps;
  onClickVideo?: (video: Video) => void;
}

export function VideoGroup({
  groupId,
  videos,
  getHref,
  box,
  onClickVideo,
}: VideoGroupProps) {
  const handleClick: (video: Video) => VideoCardProps['onClick'] =
    (video) => (event) => {
      onClickVideo?.(video);
    };

  return (
    <HorizontalList groupId={groupId} {...box}>
      {videos.map((video) => (
        <chakra.li key={video.slug}>
          <VideoCard
            video={video}
            href={getHref(video)}
            thumbnailPx="225"
            thumbnailRatio={4 / 3}
            direction="column"
            onClick={handleClick(video)}
          />
        </chakra.li>
      ))}
    </HorizontalList>
  );
}
