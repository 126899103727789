import { assign, createMachine } from 'xstate';

export const mediaSuspenderMachine = createMachine(
  {
    id: 'mediaSuspender',
    tsTypes: {} as import('./media-suspender.machine.typegen').Typegen0,
    schema: {
      context: {} as { activeSuspensions: number },
      events: {} as
        | { type: 'Suspend' }
        | { type: 'Remove' }
        | { type: 'Clear' },
    },
    context: {
      activeSuspensions: 0,
    },
    initial: 'idle',
    states: {
      idle: {},
      suspended: {
        on: {
          Remove: [
            {
              target: 'idle',
              actions: 'remove',
              cond: 'isLastBlock',
            },
            { actions: 'remove' },
          ],
          Clear: {
            target: 'idle',
            actions: 'clear',
          },
        },
      },
    },
    on: {
      Suspend: {
        target: 'suspended',
        actions: 'suspend',
      },
    },
  },
  {
    guards: {
      isLastBlock: (ctx) => ctx.activeSuspensions === 1,
    },
    actions: {
      suspend: assign({
        activeSuspensions: (ctx) => ctx.activeSuspensions + 1,
      }),
      remove: assign({
        activeSuspensions: (ctx) => Math.max(0, ctx.activeSuspensions - 1),
      }),
      clear: assign({
        activeSuspensions: (ctx) => 0,
      }),
    },
  },
);
