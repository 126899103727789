import { z } from 'zod';

import { useDataStoreQuery } from '@strive/utils';

export function useSubtitlesPreference() {
  const [preference, setPreference] = useDataStoreQuery({
    key: 'Media.Subtitles',
    schema: z.union([z.string(), z.literal(false)]),
    fallback: 'en',
  });
  return [
    preference.data !== undefined ? preference.data : undefined,
    setPreference.mutateAsync,
  ] as const;
}
