import { isPlayableVideo } from '@arena-labs/shared-models';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { VideoModal } from '@strive/av';

export function TeamVideoModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [homepage, { refresh }] = useHomepage();

  const video = homepage?.team_video_data;
  if (!isPlayableVideo(video)) {
    return null;
  }

  return (
    <VideoModal
      video={video}
      orientation="portrait"
      isOpen={isOpen}
      onEnded={onClose}
      onClose={() => {
        refresh();
        onClose();
      }}
      title={video.title}
      description={video.description}
    />
  );
}
