import { Channel, StreamChat } from 'stream-chat';
import { assign, createMachine } from 'xstate';

import { StriveChatGenerics } from '@arena-labs/chat';
import { withTimeout } from '@strive/utils';

const pushProviderName =
  process.env.NEXT_PUBLIC_STREAM_PUSH_PROVIDER || 'CoachChat';

export type ChatConnectionProps = {
  userId: string;
  userToken: string;
  channelType: string;
  channel: string;
  userProps?: Record<string, unknown>;
};

export const chatConnectionMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGMAWBDALgYQPYDt8xlMBLAgYgBFTZkCiSBtABgF1FQAHXWUsgpxAAPRAFYA7ABoQAT0QBGMQGYAdBIAsm5QCYWLAJw6DCncoC+5mWix5CxAfgoBlMJgAEXAK6xU7zLgA1mD4rBxIIDx8jkKiCGImqoYAHMoKGqmpAGwSWTLyCBkaqsYGGgb6WVkGNQYSltYYOAwO5PiqpBAANmAUdoyYYUJR-G2xiGkKqmIaGjpZ82I6Swl5coq5qlp6ChLJSioGFlYgNs32JG2q-a34UBQQBGAd+ABuQc9nN5cE1y2XdwQpDeuGQWDaYSGERGMQicQkyjE02SEj0uSMEjELAU+QmWnUaQROg0CkMGgapya30cfwuZDuFDAACcmbgmaouF0sAAzNkAW1UX3+NOpwKgQJBYMckPYw14o0EcMQCKRYhRaOqOkx2NxCF2ElUdXmFSqCgUySxWQpQrpV1FDOEsEwWGe6G5mGZAApsAB5AByfoAotgACoASX9AH1wwBZQM+gCqIYAlBQbQM7cKxVDuPLYaB4YjkaiWOitVices9WlVOlUnVERIjjozNaqVnftTIC43J4fH4AsFQrLoXmxkq9SwqlsLVllHMEspDGsCkodLWWDpkllyrlkhoFm3bB32qKCO5uehSD0IH1hTnImPFQXECi1DuWBllJoWMkjMpdX2VRdGMYliV0BQanJE501aTthW7akHxhccXwQN9VA-L8fz-MxdWMA0TWWIwDAWLU50sE58FwCA4CEWCfnwOVolQkREAMXUAFosiSfQ+P4-j6hg9tbV+ToemYhUmInFsDWSC0NFLZJP2WOcdF1XYeI-SpzTRPYj3ODN4NtO5JPzNjCiLHJUkxTFtzMZYNJUEplnIrdtkMAyz1PBCIDM1i4lJUt1E0Od5JJeTlGSfC9kNIwFB3WYTDNZIvJPWkjPwC8rxvfznwsoKDQkUKov3c1Ml1I5MIUXQEkU-dt0-SjzCAA */
  createMachine(
    {
      id: 'chatConnection',
      tsTypes: {} as import('./chat-connection.machine.typegen').Typegen0,
      predictableActionArguments: true,

      schema: {
        context: {} as {
          client: StreamChat;
          channel?: Channel | null;
          pushToken?: string | null;
          pushEnabled?: boolean;
        },
        events: {} as
          | { type: 'Connect'; data: ChatConnectionProps }
          | { type: 'Disconnect' }
          | { type: 'Set push token'; token?: string; enable?: boolean },
        services: {} as {
          createStreamConnection: { data: Channel };
        },
      },

      context: {
        client: new StreamChat<StriveChatGenerics>(
          process.env.NEXT_PUBLIC_STREAM_CHAT_KEY ?? '',
          { timeout: 10_000 },
        ),
        channel: undefined,
        pushToken: undefined,
        pushEnabled: undefined,
      },

      initial: 'idle',

      states: {
        idle: {
          on: {
            Connect: 'Connecting',
          },
        },

        Connecting: {
          invoke: {
            src: 'createStreamConnection',
            onDone: 'Connected',
            onError: 'Connection failed',
          },

          after: {
            CONNECTION_TIMEOUT: 'Connection failed',
          },
        },

        Connected: {
          entry: ['assignChannel', 'registerDevice'],
          exit: 'clearChannel',

          invoke: {
            src: 'watchStreamConnection',
          },

          on: {
            'Set push token': {
              actions: ['setPushToken', 'registerDevice'],
            },

            Connect: 'Connecting',
          },
        },

        'Connection failed': {
          on: {
            Connect: 'Connecting',
          },
        },
      },

      on: {
        Disconnect: {
          target: '.idle',
          actions: 'disconnectUser',
        },

        'Set push token': {
          actions: 'setPushToken',
        },
      },
    },
    {
      delays: {
        CONNECTION_TIMEOUT: 30_000,
      },
      actions: {
        assignChannel: assign({
          channel: (context, event) =>
            'data' in event ? event.data : context.channel,
        }),
        clearChannel: assign({
          channel: (_context) => null,
        }),
        setPushToken: assign({
          pushToken: (_context, event) => event.token,
          pushEnabled: (_context, event) => event.enable,
        }),
        registerDevice: async (context, event) => {
          const { client } = context;
          // Remove all devices if push is disabled
          const removeDevices =
            (event.type === 'Set push token' && event.enable === false) ||
            (event.type ===
              'done.invoke.chatConnection.Connecting:invocation[0]' &&
              context.pushEnabled === false);
          if (removeDevices) {
            const { devices } = await client.getDevices();
            console.info(
              '💬 %cChat:',
              'font-weight:bold',
              `Chat: Removing ${devices?.length ?? 0} devices`,
              devices,
            );
            await Promise.all(
              devices?.map((device) => client.removeDevice(device.id)) ?? [],
            );
            return;
          }

          const token =
            event.type === 'Set push token' ? event.token : context.pushToken;
          if (!token) return;

          client
            .addDevice(token, 'firebase', undefined, pushProviderName)
            .then(() =>
              console.info(
                '💬 %cChat:',
                'font-weight:bold',
                'Registered device token with Stream Chat',
              ),
            )
            .catch((err) =>
              console.error(
                '💬 %cChat:',
                'font-weight:bold',
                'Error registering device token with Stream',
                err,
              ),
            );
        },
        disconnectUser: async (context, event) => {
          const { client, pushToken } = context;
          if (pushToken) {
            console.info(
              '💬 %cChat:',
              'font-weight:bold',
              'Unregistering push notifications',
            );

            const { devices } = await client.getDevices();
            if (devices?.find((d) => d.id === pushToken)) {
              console.info('💬 %cChat:', 'font-weight:bold', 'Removing device');
              await client.removeDevice(pushToken);
            }
          }

          console.info(
            '💬 %cChat:',
            'font-weight:bold',
            'Signing out chat client',
          );
          client.disconnectUser();
        },
      },
      services: {
        createStreamConnection: async (context, { data }) => {
          const { client } = context;
          try {
            console.info(`💬 Chat: Connecting to Stream Chat`);
            await withTimeout(
              10_000,
              client.connectUser(
                { ...data.userProps, id: data.userId },
                data.userToken,
              ),
            );

            console.info(`💬 Chat: Subscribing to ${data.channel}`);
            const channel = client.channel(data.channelType, data.channel);
            await withTimeout(10_000, channel.watch());

            console.info(`💬 Chat: Connected ✅`);
            return channel;
          } catch (e) {
            console.error('❌ connect error', e);
            throw e;
          }
        },
        watchStreamConnection: (context, event) => (callback, onReceive) => {
          const { client } = context;
          const handleConnectionChange = async ({ online = false }) => {
            if (!online)
              return console.info(
                '💬 %cChat:',
                'font-weight:bold',
                'connection lost',
              );
            try {
              await client.recoverState();
            } catch (e) {
              console.error('❌ recoverState failed', e);
            }
          };

          client.on('connection.changed', handleConnectionChange);

          return () => {
            client.off('connection.changed', handleConnectionChange);
          };
        },
      },
    },
  );
