import { Capacitor } from '@capacitor/core';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';

import { AlertBox } from '@arena-labs/strive2-ui';
import { useUpdateUserProfileMutation, useUserProfile } from '@strive/api';

import { usePushPermissions, usePushRequestPermissions } from './use-push';
import { openSettingsPage } from './utils';

export type PushPermissionsRequiredProps = {
  children: React.ReactNode;
};

export function PushPermissionsRequired({
  children,
}: PushPermissionsRequiredProps) {
  const { data: user } = useUserProfile();
  const pushPermissions = usePushPermissions();
  const requestPermission = usePushRequestPermissions();
  const updateUser = useUpdateUserProfileMutation();

  const enablePushNotifications = useMutation(async () => {
    if (pushPermissions?.data === 'prompt') {
      await requestPermission.mutateAsync();
    }
    if (user && !user.profile.send_push) {
      await updateUser.mutateAsync({ profile: { send_push: true } });
    }
  });

  if (pushPermissions?.data === 'denied') {
    return (
      <AlertBox
        status="warning"
        title="Notifications"
        description={
          <Flex direction="column" gap="2">
            It seems that you&apos;ve disabled notifications for our app at the
            system level. To receive valuable reminders and stay on track with
            your goals, please head to your device&apos;s settings and enable
            notifications for our app.
            {Capacitor.isPluginAvailable('NativeSettings') ? (
              <Button size="xs" onClick={() => openSettingsPage()} mx="auto">
                Open Settings
              </Button>
            ) : null}
          </Flex>
        }
      />
    );
  }

  if (pushPermissions?.data === 'prompt' || (user && !user.profile.send_push)) {
    return (
      <AlertBox
        title="Notifications"
        description={
          <Flex direction="column" gap="3">
            <Text fontSize="sm">
              To enable helpful reminders and make the most of our app, we need
              your permission to send push notifications.
            </Text>
            <Button
              ml="auto"
              size="xs"
              variant="solid"
              onClick={() => enablePushNotifications.mutate()}
              isLoading={enablePushNotifications.isLoading}
            >
              Activate now
            </Button>
          </Flex>
        }
      />
    );
  }

  return (
    <>
      {enablePushNotifications.isSuccess ? (
        <AlertBox
          status="success"
          title="Notifications"
          description="Permission is granted. Please choose a time for your daily reminder."
        ></AlertBox>
      ) : null}
      {children}
    </>
  );
}
