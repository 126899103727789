import { useEffect, useRef, useState } from 'react';
import { useCallbackRef } from '@chakra-ui/react';

type UseStateTuple<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export function useSyncState<StateType>(
  stateGetter: StateType | (() => StateType),
  deps: unknown[] = stateGetter instanceof Function ? [] : [stateGetter],
): UseStateTuple<StateType> {
  // Sync initial state
  const [state, setLocalState] = useState(stateGetter);

  const getter = useCallbackRef(
    stateGetter instanceof Function ? stateGetter : () => stateGetter,
  );

  const localRef = useRef(state);
  useEffect(() => {
    const next = getter();
    if (localRef.current !== next) {
      // only update local state if it differs with the current state
      localRef.current = next;
      setLocalState(next);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, getter]);

  return [state, setLocalState];
}
