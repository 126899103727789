import { Preferences } from '@capacitor/preferences';
import { useCallbackRef } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AuthResponse } from '@arena-labs/shared-models';
import { useHomeScreenData } from '@arena-labs/strive2-coaching';
import {
  authClient,
  AuthTokenStore,
  setAuthTokens,
  useStriveEnvironments,
} from '@strive/api';
import { useMediaProgressActions } from '@strive/av';

import { GenerateDataData } from './admin-schema';

export type ImpersonateRequest = { user_id: number } | { username: string };

export type TeleportRequest =
  | { learning_state: 'onboarding' }
  | { learning_state: 'in_progress'; learning_session: number }
  | { learning_state: 'complete' };

export type DeleteDataRequest = {
  after?: Date | null;
  before?: Date | null;
  check_ins?: boolean;
  practices?: boolean;
  audio_plays?: boolean;
  video_views?: boolean;
  sensor_data?: boolean;
  pre_assessment?: boolean;
  post_assessment?: boolean;
  onboard?: boolean;
  app_gate?: boolean;
  incentives?: boolean;
  push_tokens?: boolean;
  local?: boolean;
};

function useInvalidateCache() {
  const queryClient = useQueryClient();
  const [, { refreshState }] = useHomeScreenData();
  return useCallbackRef(() => {
    queryClient.invalidateQueries();
    refreshState();
  });
}

async function clearStorage(protectedKeys = [AuthTokenStore.key]) {
  const { keys } = await Preferences.keys();
  const deletableKeys = keys.filter((key) => !protectedKeys.includes(key));
  await Promise.all(deletableKeys.map((key) => Preferences.remove({ key })));
}

export function useImpersonateMutation() {
  const clearCache = useInvalidateCache();

  return useMutation(async (data: ImpersonateRequest) => {
    const { data: tokens } = await authClient.post<AuthResponse>(
      `/secret/impersonate/`,
      data,
    );
    setAuthTokens(tokens);
    clearCache();

    return tokens;
  });
}

export function useTeleportMutation() {
  const clearCache = useInvalidateCache();
  const { reset: resetMediaProgress } = useMediaProgressActions();

  return useMutation(async (data: TeleportRequest) => {
    await authClient.post(`/secret/relocate/`, data);
    if (data.learning_state === 'onboarding') {
      clearStorage();
    }
    clearCache();
    resetMediaProgress();
  });
}

export function useDeleteDataMutation() {
  const clearCache = useInvalidateCache();

  return useMutation(async (data: DeleteDataRequest) => {
    const request = {
      from_datetime: data.after?.toISOString(),
      to_datetime: data.before?.toISOString(),
      fields: [
        data.check_ins && 'check_ins',
        data.practices && 'practices',
        data.audio_plays && 'audio_plays',
        data.video_views && 'video_views',
        data.sensor_data && 'sensor_data',
        data.pre_assessment && 'pre_assessment',
        data.post_assessment && 'post_assessment',
        data.app_gate && 'app_gate',
        data.onboard && 'onboard',
        data.incentives && 'incentives',
        data.push_tokens && 'push_tokens',
      ].filter(Boolean),
    };
    if (request.fields.length) {
      await authClient.post(`/secret/delete_data/`, request);
    }
    if (data.local || data.onboard) {
      await clearStorage();
    }
    clearCache();
  });
}

export function useFactoryResetMutation() {
  const clearCache = useInvalidateCache();

  return useMutation(async () => {
    await Promise.all([
      authClient.delete(`/secret/factory_reset/`),
      clearStorage(),
    ]);
    clearCache();
  });
}

export function useGenerateDataMutation() {
  const clearCache = useInvalidateCache();

  return useMutation(async (data: GenerateDataData) => {
    await authClient.post(`/secret/generate_data/`, data);
    clearCache();
  });
}

export function useAdminUndoMutation() {
  const clearCache = useInvalidateCache();

  return useMutation(async () => {
    const { data: lastAction } = await authClient.get<{ message: string }>(
      `/secret/undo/`,
    );
    clearCache();
    return lastAction;
  });
}

export function useEnvironmentMutation() {
  const clearCache = useInvalidateCache();
  const { setCustomEnvironment, setEnvironment } = useStriveEnvironments();

  return useMutation(async function mutateEnv(data: {
    environment: string;
    server?: string;
    api?: string;
  }): Promise<void> {
    if (data.environment === 'custom' && data.server && data.api) {
      setCustomEnvironment(data.server, data.api);
    }
    setEnvironment(data.environment);

    clearCache();
  });
}
