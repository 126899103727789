import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: ({ path, originalValue }) => `${path} is not valid`,
  },
});

export const stringSchema = yup.string().required().trim();
export const numberSchema = yup
  .number()
  .integer()
  .required()
  // eslint-disable-next-line no-template-curly-in-string
  .typeError('${label} is required');

const currentYear = new Date().getFullYear();

export const emailSchema = stringSchema.label('Email').email();
export const passwordSchema = stringSchema.label('Password').min(8);

export const phoneSchema = stringSchema
  .label('Phone number')
  .min(8)
  .max(20)
  .test(
    'is-valid-phone-number',
    'Please enter a valid phone number',
    (phone) => typeof phone === 'string' && isValidPhoneNumber(phone, 'US'),
  );

const optional = <T extends yup.AnySchema>(type: T) =>
  yup.lazy((val) => (val === '' ? yup.string().transform(() => null) : type));

export const dobSchema = yup.object({
  month: optional(yup.number().integer().label('Month').min(1).max(12)),
  day: optional(
    yup
      .number()
      .integer()
      .label('Day')
      .min(1)
      .max(31)
      .test(
        'is-valid-date',
        'Please enter a valid date',
        (day, { parent: dob }) => {
          if (dob?.year && dob?.month && dob?.day) {
            const date = new Date(dob.year, dob.month - 1, dob.day);
            return (
              date.getFullYear() === dob.year &&
              date.getMonth() + 1 === dob.month &&
              date.getDate() === dob.day
            );
          }
          return true;
        },
      ),
  ),
  year: optional(
    yup
      .number()
      .integer()
      .label('Year')
      .min(currentYear - 100)
      .max(currentYear - 17),
  ),
});

export const marketingSchema = yup
  .object({
    email: yup.boolean(),
    sms: yup.boolean().oneOf([true]),
    push: yup.boolean(),
  })
  .required();

export const isMaybeEmail = (value: string) =>
  value.length > 5 && value.includes('@');
export const isMaybePhone = (value: string) =>
  value.length > 5 && value.match(/^[-+.\d ]+$/);

export const emailOrPhoneSchema = yup.lazy((value: string) =>
  isMaybeEmail(value)
    ? emailSchema.required()
    : isMaybePhone(value)
    ? phoneSchema.required()
    : yup
        .string()
        .label('Email/phone')
        .required()
        .test(
          'userId-type',
          'Please enter a valid email address or phone number',
          () => false,
        ),
);
