import { useEffect } from 'react';
import router from 'next/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const ignoredUrls = ['/launch'];

// Handle Deep Links
// https://capacitorjs.com/docs/guides/deep-links
export function useAppUrlListener() {
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);

      if (ignoredUrls.includes(url.pathname)) return;

      router.replace(url.pathname);
    });
  }, []);
}
