import { useEffect, useRef, useState } from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  ToastId,
} from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import semver from 'semver';

import { AppTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  AlertBox,
  Icon,
  InfoIcon,
  toast,
  useAppStateChange,
} from '@arena-labs/strive2-ui';

import {
  CapgoUpdateActor,
  useCapgoUpdateMachine,
} from './capgo-update.machine';
import { useCheckAppUpdateMachine } from './check-app-update.machine';
import { useInstalledVersionTracker } from './use-installed-version-tracker';

export type CheckLatestVersionProps = {
  children: React.ReactNode;
};

export function CheckLatestVersion({ children }: CheckLatestVersionProps) {
  const analytics = useAnalytics();
  useInstalledVersionTracker();

  const [state, send] = useCheckAppUpdateMachine({
    onUpdateAvailable: (_, event) => {
      // Log info about the update to analytics
      const { currentVersion, latestVersion, appUpdate } = event.data;
      analytics.logEvent(AppTrackingEvent.AppUpdateAvailable, {
        current: currentVersion,
        latest: latestVersion,
        currentNative: appUpdate?.currentVersion,
        latestNative: appUpdate?.availableVersion,
      });
    },
  });

  // Start the machine on mount and when the app becomes active
  useEffect(() => void send('Start'), [send]);
  useAppStateChange((isActive) => {
    if (isActive) {
      send('Start');
    }
  });

  const toastRef = useRef<ToastId>();
  const [_, __, capgoUpdateService] = useCapgoUpdateMachine((state) => {
    const next = state.context.nextBundle;
    const diff = semver.diff(
      state.context.currentVersion,
      next?.version ?? state.context.currentVersion,
    );
    if (
      !toastRef.current &&
      next &&
      (diff === 'minor' || diff === 'preminor')
    ) {
      analytics.logEvent(AppTrackingEvent.AppUpdatePrompt, {
        current: state.context.currentVersion,
        latest: next.version,
        type: diff,
      });

      toastRef.current = toast({
        render: () => (
          <VersionUpdateToast
            bundle={next}
            service={capgoUpdateService}
            onClose={() => toast.closeAll()}
          />
        ),
        duration: null,
      });
    }
  });

  return state.matches('Update Available.Force upgrade') ? (
    <Modal isOpen isCentered onClose={() => null}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h1" fontSize="h2" display="flex" gap="2">
            <Icon as={InfoIcon} size="6" /> Update available
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text>
            Please install the latest version of Arena&nbsp;Strive to continue.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            alignSelf="end"
            onClick={() => {
              analytics.logEvent(AppTrackingEvent.AppUpdateVisitAppStore);
              AppUpdate.openAppStore();
            }}
          >
            Visit App Store
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    children
  );
}

function VersionUpdateToast({
  bundle,
  service,
  onClose,
}: {
  bundle: BundleInfo;
  service: CapgoUpdateActor;
  onClose: () => unknown;
}) {
  const analytics = useAnalytics();
  const [updating, setUpdating] = useState(false);
  const updateNow = async () => {
    if (updating) return;

    analytics.logEvent(AppTrackingEvent.AppUpdateNow);
    setUpdating(true);
    // Slight delay to allow analytics tracking to submit
    setTimeout(() => {
      SplashScreen.show();
      CapacitorUpdater.set({ id: bundle.id });
    }, 1000);
  };

  const state = useSelector(service, (state) => String(state.value));
  const downloadPercent = useSelector(
    service,
    (state) => state.context.downloadPercent,
  );

  const handleClose = () => {
    analytics.logEvent(AppTrackingEvent.AppUpdateLater);
    onClose();
  };

  return (
    <AlertBox
      variant="toast"
      status="info"
      showIcon
      title="Update available"
      description={
        state === 'downloading' || state === 'download complete' ? (
          <Flex direction="column" gap="2">
            <Text>Downloading update...</Text>
            {downloadPercent ? (
              <Progress min={0} max={100} value={downloadPercent} w="full" />
            ) : null}
          </Flex>
        ) : state === 'update available' ? (
          <Flex direction="column" gap="2">
            <Text>
              A new version of Arena Strive has been installed. It may include
              important fixes.
            </Text>

            <Flex>
              <Button
                size="xs"
                py="2"
                variant="secondary"
                onClick={handleClose}
              >
                Restart later
              </Button>

              <Button
                size="xs"
                py="2"
                variant="primary"
                onClick={() => updateNow()}
                isLoading={updating}
                loadingText="Restarting..."
                marginLeft="auto"
              >
                Restart now (recommended)
              </Button>
            </Flex>
          </Flex>
        ) : state === 'download failed' ? (
          <Flex direction="column" gap="2">
            <Text>Update failed to download.</Text>
            <Button size="xs" py="2" variant="secondary" onClick={handleClose}>
              Try again later
            </Button>
          </Flex>
        ) : null
      }
    />
  );
}
