import {
  Box,
  BoxProps,
  RadioProps,
  useRadio,
  useStyleConfig,
} from '@chakra-ui/react';

export interface RadioButtonProps extends RadioProps {
  wrapper?: BoxProps;
  radio?: BoxProps;
}

export function RadioButton(props: RadioButtonProps) {
  const styles = useStyleConfig('CheckboxButton', props);
  const { getInputProps, getCheckboxProps } = useRadio(props);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps = getInputProps() as any;
  const radioProps = getCheckboxProps() as RadioProps;
  const labelId = props.radio?.id ?? `radio-${props.name}-${props.value}`;

  return (
    <Box as="label" {...props.wrapper} position="relative">
      <input
        {...inputProps}
        aria-labelledby={labelId}
        style={{
          position: 'absolute',
          opacity: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        {...radioProps}
        display="grid"
        h="full"
        id={labelId}
        {...props.radio}
        __css={styles}
      >
        {props.children}
      </Box>
    </Box>
  );
}
