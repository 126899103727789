import { Fragment, memo } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const HYPHENATED_WORD = /(\S+(?:[-‐]|&#45;|&hyphen;|&#8208;)\S+)/;

export type NoBreakProps = {
  text: string;
} & Omit<TextProps, 'children'>;

// For a more generic solution, see https://npm.im/react-string-replace
export const NoBreakText = memo(function NoBreakText({
  text,
  ...textProps
}: NoBreakProps) {
  const matches = text.split(HYPHENATED_WORD);
  // loop over matches 2 at a time, wrapping each odd index with a no-wrap class
  return (
    <Text {...textProps} sx={{ '.no-wrap': { whiteSpace: 'nowrap' } }}>
      {matches.map((match, i) => {
        if (i % 2 === 0) {
          return <Fragment key={i}>{match}</Fragment>;
        }
        return (
          <span className="no-wrap" key={i}>
            {match}
          </span>
        );
      })}
    </Text>
  );
});
