import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { playableVideoSchema } from './media.schema';

export type AppGates = z.infer<typeof appGatesSchema>;
export type AppGateProps<AppGateType extends AppGates['type']> = Extract<
  AppGates,
  { type: AppGateType }
>['properties'];

function appGateConfig<
  AppGateType extends string | null,
  AppGateProperties extends z.Schema,
>(type: AppGateType, properties: AppGateProperties) {
  return z.object({
    type: z.literal(type),
    slug: z.string(),
    can_skip: z.boolean(),
    order: z.number().nullable(),
    properties,
  });
}

export const appGatesSchema = z.discriminatedUnion('type', [
  appGateConfig(
    'typeform_gate',
    z.object({
      assessment_id: z.string().optional(),
      video: playableVideoSchema.optional().catch(undefined),
    }),
  ),
  appGateConfig(
    'reward_gate',
    z.object({ image: z.string(), reward: z.string(), trigger: z.string() }),
  ),
  appGateConfig('reminder_prefs', z.unknown().nullable()),
  appGateConfig(
    'competition_completed',
    z.object({
      is_winner: z.boolean(),
      score: z.number(),
      total_practices: z.number(),
      consistency: z.number(),
      hrv_above_avg: z.number(),
    }),
  ),
  appGateConfig(null, z.null()),
]);

const completedGate = z.object({
  slug: z.string(),
  skipped: z.literal(false).default(false),
});

export const appGateApi = makeApi([
  {
    alias: 'updateAppGate',
    description: 'Post data to an app gate',
    method: 'post',
    path: '/app-gate/',
    response: z.unknown(),
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.union([
          // Either skip the gate
          z.object({
            type: z.null().optional(),
            slug: z.string(),
            skipped: z.literal(true).default(true),
          }),

          // Or complete the gate
          completedGate.extend({
            type: z.literal('typeform_gate'),
            response_id: z.string(),
          }),
          completedGate.extend({
            type: z.literal('reminder_prefs'),
            preferences: z.object({
              selected_time: z.string(),
            }),
          }),
          completedGate.extend({ type: z.literal('reward_gate') }),
        ]),
      },
    ],
  },
]);
