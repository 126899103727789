import { Circle, Divider, Flex, Grid, Image } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

export default function CompetitionIncentiveBundle() {
  return (
    <Flex direction="column" h="full" justifyContent={'space-evenly'}>
      <Flex
        direction={'column'}
        px={2}
        justify={'space-between'}
        rounded={'sm'}
        border={'1px solid'}
        borderColor={'neutral.900'}
        gap={'2'}
        py="2"
        textAlign={'center'}
      >
        <TEXT.P1_BOLD>Premier Prize</TEXT.P1_BOLD>
        <TEXT.P2 color={'neutral.400'}>Select 1 out of 3</TEXT.P2>
        <Divider />
        <PremierPrizeEntry
          item={'Day Bright'}
          maker={'Sunrise Sensations'}
          copy={'Get In Light Exposure'}
          imageSrc={'./images/competition/sun-light.webp'}
        />
        <PremierPrizeEntry
          item={'Theragun Mini'}
          maker={'Therabody'}
          copy={'Unwind After Energizing Movement'}
          imageSrc={'./images/competition/theragun.webp'}
        />
        <PremierPrizeEntry
          item={'Smart Goggles'}
          maker={'Therabody'}
          copy={'Improve your Sleep Environment'}
          imageSrc={'./images/competition/smart-goggle.webp'}
        />
      </Flex>

      <Flex direction="row" gap={6} justify={'space-evenly'}>
        <SecondaryPrizeEntry
          item={'Quencher H20'}
          maker={'Stanley'}
          copy={'Practice Hydration'}
          imageSrc="./images/competition/stanley-quencher.webp"
        />
        <SecondaryPrizeEntry
          item={'Gratitude Journal'}
          maker={'Arena Labs'}
          copy={'Examine Gratitude'}
          imageSrc="./images/competition/grat-journal.webp"
        />
      </Flex>
    </Flex>
  );
}

function SecondaryPrizeEntry({
  item,
  maker,
  copy,
  imageSrc,
}: {
  item: string;
  maker: string;
  copy: string;
  imageSrc: string;
}) {
  return (
    <Flex
      rounded={'sm'}
      direction={'column'}
      border={'1px solid'}
      borderColor={'neutral.900'}
      textAlign={'center'}
      py={2}
      w="full"
      maxW={'200px'}
      gap={2}
    >
      <TEXT.P1_BOLD px={-3}>{item}</TEXT.P1_BOLD>
      <TEXT.P3 color={'neutral.400'}>
        <i>{maker}</i>
      </TEXT.P3>
      <TEXT.P2 color={'neutral.400'}>{copy}</TEXT.P2>
      <Circle size="72px" bg="neutral.white" alignSelf={'center'}>
        <Image
          ml={imageSrc.includes('quencher') ? 2 : 0}
          src={imageSrc}
          alt={item}
          w="35px"
        />
      </Circle>
    </Flex>
  );
}

function PremierPrizeEntry({
  imageSrc,
  item,
  maker,
  copy,
}: {
  imageSrc: string;
  item: string;
  maker: string;
  copy: string;
}) {
  return (
    <Grid templateColumns={'auto 1fr'} gap={3} alignItems={'center'}>
      <Circle size="72px" bg="neutral.white" alignSelf={'center'}>
        <Image
          src={imageSrc}
          alt={item}
          w={imageSrc.includes('light') ? '50px' : '70px'}
          alignSelf={'center'}
        />
      </Circle>
      <Flex direction={'column'} textAlign={'left'} gap={2}>
        <Grid templateColumns={'auto 1fr'} gap={2} alignItems={'center'}>
          <TEXT.P1_BOLD>{item}</TEXT.P1_BOLD>
          <TEXT.P3 color={'neutral.400'}>
            <i>{maker}</i>
          </TEXT.P3>
        </Grid>
        <TEXT.P2 color={'neutral.400'}>{copy}</TEXT.P2>
      </Flex>
    </Grid>
  );
}
