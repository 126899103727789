import { useEffect, useState } from 'react';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { useQuery } from '@tanstack/react-query';
import { isString } from 'lodash-es';

import { NotificationTrackingEvent, useAnalytics } from '@arena-labs/analytics';

import { usePushNotificationAction } from '../push-notifications/use-push-notification-action';
import { NotificationModal } from './notification-modal';

// Log analytics when the notification is clicked
export function NotificationListener({
  children,
}: {
  children: React.ReactNode;
}) {
  const analytics = useAnalytics();
  const [notification, setNotification] = useState<{
    title: string;
    body: string;
  } | null>(null);

  usePushNotificationAction();

  useEffect(() => {
    // Log when notifications are clicked on
    let listener: PluginListenerHandle | null = null;
    async function setupListener() {
      listener = await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (data) => {
          analytics.logEvent(NotificationTrackingEvent.LocalNotificationClick, {
            notificationId: data.notification.id,
            notificationTitle: data.notification.extra?.title,
            notificationPillar: data.notification.extra?.pillar,
            notificationPractice: data.notification.extra?.practice,
          });

          const { title, body } = data.notification.extra || {};
          if (isString(title) && isString(body)) {
            setNotification({ title, body });
          }
        },
      );
    }

    setupListener();
    return () =>
      Capacitor.getPlatform() !== 'web' ? void listener?.remove() : undefined;
  }, [analytics]);

  // Log the user permissions
  const { data } = useQuery(['notifications', 'permission-check'], () =>
    LocalNotifications.checkPermissions(),
  );

  const permission = data?.display;
  useEffect(() => {
    if (permission) {
      analytics.logEvent(
        NotificationTrackingEvent.LocalNotificationPermission,
        { permission },
      );
    }
  }, [analytics, permission]);

  return (
    <>
      {children}
      {notification && (
        <NotificationModal
          {...notification}
          closeText="Let's go"
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
}
