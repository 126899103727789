import { z } from 'zod';

import {
  contentLabelSkillSchema,
  playableVideoSchema,
  videoListItemSchema,
} from './media.schema';

export const playlistSchema = z.object({
  video_count: z.number(),
  total_duration: z.number().nullable(),
  percent_complete: z.number(),
  title: z.string(),
  image: z.string().nullable(),
  slug: z.string(),
  description: z.string(),
  status: z.enum(['unenrolled', 'enrolled', 'completed']).nullable(),
  skill: contentLabelSkillSchema.optional(),
  next_video: videoListItemSchema.nullable().default(null),
});

export const playlistDetailSchema = playlistSchema.extend({
  description: z.string(),
  videos: z.array(playableVideoSchema),
  next_video_slug: z.string().nullable(),
});
