import { useUserProfile } from '@strive/api';

/**
 * Creates a higher-order context provider which only renders if the user is logged in.
 * The children will always be rendered, even if the user is not logged in.
 * This is useful for context providers which layer in extra functionality, but only if the user is logged in.
 *
 * @param {React.ComponentType<Props>} Provider - The component to be wrapped.
 * @returns {React.ComponentType<Props>} A new component that checks if the user is authenticated before rendering the provided component.
 */
export function createLoggedInProvider<Props extends React.PropsWithChildren>(
  Provider: React.ComponentType<Props>,
): React.ComponentType<Props> {
  return function LoggedInProvider(props: Props) {
    const { data: user } = useUserProfile();
    if (!user) return <>{props.children}</>;
    return <Provider {...props} />;
  };
}
