import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

import { shadows } from '@strive/theme/tokens';

export type ElevationProps = {
  level: keyof typeof shadows;
} & BoxProps;

export const Elevation = forwardRef(function Elevation(
  { level, bg, ...props }: ElevationProps,
  ref,
) {
  return (
    <Box
      ref={ref}
      boxShadow={level}
      bg={bg ?? `elevation.${level}`}
      {...props}
    />
  );
});
