import { MouseEvent } from 'react';
import NextLink from 'next/link';
import {
  AspectRatio,
  BoxProps,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  LinkOverlayProps,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';

import { withAnalyticsContext } from '@arena-labs/analytics';
import { PlayableVideo, Video } from '@arena-labs/shared-models';
import {
  CheckIcon,
  ChevronRightIcon,
  formatCoachName,
  Icon,
  PlayIcon,
  SleepClockIcon,
  TEXT,
  ZStack,
} from '@arena-labs/strive2-ui';

import { useVideoCache } from '../../api';
import { getVideoTrackingContext } from '../../lib/tracking-helpers';

export type VideoCardProps = {
  href: string;
  video: Video;
  direction?: 'row' | 'column';
  thumbnailPx: string | number;
  thumbnailRatio?: number;
  thumbnailPosition?: string;
  disableLinkBox?: boolean;
  onClick?: (
    event: MouseEvent<HTMLAnchorElement>,
    video: PlayableVideo,
  ) => void;
  linkTarget?: string;
} & Omit<BoxProps, 'onClick'>;

export const VideoCard = withAnalyticsContext(
  ({ video }) => getVideoTrackingContext(video),
  VideoCardImpl,
);

function VideoCardImpl({ direction, ...props }: VideoCardProps) {
  return direction === 'row' ? (
    <VideoCardRow {...props} />
  ) : (
    <VideoCardTile {...props} />
  );
}

export function VideoCardTile({
  href,
  video,
  thumbnailPx,
  thumbnailRatio = 16 / 10,
  thumbnailPosition = 'center',
  disableLinkBox = false,
  onClick,
  linkTarget,
  ...boxProps
}: Omit<VideoCardProps, 'direction'>) {
  const setVideoCache = useVideoCache();
  const imageUrl = `https://image.mux.com/${video.playback_id}/thumbnail.png?width=${thumbnailPx}&time=${video.thumbnail_time_code}`;
  const duration = Math.round(Number(video.duration) / 60);

  const handleClick: LinkOverlayProps['onClick'] = (event) => {
    setVideoCache(video);
    onClick?.(event, video);
  };

  return (
    <LinkBox
      as="article"
      bg="strive.background"
      shadow="md"
      {...(disableLinkBox ? { position: 'initial' } : {})}
      {...boxProps}
    >
      <ZStack maxWidth={`${thumbnailPx}px`} w="full">
        <AspectRatio ratio={thumbnailRatio} minWidth={`${thumbnailPx}px`}>
          <Image
            src={imageUrl}
            alt={`thumbnail for ` + video.title}
            objectFit="cover"
            objectPosition={thumbnailPosition}
            loading="eager"
            flexShrink={0}
          />
        </AspectRatio>
        <Stack
          background="#000000AA"
          direction="column"
          spacing="0"
          width="100%"
          textAlign="left"
          px="2"
          marginTop="auto"
          height="min-content"
          isolation="isolate"
        >
          <LinkOverlay
            href={href}
            as={NextLink}
            onClick={(event) => handleClick(event)}
            target={linkTarget}
          >
            <Text fontSize="sm" noOfLines={1} fontWeight="bold">
              <Icon as={PlayIcon} size="2" mr="1" />
              {video.title}
            </Text>
          </LinkOverlay>

          <Text fontSize="xs">{formatCoachName(video.coach)}</Text>
          <Flex gap="2" justifyContent="space-between" fontSize="xs">
            <Text as="span" display="flex" gap="1" alignItems="center">
              <Icon as={SleepClockIcon} size="3" />
              <span aria-hidden>{duration}m</span>
              <VisuallyHidden>{duration} minutes.</VisuallyHidden>
            </Text>
            {video.watched && (
              <>
                <Text as="span" display="flex" gap="1" alignItems="center">
                  <Icon as={CheckIcon} size="3" />
                  Watched
                </Text>
              </>
            )}
          </Flex>
        </Stack>
      </ZStack>
    </LinkBox>
  );
}

export function VideoCardRow({
  href,
  video,
  thumbnailPx,
  thumbnailRatio = 16 / 10,
  thumbnailPosition = 'center',
  disableLinkBox = false,
  onClick,
  linkTarget,
  ...boxProps
}: Omit<VideoCardProps, 'direction'>) {
  const setVideoCache = useVideoCache();
  const imageUrl = `https://image.mux.com/${video.playback_id}/thumbnail.png?width=${thumbnailPx}&time=${video.thumbnail_time_code}`;
  const duration = Math.round(Number(video.duration) / 60);

  const handleClick: LinkOverlayProps['onClick'] = (event) => {
    setVideoCache(video);
    onClick?.(event, video);
  };

  return (
    <LinkBox
      as="article"
      bg="strive.background"
      shadow="md"
      {...(disableLinkBox ? { position: 'initial' } : {})}
      {...boxProps}
    >
      <Stack direction="row" spacing="2" w="full">
        <AspectRatio ratio={thumbnailRatio} minWidth={`${thumbnailPx}px`}>
          <Image
            src={imageUrl}
            alt={`thumbnail for ` + video.title}
            objectFit="cover"
            objectPosition={thumbnailPosition}
            loading="eager"
            flexShrink={0}
          />
        </AspectRatio>
        <Stack
          direction="column"
          spacing="2"
          width="100%"
          textAlign="left"
          py="2"
          pr="2"
        >
          <LinkOverlay
            href={href}
            as={NextLink}
            onClick={handleClick}
            target={linkTarget}
          >
            <TEXT.P2 noOfLines={1}>{video.title}</TEXT.P2>
          </LinkOverlay>

          <TEXT.P3 color="neutral.400">{formatCoachName(video.coach)}</TEXT.P3>
          <Stack direction="row" spacing="2" fontSize="xs" color="neutral.400">
            <TEXT.P3 as="span">
              <span aria-hidden>{duration}m</span>
              <VisuallyHidden>{duration} minutes.</VisuallyHidden>
            </TEXT.P3>
            {video.watched && (
              <>
                <TEXT.P3 as="span" aria-hidden>
                  &bull;
                </TEXT.P3>
                <TEXT.P3 as="span">Watched</TEXT.P3>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Flex
        alignItems="center"
        position="absolute"
        top="50%"
        right="0"
        transform="translateY(-50%)"
      >
        <Icon as={ChevronRightIcon} ml="3" />
      </Flex>
    </LinkBox>
  );
}
