import { BoxProps } from '@chakra-ui/react';

import { LottieFile, LottieFileProps } from './lottie-file';

export type PracticeIconProps = {
  slug: string;
} & Omit<LottieFileProps, 'as'>;

export function PracticeIcon({ slug, ...props }: PracticeIconProps) {
  const [lottie, boxProps] = getLottieForPractice(slug);

  return (
    <LottieFile
      overflow="visible"
      display="grid"
      placeItems="center"
      {...boxProps}
      {...props}
      as={lottie}
    />
  );
}

function getLottieForPractice(slug: string) {
  const lottiesForPractices: Record<
    string,
    [() => Promise<{ default: unknown }>, BoxProps]
  > = {
    // First Steps
    DestressBreath_Practice: [
      () => import('./lottie-files/destress-breath.lottie.json'),
      { transform: 'scale(2)' },
    ],
    Tool_ColdDarkQuiet: [
      () => import('./lottie-files/sleep-op.lottie.json'),
      {},
    ],
    Hydration_Practice: [
      () => import('./lottie-files/hydration.lottie.json'),
      {},
    ],

    // Power of Light
    Light_Exposure_Practice: [
      () => import('./lottie-files/light-exp.lottie.json'),
      {},
    ],
    Tool_LightRestriction: [
      () => import('./lottie-files/light-restriction.lottie.json'),
      {},
    ],
    Tool_PanoramicVision: [
      () => import('./lottie-files/panoramic-viz.lottie.json'),
      { transform: 'scale(1.3)' },
    ],

    // Power of Breath
    Tool_DeactivationBreath: [
      () => import('./lottie-files/deact-breath.lottie.json'),
      {},
    ],
    Tool_ActivationBreath: [
      () => import('./lottie-files/activation-breath.lottie.json'),
      {},
    ],
    Tool_BoxBreath: [
      () => import('./lottie-files/box-breath.lottie.json'),
      { transform: 'scale(2)' },
    ],

    // Power of Rest
    Tool_YogaNidra: [() => import('./lottie-files/yoga-nidra.lottie.json'), {}],
    Tool_SelfHypnosis: [
      () => import('./lottie-files/self-hyp.lottie.json'),
      {},
    ],
    Tool_ActiveRecovery: [
      () => import('./lottie-files/active-recover.lottie.json'),
      {},
    ],
    Tool_SleepScheduling: [
      () => import('./lottie-files/sleep-time.lottie.json'),
      {},
    ],

    // Power of Movement
    Tool_EnergizingMovement: [
      () => import('./lottie-files/energize.lottie.json'),
      {},
    ],
    Tool_RelaxationMovement: [
      () => import('./lottie-files/relax-movement.lottie.json'),
      {},
    ],

    // Power of Mind
    Tool_StimulusLabeling: [
      () => import('./lottie-files/stimulus-label.lottie.json'),
      { transform: 'scale(1.3)' },
    ],
    Tool_Visualization: [() => import('./lottie-files/viz.lottie.json'), {}],
    Tool_ExamineGratitude: [
      () => import('./lottie-files/gratitude.lottie.json'),
      {},
    ],

    // Power of Fueling
    Tool_MindDiet: [() => import('./lottie-files/mind-diet.lottie.json'), {}],
    Tool_EnergyStabilization: [
      () => import('./lottie-files/energy-stabilization.lottie.json'),
      {},
    ],
    Meal_Timing: [() => import('./lottie-files/meal-timing.lottie.json'), {}],

    // Power of Fitness
    Tool_MinimumEffectiveDose: [
      () => import('./lottie-files/minimum-effective-dose.lottie.json'),
      { transform: 'scale(1.75)' },
    ],
    Tool_ExerciseSnacks: [
      () => import('./lottie-files/exercise-snacks.lottie.json'),
      {},
    ],
    Tool_AMPM_Routine: [
      () => import('./lottie-files/am-pm-movement.lottie.json'),
      { transform: 'scale(1.2)' },
    ],

    // Power of Communication
    Tool_SituationalDialogue: [
      () => import('./lottie-files/situational-dialogue.lottie.json'),
      { transform: 'scale(0.8)' },
    ],
    Tool_Debriefing: [
      () => import('./lottie-files/debriefing.lottie.json'),
      { transform: 'scale(0.9)' },
    ],
    Tool_AfterActionReview: [
      () => import('./lottie-files/after-action-review.lottie.json'),
      { transform: 'scale(0.8)' },
    ],

    // ----------
    // New Pillar/Tools organization

    // Stabilize Energy
    Tool_ShiftingSleepWake: [
      () => import('./lottie-files/shifting-sleep-wake.lottie.json'),
      {},
    ],
    Tool_ExerciseTiming: [
      () => import('./lottie-files/exercise-timing.lottie.json'),
      {},
    ],
    Tool_FuelTiming: [
      () => import('./lottie-files/fuel-and-supplements.lottie.json'),
      {},
    ],
  };

  return lottiesForPractices[slug] ?? [null, {}];
}
