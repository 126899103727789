import React from 'react';
import { Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { PillarPractice } from '@arena-labs/shared-models';
import { ZStack } from '@arena-labs/strive2-ui';

import { useAciMachine } from './aci-context';
import { CheckInInsights } from './checkin-insights/check-in-insights';
import { ComparisonAnimation } from './checkin-insights/comparison-animation';
import { GeneratingInsights } from './checkin-insights/generating-insights';
import { ContextInfo } from './context-info';
import { AciUserInputs } from './user-input-views/aci-user-inputs';

export function ACI({
  banner,
  logPracticeCard,
}: {
  banner: (onNavigateBack?: () => void) => React.ReactNode;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const [state, send] = useAciMachine();

  const shouldShowBanner =
    state.matches({ Open: 'userInputs' }) ||
    state.matches({ Open: 'insights' }) ||
    state.matches({ Open: 'contextInfo' });

  const canNavigateBack = state.can({ type: 'back' });
  const goBack = () => send({ type: 'back' });

  return (
    <>
      {/* i want to move the banner up to this level, but i think we might want to combine the state machines in that case TODO */}
      {shouldShowBanner && banner(canNavigateBack ? goBack : undefined)}
      <Flex direction={'column'} w={'full'} pt={0} px={6} gridArea="content">
        <ZStack h={'full'}>
          <AnimatePresence>
            {state.matches({ Open: 'userInputs' }) ? (
              <AciUserInputs key="inputs" logPracticeCard={logPracticeCard} />
            ) : null}
          </AnimatePresence>

          <AnimatePresence>
            {state.matches({ Open: 'generatingInsights' }) ? (
              <GeneratingInsights />
            ) : null}
          </AnimatePresence>

          <AnimatePresence>
            {state.matches({ Open: 'comparison' }) ? (
              <ComparisonAnimation
                onAnimationEnd={() => send({ type: 'ANIMATION_END' })}
                estimate={state.context.aciState.recharge_estimate}
              />
            ) : null}
          </AnimatePresence>

          <AnimatePresence>
            {state.matches({ Open: 'insights' }) ? (
              <CheckInInsights
                logPracticeCard={logPracticeCard}
                onOpenInfo={() => send({ type: 'OPEN_CONTEXT_INFO' })}
              />
            ) : null}
          </AnimatePresence>

          <AnimatePresence>
            {state.matches({ Open: 'contextInfo' }) ? <ContextInfo /> : null}
          </AnimatePresence>
        </ZStack>
      </Flex>
    </>
  );
}

//stub object
export const hypotheticalACI = {
  daily: {
    inner_state_trend: 'below',

    hrv_trend: 'above',
    hrv_value: 150,
    baseline_hrv: 80,

    practice_count: 3,
    practice_trend: 'above',
    practice_trend_from: '2024-04-25T00:00:00Z',
    practice_previous_count: 2,

    recommend_practice: {
      coach_me_audio: null,
      intro_audio: null,
      coach_me: {
        title: 'Strategic Food Timing Practice',
        url: 'https://strive-api-staging.herokuapp.com/content/videos/Strategic_Food_Timing_Practice/',
        coach: {
          first_name: 'Arena',
          last_name: 'Strive',
          suffix: '',
          headshot_url: '',
          slug: 'arena_strive',
          bio: 'Arena Labs, founded in 2016, is revolutionizing the way frontline clinicians are trained for the rapidly-changing landscape of healthcare. With a team of military leaders, Olympic athletes, clinical professionals, and other experts across the world’s highest-performing disciplines, Arena Labs is the creator of the world’s first performance coaching platform for healthcare, Arena Strive. Arena Strive trains how clinicians prepare for, respond to, and recover from the chronic pressure of living on the frontlines of healthcare with three key components: data-driven methodologies backed by world-class scientists, clinicians, and experts in human performance; actionable daily practices and coaching that optimize stress, energy, and recovery management; and data insights that track changes and measure progress in clinicians’ biomarkers.',
        },
        slug: 'Strategic_Food_Timing_Practice',
        skill: {
          name: 'Self-Awareness',
          label_type: 'Skill',
          category: 'Individual',
          foreground_color: '#FF5F1F',
          background_color: '#38170B',
          slug: 'self-awareness',
        },
        asset_id: '3jA00rppUvMIiHny02h02I7vLWDYNJo3L00Z9fW3oYHm2FM',
        playback_id: '1G00nPxWva2yVgBfKHpKaXTTBlNT01nOba8401f7PKB7Ng',
        length: 'short',
        duration: '108.73',
        thumbnail_time_code: '0.00',
        favorited: false,
        watched: false,
        watched_within_current_session: false,
        aspect_ratio: '2160:3840',
        type: 'video',
        description:
          'Time your meals strategically to enhance health and energy. Align eating with activities and circadian rhythms for immediate benefits and well-being.',
      },
      intro: {
        title: 'Strategic Food Timing Practice Introduction',
        url: 'https://strive-api-staging.herokuapp.com/content/videos/Strategic_Food_Timing_Practice_Intro/',
        coach: {
          first_name: 'Arena',
          last_name: 'Strive',
          suffix: '',
          headshot_url: '',
          slug: 'arena_strive',
          bio: 'Arena Labs, founded in 2016, is revolutionizing the way frontline clinicians are trained for the rapidly-changing landscape of healthcare. With a team of military leaders, Olympic athletes, clinical professionals, and other experts across the world’s highest-performing disciplines, Arena Labs is the creator of the world’s first performance coaching platform for healthcare, Arena Strive. Arena Strive trains how clinicians prepare for, respond to, and recover from the chronic pressure of living on the frontlines of healthcare with three key components: data-driven methodologies backed by world-class scientists, clinicians, and experts in human performance; actionable daily practices and coaching that optimize stress, energy, and recovery management; and data insights that track changes and measure progress in clinicians’ biomarkers.',
        },
        slug: 'Strategic_Food_Timing_Practice_Intro',
        skill: {
          name: 'Intentionality',
          label_type: 'Skill',
          category: 'Individual',
          foreground_color: '#C6FF46',
          background_color: '#3C4B29',
          slug: 'intentionality',
        },
        asset_id: '1UEWLjLPt01GCwyKGxkgdMhxKzacY6eA1jHNbAVJivtI',
        playback_id: 'VlfqPM5jjsr6Y00SYzL55zYS6ij01waCuWwbqRRylEUx00',
        length: 'short',
        duration: '101.98',
        thumbnail_time_code: '0.00',
        favorited: false,
        watched: false,
        watched_within_current_session: false,
        aspect_ratio: '2160:3840',
        type: 'video',
        description:
          'Time your meals strategically to enhance health and energy. Align eating with activities and circadian rhythms for immediate benefits and well-being.',
      },
      log_count: 0,
      order: 18,
      related_videos: [],
      slug: 'Food_Timing',
      title: 'Strategic Food Timing',
      short_title: 'Food Timing',
      prompt:
        'Time your meals strategically to enhance health and energy. Align eating with activities and circadian rhythms for immediate benefits and well-being.',
      completed_today: false,
      completion_count: 0,
      streak: 0,
      favorite: false,
      status: 'not_started',
      icon: null,
      background_image:
        'https://strive-backend.s3.amazonaws.com/media/practice_backgrounds/strategic_food_timing_LLZeyWc.jpeg',
    } as PillarPractice,

    llm_title: 'Try more practices today!',
    llm_description: `Your HRV is trending up! It seems yesterday's practice helped.
     Try to repeat or increase your practices to at least 3 sessions over the next 24 hours.`,
  },
  weekly: {
    match_streak: 1,
    max_match_streak: 4,
    total_interoception_matches: 2,
    current_period_interoception_matches: 3,
    comparison_chart_data: [
      {
        date: '2024-04-10T00:00:00Z',
        inner_state: 'above',
        hrv: null,
      },
      {
        date: '2024-04-11T00:00:00Z',
        inner_state: 'below',
        hrv: 'above',
      },
      {
        date: '2024-04-12T00:00:00Z',
        inner_state: 'above',
        hrv: 'above',
      },
      {
        date: '2024-04-13T00:00:00Z',
        inner_state: 'below',
        hrv: 'below',
      },
      {
        date: '2024-04-14T00:00:00Z',
        inner_state: 'above',
        hrv: null,
      },
      {
        date: '2024-04-15T00:00:00Z',
        inner_state: 'below',
        hrv: 'above',
      },
      {
        date: '2024-04-16T00:00:00Z',
        inner_state: 'above',
        hrv: 'below',
      },
    ],
    llm_title: 'Excellent Body-Mind Alignment',
    llm_description: `Come back tomorrow to fire up your streak and grow your interoception!`,
  },
};
