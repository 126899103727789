import { Preferences } from '@capacitor/preferences';
import { z } from 'zod';
import create from 'zustand';

import { setClientEnvironment } from '../clients';

const envSchema = z.object({
  name: z.string().optional(),
  server: z.string().url(),
  api: z.string().url(),
});

type StriveEnvironment = {
  name?: string;
  server: string;
  api: string;
};

const defaultEnv: StriveEnvironment = {
  server:
    process.env['NEXT_PUBLIC_HOST'] ??
    (typeof location !== 'undefined' ? location.origin : ''),
  api: process.env['NEXT_PUBLIC_API_HOST'] ?? '',
};

export type StriveEnvironmentStore = {
  current: string;
  environments: Record<string, StriveEnvironment>;
  customEnvironment: StriveEnvironment;
  setCustomEnvironment: (server: string, api: string) => void;
  setEnvironment: (environment: string) => void;
  getEnvironment: (environment?: string) => StriveEnvironment | undefined;
};

/**
 * Zustand Store for managing the current environment
 */
export const useStriveEnvironments = create<StriveEnvironmentStore>(
  (set, get) => ({
    current: 'default',
    environments: loadEnvironmentConfig(),
    customEnvironment: {
      server: '',
      api: '',
    },
    setCustomEnvironment: async (server, api) => {
      set(() => ({
        current: 'custom',
        customEnvironment: {
          server,
          api,
        },
      }));
      await saveCustomEnvironment(server, api);
    },
    setEnvironment: async (environment) => {
      const config = get().getEnvironment(environment);
      if (config?.api && config?.server) {
        set(() => ({ current: environment }));
        setClientEnvironment(config.server, config.api);
        await saveCurrentEnvironment(environment);
      } else {
        console.error('Invalid environment', environment);
      }
    },
    getEnvironment: (environment = get().current) => {
      if (environment === 'custom') {
        return get().customEnvironment;
      }

      return get().environments[environment];
    },
  }),
);

/**
 * Loads the available environments which were configured at build time
 */
function loadEnvironmentConfig() {
  const json = safeJSONParse(process.env['NEXT_PUBLIC_ENVIRONMENTS'] ?? '{}');
  if (!json) {
    console.error(
      'Error parsing environment config',
      process.env['NEXT_PUBLIC_ENVIRONMENTS'],
    );
  }

  const envs: Record<string, StriveEnvironment> = {
    default: { name: 'Default', ...defaultEnv },
  };

  // Parse the environment config
  try {
    // Merge in additional environments
    const parsed = z.record(envSchema).parse(json);
    Object.entries(parsed).forEach(([key, value]) => {
      if (
        !['default', 'custom'].includes(key) &&
        value.server !== defaultEnv.server &&
        value.api !== defaultEnv.api
      ) {
        envs[key] = value;
      }
    });
  } catch (e) {
    console.error('Error parsing environment config', e);
  }

  return envs;
}

/**
 * Loads the configuration for the custom environment (server/api URLs)
 */
async function loadCustomEnvironment() {
  const { value: customEnv } = await Preferences.get({
    key: 'StriveCustomEnvironment',
  });
  if (customEnv) {
    try {
      const parsedCustom = envSchema.parse(safeJSONParse(customEnv ?? '{}'));
      return parsedCustom;
    } catch (error) {
      console.error(['Error parsing custom environment', error]);
    }
  }
  return undefined;
}

/**
 * Saves the configuration for the custom environment (server/api URLs)
 */
async function saveCustomEnvironment(server: string, api: string) {
  await Preferences.set({
    key: 'StriveCustomEnvironment',
    value: JSON.stringify({ server, api }),
  });
}

/**
 * Loads the current environment from Preferences
 */
async function loadCurrentEnvironment() {
  const { value: environment } = await Preferences.get({
    key: 'StriveEnvironment',
  });
  return environment;
}

/**
 * Saves the current environment to Preferences
 */
async function saveCurrentEnvironment(name: string) {
  await Preferences.set({
    key: 'StriveEnvironment',
    value: name,
  });
}

/**
 * Initialize the environment store at runtime from the StriveCustomEnvironment Preferences key
 */
export async function initializeEnvironment() {
  if (typeof window === 'undefined') {
    return;
  }

  // Load values from storage
  const [environment, custom] = await Promise.all([
    loadCurrentEnvironment(),
    loadCustomEnvironment(),
  ]);

  if (environment === 'custom' && custom) {
    useStriveEnvironments
      .getState()
      .setCustomEnvironment(custom.server, custom.api);
  }

  useStriveEnvironments.getState().setEnvironment(environment ?? 'default');
}

export function getCSPUrls() {
  const envs = loadEnvironmentConfig();
  const urls = Object.values(envs).flatMap((env) => [env.api, env.server]);
  return urls;
}

function safeJSONParse(json: string) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
}
