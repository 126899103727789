import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { GetContentLabelSkillsResponse } from '@arena-labs/shared-models';
import { authClient } from '@strive/api';

export async function getSkills(): Promise<GetContentLabelSkillsResponse> {
  return (await authClient.get(`/content/labels/?search=skill&limit=9999`))
    .data;
}

export function useSkills() {
  return useQuery<GetContentLabelSkillsResponse, AxiosError>(['skills'], () =>
    getSkills(),
  );
}
