import { makeApi, ZodiosResponseByAlias } from '@zodios/core';
import { z } from 'zod';

import {
  Depaginate,
  paginated,
  paginationParams,
  zIsoDateTime,
} from '../lib/schema-utils';
import { learningStateSchema, streakSchema } from './user-profile';

export type CoachUserResult = Depaginate<
  ZodiosResponseByAlias<typeof coachDashboardApi, 'searchUsers'>
>;

export type ACIScoreSummary = z.infer<typeof ACIScoreSchema>;
export type ACIValueSummary = z.infer<typeof ACIValueSchema>;
export type BiometricHistoryEntrySchema = z.infer<
  typeof biometricHistoryEntrySchema
>;

const ACIScoreSchema = z.object({
  created_at: zIsoDateTime,
  arena_location: z.string().nullable(),
  score: z.number().nullable(),
  current_stress: z.number().nullable(),
  current_energy: z.number().nullable(),
  current_focus: z.number().nullable(),
});

const ACIValueSchema = z.object({
  avg: z.number().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

const sensorDataSchema = z.object({
  data: z.number(),
  percentage: z.number().nullable(),
  date: zIsoDateTime,
});

const evaluationSchema = z
  .object({
    score: z.number(),
    color: z.enum(['red', 'yellow', 'green']),
  })
  .transform((value) => ({
    score: value.score,
    status:
      value.color === 'red'
        ? ('poor' as const)
        : value.color === 'yellow'
        ? ('fair' as const)
        : ('good' as const),
  }));

const biometricHistoryEntrySchema = z.object({
  action_status: z.string(),
  last_synced: zIsoDateTime,
  value: z.number(),
  value_status: z.string(),
});

const AutomatedMessagesResponseSchema = z.object({
  status: z.string(),
});

export const coachDashboardApi = makeApi([
  {
    alias: 'getTeams',
    description: 'Get all teams',
    method: 'get',
    path: '/coach/teams/',
    parameters: [...paginationParams],
    response: paginated(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    ),
  },
  {
    alias: 'searchUsers',
    description: 'Search for users',
    method: 'get',
    path: '/coach/search/',
    parameters: [
      {
        type: 'Query',
        name: 'query',
        schema: z.string().optional().nullable(),
      },
      { type: 'Query', name: 'team', schema: z.string().optional().nullable() },
      ...paginationParams,
    ],
    response: paginated(
      z.object({
        id: z.number().nullable(),
        stream_username: z.string().nullable(),
        channel_name_with_coach: z.string().nullable(),
        channel_name_with_launchpad: z.string().nullable(),
        learning_state: learningStateSchema,
        last_login: zIsoDateTime.nullable(),
      }),
    ),
  },
  {
    alias: 'getProfileSummary',
    description: 'Get profile summary',
    method: 'get',
    path: '/coach/profile-summary/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
    ],
    response: z.object({
      id: z.number(),
      first_name: z.string(),
      last_name: z.string(),
      completed_percentage: z.number(),
      learning_journey_start: zIsoDateTime.nullable(),
      last_login: zIsoDateTime.nullable(),
      end_date: zIsoDateTime.nullable(),
      goal: z.number().nullable(),
      goal_text: z.string().nullable(),
      score: z.number(),
      last_practice: z.string().nullable(),
      last_learning: z.string().nullable(),
      biometric: z.object({
        baseline: z.object({
          hours_of_sleep: z
            .number()
            .transform((number) => number / (1000 * 60 * 60)),
          hrv: z.number(),
          rhr: z.number(),
        }),
        hours_of_sleep: z.array(biometricHistoryEntrySchema),
        hrv: z.array(biometricHistoryEntrySchema),
        rhr: z.array(biometricHistoryEntrySchema),
      }),
      aci: z
        .object({
          aggregate: z.object({
            score: ACIValueSchema,
            energy: ACIValueSchema,
            stress: ACIValueSchema,
            focus: ACIValueSchema,
          }),
          history: z.array(ACIScoreSchema),
        })
        .nullable()
        .catch(null),
      sleep: z
        .object({
          hours_of_sleep: sensorDataSchema,
          efficiency: sensorDataSchema,
          rem: sensorDataSchema,
          sws: sensorDataSchema,
        })
        .nullable()
        .catch(null),
      hrv: sensorDataSchema.nullable(),
      rhr: sensorDataSchema.nullable(),
      professional_fulfillment: evaluationSchema.nullable(),
      burnout_propensity: evaluationSchema.nullable(),
      self_valuation: evaluationSchema.nullable(),
      stream_username: z.string().nullable(),
      channel_name_with_coach: z.string(),
      streak: streakSchema,
      post_rolls: z
        .array(
          z.object({
            id: z.number(),
            loom_id: z.string(),
            completed: z.boolean(),
          }),
        )
        .nullable()
        .catch(null),
      strive_data: z
        .object({
          date: zIsoDateTime,
          awareness: z.number(),
          regeneration: z.number(),
          adaptation: z.number(),
          avg_awareness: evaluationSchema.nullable(),
          avg_regeneration: evaluationSchema.nullable(),
          avg_adaptation: evaluationSchema.nullable(),
        })
        .nullable()
        .catch(null),
    }),
  },
  {
    alias: 'getProfileLaunchpadSummary',
    description: 'Get profile launchpad summary',
    method: 'get',
    path: '/launchpad-guide/:id/',
    parameters: [
      {
        type: 'Path',
        name: 'id',
        schema: z.number().or(z.string()),
        description: 'Profile id, or stream channel name',
      },
    ],
    response: z.object({
      first_name: z.string(),
      last_name: z.string(),
      cohort_launch_at: zIsoDateTime,
      launch_events: z.string(),
      team_name: z.string(),
      team_dashboard: z.object({
        pfi_complete_count: z.number(),
        wearable_synced_count: z.number(),
        ring_size_entered_count: z.number(),
        ring_on_order_count: z.number(),
        playlist_watched_count: z.number(),
        coach_expectations_complete_count: z.number(),
      }),
      checklist: z.array(
        z.object({
          slug: z.string(),
          status: z.enum(['locked', 'active', 'skipped', 'completed']),
          label: z.string(),
          completed_at: zIsoDateTime.nullable(),
          children: z
            .array(
              z.object({
                slug: z.string(),
                status: z.enum(['locked', 'active', 'skipped', 'completed']),
                label: z.string(),
                completed_at: zIsoDateTime.nullable(),
              }),
            )
            .nullable()
            .catch(null),
        }),
      ),
    }),
  },
  {
    alias: 'getLeaderProfiles',
    description: 'Get profiles list for leader',
    method: 'get',
    path: '/coach/leadership/',
    parameters: [
      {
        type: 'Query',
        name: 'limit',
        schema: z.number().optional().nullable(),
      },
      {
        type: 'Query',
        name: 'offset',
        schema: z.number().optional().nullable(),
      },
    ],
    response: paginated(
      z.object({
        biometric: z.object({
          baseline: z.object({
            hours_of_sleep: z
              .number()
              .transform((number) => number / (1000 * 60 * 60)),
            hrv: z.number(),
            rhr: z.number(),
          }),
          hours_of_sleep: z.array(biometricHistoryEntrySchema),
          hrv: z.array(biometricHistoryEntrySchema),
          rhr: z.array(biometricHistoryEntrySchema),
        }),
        aci: z
          .object({
            aggregate: z.object({
              score: ACIValueSchema,
              energy: ACIValueSchema,
              stress: ACIValueSchema,
              focus: ACIValueSchema,
            }),
            history: z.array(ACIScoreSchema),
          })
          .nullable()
          .catch(null),
        id: z.number(),
        completed_percentage: z.number(),
        learning_journey_start: zIsoDateTime.nullable(),
        last_login: zIsoDateTime.nullable(),
        end_date: zIsoDateTime.nullable(),
        goal: z.number().nullable(),
        goal_text: z.string().nullable(),
        score: z.number(),
        last_practice: z.string().nullable(),
        last_learning: z.string().nullable(),
        sleep: z
          .object({
            hours_of_sleep: sensorDataSchema,
            efficiency: sensorDataSchema,
            rem: sensorDataSchema,
            sws: sensorDataSchema,
          })
          .nullable()
          .catch(null),
        hrv: sensorDataSchema.nullable(),
        rhr: sensorDataSchema.nullable(),
        professional_fulfillment: evaluationSchema.nullable(),
        burnout_propensity: evaluationSchema.nullable(),
        self_valuation: evaluationSchema.nullable(),
        streak: streakSchema,
        strive_data: z
          .object({
            date: zIsoDateTime,
            awareness: z.number(),
            regeneration: z.number(),
            adaptation: z.number(),
            avg_awareness: evaluationSchema.nullable(),
            avg_regeneration: evaluationSchema.nullable(),
            avg_adaptation: evaluationSchema.nullable(),
          })
          .nullable()
          .catch(null),
      }),
    ),
  },
  {
    alias: 'addLoomToPostRoll',
    description: ' Add a Coach Loom to User Session',
    method: 'post',
    path: '/coach/postroll/',
    parameters: [
      {
        type: 'Body',
        name: 'Data',
        schema: z.object({
          loom_id: z.string(),
          profile: z.number(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: 'sendAutomatedMessages',
    description: `Run automated messages script`,
    method: 'post',
    path: '/coach/message/automated/',
    parameters: [
      {
        type: 'Body',
        name: 'Data',
        schema: z.object({
          type: z.enum(['launchpad', 'coachportal']),
        }),
      },
    ],
    response: AutomatedMessagesResponseSchema,
  },
]);
