import {
  ActionPerformed,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { assign, createMachine } from 'xstate';

export const pushNotificationsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAcCusAWA5A9gFwEsAzAgYwENCcA7WAYgCUBRAcQEkBlAFSeYBEA2gAYAuohQ5YBKtXEgAHogAsAJgA0IAJ7KA7EIB0ADhUqhAVgCMATjMA2IUsNWAvs41pMuQiQoz6zdm5eAH0AMQBBNgAZJkFROWRJaQIaOUUEVQ1tBAtDJX0lWx1CszMdCyEdFQszV3d0bHxiMkoU2n0GMHIITQACPBxeztIwAgA3MDoAWSYODnCWJmDmAGEmNgA1WOExJBBEqRk0xABmHSsC8zMbQpUT20ytU9sLIwcdWxsKpStDW1s6vsGl5mr42rAOl0ev1BsNRhM6OEVlw2AB5LDBAAKvFCqIYMziuwkhzaxwQZx0+hOJ0MZhOSjpFgeNKyiBUtjM+j0JxqRV+QisNNcbhA1BwEDgCWBTR8rRo8D2B2SqT26QAtLZWQgNYCPI1vC0-JDun0BkMwCNxmAEkkjqrTnl9BUXuYhC97iolFrbvorG72UoLD8dHolEpddKDWD5ZCoARYHgAE5y6i9ULkAgAG0gNpJKtA6RpKipNLpDJ5zMMWqZxaqFhOZkMHzOVjDhgjnhlhvB+gA6hnCNQoLnlbJ7Rl1E8EIZXo47EU6UUqjPhc4gA */
  createMachine(
    {
      context: {},
      tsTypes: {} as import('./push-notifications.machine.typegen').Typegen0,
      schema: {
        context: {} as {
          token?: Token | null;
          received?: PushNotificationSchema;
          actionPerformed?: ActionPerformed;
        },
        events: {} as
          | { type: 'REGISTERED'; data: Token }
          | { type: 'REGISTER_FAILED' }
          | { type: 'MESSAGE_RECEIVED'; data: PushNotificationSchema }
          | { type: 'ACTION_PERFORMED'; data: ActionPerformed },
      },
      description: `Watches push notification events. Useful for debugging`,
      id: 'pushNotifications',
      initial: 'Waiting',
      predictableActionArguments: true,

      states: {
        'Ready to Receive': {
          entry: 'assignToken',
          on: {
            MESSAGE_RECEIVED: {
              actions: 'assignReceived',
            },
            ACTION_PERFORMED: {
              actions: 'assignActionPerformed',
            },
          },
        },
        'Registration Failed': {
          description:
            'We could not get a token, and cannot send push notifications',
        },
        Waiting: {
          description: 'We are waiting for the device to give us a token',
        },
      },

      on: {
        REGISTERED: {
          target: '.Ready to Receive',
        },
        REGISTER_FAILED: {
          target: '.Registration Failed',
        },
      },
    },
    {
      actions: {
        assignToken: assign({ token: (ctx, evt) => evt.data }),
        assignReceived: assign({ received: (ctx, evt) => evt.data }),
        assignActionPerformed: assign({
          actionPerformed: (ctx, evt) => evt.data,
        }),
      },
    },
  );
