import { Preferences } from '@capacitor/preferences';
import {
  Button,
  Code,
  Flex,
  Icon,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Card,
  LoadingOrError,
  pluralize,
  TrashIcon,
} from '@arena-labs/strive2-ui';

export function AdminStorage() {
  const { data: items, status, refetch } = useStoredItems();
  const deleteItem = useDeleteStoredItem();

  if (status !== 'success') {
    return <LoadingOrError status={status} retry={refetch} />;
  }

  return (
    <VStack spacing="4" align="stretch">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontStyle="italic">
          {items.length} {pluralize(items.length, 'item')}
        </Text>
        <Button alignSelf="end" size="sm" onClick={() => refetch()}>
          Refresh
        </Button>
      </Flex>
      {items?.map(([name, value]) => (
        <AdminStorageCard
          key={name}
          name={name}
          value={value}
          onDelete={() => deleteItem.mutate(name)}
        />
      ))}
    </VStack>
  );
}

type AdminStorageCardProps = {
  name: string;
  value: string;
  onDelete: () => void;
};
function AdminStorageCard({ name, value, onDelete }: AdminStorageCardProps) {
  const { hasCopied, onCopy } = useClipboard(value);
  return (
    <Card colorScheme="dark" width="full">
      <VStack spacing="2" align="stretch">
        <Card.Heading>{name}</Card.Heading>
        <Code
          display="block"
          borderRadius="md"
          p="2"
          overflowWrap="anywhere"
          userSelect="all"
        >
          {value}
        </Code>
        <Flex gap="2" alignItems="center" justifyContent="space-between">
          <Button
            variant="outline"
            size="sm"
            onClick={onCopy}
            w="5rem"
            bg={hasCopied ? 'green.600' : undefined}
            border={hasCopied ? 'none' : undefined}
          >
            {hasCopied ? 'Copied' : 'Copy'}
          </Button>
          <Button
            variant="solid"
            colorScheme="danger"
            size="sm"
            onClick={() => onDelete()}
          >
            <Icon as={TrashIcon} />
          </Button>
        </Flex>
      </VStack>
    </Card>
  );
}

/**
 * Get all entries in capactior storage
 * @returns {Promise<[string, string][]>} Array of key-value pairs
 */
function useStoredItems() {
  return useQuery(['app', 'storage'], async () => {
    const { keys } = await Preferences.keys();
    const items = await Promise.all(
      keys.map(async (key) => {
        const { value } = await Preferences.get({ key });
        return [key, value ?? ''] as [string, string];
      }),
    );
    return items;
  });
}

/**
 * A react-query mutation which deletes an item from capacitor storage
 */
function useDeleteStoredItem() {
  const queryClient = useQueryClient();
  return useMutation(
    async (key: string) => {
      await Preferences.remove({ key });
    },
    { onSuccess: () => queryClient.invalidateQueries(['app', 'storage']) },
  );
}
