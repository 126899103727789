import { useMachine } from '@xstate/react';
import { assign, createMachine } from 'xstate';

import { useThrottleCallbackRef } from '@strive/utils';

export const mediaScrubberMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFtIEsCGBlAxgJwFcAjIsPAYhwAsMA7GAbQAYBdRUABwHtY0AXNF1rsQAD0QAWAEwAaEAE9EARgCcUgHQSA7FKZMVADn0GJKgKwBfC3NQRMuQiTLrqdKGnqUa9MAFFaEMxsSCDcvAJCIuIIAMxaSuoAbEwGUiqqTFoSZkqJEnKKCLpm6gZKZnkpRtp5UlbWILRcEHAitvb4xKR4ImH8gsIh0QC0iQWIo1Y26NidTnjqaBAANmC9PP2RQ4hmWjGaielqZlLZMafjRRISpYZ65xLnMWbZUyDts47dLt7u9OvhAZRRBSXTqGIGF5KbRKJSpKSpS6gjSJF76a5aRKqIwGeoWIA */
  createMachine(
    {
      context: { current: 0 },
      tsTypes: {} as import('./media-scrubber.machine.typegen').Typegen0,
      schema: {
        context: {} as { current: number },
        events: {} as
          | { type: 'update'; value: number }
          | { type: 'changeStart'; value: number }
          | { type: 'change'; value: number }
          | { type: 'changeEnd'; value: number },
      },
      description:
        'Calculate the "display value" for an audio scrubber. \n\n* *While changing*: keep track of the scrubber value, and update the audio position asyncronously\n* *While idle*: keep track of `audio.currentTime`',
      predictableActionArguments: true,

      on: {
        change: {
          actions: ['setCurrent', 'updateAsync'],
          description: '`slider.onChange`',
          target: '.changing',
        },
      },
      id: 'mediaScrubber',
      initial: 'idle',
      states: {
        idle: {},
        changing: {
          description: 'User is scrubbing',
          on: {
            changeEnd: {
              actions: ['setCurrent', 'updateSync'],
              description: '`slider.onChangeEnd`',
              target: 'idle',
            },
          },
        },
      },
    },
    {
      actions: {
        setCurrent: assign((context, event) => ({
          current: event.value,
        })),
      },
    },
  );

type UseMediaScrubberValueReturn = [
  value: number,
  handlers: {
    onChange: (val: number) => void;
    onChangeEnd: (val: number) => void;
  },
];

export function useMediaScrubberValue(
  inputValue: number,
  onChange: (val: number) => unknown,
): UseMediaScrubberValueReturn {
  const throttledOnChange = useThrottleCallbackRef(onChange, 100);

  const [state, send] = useMachine(mediaScrubberMachine, {
    actions: {
      updateAsync: (context, event) => {
        throttledOnChange(event.value);
      },
      updateSync: (context, event) => {
        throttledOnChange.cancel();
        onChange(event.value);
      },
    },
  });

  const value = state.matches('changing') ? state.context.current : inputValue;

  return [
    value,
    {
      onChange: (newValue: number) => send({ type: 'change', value: newValue }),
      onChangeEnd: (newValue: number) =>
        send({ type: 'changeEnd', value: newValue }),
    },
  ];
}
