import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { ZStack } from './z-stack';

export type SlidePresenceProps = React.ComponentProps<typeof ZStack> & {
  motion?: React.ComponentProps<typeof motion.div>;
  skipInitialTransition?: boolean;
};

export function SlidePresence({
  children,
  motion: motionProps,
  skipInitialTransition = false,
  ...props
}: SlidePresenceProps) {
  // Work out if we're on the first slide
  const child = React.Children.toArray(children)[0];
  const key = React.isValidElement(child) ? child.key : child;
  const [initialKey] = React.useState(() => key);
  const isFirstSlide = initialKey === key;

  motionProps = {
    initial: skipInitialTransition && isFirstSlide ? {} : { x: '100%' },
    animate: { x: 0 },
    exit: { x: '-100%' },
    transition: { duration: 0.5 },
    ...motionProps,
  };

  // Loop through children and add animation props
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, motionProps);
    }
    return child;
  });

  return (
    <ZStack variant="position" {...props}>
      <AnimatePresence>{childrenWithProps}</AnimatePresence>
    </ZStack>
  );
}
