import { NonEmptyArray } from '@arena-labs/shared-models';

import { WearableMode, WearableType } from './types';
import { useWearableMode } from './use-wearable-mode';

type IfWearableProps<Mode extends WearableMode | NonEmptyArray<WearableMode>> =
  {
    mode?: Mode;
    children: React.ReactNode | ((data: DataForMode<Mode>) => React.ReactNode);
  };

/**
 * Conditional rendering based on the current mode of the wearable.
 * Displays children only if the current mode matches the specified mode(s).
 * If no mode is specified, it will display the children for all modes except `sensorless`.
 */
export function IfWearable<
  Mode extends WearableMode | NonEmptyArray<WearableMode> = [
    'partner',
    'striveware',
  ],
>({
  mode = ['partner', 'striveware'] as unknown as Mode,
  children,
}: IfWearableProps<Mode>) {
  const { data } = useWearableMode();
  const modes = Array.isArray(mode) ? mode : [mode];

  if (!data || !modes.includes(data.mode as Mode)) {
    return null;
  }

  return typeof children === 'function'
    ? children(data as DataForMode<Mode>)
    : children;
}

// Utility types to infer the data that gets passed to render function based on the `mode`
type DataForMode<Mode> = Mode extends Array<infer U>
  ? U extends WearableMode
    ? ExtractDataType<U>
    : never
  : ExtractDataType<Mode>;

type ExtractDataType<Mode> = Mode extends keyof WearableType
  ? { mode: Mode; wearable: WearableType[Mode] }
  : never;
