import { Text, TextProps } from '@chakra-ui/react';

import { textStyles } from '@strive/theme/tokens';

export const TEXT = {
  H0: typography('h0'),
  H1: typography('h1', { as: 'h1' }),
  H2: typography('h2', { as: 'h2' }),
  H3: typography('h3', { as: 'h3' }),
  P1: typography('p1'),
  P1_SEMIBOLD: typography('p1_semibold'),
  P1_BOLD: typography('p1_bold'),
  P2: typography('p2'),
  P3: typography('p3'),
};

/**
 * Creates a text component with the given style from the theme
 */
function typography(textStyle: keyof typeof textStyles, textProps?: TextProps) {
  const Component = function (props: TextProps) {
    return <Text {...textStyles[textStyle]} {...textProps} {...props} />;
  };
  Component.displayName = `text.${textStyle}`;
  return Component;
}
