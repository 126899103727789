import { Center, SimpleGrid } from '@chakra-ui/react';
import { produce } from 'immer';

import { VideoDuration, VideoDurations } from '@arena-labs/shared-models';
import { CheckboxButton } from '@arena-labs/strive2-ui';

import { FilterSection } from './filter-section';

export interface FilterSectionDurationsProps {
  selected: Set<VideoDurations>;
  onChange: (durations: Set<VideoDurations>) => void;
}

export function FilterSectionDurations({
  selected,
  onChange,
}: FilterSectionDurationsProps) {
  const handleToggle = (duration: VideoDurations) => () => {
    const updated = produce(selected, (draft) => {
      if (draft.has(duration)) {
        draft.delete(duration);
      } else {
        draft.add(duration);
      }
    });
    onChange(updated);
  };

  return (
    <FilterSection
      name="DURATION"
      applied={selected.size}
      onClear={() => onChange(new Set())}
    >
      <SimpleGrid columns={3} spacingX={2} spacingY={1}>
        {Object.entries(VideoDuration).map(([name, label]) => (
          <CheckboxButton
            size="sm"
            key={name}
            name={name}
            isChecked={selected.has(name as VideoDurations)}
            onChange={handleToggle(name as VideoDurations)}
          >
            <Center textAlign="center">{label} mins</Center>
          </CheckboxButton>
        ))}
      </SimpleGrid>
    </FilterSection>
  );
}
