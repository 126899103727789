import { useCallback } from 'react';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance } from 'axios';

import {
  GetVideosResponse,
  Video,
  VideoQuery,
} from '@arena-labs/shared-models';
import { authClient } from '@strive/api';

import { useContentLibraryStore } from '../state';

// Videos list
export async function getVideos(
  query: VideoQuery | string,
  apiClient: AxiosInstance = authClient,
): Promise<GetVideosResponse> {
  const videosUrl = `/content/videos/`;
  const queryUrl =
    typeof query === 'string' ? query : `${videosUrl}?${query.toString()}`;
  return (await apiClient.get(queryUrl)).data;
}

export function useVideosAll() {
  const query = useContentLibraryStore((state) => state.query.VIDEOS);
  return useInfiniteQuery<GetVideosResponse, AxiosError>(
    ['videos', query.toString()],
    ({ pageParam }: { pageParam?: string }) => getVideos(pageParam || query),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      getPreviousPageParam: (lastPage) => lastPage.previous,
    },
  );
}

// Video detail
export async function getVideo(
  slug: string,
  apiClient: AxiosInstance = authClient,
): Promise<Video> {
  const video = (await apiClient.get(`/content/videos/${slug}/`)).data;

  return { ...video, related_programs: video.related_programs ?? [] };
}

export async function getGuestVideo(
  slug: string,
  apiClient: AxiosInstance = authClient,
): Promise<Video> {
  return (await apiClient.get(`/trial/${slug}/`)).data;
}

export function useVideo(
  slug: string,
  options?: UseQueryOptions<Video, AxiosError, Video>,
) {
  return useQuery<Video, AxiosError>(
    ['video', slug],
    () => getVideo(slug),
    options,
  );
}
export function useGuestVideo(
  slug: string,
  options?: UseQueryOptions<Video, AxiosError, Video>,
) {
  return useQuery<Video, AxiosError>(
    ['guest-video', slug],
    () => getGuestVideo(slug),
    options,
  );
}

export function useVideoCache() {
  const queryClient = useQueryClient();
  return useCallback(
    (video: Video, stale = true) => {
      const videoQuery = ['video', video.slug];
      if (!queryClient.getQueryData(videoQuery)) {
        queryClient.setQueryData(videoQuery, video);
      }
      if (stale) {
        queryClient.invalidateQueries(videoQuery);
      }
    },
    [queryClient],
  );
}
