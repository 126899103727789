import {
  isVideoDetail,
  Video,
  VideoFilters,
  VideoSorting,
} from '@arena-labs/shared-models';
import { formatCoachName } from '@arena-labs/strive2-ui';

export function getVideoTrackingContext(video: Video, extra = {}) {
  const common = {
    videoSlug: video.slug,
    videoTitle: video.title,
    videoDuration: parseFloat(video.duration),
    favorited: video.favorited,
    coach: formatCoachName(video.coach),
    coachSlug: video.coach.slug,
    skillSlug: video.skill?.slug,
    skill: video.skill?.name,
    category: video.skill?.category,
    ...extra,
  };
  if (isVideoDetail(video)) {
    return Object.assign(common, {
      liked: video.liked,
    });
  }
  return common;
}

export function getFilterTrackingContext(filters: VideoFilters) {
  return {
    duration: Array.from(filters.duration),
    coaches: Array.from(filters.coaches),
    skills: Array.from(filters.skills),
  };
}

export function getSortingTrackingContext(sorting: VideoSorting) {
  return {
    sortField: sorting.field,
    sortDirection: sorting.direction,
  };
}
