import { useEffect } from 'react';
import {
  BoxProps,
  RadioProps,
  Stack,
  StackProps,
  useCallbackRef,
  useRadioGroup,
} from '@chakra-ui/react';
import { z } from 'zod';

import { RadioButton } from '@arena-labs/strive2-ui';
import { useDataStoreQuery } from '@strive/utils';

export type PlaybackRateButtonsProps = {
  rates?: number[];
  currentRate: number;
  onChange: (rate: number) => void;
  direction?: 'row' | 'column';
} & Omit<StackProps, 'onChange'>;

export function PlaybackRateButtons({
  rates = [1, 1.5, 2],
  currentRate,
  onChange,
  direction = 'row',
  ...props
}: PlaybackRateButtonsProps) {
  const [preference, setPreference] = useDataStoreQuery({
    key: 'Media.PlaybackRate',
    schema: z.number().refine((n) => rates.includes(n)),
    fallback: currentRate,
  });
  const rate = preference.data ?? currentRate;
  const handleChange = useCallbackRef(onChange);
  useEffect(() => {
    handleChange(rate);
  }, [rate, handleChange]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'playbackRate',
    value: String(rate),
    onChange: (val) => {
      setPreference.mutate(Number(val));
    },
  });

  return (
    <Stack
      direction={direction}
      as="fieldset"
      spacing="2"
      aria-label="Playback rate"
      {...props}
      {...(getRootProps() as BoxProps)}
    >
      {rates.map((value) => (
        <RadioButton
          key={value}
          size="sm"
          colorScheme="dark"
          variant="glass"
          {...(getRadioProps({ value: String(value) }) as RadioProps)}
          radio={{ textAlign: 'center' }}
        >
          {value}x
        </RadioButton>
      ))}
    </Stack>
  );
}
