const quotes: Array<[quote: string, source: string]> = [
  [
    'To be conscious of being, you need to reclaim consciousness from the mind. This is one of the most essential tasks on your spiritual journey.',
    'Eckhart Tolle',
  ],
  ['The only true wisdom is in knowing you know nothing.', 'Socrates'],
  [
    'The first step toward change is awareness. The second step is acceptance.',
    'Nathaniel Branden',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  [
    'There is no greater journey than the one that you must take to discover all of the mysteries that lie within you.',
    'Michelle Sandlin',
  ],
  [
    'The better you know yourself, the better your relationship with the rest of the world.',
    'Toni Collette',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  [
    'Self-awareness is the ability to take an honest look at your life without any attachment to it being right or wrong, good or bad.',
    'Debbie Ford',
  ],
  [
    'The greatest discovery of my generation is that a human being can alter his life by altering his attitudes.',
    'William James',
  ],
  [
    'He who knows others is wise; he who knows himself is enlightened.',
    'Lao Tzu',
  ],
  [
    'To be conscious of being, you need to reclaim consciousness from the mind. This is one of the most essential tasks on your spiritual journey.',
    'Eckhart Tolle',
  ],
  [
    'Until you make the unconscious conscious, it will direct your life and you will call it fate.',
    'Carl Jung',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  [
    'Without self-awareness, we cannot have growth, and without growth, we cannot have personal success.',
    'Jason Goldberg',
  ],
  ['The most terrifying thing is to accept oneself completely.', 'Carl Jung'],
  [
    'Self-awareness is the first step toward self-improvement.',
    'Gary Vaynerchuk',
  ],
  [
    'The more you know yourself, the more you understand your own limitations and capabilities.',
    'Martin Freeman',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  [
    'The most common form of despair is not being who you are.',
    'Søren Kierkegaard',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  ['The only true wisdom is in knowing you know nothing.', 'Socrates'],
  [
    'The better you know yourself, the better your relationship with the rest of the world.',
    'Toni Collette',
  ],
  [
    'He who knows others is wise; he who knows himself is enlightened.',
    'Lao Tzu',
  ],
  [
    'Self-awareness gives you the capacity to learn from your mistakes as well as your successes.',
    'Lawrence Bossidy',
  ],
  [
    'Self-awareness is the key to personal growth and transformation.',
    'Gary Zukav',
  ],
  [
    'To know oneself is to study oneself in action with another person.',
    'Bruce Lee',
  ],
  [
    'If you know yourself, you will not be harmed by what is said about you.',
    'Lao Tzu',
  ],
  [
    'The better you know yourself, the better your relationship with the rest of the world.',
    'Toni Collette',
  ],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'Daniel Goleman',
  ],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'John Locke',
  ],
  [
    'To be aware of a single shortcoming in oneself is more useful than to be aware of a thousand in someone else.',
    'Dalai Lama',
  ],
  ['Without self-awareness, self-improvement is impossible.', 'Gino Wickman'],
  [
    'Self-awareness is a powerful tool for personal growth and transformation.',
    'Louise Hay',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  [
    "You cannot change anything in your life with intention alone, which can become a watered-down, occasional hope that you'll get to tomorrow. Intention without action is useless.",
    'Caroline Myss',
  ],
  [
    'Self-awareness is the ability to monitor our own thoughts and feelings and is essential to being able to understand and control our emotions.',
    'Daniel Goleman',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'Daniel Goleman',
  ],
  [
    'The biggest challenge in life is being yourself... in a world trying to make you like everyone else.',
    'Unknown',
  ],
  [
    'Knowing others is intelligence; knowing yourself is true wisdom. Mastering others is strength; mastering yourself is true power.',
    'Lao Tzu',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  [
    'It is only through mystery and madness that the soul is revealed.',
    'Thomas Moore',
  ],
  [
    'The moment you become aware of your thoughts, you become conscious.',
    'Eckhart Tolle',
  ],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'Daniel Goleman',
  ],
  [
    'Until you make the unconscious conscious, it will direct your life and you will call it fate.',
    'Carl Jung',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  ['The only journey is the journey within.', 'Rainer Maria Rilke'],
  [
    'Self-awareness is the foundation of emotional intelligence.',
    'Daniel Goleman',
  ],
  [
    'The more you know yourself, the more you understand your own limitations and capabilities.',
    'Martin Freeman',
  ],
  [
    "If you don't know who you are, you'll never know what you're capable of.",
    'Chris Witty',
  ],
  [
    'Self-awareness is the first step toward self-improvement.',
    'Gary Vaynerchuk',
  ],
  ['Knowing yourself is the beginning of all wisdom.', 'Aristotle'],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'Daniel Goleman',
  ],
  [
    'The most common form of despair is not being who you are.',
    'Søren Kierkegaard',
  ],
  [
    'The greatest discovery of my generation is that a human being can alter his life by altering his attitudes.',
    'William James',
  ],
  [
    'To know oneself is to study oneself in action with another person.',
    'Bruce Lee',
  ],
  [
    "Until you make peace with who you are, you'll never be content with what you have.",
    'Doris Mortman',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  [
    'Self-awareness is the ability to take an honest look at your life without any attachment to it being right or wrong, good or bad.',
    'Debbie Ford',
  ],
  [
    'To be conscious of being, you need to reclaim consciousness from the mind.',
    'Eckhart Tolle',
  ],
  ['Self-awareness is the key to self-mastery.', 'Gretchen Rubin'],
  [
    'The greatest glory in living lies not in never falling, but in rising every time we fall.',
    'Nelson Mandela',
  ],
  [
    'We cannot change anything until we accept it. Condemnation does not liberate, it oppresses.',
    'Carl Jung',
  ],
  ['The unexamined life is not worth living.', 'Socrates'],
  [
    'When you know yourself, you are empowered. When you accept yourself, you are invincible.',
    'Tina Lifford',
  ],
  [
    'You are not a drop in the ocean. You are the entire ocean in a drop.',
    'Rumi',
  ],
  [
    'When we are no longer able to change a situation - we are challenged to change ourselves.',
    'Viktor Frankl',
  ],
  [
    'Self-awareness gives us the power to be in charge of our emotions, rather than the other way around.',
    'Amy Leigh Mercree',
  ],
  [
    'When you become comfortable with uncertainty, infinite possibilities open up in your life.',
    'Eckhart Tolle',
  ],
  [
    'The more you understand yourself, the less cause you have for judgment and criticism.',
    'Tony Robbins',
  ],
  [
    'A man who knows himself can step outside himself and watch his own reactions like an observer.',
    'Adam Smith',
  ],
  [
    'When we are no longer able to change a situation, we are challenged to change ourselves.',
    'Viktor Frankl',
  ],
  [
    'The key to success is to keep growing in all areas of life - mental, emotional, spiritual, as well as physical.',
    'Julius Erving',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  [
    'The first step toward change is awareness. The second step is acceptance.',
    'Nathaniel Branden',
  ],
  [
    'A person who knows themselves and accepts themselves will also be more accepting of others.',
    'Unknown',
  ],
  [
    'When we have awareness of our patterns and habits, we can make conscious choices to change them.',
    'Sharon Salzberg',
  ],
  // [
  //   'Self-awareness is not self-centeredness, and spirituality is not narcissism. Know thyself is not a narcissistic pursuit.',
  //   'Marianne Williamson',
  // ],
  [
    'One of the greatest gifts you can give yourself is to discover the truth about who you are.',
    'Oprah Winfrey',
  ],
  [
    'In order to change the world, you have to get your head together first.',
    'Jimi Hendrix',
  ],
  [
    'The better you know yourself, the better your relationship with the rest of the world.',
    'Toni Collette',
  ],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krishnamurti',
  ],
  ['You are the only person on earth who can use your ability.', 'Zig Ziglar'],
  ['Awareness is the greatest agent for change.', 'Eckhart Tolle'],
  [
    "We can't change anything until we get some fresh ideas, until we begin to see things differently.",
    'James Hillman',
  ],
  ['Without self-awareness, we cannot hope to grow.', 'John C. Maxwell'],
  [
    "Your time is limited, don't waste it living someone else's life.",
    'Steve Jobs',
  ],
  ['The unexamined life is not worth living.', 'Socrates'],
  [
    'The more you know yourself, the more clarity there is. Self-knowledge has no end.',
    'Jiddu Krish',
  ],
  [
    'Self-awareness is the capacity for introspection and the ability to recognize oneself as an individual separate from the environment and other individuals.',
    'Daniel Goleman',
  ],
  [
    'The more you know yourself, the more you understand your emotions and the more you can relate to others.',
    'Unknown',
  ],
  [
    'The journey of self-discovery is the most important journey we can undertake.',
    'Unknown',
  ],
  [
    'Self-awareness allows us to become aware of our strengths and weaknesses, and to make positive changes in our lives.',
    'Daniel Goleman',
  ],
  [
    'Self-awareness is the foundation for personal growth and development.',
    'Unknown',
  ],
  [
    'Self-awareness is not only the cornerstone of emotional intelligence, but also the key to success in life.',
    'Daniel Goleman',
  ],
  [
    'True self-awareness is not a function of how much we know ourselves, but how much we are willing to know ourselves.',
    'Unknown',
  ],
  [
    'Self-awareness is a journey that never ends. The more we learn about ourselves, the more we can grow and evolve.',
    'Unknown',
  ],
  [
    'The more we understand ourselves, the better we can understand others.',
    'Unknown',
  ],
  [
    "Self-awareness is not a destination, it's a journey. And like any journey, it requires effort, time, and commitment.",
    'Unknown',
  ],
];

export function getRandomQuote() {
  const selected = quotes[Math.floor(Math.random() * quotes.length)];
  return selected ? { text: selected[0], source: selected[1] } : null;
}
