import { useState } from 'react';
import { useDebounce } from 'react-use';
import { BoxProps, Center, VStack } from '@chakra-ui/react';

import { BrandLogo } from '../brand/brand-logo';
import { FetchError } from './fetch-error';

export type LoadingProps = {
  status: 'loading' | 'error' | 'success';
  errorTitle?: string;
  errorMessage?: string;
  retry?: () => void;
  isRetrying?: boolean;
} & BoxProps;
export function LoadingOrError({
  status,
  errorMessage,
  errorTitle,
  retry,
  isRetrying,
  ...boxProps
}: LoadingProps) {
  const [isSlow, setIsSlow] = useState(false);
  useDebounce(() => setIsSlow(true), 500, []);

  return (
    <VStack
      px={6}
      alignItems="stretch"
      w="full"
      h="full"
      alignSelf="center"
      {...boxProps}
    >
      <Center w="full" h="100%">
        {status === 'loading' && isSlow ? (
          <BrandLogo spin noText iconSize={24} />
        ) : null}
        {status === 'error' ? (
          <FetchError
            title={errorTitle ? errorTitle : 'Error'}
            message={errorMessage}
            retry={retry}
            isRetrying={isRetrying}
          />
        ) : null}
      </Center>
    </VStack>
  );
}
