import { Box, Button, ButtonProps, Icon, Text } from '@chakra-ui/react';

import { FastForwardIcon } from '@arena-labs/strive2-ui';

export type AudioSeekButtonProps = ButtonProps & {
  seconds: number;
};

export function AudioSeekButton({ seconds, ...props }: AudioSeekButtonProps) {
  return (
    <Button
      variant="unstyled"
      borderRadius="full"
      shadow="md"
      display="grid"
      placeItems="center"
      height="20"
      width="20"
      {...props}
    >
      <Box>
        <Icon
          as={FastForwardIcon}
          boxSize="8"
          sx={seconds < 0 ? { transform: 'scaleX(-1)' } : {}}
        />
        <Text fontSize="xs">{Math.abs(seconds)}s</Text>
      </Box>
    </Button>
  );
}
