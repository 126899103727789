import React, { useEffect } from 'react';
import {
  Box,
  Center,
  CircularProgress,
  useCallbackRef,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { AlertBox } from '@arena-labs/strive2-ui';
import { useAuthState } from '@strive/api';

type ContainerType =
  | React.ComponentType<{ children: React.ReactNode }>
  | string;

export type PrivateAuthHandoffProps = {
  container?: ContainerType;
  successContainer?: ContainerType;
  onSuccess?: () => void;
  onError?: () => void;
  children?: React.ReactNode;
};

export function PrivateAuthHandoff({
  container = 'div',
  successContainer = container,
  onSuccess,
  onError,
  children,
}: PrivateAuthHandoffProps) {
  const [authState] = useAuthState();

  // Analytics
  const analytics = useAnalytics();

  const loginState = authState.matches('Logged in')
    ? 'success'
    : authState.matches('Logged out')
    ? 'error'
    : 'processing';
  const notify = useCallbackRef((state: typeof loginState) => {
    if (state === 'success') {
      onSuccess?.();
    } else if (state === 'error') {
      analytics.logEvent(GeneralTrackingEvent.AccessDenied);
      onError?.();
    }
  });
  useEffect(() => {
    notify(loginState);
  }, [loginState, notify]);

  const Wrapper = loginState === 'success' ? successContainer : container;
  if (loginState === 'success' && children) {
    return <Wrapper>{children}</Wrapper>;
  }

  return (
    <Wrapper>
      <Box color="gray.100">
        {loginState === 'processing' ||
        (loginState === 'success' && !children) ? (
          <Center>
            <CircularProgress
              isIndeterminate
              color="brand.500"
              trackColor="gray.800"
            />
          </Center>
        ) : loginState === 'error' ? (
          <AlertBox
            showIcon
            status="warning"
            title="Access denied"
            description="Sorry, we could not validate your access to Arena Strive at this time.
          Please check the link that brought you here."
          />
        ) : null}
      </Box>
    </Wrapper>
  );
}
