import { useEffect, useRef } from 'react';
import { useLatest } from 'react-use';
import { Box, BoxProps } from '@chakra-ui/react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

export type UseVisibleOnMountOptions = {
  delay?: number;
  block?: 'start' | 'center' | 'end' | 'nearest';
  inline?: 'start' | 'center' | 'end' | 'nearest';
};

export type VisibleOnMountProps = UseVisibleOnMountOptions & BoxProps;

export function VisibleOnMount({
  tabIndex,
  delay,
  block,
  inline,
  ...props
}: VisibleOnMountProps) {
  const ref = useRef<HTMLDivElement>(null);

  useVisibleOnMount(ref, { delay, block, inline });
  return <Box {...props} tabIndex={tabIndex ?? -1} ref={ref} />;
}

export function useVisibleOnMount(
  ref: React.RefObject<HTMLDivElement>,
  options: UseVisibleOnMountOptions = {},
) {
  const { delay = 0, ...scrollOptions } = options;
  const scrollOptionsRef = useLatest(scrollOptions);
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        scrollIntoView(ref.current, {
          behavior: 'smooth',
          ...scrollOptionsRef.current,
        });
      }
    }, delay);
  }, [delay, ref, scrollOptionsRef]);
  return ref;
}
