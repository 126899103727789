import { elevation } from './colors';

const getElevationLayer = (level: keyof typeof elevation) => ({
  bg: `elevation.${level}`,
  boxShadow: level,
});

export const layerStyles = {
  '0dp': getElevationLayer('0dp'),
  '1dp': getElevationLayer('1dp'),
  '2dp': getElevationLayer('2dp'),
  '3dp': getElevationLayer('3dp'),
  '4dp': getElevationLayer('4dp'),
  '6dp': getElevationLayer('6dp'),
  '8dp': getElevationLayer('8dp'),
  '12dp': getElevationLayer('12dp'),
  '16dp': getElevationLayer('16dp'),
  '24dp': getElevationLayer('24dp'),
};
