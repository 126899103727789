import {
  JStyleCallback,
  JStyleCallbackId,
  JStyleIterable,
} from '../definitions';
import { JStyle } from '../jstyle';

type JStyleDataFunction<Options extends {}, DataType> = (
  options: Options | undefined,
  callback: JStyleCallback<DataType>,
) => Promise<JStyleCallbackId>;

export function getDataIterator<Options extends {}, DataType>(
  getData: JStyleDataFunction<Options, DataType>,
) {
  return (options?: Options & { signal?: AbortSignal }) => {
    let callbackId: JStyleCallbackId | null = null;
    let error: unknown = null;

    async function abort(thisError: unknown = new Error('Aborted')) {
      error ??= thisError;
      if (callbackId) {
        await JStyle.cancelCall({ id: callbackId });
        callbackId = null;
      }
    }

    const { signal, ...restOptions } = options ?? {};
    signal?.addEventListener('abort', () => abort());

    return {
      abort,
      [Symbol.asyncIterator]: async function* () {
        let dataQueue: JStyleIterable<DataType>[] = [];

        if (error) {
          // If the task was cancelled before the iterator was started, throw cancellation error
          throw error;
        }

        // Start the data retrieval task, & set up the receiver
        callbackId = await getData(
          restOptions as Options,
          (data, dataError) => {
            if (dataError) {
              abort(dataError);
            } else if (data) {
              dataQueue.push(data);
            }
          },
        );

        const nextTick = () =>
          new Promise(resolve => requestAnimationFrame(resolve));

        try {
          // Yield each item from the data queue
          while (true) {
            if (error) {
              // Task was cancelled, or an error occurred (plugin might have timed out the task)
              throw error;
            }

            const next = dataQueue.shift();
            if (!next) {
              await nextTick();
              continue;
            }
            yield next;
            if (next._end) {
              break;
            }
          }
        } finally {
          callbackId = null;
          dataQueue = [];
        }
      },
    };
  };
}
