import { isNull, omitBy } from 'lodash-es';
import LogRocket from 'logrocket';

if (process.env.LOG_ROCKET_ID) {
  LogRocket.init(process.env.LOG_ROCKET_ID);
}

export function identifyLogRocket(
  id: string,
  attributes: Record<string, string | number | boolean | null>,
) {
  const attrs = omitBy(attributes, isNull) as Record<
    string,
    string | number | boolean
  >;
  if (process.env.LOG_ROCKET_ID) {
    LogRocket.identify(id, attrs);
  }
}

export function resetLogRocket() {
  if (process.env.LOG_ROCKET_ID) {
    LogRocket.startNewSession();
  }
}

export function reportErrorLogRocket(error: unknown) {
  if (process.env.LOG_ROCKET_ID) {
    if (error instanceof Error) {
      LogRocket.captureException(error);
    } else {
      LogRocket.captureMessage(String(error));
    }
  }
}
