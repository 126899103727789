import { useEffect } from 'react';
import {
  Box,
  Button,
  Circle,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { CheckIcon, pluralize } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { useIncentiveHub } from '../use-incentive-hub';

export default function StreaksHub() {
  const incentiveHub = useIncentiveHub();
  const analytics = useAnalytics();

  const { data: streak } = $API.useGetStreakData();
  const currentLevel = streak?.streak.current_level;

  useEffect(() => {
    incentiveHub.isOpen &&
      analytics.logEvent(GeneralTrackingEvent.StreaksHubOpen);
  }, [analytics, incentiveHub.isOpen]);

  return (
    <Drawer
      isFullHeight
      isOpen={incentiveHub.isOpen}
      placement="top"
      onClose={() => incentiveHub.onClose()}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p="0">
          <Flex
            h="full"
            direction={'column'}
            justifyContent={'space-evenly'}
            px={6}
            py={'4'}
            textAlign={'center'}
          >
            <Box>
              <DrawerCloseButton
                size={'xl'}
                pr="2"
                pt="2"
                onClick={() => {
                  analytics.logEvent(GeneralTrackingEvent.StreaksHubClosed);
                  incentiveHub.onClose();
                }}
              />
              <Heading lineHeight="1">Check-In Streak</Heading>
            </Box>
            <Divider />
            {streak?.levels &&
              Object.keys(streak.levels).map((key) => {
                return (
                  <HStack
                    key={key}
                    position={'relative'}
                    bg={
                      currentLevel === streak.levels[key]
                        ? 'strive.card3.bg'
                        : 'inherit'
                    }
                    borderRadius="card"
                    py="2"
                    px="3"
                    border={'2px solid'}
                    borderColor={
                      currentLevel === streak.levels[key]
                        ? 'strive.primary'
                        : 'strive.card2.bg'
                    }
                    color={
                      currentLevel === streak.levels[key]
                        ? 'strive.primary'
                        : 'white'
                    }
                    w="full"
                    fontWeight="bold"
                  >
                    <Text>
                      {key}
                      {key ===
                      Object.keys(streak.levels)[
                        Object.keys(streak.levels).length - 1
                      ]
                        ? '+'
                        : null}
                      {pluralize(Number(key), ' Day', ' Days')}
                    </Text>
                    <Spacer />
                    <Text>{streak?.levels[Number(key)]}</Text>
                    {currentLevel === streak.levels[key] && (
                      <Circle
                        position="absolute"
                        size="20px"
                        bg="strive.primary"
                        color=" "
                        top="-10px"
                        right="-10px"
                      >
                        <Icon
                          as={CheckIcon}
                          boxSize={4}
                          color={'strive.card4.bg'}
                        />
                      </Circle>
                    )}
                  </HStack>
                );
              })}
            <Heading fontSize={'h3'}>
              Keep up the hard work. <br />
              Consistency pays off!
            </Heading>
            <Button
              variant="solid"
              onClick={() => {
                analytics.logEvent(GeneralTrackingEvent.StreaksHubClosed);
                incentiveHub.onClose();
              }}
              mt="4"
              alignSelf="center"
              px="8"
              py="4"
            >
              Close
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
