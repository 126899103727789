import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}

export function identifyCapgo(customId: string) {
  if (Capacitor.isNativePlatform()) {
    CapacitorUpdater.setCustomId({ customId });
  }
}
