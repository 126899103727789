import { Button, Flex, Spacer } from '@chakra-ui/react';

import { AlertBox, AlertBoxProps } from './alert-box';

export type FetchErrorProps = {
  title: string;
  message?: string;
  retry?: () => void;
  isRetrying?: boolean;
} & AlertBoxProps;

export function FetchError({
  title,
  message,
  retry,
  isRetrying,
  ...props
}: FetchErrorProps) {
  const retryButton = retry && (
    <Button variant="solid" onClick={retry} ml="2" isLoading={isRetrying}>
      Retry
    </Button>
  );

  return (
    <AlertBox
      status="error"
      title={title}
      description={
        retryButton || message ? (
          <Flex justifyContent="space-between" w="full">
            {message}
            <Spacer />
            {retryButton}
          </Flex>
        ) : null
      }
      {...props}
    />
  );
}
