import { Icon, SimpleGrid, useDisclosure } from '@chakra-ui/react';

import { ContentTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  VideoFilters,
  VideoQuery,
  VideoSorting,
} from '@arena-labs/shared-models';
import { FilterIcon, SortIcon } from '@arena-labs/strive2-ui';

import {
  getFilterTrackingContext,
  getSortingTrackingContext,
} from '../../lib/tracking-helpers';
import { FilterButton } from './filter-button';
import { Filters } from './filters';
import { Sorting } from './sorting';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FilterBarProps {
  filters: VideoQuery;
  onChange: (updated: VideoQuery) => void;
}

export function FilterBar({ filters, onChange }: FilterBarProps) {
  const analytics = useAnalytics();

  const filterToggle = useDisclosure();
  const handleFilterOpen = () => {
    filterToggle.onOpen();
    analytics.logEvent(
      ContentTrackingEvent.OpenVideoFilters,
      getFilterTrackingContext(filters),
    );
  };
  const handleFilterChange = (changed: VideoFilters) => {
    onChange(filters.setFilters(changed));
    filterToggle.onClose();
    analytics.logEvent(
      ContentTrackingEvent.ApplyVideoFilters,
      getFilterTrackingContext(changed),
    );
  };

  const sortingToggle = useDisclosure();
  const handleSortingOpen = () => {
    sortingToggle.onOpen();
    analytics.logEvent(
      ContentTrackingEvent.OpenSorting,
      getSortingTrackingContext(filters.sorting),
    );
  };
  const handleSortingChange = (changed: VideoSorting) => {
    onChange(filters.setSorting(changed));
    sortingToggle.onClose();
    analytics.logEvent(
      ContentTrackingEvent.ApplySorting,
      getSortingTrackingContext(changed),
    );
  };

  return (
    <>
      <SimpleGrid columns={2} spacing="1">
        <FilterButton
          onClick={handleFilterOpen}
          active={filters.hasActiveFilters()}
          aria-label="Open filter options"
        >
          <Icon as={FilterIcon} />
        </FilterButton>
        <FilterButton
          onClick={handleSortingOpen}
          active={filters.hasActiveSorting()}
          aria-label="Open sorting options"
        >
          <Icon as={SortIcon} />
        </FilterButton>
      </SimpleGrid>
      <Filters
        isOpen={filterToggle.isOpen}
        filters={filters}
        onClose={filterToggle.onClose}
        onApply={handleFilterChange}
      />
      <Sorting
        isOpen={sortingToggle.isOpen}
        sorting={filters.sorting}
        onClose={sortingToggle.onClose}
        onApply={handleSortingChange}
      />
    </>
  );
}
