import NextLink from 'next/link';
import {
  AspectRatio,
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';

import { Video, VideoPageMetadata } from '@arena-labs/shared-models';
import {
  ChevronRightIcon,
  Elevation,
  formatCoachName,
  TEXT,
} from '@arena-labs/strive2-ui';

import { Program, useProgramCache, useVideoCache } from '../../api';
import { ProgramIcon } from './program-icon';
import { ProgramProgressBar } from './program-progress-bar';

export type ProgramUpNextCardProps = {
  program: Program;
  href: string;
  video?: Video | null;
  getVideoPage: (video: Video, meta: VideoPageMetadata) => string;
  headingLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  layout?: 'narrow' | 'wide' | 'responsive';
};

export function ProgramUpNextCard({
  program,
  href,
  video,
  getVideoPage,
  layout = 'responsive',
}: ProgramUpNextCardProps) {
  const setProgramCache = useProgramCache();
  const setVideoCache = useVideoCache();
  const getImageUrl = (video: Video) =>
    `https://image.mux.com/${video.playback_id}/thumbnail.png?width=120&time=${video.thumbnail_time_code}`;

  return (
    <Elevation
      w="full"
      shadow="2dp"
      borderRadius="card"
      p="4"
      pb="0"
      level={'2dp'}
      border={'1px solid'}
      borderColor={'tertiary.900'}
      display="flex"
      direction="column"
    >
      <LinkBox as="article" w="full">
        <Flex direction="column" gap={3} gridArea="title">
          <Grid
            templateColumns="auto 1fr"
            gap="3"
            alignContent="center"
            alignItems="center"
          >
            <ProgramIcon program={program} boxSize="7" />

            <LinkOverlay
              as={NextLink}
              href={href}
              onClick={() => setProgramCache(program)}
              w="full"
              alignContent={'center'}
              alignItems={'center'}
            >
              <TEXT.P2 mr="auto" w="full" h="full" textTransform={'uppercase'}>
                {program.title}
              </TEXT.P2>
            </LinkOverlay>
          </Grid>
          <Box>
            <ProgramProgressBar program={program} bg="neutral.white" />
            <TEXT.P3 color="neutral.500" mt="1.5">
              {program.percent_complete
                ? `Program ${Math.round(program.percent_complete)}% complete`
                : 'Program not started'}
            </TEXT.P3>
          </Box>
        </Flex>

        {video && (
          <Flex gridArea="up-next" direction="column" gap={4} my="4">
            <Divider />
            <LinkBox borderRadius="sm" bg="strive.card4.bg" w="full">
              <Flex align="center" gap={2} w="full">
                <AspectRatio ratio={4 / 3} minW="80px">
                  <Image
                    src={getImageUrl(video)}
                    alt={`thumbnail for ` + video.title}
                    objectFit="cover"
                    loading="eager"
                    flexShrink={0}
                  />
                </AspectRatio>
                <Flex direction="column" ml="2">
                  <LinkOverlay
                    as={NextLink}
                    href={getVideoPage(video, { program: program.slug })}
                    onClick={() => setVideoCache(video)}
                  >
                    <TEXT.P2
                      lineHeight={'tall'}
                      fontSize="sm"
                      noOfLines={2}
                      fontWeight="bold"
                    >
                      {video.title}
                    </TEXT.P2>
                  </LinkOverlay>
                  <Box fontSize="xs" color="strive.cardTextSecondary">
                    <span>{formatCoachName(video.coach)}</span>
                    <Text as="span" mx="2" aria-hidden>
                      &bull;
                    </Text>
                    <span>
                      <span aria-hidden>
                        {Math.round(Number(video.duration) / 60)}m
                      </span>
                      <VisuallyHidden>
                        {Math.round(Number(video.duration) / 60)} minutes.
                      </VisuallyHidden>
                    </span>
                  </Box>
                </Flex>
                <Icon ml="auto" as={ChevronRightIcon} boxSize={3} />
              </Flex>
            </LinkBox>
          </Flex>
        )}
      </LinkBox>
    </Elevation>
  );
}
