import { Flex, HStack, Text } from '@chakra-ui/react';
import { produce } from 'immer';

import {
  CheckboxButton,
  formatCoachName,
  PersonIcon,
} from '@arena-labs/strive2-ui';

import { useCoaches } from '../../api/coaches';
import { FilterSection } from './filter-section';

export interface FilterSectionCoachesProps {
  selected: Set<string>;
  onChange: (coaches: Set<string>) => void;
}

export function FilterSectionCoaches({
  selected,
  onChange,
}: FilterSectionCoachesProps) {
  const { data: coaches } = useCoaches();

  if (!coaches?.results) {
    return null;
  }

  const handleToggle = (duration: string) => () => {
    const updated = produce(selected, (draft) => {
      if (draft.has(duration)) {
        draft.delete(duration);
      } else {
        draft.add(duration);
      }
    });
    onChange(updated);
  };

  return (
    <FilterSection
      name="PERFORMANCE AMBASSADOR"
      applied={selected.size}
      onClear={() => onChange(new Set())}
    >
      <HStack
        spacing={2}
        mr={-6}
        pr={6}
        pb={2}
        overflowX="auto"
        className="custom-scrollbar"
        sx={{
          '--scrollbar-track': 'transparent',
          '--scrollbar-color': 'var(--chakra-colors-gray-300)',
        }}
      >
        {coaches.results.map((coach) => (
          <CheckboxButton
            size="sm"
            key={coach.slug}
            name={formatCoachName(coach)}
            isChecked={selected.has(coach.slug)}
            onChange={handleToggle(coach.slug)}
            wrapper={{ alignSelf: 'stretch' }}
            checkbox={{ height: '100%' }}
          >
            <Flex
              width="85px"
              direction="column"
              alignItems="center"
              textAlign="center"
              py={1}
            >
              <PersonIcon person={coach} size="lg" mb="2" />
              <Text fontSize="2xs" noOfLines={1}>
                {formatCoachName(coach)}
              </Text>
              <Text fontSize="3xs" noOfLines={1}>
                {coach.list_title}
              </Text>
            </Flex>
          </CheckboxButton>
        ))}
      </HStack>
    </FilterSection>
  );
}
