import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { LoadingOrError } from '../common/loading-or-error';

export type WaitForQueryProps<
  QueryType extends UseQueryResult<unknown, unknown>,
> = Omit<WaitForQueriesProps<[QueryType]>, 'queries'> & {
  /**
   * The query to wait for
   */
  query: QueryType;
};

export function WaitForQuery<
  QueryType extends UseQueryResult<unknown, unknown>,
>({ query, ...props }: WaitForQueryProps<QueryType>) {
  return <WaitForQueries queries={[query]} {...props} />;
}

type QueryTuple = [
  UseQueryResult<unknown, unknown>,
  ...UseQueryResult<unknown, unknown>[],
];

export type WaitForQueriesProps<T extends QueryTuple> = {
  /**
   * The queries to wait for
   */
  queries: T;

  /**
   * What to render when the queries are all loaded with data (query.status = success)
   * This is a function which receives the expected `data` for the queries
   */
  children: (...data: ExtractData<T>) => React.ReactNode;

  /**
   * What to render if any queries are loading (query.status = loading)
   * @default <LoadingOrError />
   */
  loading?: React.ReactNode;

  /**
   * What to render if any queries errored (query.status = error)
   * @default <LoadingOrError />
   */
  error?: React.ReactNode;

  /**
   * `errorTitle` to pass along to the fallback <LoadingOrError /> component
   */
  fallbackErrorTitle?: string;

  /**
   * `errorMessage` to pass along to the fallback <LoadingOrError /> component
   */
  fallbackErrorMessage?: string;
};

type ExtractData<T extends QueryTuple> = {
  [K in keyof T]: T[K] extends UseQueryResult<infer D, unknown> ? D : never;
};

export function WaitForQueries<T extends QueryTuple>({
  queries,
  children,
  loading,
  error,
  fallbackErrorTitle,
  fallbackErrorMessage,
}: WaitForQueriesProps<T>) {
  const anyLoading = queries.some((query) => query.status === 'loading');
  const anyError = queries.some((query) => query.status === 'error');

  const fallback = (
    <LoadingOrError
      status={anyError ? 'error' : 'loading'}
      errorMessage={fallbackErrorMessage}
      errorTitle={fallbackErrorTitle}
      retry={() =>
        queries.forEach((query) => {
          if (query.status === 'error') query.refetch();
        })
      }
      isRetrying={queries.some((query) => query.isFetching)}
      px="0"
    />
  );

  // if loading/error are null/false, we don't want to render anything
  if (anyLoading) return loading !== undefined ? loading : fallback;
  if (anyError) return error !== undefined ? error : fallback;

  return children(...(queries.map((query) => query.data) as ExtractData<T>));
}
