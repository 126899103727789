export function formatMarkup(markup: string): string {
  // replace double-newlines with paragraph tags
  const paragraphs = markup
    .split(/\n{2,}/)
    .map((paragraph) => `<p>${paragraph}</p>`);

  // replace newlines with br tags
  const formatted = paragraphs
    .map((paragraph) => paragraph.split('\n').join('<br>'))
    .join('\n');

  return formatted;
}
