import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';

import { BookmarkFilledIcon, BookmarkOutlineIcon } from '../icons';

export type BookmarkButtonProps = Omit<
  IconButtonProps,
  'aria-label' | 'onClick'
> & {
  isSaved: boolean;
  onClick: (save: boolean) => void;
};

export function BookmarkButton({
  isSaved,
  onClick,
  ...props
}: BookmarkButtonProps) {
  const icon = isSaved ? BookmarkFilledIcon : BookmarkOutlineIcon;
  return (
    <IconButton
      aria-label="Save for later"
      aria-pressed={isSaved}
      icon={<Icon as={icon} w="14px" />}
      variant="unstyled"
      display="flex"
      h="5"
      minW="5"
      onClick={(event) => {
        event.preventDefault();
        onClick(!isSaved);
      }}
      {...props}
    />
  );
}
