import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { looseEnum, zIsoDateTime } from '../lib/schema-utils';
import { pillarPracticeSchema } from './pillars.schema';
import { streakSchema } from './user-profile';

export const ACILocationSchema = z.enum([
  'ramping_up',
  'in_the_thick',
  'winding_down',
  'recharging',
]);

export const ACISchema = z.object({
  offset: z.number(), // TODO - we scan remove this now that we have a Timezone request header
  // TODO fix the ramping_up in the score results
  arena_location: ACILocationSchema,
  current_stress: z.number().optional(),
  current_energy: z.number().optional(),
  current_focus: z.number().optional(),
  challenge_anticipated: z.number().optional(),
  preparedness: z.number().optional(),
  make_key_decisions: z.number().optional(),
  clinical_performance: z.number().optional(),
  recharging_needed: z.number().optional(),
  recharging_ability: z.number().optional(),
});

export const ACIScoreSchema = z.object({
  date: zIsoDateTime,
  score: z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
  ]),
  label: z.string(),
  location: z.string(),
  trend: looseEnum(['positive', 'negative', 'neutral']).nullable(),
  image: z.string(),
  heading: z.string(),
  copy: z.string(),
});
export const ACIResponseSchema = z.object({
  created_at: zIsoDateTime,
  previous: ACIScoreSchema.nullable().catch(null),
  current: ACIScoreSchema,
  streak: streakSchema,
});

export const ACI2Schema = z.object({
  reflection_selection: z.enum(['below', 'above']),
  reflection_description: z.string().optional(),
});

export const aci2ResponseSchema = z.object({
  daily: z
    .object({
      inner_state_trend: z.enum(['below', 'above']),

      hrv_trend: z.enum(['below', 'above']).nullable(),
      latest_hrv: z.number().nullable(),
      baseline_hrv: z.number().nullable(),

      practice_count: z.number(),
      practice_trend: z.enum(['up', 'down', 'stable']),
      practice_trend_from: zIsoDateTime,
      practice_previous_count: z.number(),

      llm_title: z.string(),
      llm_description: z.string(),
      recommended_practice: pillarPracticeSchema.nullable(),
    })
    .nullable(),
  reflection_description: z.string().nullable(),
  reflection_selection: z.enum(['below', 'above']).nullable(),
  skipped: z.boolean(),
  created_at: zIsoDateTime,
});

export const aciApi = makeApi([
  {
    alias: 'submitACI',
    description: 'Submit the ACI form',
    method: 'post',
    path: '/data/aci/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: ACISchema,
      },
    ],
    response: ACIResponseSchema,
  },
  {
    alias: 'getRecentACI2',
    description: 'Get the most Recent ACI2',
    method: 'get',
    path: '/data/aci/v2.0/',
    parameters: [],
    response: aci2ResponseSchema.optional(),
  },
  {
    alias: 'submitACI2',
    description: 'Submit ACI2 to the BE',
    method: 'post',
    path: '/data/aci/v2.0/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: ACI2Schema,
      },
    ],
    response: aci2ResponseSchema,
  },
  {
    alias: 'skipACI2',
    description: 'Skip the ACI2',
    method: 'get',
    path: '/data/aci/v2.0/skip/',
    parameters: [],
    response: z.unknown(),
  },
]);
