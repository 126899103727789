import { useForm } from 'react-hook-form';
import {
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TEXT, WaitForQuery } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

export type PreferredNameEntryProps = {
  onSubmit: (preferred_name: string) => Promise<unknown>;
};
export function PreferredNameEntry({ onSubmit }: PreferredNameEntryProps) {
  const userQuery = useUserProfile();

  const formMethods = useForm<{ preferred_name: string }>({
    resolver: yupResolver(yup.object({ preferred_name: yup.string().max(25) })),
    defaultValues: {
      preferred_name: userQuery.data?.profile.preferred_name ?? '',
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = formMethods;

  return (
    <Flex
      h="full"
      direction="column"
      pb="calc(env(safe-area-inset-bottom) + 1rem)"
    >
      <Grid
        px="6"
        py="3"
        shadow="4dp"
        w="full"
        templateColumns={'40px 1fr 40px'}
        alignItems={'center'}
        textAlign={'center'}
        color={'logo'}
      >
        <TEXT.H3 gridColumn={2} aria-colspan={1}>
          Welcome to Strive
        </TEXT.H3>
      </Grid>

      <WaitForQuery query={userQuery}>
        {(user) => (
          <chakra.form
            px={'6'}
            display={'flex'}
            flexDirection={'column'}
            mt={10}
            h={'full'}
            onSubmit={handleSubmit((values) => {
              onSubmit(values.preferred_name);
            })}
          >
            <TEXT.H2 mb={2}>Your Profile is Ready!</TEXT.H2>

            <TEXT.P1 color={'neutral.400'}>
              We have successfully matched your profile to your cohort at{' '}
              <chakra.span color={'logo'}>{user.profile.team}</chakra.span>.
            </TEXT.P1>

            <Divider my={10} />

            <Flex direction={'column'} mb={'auto'} mt={6} gap={4}>
              <TEXT.H3>Your Profile Details</TEXT.H3>

              <Flex direction={'column'} gap={'2px'}>
                <TEXT.P3 color={'neutral.400'}>Name</TEXT.P3>

                <TEXT.P1>
                  {user.first_name} {user.last_name}
                </TEXT.P1>
              </Flex>

              <FormControl
                id={'preferred-name-input'}
                isInvalid={Boolean(errors.preferred_name?.message)}
              >
                <FormLabel color={'neutral.400'} fontSize={'p3'}>
                  Preferred Name (Optional)
                </FormLabel>

                <Input
                  placeholder={'e.g Shaq'}
                  {...register('preferred_name')}
                />
                <FormErrorMessage>
                  {errors.preferred_name?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex direction={'column'} gap={'2px'}>
                <TEXT.P3 color={'neutral.400'}>Work Email</TEXT.P3>
                <TEXT.P1>{user.email}</TEXT.P1>
              </Flex>

              <Flex direction={'column'} gap={'2px'}>
                <TEXT.P3 color={'neutral.400'}>Phone Number</TEXT.P3>
                <TEXT.P1>{user.profile.phone_number}</TEXT.P1>
              </Flex>
            </Flex>

            <Button
              mt={'auto'}
              variant={'primary'}
              w={'full'}
              type={'submit'}
              isLoading={isSubmitting || isSubmitSuccessful}
            >
              Continue
            </Button>
          </chakra.form>
        )}
      </WaitForQuery>
    </Flex>
  );
}
