import axios from 'axios';

export function isOfflineError(error: unknown) {
  if (typeof navigator !== undefined && !navigator.onLine) {
    return true;
  }

  if (axios.isAxiosError(error)) {
    return (
      !error.response ||
      error.code === 'ECONNABORTED' ||
      Boolean([408, 503, 504].includes(error.response.status))
    );
  }
  return false;
}
