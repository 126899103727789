import { assign, createMachine } from 'xstate';

import {
  AuthResponse,
  LoginResponse,
  ProfileFoundResponse,
} from '@arena-labs/shared-models';
import { claimUserProfile, matchUserProfile } from '@strive/api';
import { assert } from '@strive/utils';

type VerifyEmailContextType = {
  authTokens: LoginResponse | null;
  profile: ProfileFoundResponse | null;
  error: unknown;
};
type VerifyEmailEventType =
  | { type: 'Find Profile'; email: string }
  | { type: 'Confirm' }
  | { type: 'Start again' };

type VerifyEmailServiceType = {
  findProfile: { data: ProfileFoundResponse };
  confirmProfile: { data: AuthResponse };
};

export const verifyEmailMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QFEC2BDAlgGwAQFl0AXAYwAtMA7KAOgDEB7AJ1QGI6qJcAFJhgMxxgA2gAYAuolAAHBrExFMDSlJAAPRAEZRADk00dANgCcAdmObDogCyjN1wwBoQAT0QBWY6IMOAzACZ-d1N-UVMbAF8I5zQsPEJSCmp6ZlQaABkGBgBrAFdpVghlMBoqADcckticAmJyKlpGFgysvOkEcoYSYiVKMXF+1Vl5RWVVDQR3f2c3BHt3Gk13bRCda1X3QyiYjBqE+uSmtMyc-NYwJj4mGmlsYn5Ummr4uqTGx5O2jsoK7tG+iSDJAgYYKXrjLTWUI0UTGLyafw6QJQiwzDzuBa2ILLXyGXxLfHbEDPWqJBo0ADCykELB6ylYAGUiOgmERcOgoFgAZJgaD-hCEBZvKI7P5jL5cdYoSE0ZNTDoaL4-OZrL4piZ3ESSfs3pTqZhaf8aABJCDYMCsKmUGmoIEyORgsbAiaacI0QzuHQ6XzGNY2XGGHSy1XGGi2Qx4z06GymXxa3YvMnJK02umUPXWg2oBqFYqlH6VJ4J0kHWgprNpjM2hrfX5p-p2kEO-nOxBCmGi8WS6XTVyIAM0ZaepVI6x6USGUzxuIl3Xlw29KtZnMXK43O5EB7NbWvcnzjBG-c1zp-XoNiRDZvg1uTXTu3SiAJmGzuWHuYOPmjGQyIwwOUxLBiWxEpQDAQHAqg7kmUCXiM16gBMAC0xiyohCxwhhmEYToITTnsu6HKksGOioN7aBKBgmOYlg2HYDjBu4vgwp65g-oY2j+OEwE7DOOrkkcJpmmAxEtghWjaKGwR6NYnjLFKOGyh63iTpoOhCqIUzWN+eGJqWKTNJ8+QifB6haIE6EyfiY4RvKpiypspiDnCwSTss-h4joOmzvxjzIJczDGU6YlzJ43iaN+AEIkOIqaPZtiKnosacUiQQOF5fHJvqC5BU2cE5RMPqyu5jm2fKWnuXCvhTtExLFhlZZZQei6muagWkcFCl9ggIaDqYDiBpp4rhelBENZm2Xpke1BtQKYr6FVWlGP4UK6OYdlddYliLJ4akip4EZ-iN0FLhNTz+UwM03uE+jyssmi+EYamMdYH76MYtg2CpD2TtVPH4cdAAixSXcFmjaNYg73Y+aohEEXqyriTHLT+ZjuGOdgTlEURAA */
    tsTypes: {} as import('./find-and-verify-email.machine.typegen').Typegen0,

    schema: {
      context: {} as VerifyEmailContextType,
      events: {} as VerifyEmailEventType,
      services: {} as VerifyEmailServiceType,
    },

    context: {
      authTokens: null,
      profile: null,
      error: null,
    },

    id: 'Email Matching',

    description: `Guides the user through the process of linking their phone number to a profile by using the email adddress`,

    predictableActionArguments: true,

    states: {
      Form: {
        states: {
          Idle: {},

          Lookup: {
            invoke: {
              src: 'findProfile',
              onDone: {
                target: '#Email Matching.Confirmation',
                actions: 'assignProfile',
              },
              onError: {
                target: 'Error',
                actions: 'setError',
              },
            },
          },

          Error: {
            exit: ['clearError', 'clearError'],
          },
        },

        initial: 'Idle',

        on: {
          'Find Profile': '.Lookup',
        },
      },

      Confirmation: {
        states: {
          Idle: {
            on: {
              Confirm: 'Confirming',
            },
          },

          Confirming: {
            invoke: {
              src: 'confirmProfile',
              onDone: {
                target: '#Email Matching.Done',
              },
              onError: {
                target: 'Error',
                actions: 'setError',
              },
            },
          },

          Error: {
            exit: 'clearError',
          },
        },

        initial: 'Idle',

        on: {
          'Start again': 'Form',
        },

        exit: 'clearProfile',
      },

      Done: {
        type: 'final',

        entry: ['setAuthTokens'],
      },
    },

    initial: 'Form',
  },
  {
    actions: {
      setAuthTokens: assign({
        authTokens: (ctx, event) => event.data,
      }),
      setError: assign({
        error: (ctx, event) => event.data,
      }),
      clearError: assign({
        error: (ctx, event) => null,
      }),
      assignProfile: assign({
        profile: (ctx, event) => event.data,
      }),
      clearProfile: assign({
        profile: (ctx, event) => null,
      }),
    },
    services: {
      findProfile: (ctx, event) => {
        assert(ctx.authTokens, 'authTokens are required');
        const token =
          'token' in ctx.authTokens
            ? ctx.authTokens.token
            : ctx.authTokens.access;
        return matchUserProfile(event.email, token);
      },
      confirmProfile: (ctx, event) => {
        assert(ctx.profile, 'Profile is required');
        return claimUserProfile(ctx.profile.token);
      },
    },
  },
);
