import { Box, Divider, Flex, Grid } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

import CompetitionScoreGauge from '../competition-score-gauge';

export default function CompetitionScore({
  explanationFocus,
}: {
  explanationFocus: 'Practice_Consistency' | 'HRV_Avg';
}) {
  return (
    <Flex direction="column" h="full" justifyContent="space-evenly" pb={4}>
      <CompetitionScoreGauge
        hrv_above_avg={{
          value: 70,
          trend: 'up',
        }}
        consistency={{
          value: 80,
          trend: 'down',
        }}
        score={{
          value: 75,
          trend: 'up',
        }}
        position={13}
        explain={explanationFocus}
      />
      <Box>
        <Divider px={6} ml={-6} />
      </Box>
      <Flex direction="column" rowGap={4}>
        <MetricExplanation explanationFocus={explanationFocus} />
      </Flex>
    </Flex>
  );
}

function MetricExplanation({
  explanationFocus,
}: {
  explanationFocus: 'Practice_Consistency' | 'HRV_Avg';
}) {
  return (
    <>
      <TEXT.H3>
        {explanationFocus === 'Practice_Consistency'
          ? 'Practice Consistency'
          : 'HRV Above Avg.'}
      </TEXT.H3>
      <TEXT.P2 color="neutral.500">
        {explanationFocus === 'Practice_Consistency'
          ? 'Unlock and log Practices in your Arena Strive app as consistently as possible.'
          : "Your Heart Rate Variability is critical to success. We'll establish your baseline, and your goal will be to stay above it!"}
      </TEXT.P2>
      <Grid
        templateColumns={'auto auto 1fr'}
        alignItems={'center'}
        textAlign={'center'}
        columnGap={2}
        mx={2}
      >
        <TEXT.P2>
          {explanationFocus === 'Practice_Consistency'
            ? 'Practice Consistency'
            : 'HRV Above Avg.'}
        </TEXT.P2>
        <TEXT.P2 color={'neutral.700'}>=</TEXT.P2>
        <Flex w="full" direction={'column'} gap={2} px={2}>
          <TEXT.P2>
            {' '}
            {explanationFocus === 'Practice_Consistency'
              ? 'Days Practiced'
              : 'Days above HRV Baseline'}
          </TEXT.P2>
          <Divider />
          <TEXT.P2>Total Days on Strive</TEXT.P2>
        </Flex>
      </Grid>
    </>
  );
}
