import React from 'react';
import { useMeasure } from 'react-use';
import { Box, BoxProps, Grid } from '@chakra-ui/react';

export type AppBannerProps = {
  gridArea?: string;
  sticky?: boolean;
  children: React.ReactNode;
} & BoxProps;

export function AppBanner({
  sticky,
  gridArea = 'banner',
  children,
  ...props
}: AppBannerProps) {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const stickyProps = {
    sx: {
      // Only be sticky if there's enough vertical space
      '@media(min-height: 650px)': {
        position: 'fixed',
        top: 'env(safe-area-inset-top)',
        zIndex: 100,
      },
    },
  } as const;

  return (
    <Box height={`${height}px`} gridArea={gridArea}>
      <Box
        ref={ref}
        as="header"
        bg="strive.appBanner.bg"
        shadow="4dp"
        color="strive.appBanner.fg"
        w="full"
        {...(sticky ? stickyProps : {})}
        {...props}
      >
        <Grid
          py={3}
          px={6}
          w="full"
          templateColumns="minmax(40px, max-content) 1fr minmax(40px, max-content)"
          alignItems="center"
          textAlign={'center'}
        >
          {children}
        </Grid>
      </Box>
    </Box>
  );
}
