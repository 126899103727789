import Head from 'next/head';
import {
  Box,
  Button,
  Heading,
  Image,
  SlideFade,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import { isCoachDetail, Video } from '@arena-labs/shared-models';
import {
  formatCoachName,
  LoadingOrError,
  PersonIcon,
} from '@arena-labs/strive2-ui';

import { useCoach, useCoachCourses } from '../api/coaches';
import { CoachLinks } from '../components/coach-links';
import { VideoGroup } from '../components/video/video-group';

export type CoachDetailProps = {
  slug: string;
  getVideoPage: (video: Video) => string;
};

export function CoachDetail({ slug, getVideoPage }: CoachDetailProps) {
  const { data: coach, status, refetch } = useCoach(slug);
  const { data: courses } = useCoachCourses(slug);
  const displayArenaHeader = useBreakpointValue({ base: false, sm: true });
  const linksToggle = useDisclosure();
  const bioToggle = useDisclosure();

  if (status !== 'success') {
    return (
      <LoadingOrError
        status={status}
        errorMessage={`There was a problem loading this Performance Ambassador.`}
        retry={refetch}
      />
    );
  }
  const bio = isCoachDetail(coach) ? coach.bio.split(/\n\n*/) : null ?? [];
  const visibleBio = bioToggle.isOpen ? bio : bio.slice(0, 1);
  const showMoreBioButton = (bioIndex: number) => {
    const isLastVisible = bioIndex === visibleBio.length - 1;
    const hasHiddenBio = bio.length !== visibleBio.length;
    return isLastVisible && hasHiddenBio;
  };

  return (
    <Box pb={6}>
      <Head>
        <title>
          {`Arena Strive | Performance Ambassadors | ${formatCoachName(coach)}`}
        </title>
      </Head>
      <VStack spacing="8">
        <Box w="100%">
          <Image
            loading="eager"
            height="300px"
            w="100%"
            objectFit="cover"
            alt={`${formatCoachName(coach)}'s photo`}
            src={
              displayArenaHeader
                ? '/images/log-in-hero.png'
                : coach.headshot_url
            }
          />
          {displayArenaHeader ? (
            <Box alignSelf="flex-start" mt="-20" pl="8">
              <PersonIcon person={coach} size="2xl" />
            </Box>
          ) : null}
        </Box>
        <Box px="8" width="100%">
          <Heading as="h1" size="lg" mb="1">
            {formatCoachName(coach)}
          </Heading>
          {isCoachDetail(coach) && (
            <>
              <Text
                fontSize="xs"
                fontWeight="bold"
                textTransform="uppercase"
                mb="4"
              >
                {coach.detail_title}
              </Text>
              <VStack spacing="4">
                {visibleBio.map((para, idx) => (
                  <Box key={idx}>
                    <SlideFade in={true} offsetY="20px">
                      <Text key={idx} display="block" fontSize="sm">
                        {para}
                      </Text>
                    </SlideFade>
                    {showMoreBioButton(idx) && (
                      <Button
                        variant="unstyled"
                        onClick={bioToggle.onOpen}
                        fontSize="sm"
                        alignSelf="flex-start"
                      >
                        SEE MORE
                      </Button>
                    )}
                  </Box>
                ))}
                {coach.coach_links.length > 0 && (
                  <>
                    <Button
                      variant="unstyled"
                      onClick={linksToggle.onOpen}
                      fontSize="sm"
                      alignSelf="flex-start"
                    >
                      SEE MY LINKS
                    </Button>
                    <CoachLinks
                      coach={coach}
                      isOpen={linksToggle.isOpen}
                      onClose={linksToggle.onClose}
                    />
                  </>
                )}
              </VStack>
            </>
          )}
        </Box>
        {courses && courses.results.length > 0 && (
          <Box as="section" px="8" width="100%">
            <Heading as="h2" size="sm" mb="4">
              VIEW MY COURSES
            </Heading>
            <VideoGroup
              groupId={`coach-courses--${coach.slug}`}
              videos={courses.results}
              getHref={getVideoPage}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
}
