import * as React from 'react';

export function UserLowRecharge(props: React.SVGProps<SVGSVGElement>) {
  const gradientId1 = React.useId();
  const gradientId2 = React.useId();
  const gradientId3 = React.useId();
  const gradientId4 = React.useId();
  const gradientId5 = React.useId();
  const gradientId6 = React.useId();
  return (
    <svg viewBox="0 0 112 114" fill="none" {...props}>
      <circle
        cx={79.233}
        cy={27.301}
        r={9.323}
        stroke={`url(#${gradientId1})`}
        strokeWidth={5.952}
      />
      <path
        d="M69.667 42.334c-21.183 0-34.849 8.2-34.849 28.699l25.966 10.933-12.3 30.065"
        stroke={`url(#${gradientId2})`}
        strokeWidth={5.952}
      />
      <path
        d="M66.934 39.6v32.8"
        stroke={`url(#${gradientId3})`}
        strokeWidth={5.952}
      />
      <path
        d="M61.467 8.602v17.766"
        stroke={`url(#${gradientId4})`}
        strokeWidth={4.592}
      />
      <path
        d="M53.267 16.802v16.4"
        stroke={`url(#${gradientId5})`}
        strokeWidth={4.592}
      />
      <path
        d="M45.068 12.702v16.4"
        stroke={`url(#${gradientId6})`}
        strokeWidth={4.592}
      />
      <defs>
        <linearGradient
          id={gradientId1}
          x1={90.549}
          y1={18.589}
          x2={65.781}
          y2={47.319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={50.686}
          y1={40.284}
          x2={45.377}
          y2={138.562}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId3}
          x1={67.426}
          y1={39.601}
          x2={78.237}
          y2={80.233}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId4}
          x1={61.467}
          y1={8.602}
          x2={61.475}
          y2={26.368}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId5}
          x1={53.267}
          y1={16.802}
          x2={53.274}
          y2={33.201}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId6}
          x1={45.068}
          y1={12.702}
          x2={45.074}
          y2={29.101}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5C00" />
          <stop offset={1} stopColor="#FF5C00" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoUserLowRecharge = React.memo(UserLowRecharge);
export default MemoUserLowRecharge;
