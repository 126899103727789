import { BoxProps, HStack } from '@chakra-ui/react';

import { useRestoredScroll } from '../utils/restored-scroll';

export type HorizontalListProps = {
  children: React.ReactNode;
  groupId: string;
} & BoxProps;

export function HorizontalList({
  groupId,
  children,
  ...boxProps
}: HorizontalListProps) {
  const restoreScroll = useRestoredScroll(groupId);
  return (
    <HStack
      as="ul"
      role="list"
      listStyleType="none"
      spacing="4"
      overflowY="visible"
      overflowX="auto"
      mr="-8"
      pr="8"
      className="custom-scrollbar"
      sx={{
        '--scrollbar-track': 'transparent',
        '--scrollbar-color': 'var(--chakra-colors-gray-300)',
      }}
      maxWidth="min-content"
      minHeight="min-content"
      {...restoreScroll}
      {...boxProps}
    >
      {children}
    </HStack>
  );
}
