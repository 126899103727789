import { produce } from 'immer';
import create from 'zustand';

import { VideoQuery } from '@arena-labs/shared-models';

import { ContentSectionType } from '../constants';

type ContentLibraryQuery = {
  [K in ContentSectionType]: VideoQuery;
};

export type ContentLibraryState = {
  query: ContentLibraryQuery;
  updateQuery: (section: ContentSectionType, filters: VideoQuery) => void;
};

export const useContentLibraryStore = create<ContentLibraryState>()((set) => ({
  query: {
    PROGRAMS: new VideoQuery(),
    VIDEOS: new VideoQuery(),
    SAVED: new VideoQuery(),
    WATCHED: new VideoQuery(),
    COACHES: new VideoQuery(),
  },
  updateQuery(section: ContentSectionType, filters: VideoQuery) {
    set(
      produce((draft) => {
        draft.query[section] = filters;
      }),
    );
  },
}));
