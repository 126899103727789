import { useEffect } from 'react';
import { useLatest, useShallowCompareEffect } from 'react-use';
import { useRouter } from 'next/router';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { withScope } from '@sentry/nextjs';

import {
  AnalyticsContext,
  GeneralTrackingEvent,
  useAnalytics,
} from '@arena-labs/analytics';
import { isObjectWithKey, UserProfile } from '@arena-labs/shared-models';
import { useHomeScreenData } from '@arena-labs/strive2-coaching';
import { queryClient } from '@strive/api';

import { identifyCapgo } from '../../lib/capgo';
import {
  identifySentry,
  reportErrorSentry,
  resetSentry,
} from '../../lib/sentry';
import { AppInfo } from '../../lib/use-app-info';

// Feed errors from React Query into Sentry
queryClient.getQueryCache().config.onError = (error, query) => {
  withScope((scope) => {
    scope.setContext('query', { queryHash: query.queryHash });
    scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
    const errorObj =
      error instanceof Error
        ? error
        : typeof error === 'string'
        ? new Error(error)
        : isObjectWithKey(error, 'message')
        ? new Error(error.message as string)
        : new Error(JSON.stringify(error));
    reportErrorSentry(errorObj);
  });
};
queryClient.getMutationCache().config.onError = (
  error,
  _variables,
  _context,
  mutation,
) => {
  withScope((scope) => {
    scope.setContext('mutation', {
      mutationId: mutation.mutationId,
      variables: mutation.state.variables,
    });
    if (mutation.options.mutationKey) {
      scope.setFingerprint(
        Array.from(mutation.options.mutationKey) as string[],
      );
    }
    reportErrorSentry(error);
  });
};

export type UserTelemetryProps = {
  status: 'loading' | 'ready' | 'timeout' | 'offline' | 'undefined';
  trackPageLoad: boolean;
  userId: number | null;
  user?: UserProfile;
  appInfo?: AppInfo;
};

export function UserTelemetry({
  userId,
  appInfo,
  user,
  status,
  trackPageLoad,
  children,
}: React.PropsWithChildren<UserTelemetryProps>) {
  const [homepage] = useHomeScreenData();
  const pillarData = homepage?.pillar_data;

  // Set up global analytics context with user journey data
  const analytics = useAnalytics({
    learningState: homepage?.learning_state,
    currentPillar: pillarData && pillarData.current_pillar.order + 1,
    currentPillarSlug: pillarData && pillarData.current_pillar.slug,
    session: pillarData?.session,
  });

  // Send a 'PageLoad' event when the URL changes
  const router = useRouter();
  const routerContext = useLatest({
    ...router.query,
    route: router.pathname,
    url: router.asPath,
  });
  useEffect(() => {
    const url = router.asPath;
    if (url && trackPageLoad && status === 'ready') {
      analytics.logEvent(GeneralTrackingEvent.PageLoad, routerContext.current);
    }
  }, [trackPageLoad, router.asPath, status, analytics, routerContext]);

  // Identify the user with Sentry, LogRocket, and Amplitude
  const attributes = {
    ...appInfo,
    ...(user && {
      email: user.email,
      team: user?.profile.team_id,
      teamName: user?.profile.team,
    }),
    ...(homepage && {
      learningState: homepage.learning_state,
    }),
  };
  useShallowCompareEffect(() => {
    if (userId) {
      analytics.setUserId(String(userId));
      analytics.setUserProperties(attributes);
      identifySentry(String(userId), attributes);

      (async () => {
        let customId = `env:${appInfo?.environment}|user:${userId}|team:${user?.profile.team}`;
        try {
          const { channel } = await CapacitorUpdater.getChannel();
          if (channel) {
            customId += `|channel:${channel}`;
          }
        } catch (error) {
          console.error(error);
        }
        identifyCapgo(customId);
      })();
    } else {
      analytics.clearUser();
      resetSentry();
    }
  }, [userId, attributes]);

  return <AnalyticsContext parent={analytics}>{children}</AnalyticsContext>;
}
