import { QueryClient } from '@tanstack/react-query';
import { PersistedClient } from '@tanstack/react-query-persist-client';
import axios from 'axios';
import { throttle } from 'lodash-es';

import { DataStoreProvider, Deferred } from '@strive/utils';

const MINS = 60 * 1000;
const HOURS = 60 * MINS;
const DAYS = 24 * HOURS;

const MAX_AGE = 5 * DAYS;

// Configure the query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: MAX_AGE,
      staleTime: 5 * MINS,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          return false;
        }
        return failureCount < 1;
      },
    },
  },
});

let restoring: Deferred | null = null;

export async function isQueryClientReady() {
  if (restoring) {
    // block while restoring
    return await restoring.promise;
  }
  return true;
}

export function createQueryClientPersister(
  dataStore: DataStoreProvider,
  key = 'QueryClient',
) {
  return {
    // We throttle the persistClient function to avoid spamming
    // the backend with too many writes
    persistClient: throttle(
      async (client: PersistedClient) => {
        await dataStore.setItem(key, client);
      },
      1000,
      { leading: true, trailing: true },
    ),
    restoreClient: async () => {
      restoring = new Deferred();
      let persisted: PersistedClient | undefined;
      let cacheSize = 0;
      let timestamp: number | null = null;
      try {
        performance.mark('query-client:restoring');
        const data = await dataStore.getItem(key);
        persisted = data?.value as PersistedClient;
        cacheSize = data?.size || 0;
        timestamp = persisted.timestamp || null;
      } catch {
        // If there's no data, we'll just return undefined
      }
      restoring.resolve(true);
      performance.mark('query-client:restored', {
        detail: {
          cacheSize,
          timestamp,
        },
      });

      return persisted;
    },
    removeClient: async () => {
      await dataStore.removeItem(key);
    },
  };
}
