import { assign, createMachine } from '@xstate/fsm';

import { getHistoryLength } from '../utils/router-history';

type NavigationContext = {
  initialHistoryIndex: number;
  opened: boolean;
};

type NavigationEvent =
  | {
      type: 'OPEN';
      opened: boolean;
    }
  | {
      type: 'CLOSE';
      opened: boolean;
    }
  | {
      type: 'NAVIGATION';
      opened: boolean;
    };

type NavigationState =
  | {
      value: 'closed';
      context: NavigationContext;
    }
  | {
      value: 'open';
      context: NavigationContext;
    };

// State machine that manages ties the `isOpen` state
// to a router, so that you can close the drawer by pressing
// the browser back button
export const navigationDisclosureMachine = createMachine<
  NavigationContext,
  NavigationEvent,
  NavigationState
>({
  initial: 'closed',
  context: {
    initialHistoryIndex: -1,
    opened: false,
  },
  states: {
    closed: {
      entry: assign<NavigationContext>({
        initialHistoryIndex: -1,
        opened: true,
      }),
      exit: assign({ initialHistoryIndex: getHistoryLength() }),
      on: {
        OPEN: {
          target: 'open',
          actions: ['pushHistory'],
        },
      },
    },
    open: {
      exit: 'onClose',
      on: {
        CLOSE: {
          target: 'closed',
          actions: ['popHistory'],
        },
        NAVIGATION: [
          {
            // Close the drawer if we are `opened` and now we are
            // back to the initial history index
            target: 'closed',
            cond: (ctx, event) =>
              ctx.opened && ctx.initialHistoryIndex === getHistoryLength(),
          },
          {
            // The first navigation comes from the `pushHistory`
            // Which occurred when we left `closed` state
            actions: assign<NavigationContext>({ opened: true }),
            cond: (ctx) =>
              !ctx.opened && ctx.initialHistoryIndex < getHistoryLength(),
          },
        ],
      },
    },
  },
});
