import { useCallback } from 'react';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  Coach,
  CoachDetail,
  CoachListItem,
  GetCoachesResponse,
  GetVideosResponse,
} from '@arena-labs/shared-models';
import { authClient, frontend } from '@strive/api';

export async function getCoaches(): Promise<GetCoachesResponse> {
  const response = await authClient.get(`/content/coaches/?&limit=9999`);
  return response.data;
}

export async function getCoach(slug: string): Promise<CoachDetail> {
  const response = await authClient.get(`/content/coaches/${slug}/`);
  return response.data;
}

export async function getCoachCourses(
  slug: string,
): Promise<GetVideosResponse> {
  const response = await frontend.get(`/api/coaches/${slug}/courses`);
  return response.data;
}

export function useCoaches() {
  return useQuery<GetCoachesResponse, AxiosError>(['coaches'], () =>
    getCoaches(),
  );
}

export function useCoach(
  slug: string,
  options?: UseQueryOptions<
    CoachDetail | CoachListItem,
    AxiosError,
    CoachDetail | CoachListItem
  >,
) {
  return useQuery<CoachDetail | CoachListItem, AxiosError>(
    ['coach', slug],
    () => getCoach(slug),
    options,
  );
}

export function useCoachCourses(coach: Coach | string) {
  const slug = typeof coach === 'string' ? coach : coach.slug;
  return useQuery<GetVideosResponse, AxiosError>(
    ['coach', slug, 'courses'],
    () => getCoachCourses(slug),
  );
}

export function useCoachCache() {
  const queryClient = useQueryClient();
  return useCallback(
    (coach: Coach, stale = true) => {
      const coachQuery = ['coach', coach.slug];
      if (!queryClient.getQueryData(coachQuery)) {
        queryClient.setQueryData(coachQuery, coach);
      }
      if (stale) {
        queryClient.invalidateQueries(coachQuery);
      }
    },
    [queryClient],
  );
}
