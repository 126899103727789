import { useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Swiper } from 'swiper';

import { PillarPractice } from '@arena-labs/shared-models';
import { useFadeEntryFadeExit } from '@arena-labs/strive2-ui';

import { AciContext } from '../aci-context';
import { DailyInsights } from './daily-insights';

export function CheckInInsights({
  onOpenInfo,
  logPracticeCard,
}: {
  onOpenInfo: () => void;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const aciActor = AciContext.useActorRef();

  // TODO - we should have a way to query this - not just the response from the submission
  const aciResponse = AciContext.useSelector(
    (state) => state.context.aciResponse,
  );

  const { scope } = useFadeEntryFadeExit();
  const [swiper, setSwiper] = useState<Swiper>();

  useEffect(() => {
    if (!swiper) {
      const newSwiper = new Swiper('.swiper', {
        speed: 500,
        spaceBetween: 0,
        initialSlide: 1,
        slidesPerView: 1,
        allowTouchMove: false,
      });
      setSwiper(newSwiper);
    }
  }, [swiper]);

  return (
    <Box ref={scope} h={'full'}>
      <Flex direction={'column'} id={'body'} h={'full'}>
        <Box h={'full'} maxW={'100vw'} paddingY={6} mx={-6}>
          <Box h={'full'}>
            {aciResponse && (
              <DailyInsights
                data={aciResponse}
                logPracticeCard={logPracticeCard}
                onOpenContext={onOpenInfo}
              />
            )}
          </Box>
        </Box>

        <Button
          mt={'auto'}
          w={'full'}
          variant={'primary'}
          transition={'all 1s ease'}
          onClick={() => aciActor.send({ type: 'close' })}
        >
          Home
        </Button>
      </Flex>
    </Box>
  );
}
