import { UseQueryOptions } from '@tanstack/react-query';
import { Zodios, ZodiosEndpointDefinitions, ZodiosError } from '@zodios/core';
import { Narrow } from '@zodios/core/lib/utils.types';
import { AxiosError } from 'axios';

import { AuthResponse } from '@arena-labs/shared-models';

import { getFreshToken } from './auth/auth-api';
import { apiHost } from './constants';
import { getAdditionalHeaders } from './lib/utils';
import { authTokenPlugin } from './lib/zodios-auth-tokens';

type Errors = Error | ZodiosError | AxiosError;
export type QueryOptions<TQueryFnData, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, Errors, TData>,
  'queryKey' | 'queryFn'
>;

export type ApiClientConfig = {
  getAuthTokens: () => AuthResponse | null;
  setAuthTokens: (tokens: AuthResponse) => void;
  baseURL?: string;
  onSessionExpired?: () => void;
};

/**
 * Create an API client where auth tokens are stored & managed externally
 */
export function createApiClient<Api extends ZodiosEndpointDefinitions>(
  endpoints: Narrow<Api>,
  config: ApiClientConfig,
) {
  const client = new Zodios(endpoints, {
    axiosConfig: { baseURL: config.baseURL ?? apiHost, timeout: 10_000 },
  });

  // Add auth token plugin
  client.use(
    authTokenPlugin({
      getToken: async () => config.getAuthTokens()?.access,
      renewToken: async () => {
        const { refresh } = config.getAuthTokens() ?? {};
        if (refresh) {
          try {
            const newTokens = await getFreshToken(refresh);
            config.setAuthTokens(newTokens);
            return newTokens.access;
          } catch (err) {
            console.error('Failed to renew token', err);
            config.onSessionExpired?.();
          }
        }
        return undefined;
      },
    }),
  );

  // Add additional headers
  client.use({
    request: async (_, config) => ({
      ...config,
      headers: {
        ...config.headers,
        ...getAdditionalHeaders(),
      },
    }),
  });

  // Add session expiration handler
  client.use({
    response: async (_api, _config, response) => {
      if (response.status === 401) {
        config.onSessionExpired?.();
      }
      return response;
    },
  });

  return client;
}

/**
 * Create an API client where auth tokens are stored & managed internally
 */
export function createStandaloneApiClient<
  Api extends ZodiosEndpointDefinitions,
>(
  tokens: AuthResponse,
  endpoints: Narrow<Api>,
  config: Omit<ApiClientConfig, 'getAuthTokens' | 'setAuthTokens'> & {
    onTokensUpdated?: (tokens: AuthResponse) => void;
  } = {},
) {
  // Store tokens in closure
  let tokenStore: AuthResponse | null = tokens;

  // Create API client
  return createApiClient(endpoints, {
    baseURL: config.baseURL ?? apiHost,
    getAuthTokens: () => tokenStore,
    setAuthTokens: (tokens) => {
      tokenStore = tokens;
      config.onTokensUpdated?.(tokens);
    },
    onSessionExpired: () => {
      tokenStore = null;
      config.onSessionExpired?.();
    },
  });
}
