import { createContext, useContext, useEffect } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import { useActor } from '@xstate/react';
import { interpret } from 'xstate';

import { passportManagerMachine } from './passport-manager.machine';

const PassportManagerContext = createContext(
  interpret(passportManagerMachine, { devTools: true }).start(),
);

export function usePassportService() {
  return useActor(useContext(PassportManagerContext));
}

export function useOnLoggedIn(onLogin: () => void) {
  const handleLogin = useCallbackRef(onLogin);
  const [passportState] = usePassportService();

  const isLoggedIn =
    passportState.matches('Authenticated.Ready') ||
    passportState.matches('Authenticated.Timeout');

  useEffect(() => {
    if (isLoggedIn) {
      handleLogin();
    }
  }, [handleLogin, isLoggedIn]);
}
