import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

export type SaveAsOptions = {
  filename: string;
  type: string;
  data: string | Blob | Promise<string | Blob>;
  directory?: Directory;
};

export async function saveAs(options: SaveAsOptions) {
  if (Capacitor.isNativePlatform()) {
    const data = await options.data;
    // Convert string data to Blob if necessary
    let blobData: Blob;
    if (typeof data === 'string') {
      blobData = new Blob([data], { type: options.type });
    } else {
      blobData = data;
    }

    // Convert Blob to ArrayBuffer, then to base64
    const arrayBuffer = await blobData.arrayBuffer();
    const base64 = Buffer.from(arrayBuffer).toString('base64');

    // Write the file
    const file = await Filesystem.writeFile({
      path: options.filename,
      data: base64,
      directory: options.directory ?? Directory.Documents,
    });
    await Share.share({
      title: options.filename,
      url: file.uri,
      dialogTitle: 'Open in...',
    });
  } else {
    const data = await options.data;
    const url =
      data instanceof Blob
        ? URL.createObjectURL(data)
        : `data:${options.type},${encodeURIComponent(data)}`;
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', options.filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    if (data instanceof Blob) {
      // Cleanup: revoke the object URL after the link has been clicked
      URL.revokeObjectURL(url);
    }
  }
}
