import { useEffect, useRef } from 'react';
import { createActorContext } from '@xstate/react';

import { mediaSuspenderMachine } from './media-suspender.machine';

export const MediaSuspender = createActorContext(mediaSuspenderMachine);

/**
 * Determines whether the media should currently be suspended based on the state machine.
 * Returns a boolean indicating the suspended state.
 */
export function useMediaIsSuspended() {
  return MediaSuspender.useSelector((state) => state.matches('suspended'));
}

/**
 * Handles adding or removing a suspension block based on the passed in "suspend" parameter.
 */
export function useSuspendMedia(suspend = true) {
  const [, send] = MediaSuspender.useActor();
  useEffect(() => {
    if (suspend) {
      send('Suspend');
      return () => send('Remove');
    }
  }, [suspend, send]);
}

/**
 * Manages the play/pause state of a given HTMLMediaElement based on the current suspension state.
 * Pauses the media when suspended and resumes playing it once the suspension is lifted,
 * if it was playing before.
 */
export function useSuspendableMedia(media?: HTMLMediaElement | null) {
  const isSuspended = useMediaIsSuspended();

  const restorePlaying = useRef(false);
  useEffect(() => {
    if (!media) return;

    if (isSuspended) {
      if (!media.paused) {
        restorePlaying.current = true;
      }
      media.pause();
    } else {
      if (restorePlaying.current) {
        media.play();
        restorePlaying.current = false;
      }
    }
  }, [isSuspended, media]);

  return isSuspended;
}
