import { useState } from 'react';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import { Video, VideoPageMetadata } from '@arena-labs/shared-models';
import { durationToMs } from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';
import {
  getVideoUrls,
  useInitialMediaStartTime,
  useMediaProgressHandler,
  useVideoViewMutation,
  VideoPlayer,
} from '@strive/av';

import { Program } from '../../api';
import { VideoPlayerOverlays } from './video-player-overlays';

export type VideoLibraryPlayerProps = {
  video: Video;
  getVideoHref: (video: Video, meta?: VideoPageMetadata) => string;
  getProgramHref: (program: Program) => string;
  options?: VideoJsPlayerOptions;
  onPlayerClosed?: () => void;
  disableUpNext?: boolean;
};

export function VideoLibraryPlayer({
  video,
  getVideoHref,
  getProgramHref,
  options,
  onPlayerClosed,
  disableUpNext,
}: VideoLibraryPlayerProps) {
  const [vjsPlayer, setVjsPlayer] = useState<VideoJsPlayer>();
  const { mutateAsync: createVideoViewMutation } = useVideoViewMutation();

  const [startTime, setStartTime] = useInitialMediaStartTime(video, {
    staleTime: durationToMs({ weeks: 1 }),
  });
  const rememberProgress = useMediaProgressHandler(video);

  const { videoUrl, imageUrl } = getVideoUrls(video, { startTime });

  return (
    <>
      <VideoPlayer
        videoUrl={videoUrl}
        thumbnailUrl={imageUrl}
        options={options}
        onReady={setVjsPlayer}
        onLoadedMetadata={setStartTime}
        onTimeUpdate={rememberProgress}
        aspectRatio={video.aspect_ratio}
        onPlayerClosed={(progress) => {
          return createVideoViewMutation([video.slug, progress]).then(() => {
            queryClient.invalidateQueries(['programs', 'program']);
            onPlayerClosed && onPlayerClosed();
          });
        }}
        onEnded={() => {
          createVideoViewMutation([video.slug, 100]).then(() => {
            queryClient.invalidateQueries(['programs', 'program']);
          });
        }}
        withSpeedControl
      />
      <VideoPlayerOverlays
        player={vjsPlayer}
        video={video}
        getVideoHref={getVideoHref}
        getProgramHref={getProgramHref}
        disableUpNext={disableUpNext}
      />
    </>
  );
}
