import { parse, parseISO, startOfDay } from 'date-fns';
import { z, ZodSchema } from 'zod';

/**
 * A Zod schema to transform ISO 8601 datetime strings into a Date object.
 */
export const zIsoDateTime = z.string().transform((v) => parseISO(v));

/**
 * A Zod schema to 'YYYY-MM-DD' date strings into a Date object.
 */
export const zYYYYMMDD = z.preprocess(
  (arg) =>
    typeof arg === 'string'
      ? parse(arg, 'yyyy-MM-dd', startOfDay(new Date()))
      : arg,
  z.date(),
);

/*
 * Pagination
 */
const paginationSchema = z.object({
  count: z.number(),
  next: z.string().nullable(),
  previous: z.string().nullable(),
  results: z.array(z.unknown()),
});

export function paginated<Item extends ZodSchema>(itemSchema: Item) {
  return paginationSchema.extend({ results: z.array(itemSchema) });
}
export type Depaginate<T> = T extends { results: Array<infer R> } ? R : never;

export const paginationParams = [
  {
    type: 'Query',
    name: 'offset',
    schema: z.number().optional().nullable(),
  },
  {
    type: 'Query',
    name: 'limit',
    schema: z.number().optional().nullable(),
  },
] as const;

/**
 * An enum with autocomplete that allows any string.
 * @see https://www.youtube.com/watch?v=a_m7jxrTlaw
 */
export function looseEnum<U extends string, T extends [U, ...U[]]>(values: T) {
  return z.enum(values).or(
    z.custom<string & {}>((input) => typeof input === 'string', {
      message: 'Input is not a string',
    }),
  );
}

/**
 * An enum which validates, but falls back to `null` and emits an error on unrecognized values
 */
export function safeEnum<U extends string, T extends [U, ...U[]]>(
  values: T,
  label?: string,
) {
  return z
    .enum(values)
    .nullable()
    .catch((ctx) => {
      console.error(...(label ? [`(${label})`] : []), ctx.error);
      return null;
    });
}
