export class AbortWaitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AbortWaitError';
  }
}

export function wait(duration: number, signal?: AbortSignal) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(resolve, duration);

    if (signal) {
      signal.addEventListener('abort', () => {
        clearTimeout(timeoutId);
        reject(new AbortWaitError('Wait aborted'));
      });
    }
  });
}
