import React from 'react';
import {
  DOWN,
  LEFT,
  RIGHT,
  SwipeableProps,
  SwipeEventData,
  UP,
  useSwipeable,
} from 'react-swipeable';
import { DrawerBody, ModalBodyProps } from '@chakra-ui/react';

// type SwipeableDrawerBodyProps = SwipeableProps & ModalBodyProps;
type SwipeableDrawerBodyProps = ModalBodyProps & {
  direction?: typeof UP | typeof DOWN | typeof LEFT | typeof RIGHT;
  delta?: SwipeableProps['delta'];
  onSwipe: (eventData: SwipeEventData) => void;
};

export const SwipeableDrawerBody = React.forwardRef<
  HTMLDivElement,
  SwipeableDrawerBodyProps
>(function SwipeableDrawerBody(
  { direction, delta = {}, onSwipe, ...modalBodyProps },
  ref,
) {
  const handlers = useSwipeable({
    onSwipedLeft: direction === LEFT ? onSwipe : undefined,
    onSwipedRight: direction === RIGHT ? onSwipe : undefined,
    onSwipedUp: direction === UP ? onSwipe : undefined,
    onSwipedDown: direction === DOWN ? onSwipe : undefined,
    delta,
  });

  return <DrawerBody {...modalBodyProps} {...handlers} ref={ref} />;
});
