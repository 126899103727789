async function enableMocking() {
  if (process.env.NODE_ENV === 'production' || process.env.MOCK_API !== 'true')
    return;

  if (typeof window !== 'undefined') {
    // Big console log banner to make it obvious that mocking is enabled
    const warn = () => {
      console.warn(
        '%c⚠️ Mocking enabled. To disable, set $MOCK_API=false ⚠️',
        'font-size: 2em; color: red; font-weight: bold;',
      );
    };
    warn();

    // Log again with a timeout so it doesn't get lost at the top of the console 🤞
    setTimeout(warn, 30_000);

    // Browser mocks
    const { worker } = await import('./browser');

    return worker.start({
      // silence console warnings for unhandled requests
      // It could be useful to comment this out temporarily
      // if you're trying to debug a mocked request
      onUnhandledRequest: 'bypass',
    });
  }
}

enableMocking();
