import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { Card, ChevronLeftIcon, PageStack } from '@arena-labs/strive2-ui';
import { AchievementIncentiveGroup } from '@strive/api';

import {
  incentiveImages,
  supportStrings,
} from '../../incentives/rewards/reward-config';

export type OnboardRewardSlideProps = {
  primaryAction?: () => void;
  slideDirection?: 'next' | 'last';
};

export type IncentiveTypesProps = {
  incentives: AchievementIncentiveGroup[];
} & OnboardRewardSlideProps;

export default function IncentiveTypes({
  primaryAction,
  slideDirection,
  incentives,
}: IncentiveTypesProps) {
  const swiper = useSwiper();

  return (
    <PageStack
      h="full"
      pb="6"
      pt="10"
      spacing="4"
      align="stretch"
      gridArea="content-body"
      overflowY="auto"
      textAlign={'center'}
      position="relative"
    >
      {slideDirection === 'last' && (
        <Button
          position={'absolute'}
          top={'10px'}
          onClick={() => swiper.slidePrev()}
          bg="none"
          p={0}
        >
          <Icon as={ChevronLeftIcon} color="white" boxSize={'5'} />
        </Button>
      )}
      <Heading>Types of Reward</Heading>
      {incentives.map((rewardGroup, idx) => {
        return (
          <Card
            key={idx}
            w="full"
            bg="gray.800"
            color="white"
            textAlign={'center'}
          >
            <Card.Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                {supportStrings[rewardGroup.type_text + '_helper']}
              </Text>
              <Text fontSize="2xl" textTransform={'uppercase'}>
                {supportStrings[rewardGroup.type_text + '_heading']}
              </Text>
            </Card.Heading>
            <Flex justifyContent={'space-around'} wrap={'wrap'}>
              {rewardGroup.incentives.map((reward, idx) => {
                return (
                  <VStack
                    key={idx}
                    w={'full'}
                    maxW={'33%'}
                    fontSize={'sm'}
                    pt="4"
                  >
                    <Image
                      src={incentiveImages[reward.icon]}
                      alt={reward.alt_text}
                      w="50px"
                    />
                    <Text>{reward.title}</Text>
                  </VStack>
                );
              })}
            </Flex>
          </Card>
        );
      })}
      <Spacer />
      <Button
        variant="primary"
        onClick={
          primaryAction
            ? primaryAction
            : () =>
                slideDirection === 'last'
                  ? swiper.slidePrev()
                  : swiper.slideNext()
        }
      >
        {slideDirection === 'last' ? 'Return' : 'Next'}
      </Button>
    </PageStack>
  );
}
