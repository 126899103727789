import { Box, BoxProps, IconButton } from '@chakra-ui/react';

import { Reaction } from '@arena-labs/shared-models';

import { ThumbsDownIcon, ThumbsUpIcon } from '../icons';

export type LikeButtonsProps = {
  reaction?: Reaction;
  onClick: (reaction: Reaction) => void;
} & Omit<BoxProps, 'onClick'>;

export function LikeButtons({ reaction, onClick, ...props }: LikeButtonsProps) {
  const isLike = reaction === true;
  const isDislike = reaction === false;
  return (
    <Box {...props}>
      <IconButton
        aria-label="Like video"
        aria-pressed={isLike}
        onClick={() => onClick(isLike ? null : true)}
        backgroundColor="strive.card"
        color="strive.button.icon.fg"
        borderLeftRadius="50vh"
        borderRightRadius="0"
        shadow="md"
        _hover={{
          background: 'strive.card',
        }}
        sx={{
          '&[aria-pressed=true]': {
            color: '#00fc00',
            backgroundColor: '#316047',
          },
        }}
        mr="0.5"
      >
        <ThumbsUpIcon />
      </IconButton>
      <IconButton
        aria-label="Dislike video"
        aria-pressed={isDislike}
        onClick={() => onClick(isDislike ? null : false)}
        backgroundColor="strive.card"
        color="strive.button.icon.fg"
        borderLeftRadius="0"
        borderRightRadius="50vh"
        shadow="md"
        _hover={{
          background: 'strive.card',
        }}
        sx={{
          '&[aria-pressed=true]': {
            color: '#f42c2c',
            backgroundColor: '#603131',
          },
        }}
      >
        <ThumbsDownIcon />
      </IconButton>
    </Box>
  );
}
