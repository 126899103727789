import React, { createContext, useContext, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Box, BoxProps, useCallbackRef } from '@chakra-ui/react';

type ContainerState = {
  statusBar?: {
    style?: 'light' | 'dark';
    color: string;
  };
  setStatusBar?: (options: ContainerState['statusBar']) => void;
  resetStatusBar?: () => void;
};

const ScreenContainerContext = createContext<ContainerState>({});

export const useScreenContainer = () => useContext(ScreenContainerContext);

export function ScreenContainer({ children, ...props }: BoxProps) {
  const [statusBar, setStatusBar] = useState<ContainerState['statusBar']>();

  const resetStatusBar = useCallbackRef(() => setStatusBar(undefined));

  return (
    <ScreenContainerContext.Provider
      value={{ statusBar, setStatusBar, resetStatusBar }}
    >
      <ScreenContainerView {...props}>{children}</ScreenContainerView>
    </ScreenContainerContext.Provider>
  );
}

function ScreenContainerView({ children, ...props }: BoxProps) {
  const vars = {
    '--safe-screen-height':
      'calc(100 * var(--vh, 1vh) - env(safe-area-inset-top))',
  } as React.CSSProperties;

  const { statusBar } = useScreenContainer();
  const statusBarStyle = statusBar?.style;

  useEffect(() => {
    if (!Capacitor.isPluginAvailable('StatusBar')) return;

    StatusBar.setStyle({
      style:
        statusBarStyle === 'dark'
          ? Style.Dark
          : statusBarStyle === 'light'
          ? Style.Light
          : Style.Default,
    });
  }, [statusBarStyle]);
  return (
    <Box
      paddingTop="env(safe-area-inset-top)"
      h="calc(100 * var(--vh, 1vh))"
      maxHeight="calc(100 * var(--vh, 1vh))"
      style={vars}
      {...props}
    >
      {children}
      <Box
        top="0"
        w="full"
        height="env(safe-area-inset-top)"
        position="fixed"
        bg={statusBar?.color || 'strive.statusbar'}
      />
    </Box>
  );
}
