import { useActor } from '@xstate/react';
import { interpret, InterpreterFrom } from 'xstate';
import create from 'zustand';

import { AuthResponse } from '@arena-labs/shared-models';

import { authMachine } from './auth-service.machine';

type AuthServiceType = InterpreterFrom<typeof authMachine>;
type AuthStore = {
  service: AuthServiceType;
  setService: (service: AuthServiceType) => void;
};

/**
 * A data store for the Auth service.
 */
const useStore = create<AuthStore>()((set, get) => ({
  service: interpret(authMachine, {
    parent: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      send: () => ({}),
    },
  }),
  setService: (service) => {
    get().service.stop?.();
    set(() => ({ service }));
  },
}));

/** Get the current auth service */
export function getAuthService() {
  return useStore.getState().service;
}

/** Set the current auth service */
export function setAuthService(service: AuthServiceType) {
  return useStore.getState().setService(service);
}

//? React hooks

/** React hook to get the service */
export function useAuthService() {
  return useStore((state) => state.service);
}

/** A react hook to subscribe to the Auth service. */
export function useAuthState() {
  return useActor(useAuthService());
}

//? The following functions are intended for use **OUTSIDE** the react lifecycle
//? E.g. from the auth-client

/**
 * Set the auth tokens
 */
export function setAuthTokens(tokens: AuthResponse) {
  getAuthService().send({ type: 'Refresh', data: tokens });
}

let tokensOverride: AuthResponse | null = null;
export function setAuthTokensOverride(tokens: AuthResponse | null) {
  tokensOverride = tokens;
}

/**
 * Get the current auth tokens
 */
export function getAuthTokens() {
  if (tokensOverride) {
    return tokensOverride;
  }

  const { accessToken, refreshToken } =
    getAuthService().getSnapshot().context ?? {};
  return accessToken && refreshToken
    ? {
        access: accessToken,
        refresh: refreshToken,
      }
    : null;
}

/**
 * Get the current user id
 */
export function getUserId() {
  const { userId } = getAuthService().getSnapshot().context ?? {};
  return userId;
}

/**
 * Trigger logout
 */
export function expireAuthSession() {
  getAuthService().send('Log out');
}

/**
 * Is the user logged in?
 */
export function isLoggedIn() {
  return Boolean(getAuthService().state.matches('Logged in'));
}
