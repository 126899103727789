import { z } from 'zod';

import { useDataStoreQuery } from '@strive/utils';

export function useCompHubNewPref() {
  const [knowsAboutHub, setKnowsAboutHub] = useDataStoreQuery({
    key: 'Competition.KnowsAboutHub',
    schema: z.boolean(),
    fallback: false,
  });
  return [knowsAboutHub.data, setKnowsAboutHub.mutate] as const;
}
