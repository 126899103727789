import { Button, Grid, Text } from '@chakra-ui/react';
import create from 'zustand';

import { ChevronRightIcon, CloseIcon, Icon } from '@arena-labs/strive2-ui';
import { useStriveEnvironments } from '@strive/api';

import { AdminModal } from './admin-modal';

/**
 * A data store for the Auth service.
 */
type AdminDashboardStore = {
  isActive: boolean;
  activateAdmin: () => void;
  deactivateAdmin: () => void;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};
export const useAdminDashboard = create<AdminDashboardStore>()((set, get) => ({
  isActive: false,
  activateAdmin: () => set({ isActive: true }),
  deactivateAdmin: () => set({ isActive: false, isOpen: false }),
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set({ isOpen: !get().isOpen }),
}));

export function AdminDashboard() {
  const { isActive, deactivateAdmin, isOpen, open, close } =
    useAdminDashboard();
  const [env, envName] = useStriveEnvironments((state) => [
    state.current,
    state.getEnvironment()?.name ?? state.current,
  ]);

  // Force this to be visible if the current environment is not default
  const isMandatory = env !== 'default';
  if (!isActive && !isMandatory) {
    return null;
  }

  return (
    <>
      <Grid
        templateColumns="auto 1fr auto"
        bg="strive.card5.bg"
        pt="2"
        px="4"
        pb="max(env(safe-area-inset-bottom, 0) / 2, 8px)"
        gap="2"
      >
        {isMandatory ? (
          <div />
        ) : (
          <Button variant="inline" onClick={deactivateAdmin}>
            <Icon as={CloseIcon} boxSize="4" />
          </Button>
        )}
        <Text>ENV: {envName.toUpperCase()}</Text>
        <Button variant="inline" onClick={open}>
          expand
          <Icon
            as={ChevronRightIcon}
            boxSize="3"
            transform="rotate(-90deg)"
            ml="2"
          />
        </Button>
      </Grid>
      <AdminModal isOpen={isOpen} onClose={close} />
    </>
  );
}
