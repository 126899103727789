import { SVGProps, useId } from 'react';
import { Button, ButtonProps, chakra, keyframes } from '@chakra-ui/react';
import { omitBy, pickBy } from 'lodash-es';

import { ZStack, ZStackProps } from '@arena-labs/strive2-ui';

type ButtonEventProp = `aria-${string}` | `on${string}`;

function isButtonProp(prop: string): prop is ButtonEventProp {
  return prop.startsWith('aria-') || prop.startsWith('on');
}

export type ACIButtonProps = ZStackProps & {
  animate?: boolean;
};

export function ACIButton({ animate, ...props }: ACIButtonProps) {
  const buttonProps = pickBy(props, (_, key) =>
    isButtonProp(key),
  ) as ButtonProps;
  const restProps = omitBy(props, (_, key) => isButtonProp(key)) as ZStackProps;

  const iconSize =
    restProps.boxSize ||
    restProps.width ||
    restProps.height ||
    restProps.h ||
    restProps.w
      ? { width: '100%', height: '100%' }
      : {};
  return (
    <ZStack {...restProps}>
      <ACIIcon animate={animate} {...iconSize} />
      <Button
        variant="unstyled"
        overflow="hidden"
        width="55%"
        h="55%"
        borderRadius="full"
        placeSelf="center"
        display="block"
        aria-label="Check-in now"
        {...buttonProps}
      ></Button>
    </ZStack>
  );
}

export type ACIIconProps = SVGProps<SVGSVGElement> & {
  title?: string;
  titleId?: string;
  desc?: string;
  descId?: string;
  animate?: boolean;
  rings?: boolean;
};

const animateRing = keyframes`
    0% {
        fill-opacity: 0.02;
        stroke-opacity: var(--strokeOpacity, 0.2);
    }
    25% {
        fill-opacity: 0.08;
        stroke-opacity: 1;
    }
    50% {
        fill-opacity: 0.02;
        stroke-opacity: var(--strokeOpacity, 0.2);
    }
    75% {
        fill-opacity: 0.02;
        stroke-opacity: var(--strokeOpacity, 0.2);
    }
    100% {
        fill-opacity: 0.02;
        stroke-opacity: var(--strokeOpacity, 0.2);
    }
`;

export function ACIIcon({
  title,
  titleId,
  desc,
  descId,
  animate,
  rings = true,
  ...props
}: ACIIconProps) {
  const id = useId();
  const getId = (...parts: Array<number | string>) =>
    `${id}-${parts.join('-')}`;

  const animationDuration = 2.5;
  const getRingProps = ({
    ring,
    radius,
  }: {
    ring: number;
    radius: number;
  }) => ({
    cx: 108,
    cy: 108,
    r: radius,
    id: getId('ring', ring),
    fill: '#3597B0',
    fillOpacity: 0.02,
    stroke: `url(#${getId('ring', ring, 'linear')})`,
    strokeWidth: 2,
    strokeOpacity: 'var(--strokeOpacity)',
    '--strokeOpacity': [0, 0.6, 0.4, 0.2][ring],
    ...(animate && {
      animation: `${animateRing} ${animationDuration}s linear infinite`,
      animationDelay: `${ring * (animationDuration / 8)}s`,
    }),
  });

  const viewBox = rings ? '0 0 216 216' : '40 40 136 136';

  return (
    <svg
      width={216}
      height={216}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      aria-describedby={descId}
      {...props}
    >
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      {rings ? (
        <>
          <chakra.circle sx={getRingProps({ ring: 3, radius: 107 })} />
          <chakra.circle sx={getRingProps({ ring: 2, radius: 91 })} />
          <chakra.circle sx={getRingProps({ ring: 1, radius: 75 })} />
        </>
      ) : null}
      <g id={getId('cta')}>
        <g
          id={getId('cta-button')}
          filter={`url(#${getId('cta-button-shadow')})`}
        >
          <circle
            cx={108}
            cy={108}
            r={60}
            fill={`url(#${getId('cta-button-fill')})`}
          />
        </g>
        <path
          id={getId('cta-button-icon')}
          d="M105.187 127.687V110.812H88.3125V105.187H105.187V88.3125H110.812V105.187H127.687V110.812H110.812V127.687H105.187Z"
          fill="#95FDFD"
        />
      </g>
      <defs>
        {/*
            This filter creates a complex drop shadow effect by layering multiple
            drop shadows with varying opacities, offsets, and blur radii.
            The result is a more visually appealing and sophisticated shadow effect
            for the SVG element it is applied to.
        */}
        <filter
          id={getId('cta-button-shadow')}
          x={34}
          y={37}
          width={148}
          height={149}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={2.5} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_10840"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={7} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_770_10840"
            result="effect2_dropShadow_770_10840"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={8} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_770_10840"
            result="effect3_dropShadow_770_10840"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_770_10840"
            result="shape"
          />
        </filter>
        <linearGradient
          id={getId('ring-3-linear')}
          x1={108}
          y1={0}
          x2={108}
          y2={216}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#315B66" />
          <stop offset={1} stopColor="#10272D" />
        </linearGradient>
        <linearGradient
          id={getId('ring-2-linear')}
          x1={108}
          y1={16}
          x2={108}
          y2={200}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#315B66" />
          <stop offset={1} stopColor="#10272D" />
        </linearGradient>
        <linearGradient
          id={getId('ring-1-linear')}
          x1={108}
          y1={32}
          x2={108}
          y2={184}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#315B66" />
          <stop offset={1} stopColor="#10272D" />
        </linearGradient>
        <linearGradient
          id={getId('cta-button-fill')}
          x1={108}
          y1={48}
          x2={108}
          y2={168}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#427B8A" />
          <stop offset={1} stopColor="#193A43" />
        </linearGradient>
      </defs>
    </svg>
  );
}
