import { Button, SimpleGrid } from '@chakra-ui/react';

import { toast } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

export function AdminTestPush() {
  const testNotification = $API.useSendTestNotification(
    {},
    {
      onError: () => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Something went wrong while sending the notification',
        });
      },
    },
  );

  const send = (type: string) => () => testNotification.mutate({ type });

  const buttonProps = {
    variant: 'outline',
    h: 'auto',
    minH: 16,
    py: '2',
    sx: {
      whiteSpace: 'normal',
    },
    size: 'sm',
  };

  return (
    <SimpleGrid columns={2} gap="2">
      <Button onClick={send('aci_reminder')} {...buttonProps}>
        ⏰ ACI Reminder
      </Button>
      <Button onClick={send('streak_reminder')} {...buttonProps}>
        ⏲️ Streak Reminder
      </Button>
      <Button onClick={send('broken_streak')} {...buttonProps}>
        🤕 Broken Streak
      </Button>
      <Button onClick={send('device_connection_lost')} {...buttonProps}>
        ⚡️ Wearable Connection Lost
      </Button>
      <Button onClick={send('finish_daily_lesson_reminder')} {...buttonProps}>
        📚 Finish Lesson Reminder
      </Button>
    </SimpleGrid>
  );
}
