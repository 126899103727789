import { createContext, useContext, useMemo } from 'react';

import { AnalyticsClient, TrackingEventContext } from '.';

/**
 * React context initialized from environment variables
 * NEXT_PUBLIC_ANALYTICS_TAGS_ALLOWED & NEXT_PUBLIC_ANALYTICS_TAGS_BLOCKED
 * are strings in the form:
 * tag1,tag2,tag3|tag4,tag5|tag6,tag7
 *
 * This example shows 3 sets of tags. Each set must have all its tags present in order
 * to effectuate the allow/block.
 *
 * Blocking rules apply first, at which point the block can be reversed it contains
 * matching tags in the allow list
 */
const rootClient = new AnalyticsClient({
  isEnabled: Boolean(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY),
});
const AnalyticsClientContext = createContext<AnalyticsClient>(rootClient);
/**
 * Hook to pull the current analytics client from the react context.
 * Optionally applies a custom context to the analytics client before returning it.
 */
export function useAnalytics(context: Partial<TrackingEventContext> = {}) {
  const parentClient = useContext(AnalyticsClientContext);
  const client = useMemo(() => parentClient.createChild(), [parentClient]);
  client.context = context;

  return client;
}

/**
 * Provider component to allow you to attach custom context into the react component tree
 */
export type AnalyticsProps = {
  children: React.ReactNode;
  parent?: AnalyticsClient | null;
  context?: Partial<TrackingEventContext>;
};

export function AnalyticsContext({
  context = {},
  parent,
  children,
}: AnalyticsProps) {
  const analytics = useAnalytics(context);

  return (
    <AnalyticsClientContext.Provider value={parent ?? analytics}>
      {children}
    </AnalyticsClientContext.Provider>
  );
}
