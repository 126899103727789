import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
  Heading,
  VStack,
} from '@chakra-ui/react';

import { splitParagraphs, SwipeableDrawerBody } from '@arena-labs/strive2-ui';

export type TranscriptPopupProps = {
  transcript: string;
} & Omit<DrawerProps, 'children' | 'orientation'>;

export function TranscriptPopup({
  transcript,
  ...props
}: TranscriptPopupProps) {
  return (
    <Drawer placement="bottom" variant="containerless" {...props}>
      <DrawerContent height="40vh">
        <DrawerHeader paddingRight="48px">
          <Heading as="h3" fontSize="lg">
            Transcript
          </Heading>
          <DrawerCloseButton />
        </DrawerHeader>

        <SwipeableDrawerBody onSwipe={props.onClose}>
          <VStack spacing="4">{splitParagraphs(transcript)}</VStack>
        </SwipeableDrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
