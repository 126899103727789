import { stripIndent } from 'common-tags';

export const StriveDBConfig = {
  database: 'strive',
  encrypted: false,
  mode: 'full',
  version: 1,
};

export const StriveSQLMigrations = [
  {
    toVersion: 1,
    statements: [
      stripIndent`
        CREATE TABLE data_store (
          key TEXT PRIMARY KEY NOT NULL,
          value TEXT,
          version TEXT,
          created_at INTEGER DEFAULT (strftime('%s', 'now')),
          updated_at INTEGER DEFAULT (strftime('%s', 'now'))
        );
      `,

      stripIndent`
        CREATE TRIGGER update_last_modified_update
        AFTER UPDATE ON data_store
        BEGIN
          UPDATE data_store
          SET updated_at = strftime('%s','now')
          WHERE key = OLD.key;
        END;
      `,
    ],
  },
] satisfies Array<{ toVersion: number; statements: string[] }>;
