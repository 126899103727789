import { assign, createMachine } from 'xstate';

import { LoginResponse } from '@arena-labs/shared-models';

type VerifySMSContextType<RequestMeta> = {
  recipient: string | null;
  code: string | null;
  authTokens: LoginResponse | null;
  error: unknown;
  meta?: RequestMeta;
};
type VerifySMSEventType<RequestMeta> =
  | { type: 'Start'; data: string; meta?: RequestMeta }
  | { type: 'Resend' }
  | { type: 'Submit'; code: string }
  | { type: 'Start again' };
type VerifySMSServiceType = {
  startVerification: { data: null | undefined | void };
  verifyCode: { data: LoginResponse | null };
};

/**
 * Factory to create SMS verify machines
 *
 * @param meta Optional additional data to track as part of the SMS verification process
 */
export const createVerifySmsMachine = <RequestMeta>(meta?: RequestMeta) =>
  /** @xstate-layout N4IgpgJg5mDOIC5QGUCyyAEA1MAnAlgGb4DGAhgC74D2AdgHQCSEANmAMTIVm4WKgAHarHxU6-EAA9EAdgAcARnpyATAvkBmORoCsegCwAaEAE9E+jUoBsVldrsBOHbZkKAvm+NpMOAsXJiDL5EJvi0UBjeTLQAbtQA1mERAIIACozsEHRg9GFx8Tne2HhEpJQ0QSWEoeGR6NH5SRhpjAh51AEVANoADAC6EkIigRLSCPoOPfQqPfp22gpyDpMOVsZmCA4KDvTOOiryCj0aVj1yHl7oxX5lgfTB1U1RjLEJTS3seLjUuPQCLJRCD8ALb0IoPW4Ve5VGoRZ6vRK1FptV6dOi9AZIEBDUQVUaIDTLaY6RQqKwKKwaQk9Namcw9FT0KyuBwyfQ2BT7E4XEDgqqQujQvywurIegAdTIuPC7AASnAwLQIINhLjxFixho7LsZDJnHIZDSVAZ1ogHNqeiSFAcdD0thoZBoeXybmjKsKnvVJdKoJwAK4AI2BohVwzxGsQqn09H0+jkcn0+yOdqsOlNCGtJyZCi0VijVljPRkzquELdQpCnrFD1hmWyuQRhVL-PLNarFce4RRcTdGNDato+IzEyUhp0DkUywmdlpG2t5PoRwcWontpkKljJZ8LfKgrbtSi+99Xx+fwBFCBuFBLtKrZh7aP3Y6u9ofaxOJGEYQch6Sn0hpObQ9TzbR0xzOZF1-OQ80sckfysLdrlvF8OxFbxOG4XgMDIKApUHd9VU-UAxiONliWg39dWcM4ZHTCxrFsewlmcA53B5WhqAgOAJBvfwUOYNh+yIqRECsc1Fwddci1sf8dFoukEBUIkSScNRTjOM4dEQssUKPUUGjeJF0iE8NiIJbYJIUCx410KzWXkjYQOmf9VE5RQdF0BDPF5ZtXV0+8Dy9KUqHCaJcTIFgTPVMyEA0dl6F0FdXGZOwZAcMCDhkegzntVMDVUc5vN4gV3UrQKxW9EKoHoAAxKU2GVAiw2ikSED0JRVFss4yQTHQjAU61dWypYKR0RZlw8rSit85C7j0qJKqSKL8JirVGUSpTkvXA10oGqyrEXUaTkpFQtFWbSdzmgK4XqI9lqHI41EXfRfzmHMHVOWdEGtONsonSwFCOW19EWC6-Kuj1yvur8lJ0SzrO0MaJl1dNnGjA5c32U7E1-MHZqhCFIGhmKrLk6YTnHQlbHUcl02XRkZFsMS9TOVNWTxvjhI-UzWtY+GtERuyUYGvNszzZmnEB8diw8NwgA */
  createMachine(
    {
      tsTypes: {} as import('./verify-sms.machine.typegen').Typegen0,
      schema: {
        context: {} as VerifySMSContextType<RequestMeta>,
        events: {} as VerifySMSEventType<RequestMeta>,
        services: {} as VerifySMSServiceType,
      },
      context: {
        recipient: null,
        code: null,
        authTokens: null,
        error: null,
        meta,
      },
      id: 'SMS Verification',
      initial: 'Idle',
      description:
        'Verify that a user is in possession of the SMS number they say they have',
      predictableActionArguments: true,

      states: {
        Idle: {
          description: 'Waiting for the user to enter their SMS number',
          on: {
            Start: {
              actions: 'setRecipient',
              target: 'Verifying SMS',
            },
          },
        },
        'Verifying SMS': {
          entry: 'clearError',
          initial: 'Invoking API',
          states: {
            'Invoking API': {
              description:
                "Submit the SMS number to the backend which triggers an SMS to be sent to the user's device",
              invoke: {
                src: 'startVerification',
                onDone: [
                  {
                    description: 'An SMS has been sent to the user',
                    target: 'Waiting',
                  },
                ],
                onError: [
                  {
                    actions: 'setError',
                    description:
                      "Error, maybe the SMS number wasn't recognized",
                    target: '#SMS Verification.Idle',
                  },
                ],
              },
            },
            Waiting: {
              description:
                'Wait for the user to enter the code from their SMS messages',
              initial: 'Initial',
              states: {
                Initial: {},
                Failed: {
                  entry: 'resetCode',
                },
              },
              on: {
                Resend: {
                  description: 'Send another SMS',
                  target: 'Invoking API',
                },
                Submit: {
                  actions: 'saveCode',
                  target: 'Verifying',
                },
              },
            },
            Verifying: {
              description:
                'Send the code to the API and wait for verification success/failure',
              invoke: {
                src: 'verifyCode',
                onDone: [
                  {
                    actions: 'setAuthTokens',
                    description: 'A valid code was submitted',
                    target: '#SMS Verification.Verified',
                  },
                ],
                onError: [
                  {
                    target: '#SMS Verification.Verifying SMS.Waiting.Failed',
                  },
                ],
              },
            },
          },
          on: {
            'Start again': {
              actions: 'resetCode',
              target: 'Idle',
            },
          },
        },
        Verified: {
          entry: 'onComplete',
          description: 'Final state: user verified their SMS',
          type: 'final',
        },
      },
    },
    {
      actions: {
        setRecipient: assign({
          recipient: (ctx, event) => event.data,
          meta: (ctx, event) => event.meta,
        }),
        setAuthTokens: assign({
          authTokens: (ctx, event) => event.data,
        }),
        saveCode: assign({
          code: (ctx, event) => event.code,
        }),
        resetCode: assign({
          code: (ctx) => null,
        }),
        setError: assign({
          error: (ctx, event) => event.data,
        }),
        clearError: assign({
          error: (ctx, event) => null,
        }),
        onComplete: (ctx, evt) => null,
      },
    },
  );

// Export a simple machine that doesn't need additional `meta` data
export const verifySmsMachine = createVerifySmsMachine();
