import tokenizer from 'sbd';

/**
 * Attempt to truncate text to an ideal length on a complete sentence.
 * Failing that, truncate to an ideal length on a word.
 */
export function truncate(
  text: string,
  idealLength: number,
  margin = 0,
): string {
  if (text.length < idealLength) {
    return text;
  }

  // Try to find a logical break between minLength & idealLength
  const minLength = idealLength - margin;
  const maxLength = idealLength + margin;

  // Break text into sentences
  const sentences = tokenizer.sentences(text, {
    preserve_whitespace: true,
  });

  // Build a list of candidate which include whole sentences which
  // add up to somewhere between minLength & maxLength
  const candidates = [];
  let current = '';
  for (const sentence of sentences) {
    if (current === '') {
      current = sentence;
    } else {
      current = current + sentence;
    }
    if (current.length > maxLength) {
      break;
    }
    if (current.length >= minLength) {
      candidates.push(current);
    }
  }

  // No sentence break between minLength and maxLength
  // So just truncate to the end of the word closest to `idealLength`
  if (!candidates[0]) {
    const idealCutOff = new RegExp(`^(.{${idealLength}}\\S*).*`);
    const truncated = current.replace(idealCutOff, '$1');
    return truncated + '...';
  }

  // Find the item in candidates array which is closest in length to idealLength
  let closest = candidates[0];
  for (const candidate of candidates) {
    if (
      Math.abs(idealLength - candidate.length) <
      Math.abs(idealLength - closest.length)
    ) {
      closest = candidate;
    }
  }

  if (closest === text) {
    return text;
  }
  return closest.replace(/\.\s*$/, '') + '...';
}
