export const shadows = {
  '0dp': `
        0px 4px 4px 0px #00000040
    `,
  '1dp': `
        0px 1px 3px 0px #00000033,
        0px 2px 1px 0px #0000001F,
        0px 1px 1px 0px #00000024
    `,
  '2dp': `
        0px 1px 5px 0px #00000033,
        0px 3px 1px 0px #0000001F,
        0px 2px 2px 0px #00000024
    `,
  '3dp': `
        0px 1px 8px 0px #00000033,
        0px 3px 3px 0px #0000001F,
        0px 3px 4px 0px #00000024
    `,
  // TODO: Temporary fix for incorrect 4dp shadow - waiting for Kai to advise on preferred values
  '4dp': `
        0px 2px 4px 0px #00000033,
        0px 1px 10px 0px #0000001F,
        0px 4px 5px 0px #00000024
    `,
  '6dp': `
        0px 3px 5px 0px #00000033,
        0px 1px 18px 0px #0000001F,
        0px 6px 10px 0px #00000024
    `,
  '8dp': `
        0px 5px 5px 0px #00000033,
        0px 3px 14px 0px #0000001F,
        0px 8px 10px 0px #00000024
    `,
  '12dp': `
        0px 7px 8px 0px #00000033,
        0px 5px 22px 0px #0000001F,
        0px 12px 17px 0px #00000024
    `,
  '16dp': `
        0px 8px 10px 0px #00000033,
        0px 6px 30px 0px #0000001F,
        0px 16px 24px 0px #00000024
    `,
  '24dp': `
        0px 24px 38px rgba(0, 0, 0, 0.14),
        0px 9px 46px rgba(0, 0, 0, 0.12),
        0px 11px 15px rgba(0, 0, 0, 0.2)
    `,
};
