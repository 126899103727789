import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { isValidMotionProp, motion, MotionProps } from 'framer-motion';

const ChakraMotionBox = chakra(motion.div, {
  shouldForwardProp: (propName: string) =>
    isValidMotionProp(propName) || shouldForwardProp(propName),
});

type ChakraMotionBoxProps = React.ComponentPropsWithRef<typeof ChakraMotionBox>;

// We need to override some core Chakra types to preserve framer-motion behavior
export type MotionBoxProps = Omit<ChakraMotionBoxProps, 'transition' | 'as'> & {
  // We need to override the default Chakra `transition` prop with the
  // framer-motion `Transition` type
  transition?: MotionProps['transition'];

  // We can use the `as` feature in Chakra to render a different element
  // But to preserve the motion functionality we need to accept an element name
  // supported by framer-motion
  as?: keyof typeof motion;
};

export function MotionBox({
  transition,
  as = 'div',
  ...props
}: MotionBoxProps) {
  // Map the `as` element name to a framer-motion component
  const MotionElement = motion[as];

  return (
    <ChakraMotionBox
      as={MotionElement}
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - the generated Chakra type for transition conflicts with the framer-motion type
      transition={transition}
    />
  );
}
