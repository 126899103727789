import { Flex, Grid } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';

import { LoginResponse } from '@arena-labs/shared-models';
import {
  ChevronLeftIcon,
  Icon,
  MotionBox,
  TEXT,
  useBackButtonWithFallback,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { createLoginCode, verifyLoginCode } from '@strive/api';
import { assert, useDataStoreQuery } from '@strive/utils';

import { LoginForm } from '../components/login-form';
import { OtpForm } from '../components/otp-form';
import { lastLoginIdStore } from '../lib/last-login-id';
import { verifySmsMachine } from '../lib/verify-sms.machine';

type Authenticate = {
  onComplete: (tokens: LoginResponse) => void;
};

export function Authenticate({ onComplete }: Authenticate) {
  const [lastLoginId, setLastLoginId] = useDataStoreQuery(lastLoginIdStore);
  const [state, send, service] = useMachine(verifySmsMachine, {
    devTools: true,
    services: {
      startVerification: (ctx) => createLoginCode(ctx.recipient ?? ''),
      verifyCode: (ctx, event) =>
        verifyLoginCode(ctx.recipient ?? '', event.code),
    },
    actions: {
      onComplete: (ctx) => {
        assert(ctx.authTokens);
        setLastLoginId.mutate(ctx.recipient);
        onComplete(ctx.authTokens);
      },
    },
  });

  const goBack = useBackButtonWithFallback('/log-in');

  return (
    <MotionBox
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: '1',
      }}
      style={{
        height: '100%',
      }}
      initial={{ opacity: 0 }}
    >
      <Flex direction={'column'} gap={4} h={'full'} w={'full'} pb={'8'}>
        <Grid
          px="6"
          py="3"
          shadow="4dp"
          w="full"
          templateColumns={'40px 1fr 40px'}
          alignItems={'center'}
          textAlign={'center'}
          color={'logo'}
        >
          <Flex
            as={'button'}
            gridColumn={1}
            onClick={() => {
              if (state.matches('Verifying SMS')) {
                send('Start again');
              } else {
                goBack();
              }
            }}
          >
            <Icon as={ChevronLeftIcon} />
          </Flex>
          <TEXT.H3 gridColumn={2} aria-colspan={1}>
            Sign In
          </TEXT.H3>
        </Grid>

        <Flex px={6} direction={'column'} gap={6} h={'full'}>
          {state.matches('Idle') ? (
            <WaitForQuery query={lastLoginId}>
              {(phone) => (
                <LoginForm
                  defaultPhone={phone}
                  onSubmit={(phone) => send({ type: 'Start', data: phone })}
                  error={state.context.error}
                />
              )}
            </WaitForQuery>
          ) : state.matches('Verifying SMS') ? (
            <MotionBox
              animate={{ opacity: [0, 1] }}
              transition={{
                duration: '1',
              }}
              style={{
                height: '100%',
              }}
              initial={{ opacity: 0 }}
            >
              <OtpForm service={service} buttonLabel="Login" />
            </MotionBox>
          ) : null}
        </Flex>
      </Flex>
    </MotionBox>
  );
}
