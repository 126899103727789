import { $API, useIsLoggedIn } from '@strive/api';

import { isWearable } from './types';

/**
 * React-Query selector to get the wearable mode
 */
export function useWearableMode() {
  const isLoggedIn = useIsLoggedIn();
  return $API.useGetUserProfile(
    {},
    {
      select: (user) => {
        const wearable = user.profile.wearable?.[0];
        if (isWearable.Striveware(wearable)) {
          return { mode: 'striveware', wearable } as const;
        } else if (isWearable.Partner(wearable)) {
          return { mode: 'partner', wearable } as const;
        }
        return { mode: 'sensorless', wearable: undefined } as const;
      },
      enabled: isLoggedIn,
    },
  );
}
