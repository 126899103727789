import { useMemo } from 'react';
import NextLink from 'next/link';
import { Box, Flex, Grid, LinkBox, LinkOverlay } from '@chakra-ui/react';

import { Elevation, NoBreakText, TEXT, truncate } from '@arena-labs/strive2-ui';

import { Program } from '../../api/programs';
import { ProgramIcon } from './program-icon';
import { ProgramProgressBar } from './program-progress-bar';

export type ProgramCardProps = {
  program: Program;
  href: string;
};

export function ProgramCard({ program, href }: ProgramCardProps) {
  const description = useMemo(
    () => truncate(program.description, 100, 20),
    [program.description],
  );

  return (
    <Elevation
      as={Flex}
      gap={1}
      direction={'column'}
      shadow="2dp"
      borderRadius="card"
      py="4"
      px="3"
      level={'2dp'}
    >
      <LinkBox as="article">
        <Grid gap={3} templateColumns="auto 1fr auto">
          <ProgramIcon program={program} boxSize="8" />
          <Flex direction="column" gap={1}>
            <LinkOverlay as={NextLink} href={href}>
              <TEXT.P2 textTransform={'uppercase'}>
                <NoBreakText as="span" text={program.title} />
              </TEXT.P2>
            </LinkOverlay>

            <TEXT.P3 color="neutral.500" lineHeight={'small'}>
              {description}
            </TEXT.P3>
          </Flex>
        </Grid>
        <Box>
          <ProgramProgressBar program={program} mt="2" bg="neutral.white" />
          <TEXT.P3 color="neutral.500" mt="1.5">
            {program.percent_complete
              ? `Program ${Math.round(program.percent_complete)}% complete`
              : 'Program not started'}
          </TEXT.P3>
        </Box>
      </LinkBox>
    </Elevation>
  );
}
