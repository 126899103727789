// Define the hook with generic types for better type inference and checking

import { useEffect } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import {
  ActorRef,
  ContextFrom,
  DoneEvent,
  EventFrom,
  Interpreter,
} from 'xstate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useXstateDoneEffect<TActor extends ActorRef<any, any>>(
  service: TActor,
  onDoneCallback: (context: ContextFrom<TActor>, event: DoneEvent) => void,
) {
  const stableOnDoneCallback = useCallbackRef(onDoneCallback);

  useEffect(() => {
    // Subscribe to the done event
    (
      service as unknown as Interpreter<
        ContextFrom<TActor>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        EventFrom<TActor>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any
      >
    ).onDone((event) => {
      // The 'event' here is technically a DoneInvokeEvent, but for simplicity, treat it as TEvent
      stableOnDoneCallback(service.getSnapshot().context, event);
    });
  }, [service, stableOnDoneCallback]);
}
