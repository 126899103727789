import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { assign, createMachine } from 'xstate';

import { AuthTokenStore, queryClient } from '@strive/api';

import { useCapgoUpdateStore } from '../version-update/capgo-update.machine';

const queryParam = `_recoverStep`;

const steps = {
  CUSTOM: 'custom',
  RELOAD: 'reload',
  CLEAR_CACHE: 'clearCache',
  LOGOUT: 'logout',
  HELP: 'help',
} as const;

const stepTransitions = {
  [steps.CUSTOM]: steps.RELOAD,
  [steps.RELOAD]: steps.CLEAR_CACHE,
  [steps.CLEAR_CACHE]: steps.LOGOUT,
  [steps.LOGOUT]: steps.HELP,
  [steps.HELP]: steps.HELP,
};

type RecoverStep = (typeof steps)[keyof typeof steps];

export const errorRecoveryMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5RgE4oPYoEpgMboDdUBPAOgjwEsKBiAbQAYBdRUAB3VkoBdL0A7ViAAeiAIwB2BqQBMAVjkyAzBIAsqmQA4AbAxkyANCGKIZe0krEBObZq36tczQF9nR1Bmx5CJclVp0YixIIBxcvAJCogiS0vKKKupauvpGJghKMqqyDFZ6DJkyeRJZru5omDj4RChkFLjUYPQyweycPHyCIdGxsgrKaho6eobGiOrapLmaltoy2tZ2MmXgFV7VvvWN9Eqtoe0RXaA9Un0Jg8kjaYhy2hKkVpoSt1ZyEkp2VlYrHpXeNXV-E06Ko9mEOpFuuJTvEBklhqkxgg5pNVBIJGJbFYJNiJLYfmsqj5aqQADboACGEEo-CgNGEsG4FO4YFIFIAZiyUAAKMwMBgAShov3WxLI5KpNKgjDBB06UUQcyUDxkUjkj0kYh0SmuCE0Ygs+isYgY6tyOO0SgJniJANIuAAroz0ABbGhYACiAGEAPIANQ9WBlQnBhwVCDkqk0DzEqlN8kx2m0qisusx2QKcljmgYOh0cgYqmtfw2JJQYAlEHd3v9geDIVD8qhEajMbjiizSeTqaRjhV6i+6jESiUcitblWNv+vlwpLAFJQXopuAAFk1Pb6A0HmCG5ZDjjdW8b2wmuyndXH7vqPgWcU5TZHi6K7eSoOgHdxq5u6zuG3ujiIh7Rse8adkm55IooVikFISi5MO1hRlmrgTvw6AUPAIQiraJC7uETYHggAC02i6iRUz8pRVGUaOchPjhJJbBQeEQgB0QaBeSjQWIMhiE4VgfHMeRvPR04kpWUosWGzbopMWaYq8EyqB8qi6v0shcXiMw4jMWqiaWZCOs6LpSQRgEIBoGaWHBha3E4sm6nyGkFlk6pcXcDASPpYqkOWlamfu5lDBYqhiIhqhJghya6oUpA8cpmhPEoSamqodETthYmGXOC5LquYABWx4w8RYyZKlqaUzHIF7qFMw7aF86qKLk3wZYSWVkugb4foV4YaPcNi2Ji95jnxqmQdocgPHojVjkMKbeXaa6kmwvXNqFMilRFygVU4o66nM2RcfIKnqnxIkoUAA */
    id: 'errorRecovery',
    tsTypes: {} as import('./error-recovery.machine.typegen').Typegen0,
    schema: {
      context: {} as { step: RecoverStep; onRecover?: () => void },
      events: {} as { type: 'RECOVER' } | { type: 'RESET' },
      services: {} as { 'Check Update Availability': { data: boolean } },
    },
    initial: 'decide',
    context: {
      step: getRecoverStep(),
      onRecover: undefined,
    },
    states: {
      decide: {
        always: [
          { target: 'custom', cond: 'shouldRunCustom' },
          { target: 'reload', cond: 'shouldReload' },
          { target: 'clearCache', cond: 'shouldClearCache' },
          { target: 'logout', cond: 'shouldLogout' },
          { target: 'help' },
        ],
      },

      loading: {
        after: {
          2000: 'decide',
        },
      },

      custom: {
        on: {
          RECOVER: {
            actions: ['incrementStep', 'runCustomRecovery'],
            target: 'loading',
          },
        },
      },

      reload: {
        on: {
          RECOVER: {
            actions: ['incrementStep', 'reloadApp'],
            target: 'loading',
          },
        },
      },

      clearCache: {
        on: {
          RECOVER: {
            actions: ['incrementStep', 'clearAppCache'],
            target: 'loading',
          },
        },
      },

      logout: {
        on: {
          RECOVER: {
            actions: ['incrementStep', 'logoutUser'],
            target: 'loading',
          },
        },
      },

      help: {
        on: {
          RESET: {
            actions: ['reset'],
            target: 'decide',
          },
        },
      },
    },
  },
  {
    guards: {
      shouldRunCustom: (ctx) =>
        ctx.step === steps.CUSTOM && Boolean(ctx.onRecover),
      shouldReload: (ctx) =>
        ctx.step === steps.RELOAD ||
        (ctx.step === steps.CUSTOM && !ctx.onRecover),
      shouldClearCache: (ctx) => ctx.step === steps.CLEAR_CACHE,
      shouldLogout: (ctx) => ctx.step === steps.LOGOUT,
    },
    actions: {
      runCustomRecovery: (ctx) => ctx.onRecover?.(),
      reloadApp: (ctx) => reload(ctx.step),
      clearAppCache: async (ctx) => {
        queryClient.getQueryCache().clear();
        reload(ctx.step);
      },
      logoutUser: async (ctx) => {
        await AuthTokenStore.remove();
        queryClient.getQueryCache().clear(); // repeat this in case error occured on logout
        reload(ctx.step);
      },
      incrementStep: assign({
        step: (ctx) => stepTransitions[ctx.step],
      }),
      reset: assign({
        step: (_ctx) => steps.CUSTOM,
      }),
    },
  },
);

function getRecoverStep() {
  if (typeof window === 'undefined') return steps.CUSTOM;

  if (window.location.pathname === '/log-in') {
    return stepTransitions[steps.LOGOUT];
  }

  const urlParams = new URLSearchParams(window.location.search);
  const step = urlParams.get(queryParam) as RecoverStep;
  return Object.values(steps).includes(step) ? step : steps.RELOAD;
}

function reload(stepName: RecoverStep) {
  // Force update if there's an update available
  const updateActor = useCapgoUpdateStore.getState().actor;
  const state = updateActor.getSnapshot();
  const nextBundle = state.context.nextBundle;
  if (state.matches('update available') && nextBundle) {
    console.info(
      `Error recovery: restarting with new version ${nextBundle.version}`,
    );
    return CapacitorUpdater.set(nextBundle);
  }

  // otherwise reload the page
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(queryParam, stepName);
  window.location.search = urlParams.toString();
}
