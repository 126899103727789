import { useCallbackRef } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { ZodiosResponseByAlias } from '@zodios/core';

import { $API, striveAppApi } from '@strive/api';

export type DataResponse = ZodiosResponseByAlias<
  typeof striveAppApi,
  'getBiometrics'
>;

export function useInvalidateData() {
  const queryClient = useQueryClient();
  const key = $API.getKeyByAlias('getBiometrics');
  return useCallbackRef(() => queryClient.invalidateQueries(key));
}
