import { Text, TextProps } from '@chakra-ui/react';

export function Tag(props: TextProps) {
  return (
    <Text
      as="span"
      textTransform="uppercase"
      px={4}
      py={1}
      borderRadius="2em"
      fontFamily="brand"
      fontSize="0.5em"
      {...props}
    />
  );
}
