import { Box, Flex } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

import { Hangout } from '../hangout';

export default function CompetitionWelcome() {
  return (
    <Flex direction="column" h="full" gap="4" justifyContent="space-evenly">
      <Flex direction="column" rowGap="6">
        <TEXT.H1 as={Flex} flexDirection={'column'}>
          <span>Introducing,</span>
          <span>The Strive</span>
          <span>Leaderboard</span>
        </TEXT.H1>
        <TEXT.H3>
          Each pursuit of performance benefits from some friendly competition!
        </TEXT.H3>
      </Flex>
      <Box mx="auto" w="230px">
        <Hangout />
      </Box>
    </Flex>
  );
}
