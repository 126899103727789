import { Stack } from '@chakra-ui/react';

import { PillarPractice } from '@arena-labs/shared-models';
import { Card, NoBreakText, NumberedCard } from '@arena-labs/strive2-ui';

import { PracticeCardButtons } from './practice-card-buttons';

export type PracticeCardProps = {
  index: number;
  practice: PillarPractice;
  pillar: number;
};

export function PracticeCard({ index, practice, pillar }: PracticeCardProps) {
  return (
    <NumberedCard number={index} colorScheme={`pillar-${pillar}`}>
      <Stack
        direction="column"
        spacing="3"
        height="100%"
        shadow="md"
        borderRadius="card"
        p="4"
        overflow="hidden"
      >
        <Card.Heading as={'h3'} fontSize="md" fontWeight="bold">
          <NoBreakText as="span" text={practice.title} />
        </Card.Heading>
        <PracticeCardButtons practice={practice} pillar={pillar} />
      </Stack>
    </NumberedCard>
  );
}
