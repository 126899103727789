import { CSSProperties } from 'react';
import {
  Box,
  BoxProps,
  ComponentMultiStyleConfig,
  createStylesContext,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';

import { CardButton } from './card-button';
import { CardHeading } from './card-heading';

const [StylesProvider, useStyles] = createStylesContext('Card');

export { useStyles };

export type CardProps = {
  colorScheme?: string;
} & BoxProps;

export function Card({
  colorScheme = 'default',
  children,
  ...props
}: CardProps) {
  const styles = useMultiStyleConfig('Card', { ...props, colorScheme });
  const [fg, bg] = useToken('colors', [
    styles.card?.color as string,
    styles.card?.bg as string,
  ]);

  return (
    <Box
      __css={styles.card}
      style={
        {
          '--card-fg': fg,
          '--card-bg': bg,
          '--card-bg-dark': `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${bg}`,
          '--card-bg-light': `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), ${bg}`,
        } as CSSProperties
      }
      {...props}
    >
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
}
Card.Heading = CardHeading;
Card.Button = CardButton;

export const CardStyles: ComponentMultiStyleConfig = {
  parts: ['card', 'heading', 'props'],

  baseStyle: ({ colorScheme }) => ({
    props: {
      colorScheme,
    },
    card: {
      bg: `strive.colorScheme.${colorScheme}.main`,
      color: `strive.colorScheme.${colorScheme}.text`,
      borderRadius: 'card',
      padding: 4,
    },
    heading: {
      fontSize: 'lg',
      fontWeight: 'bold',
    },
  }),
};
