import { useMount } from 'react-use';
import semver from 'semver';
import { z } from 'zod';

import { AppTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { toast } from '@arena-labs/strive2-ui';
import { createDataStore } from '@strive/utils';

import { getAppInfo } from '../../lib/use-app-info';

export const installedVersionStore = createDataStore({
  key: 'InstalledVersion',
  schema: z.string(),
});
export const previousVersionStore = createDataStore({
  key: 'PreviousInstalledVersion',
  schema: z.string(),
});

/**
 * Keep a record of the current & previous versions in storage.
 * We can track when a user updates the app, and we can use
 * the difference between current & previous to show different
 * release notes.
 */
export function useInstalledVersionTracker() {
  const analytics = useAnalytics();

  useMount(async () => {
    try {
      const [appInfo, installed] = await Promise.all([
        getAppInfo(),
        installedVersionStore.get(),
      ]);
      const appVersion = appInfo.version;

      if (!appVersion) return;

      // Update stores if the version changed
      if (installed !== appVersion) {
        installedVersionStore.set(appVersion);
        if (installed) {
          previousVersionStore.set(installed);
        }
      }

      // This is a fresh installation
      if (!semver.valid(installed)) {
        return;
      }

      // We downgraded the app (only likely to happen internally)
      if (semver.lt(appVersion, installed)) {
        previousVersionStore.remove();
        return;
      }

      // The app updated since last usage
      if (semver.gt(appVersion, installed)) {
        const upgradeType = semver.diff(installed, appVersion);
        analytics.logEvent(AppTrackingEvent.AppUpdated, {
          version: appVersion,
          previous: installed,
          upgradeType,
        });

        if (
          upgradeType &&
          ['preminor', 'minor', 'premajor', 'major'].includes(upgradeType)
        ) {
          toast({
            status: 'success',
            title: 'Update Installed',
          });
        }
      }
    } catch (error) {
      console.error('Failed to check/set app version:', error);
    }
  });
}
