import { useEffect, useRef } from 'react';

import { useScreenContainer } from './screen-container';

type StatusBarProps = {
  color: string;
  style?: 'light' | 'dark';
};

export function StatusBar({ color, style }: StatusBarProps) {
  const { setStatusBar, resetStatusBar } = useScreenContainer();
  const previousColorRef = useRef<string | undefined>();

  useEffect(() => {
    if (!setStatusBar || !resetStatusBar) {
      console.warn('StatusBar is rendered outside of ScreenContainer');
    }

    previousColorRef.current = color;
    setStatusBar?.({ color, style });

    return () => {
      resetStatusBar?.();
    };
  }, [color, style, setStatusBar, resetStatusBar]);

  return null;
}

export default StatusBar;
