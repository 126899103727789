import { immerable, produce } from 'immer';

export type VideoDurations = 'short' | 'medium' | 'long';

export const VideoDuration: Record<VideoDurations, string> = {
  short: '0-2',
  medium: '2-5',
  long: '5+',
};

export type VideoSorting = {
  field: 'created_at' | 'title' | 'duration';
  direction: 'asc' | 'desc';
};

export type Pagination = {
  limit?: number;
  offset?: number;
};

export class VideoQuery {
  static [immerable] = true;

  static getDefaultSorting(): VideoSorting {
    return {
      field: 'title',
      direction: 'asc',
    };
  }

  constructor(
    public duration: Set<VideoDurations> = new Set(),
    public skills: Set<string> = new Set(),
    public coaches: Set<string> = new Set(),
    public sorting: VideoSorting = VideoQuery.getDefaultSorting(),
    public pagination: Pagination = {},
  ) {}

  hasActiveFilters() {
    return Boolean(this.coaches.size || this.skills.size || this.duration.size);
  }

  hasActiveSorting() {
    const defaultSorting = VideoQuery.getDefaultSorting();
    return (
      this.sorting.direction !== defaultSorting.direction ||
      this.sorting.field !== defaultSorting.field
    );
  }

  setFilters(filters: VideoFilters) {
    return produce(this, (draft) => {
      draft.duration = filters.duration;
      draft.skills = filters.skills;
      draft.coaches = filters.coaches;
    });
  }

  setSorting(sorting: VideoSorting) {
    return produce(this, (draft) => {
      draft.sorting = sorting;
    });
  }

  getOrdering() {
    if (this.sorting.direction === 'asc') {
      return this.sorting.field;
    }
    return `-${this.sorting.field}`;
  }

  getSearchParams() {
    const params = new URLSearchParams({ ordering: this.getOrdering() });
    for (const coach of this.coaches.values()) {
      params.append('coach', coach);
    }
    for (const skill of this.skills.values()) {
      params.append('skill', skill);
    }
    for (const duration of this.duration.values()) {
      params.append('length', duration);
    }
    return params;
  }

  toString() {
    return this.getSearchParams().toString();
  }
}

export type VideoFilters = Pick<VideoQuery, 'duration' | 'skills' | 'coaches'>;
