// import { Capacitor } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

export const haptics = {
  isSupported: checkSupport(),
  impact,
  vibrate,
  notification,
  selectionStart,
  selectionChanged,
  selectionEnd,
} as const;

function checkSupport() {
  const pluginAvailable = Capacitor.isPluginAvailable('Haptics');
  if (!pluginAvailable) return false;

  const isWebPlatform = Capacitor.getPlatform() === 'web';
  const webVibrationSupported =
    typeof navigator !== 'undefined' && 'vibrate' in navigator;

  return isWebPlatform ? webVibrationSupported : true;
}

async function impact(style: 'HEAVY' | 'MEDIUM' | 'LIGHT') {
  if (haptics.isSupported) {
    const impactStyle =
      ImpactStyle[
        ({ HEAVY: 'Heavy', MEDIUM: 'Medium', LIGHT: 'Light' } as const)[style]
      ];
    return Haptics.impact({ style: impactStyle });
  }
}

async function vibrate(duration: number) {
  if (haptics.isSupported) {
    return Haptics.vibrate({ duration });
  }
}

async function notification(type: 'SUCCESS' | 'WARNING' | 'ERROR') {
  if (haptics.isSupported) {
    const notificationType =
      NotificationType[
        ({ SUCCESS: 'Success', WARNING: 'Warning', ERROR: 'Error' } as const)[
          type
        ]
      ];
    return Haptics.notification({ type: notificationType });
  }
}

async function selectionStart() {
  if (haptics.isSupported) {
    return Haptics.selectionStart();
  }
}

async function selectionChanged() {
  if (haptics.isSupported) {
    return Haptics.selectionChanged();
  }
}

async function selectionEnd() {
  if (haptics.isSupported) {
    return Haptics.selectionEnd();
  }
}
