import { useMutation } from '@tanstack/react-query';

import { NotificationTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { apiClient, queryClient } from '@strive/api';
import { assert } from '@strive/utils';

import { usePushRequestPermissions } from './use-push';

export type EnableNotificationsProps = {
  onCompleted: () => void;
};

export function useEnablePushNotifications({
  onCompleted,
}: EnableNotificationsProps) {
  const pushRequest = usePushRequestPermissions();

  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async () => {
      const permStatus = await pushRequest.mutateAsync();
      if (permStatus.receive === 'granted') {
        analytics.logEvent(
          NotificationTrackingEvent.NotificationPermissionGranted,
        );
        return await apiClient.patch('/profile/update_profile/', {
          send_push: true,
        });
      } else {
        analytics.logEvent(
          NotificationTrackingEvent.NotificationPermissionDenied,
        );
        assert(false, 'Permission not granted');
      }
    },
    onSuccess: onCompleted,
    onSettled: () => queryClient.invalidateQueries(),
  });
}
