export const incentiveImages: Record<string, string> = {
  book: '/images/incentives/book.png',
  mini_flame: '/images/incentives/streak-card-flame.png',
  massage: '/images/incentives/massage.png',
  padlock: '/images/incentives/padlock.png',
  water_bottle: '/images/incentives/tumbler.png',
  journal: '/images/incentives/journal.png',
  gift_card: '/images/incentives/gift-card.png',
  outline: 'images/incetives/treasure-outline.gif',
};

export const supportStrings: Record<string, string> = {
  power_up_helper: 'Performance-Based',
  power_up_heading: 'Power Up',
  earn_by_doing_heading: 'Earn By Doing',
  earn_by_doing_helper: 'Achievement-Based',
  extra_bonus_heading: 'Leading The Pace',
  extra_bonus_helper: 'Exra Bonus',
};
