import { chakra, HeadingProps } from '@chakra-ui/react';

import { useStyles } from './card';

export type CardHeadingProps = HeadingProps;

export function CardHeading(props: CardHeadingProps) {
  const styles = useStyles();
  return <chakra.h2 __css={styles.heading} {...props} />;
}
