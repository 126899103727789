import { useForm } from 'react-hook-form';
import { Box, Checkbox, Heading, SimpleGrid, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from '@arena-labs/strive2-ui';

import { useGenerateDataMutation } from './admin-mutations';
import { GenerateDataData, generateDataSchema } from './admin-schema';

export type AdminGenerateDataProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminGenerateData({
  formId,
  ...props
}: AdminGenerateDataProps) {
  const generateData = useGenerateDataMutation();

  const { handleSubmit, register } = useForm<GenerateDataData>({
    resolver: yupResolver(generateDataSchema),
    defaultValues: {},
  });

  const onSubmit = async (values: GenerateDataData) => {
    try {
      await generateData.mutateAsync(values);
      props.onSuccess();
      toast({ title: 'Generated Data', status: 'success' });
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <Box as="fieldset">
        <Heading as="legend" fontWeight="bold" fontSize="lg" py={2}>
          DATA TO GENERATE
        </Heading>

        <SimpleGrid columns={2} spacing="2">
          <Checkbox
            size="lg"
            colorScheme="brand"
            {...register('generate_aci_data')}
          >
            ACI
          </Checkbox>

          <Checkbox
            size="lg"
            colorScheme="brand"
            {...register('generate_practice_data')}
          >
            Practices
          </Checkbox>

          <Checkbox
            size="lg"
            colorScheme="brand"
            {...register('generate_whoop_data')}
          >
            Whoop
          </Checkbox>
        </SimpleGrid>
      </Box>
    </VStack>
  );
}
