import { isObjectWithKey } from '@arena-labs/shared-models';
import { StriveApiResponse } from '@strive/api';

export type HomeScreenResponse = StriveApiResponse<'getHomepage'>;

export type LearningState = HomeScreenResponse['learning_state'];

export type AppGateType = NonNullable<HomeScreenResponse['app_gate']>['type'];

export type AppGateConfig<Type extends AppGateType | null> =
  Type extends AppGateType
    ? Extract<NonNullable<HomeScreenResponse['app_gate']>, { type: Type }>
    : Type extends null
    ? {
        type: null;
        slug: string;
        can_skip: boolean;
        order: null | number;
        properties?: null;
      }
    : never;

export function isHomeScreenResponse(
  value: unknown,
): value is HomeScreenResponse {
  return isObjectWithKey(value, 'learning_state');
}
