import { Fragment } from 'react';

// split text into <p> and <br> tags returning JSX elements
export function splitParagraphs(text: string) {
  const paragraphs = text.split(/\n\n+/).map((p, i) => (
    <p key={i}>
      {p.split('\n').map((l, j) => (
        <Fragment key={j}>
          {l}
          <br />
        </Fragment>
      ))}
    </p>
  ));
  return paragraphs;
}
