export default function GaugeConnector() {
  return (
    <svg viewBox="0 0 176 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M173.5 56V28.8677L88 28.8677V1.3113e-06"
        stroke="url(#paint0_linear_354_83888)"
        strokeWidth="4"
      />
      <path
        d="M2.5 56V28.918L88 28.918V1.3113e-06"
        stroke="url(#paint1_linear_354_83888)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_354_83888"
          x1="130.75"
          y1="56"
          x2="130.75"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#315B66" />
          <stop offset="1" stopColor="#10272D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_354_83888"
          x1="45.25"
          y1="56"
          x2="45.25"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#315B66" />
          <stop offset="1" stopColor="#10272D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
