import { addBreadcrumb } from '@sentry/nextjs';
import { useActor } from '@xstate/react';
import {
  interpret,
  InterpreterFrom,
  InterpreterStatus,
  StateValue,
  StateValueMap,
} from 'xstate';
import create from 'zustand';

import { $API, apiClient, isQueryClientReady, queryClient } from '@strive/api';

import { homepageMachine } from './homepage.machine';

const useStore = create<{ service: InterpreterFrom<typeof homepageMachine> }>(
  (set) => ({
    service: interpret(homepageMachine, { devTools: true }),
  }),
);

export async function initializeHomepageService() {
  // Initialize & store a new service instance
  const service = interpret(homepageMachine, { devTools: true }).start();
  useStore.setState({ service });

  // Prefetch the homepage
  const homepageKey = $API.getKeyByAlias('getHomepage', {});
  await isQueryClientReady();
  if (!queryClient.getQueryData(homepageKey)) {
    await queryClient.prefetchQuery({
      queryKey: homepageKey,
      queryFn: () => apiClient.getHomepage(),
    });
  }

  let prevState: StateValue;
  service.onTransition((state) => {
    addBreadcrumb({
      type: 'debug',
      category: 'xstate.homepage',
      level: 'debug',
      data: {
        value: state.value,
      },
    });

    if (prevState) {
      if (typeof state.value === 'string' || typeof prevState === 'string') {
        console.log(
          `🏡 Homepage transition: "${prevState}" to "${state.value}"`,
        );
      } else {
        Object.entries(state.value).forEach(([key, value]) => {
          const prevValue = (prevState as StateValueMap)[key];
          const prevJSON = JSON.stringify(prevValue);
          const newJSON = JSON.stringify(value);
          if (prevJSON !== newJSON) {
            console.log(
              `🏡 _Homepage transition_:\n[${key}] ${prevJSON} to ${newJSON}`,
            );
          }
        });
      }
    }
    prevState = service.getSnapshot().value;
  });

  // Cleanup
  return () => {
    service.stop();
    queryClient.setQueryData(homepageKey, () => undefined);
  };
}

/**
 * React hooks access the running service instance/state
 */
export function useHomepageService() {
  return useStore((state) => state.service);
}

export function useHomepageState() {
  return useActor(useHomepageService());
}

export function useHomepage() {
  const [state, send] = useHomepageState();
  const service = useHomepageService();
  const query = $API.useGetHomepage(
    {},
    { enabled: service.status === InterpreterStatus.Running },
  );
  return [
    query.data,
    { refresh: () => query.invalidate(), query, state, send },
  ] as const;
}

// Legacy hook for backwards compatibility
// TODO: Remove this hook
export function useHomeScreenData() {
  const [homepage, state] = useHomepage();
  return [homepage, { refreshState: state.refresh, send: state.send }] as const;
}
