import { useEffect } from 'react';
import { App, AppState } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { useCallbackRef } from '@chakra-ui/react';

/**
 * Listen for changes in the app or the activity states.
 *
 * On iOS it's fired when the native
 * UIApplication.willResignActiveNotification and
 * UIApplication.didBecomeActiveNotification events get fired.
 * On Android it's fired when the Capacitor's Activity onResume
 * and onStop methods gets called.
 * On Web it's fired when the document's visibilitychange gets fired.
 *
 * https://capacitorjs.com/docs/apis/app#addlistenerappstatechange-
 */
export function useAppStateChange(onChange: (isActive: boolean) => void) {
  const stableCallback = useCallbackRef(onChange);

  useEffect(() => {
    let listener: PluginListenerHandle | null = null;
    (async () => {
      listener = await App.addListener('appStateChange', (state: AppState) => {
        stableCallback(state.isActive);
      });
    })();

    return () => {
      listener?.remove();
    };
  }, [stableCallback]);
}
