import {
  ACILocation,
  ACIQuestionId,
  ACISubmission,
} from '@arena-labs/shared-models';

export type ACIQuestion = {
  id: ACIQuestionId;
  shortName: string;
  display: string;
  values: string[];
  label: {
    start: string;
    end: string;
  };
};

export type ACIResponses = Omit<ACISubmission, 'arena_location' | 'offset'>;

export const ACIQuestions = {
  current_stress: {
    id: 'current_stress',
    shortName: 'Stress',
    display: 'Current stress level?',
    values: [
      `None...I'm completely in control`,
      `Slight...I'm able to manage`,
      `Somewhat...I'm aware of the pressure`,
      `Moderate... I'm feeling the pressure`,
      `Elevated... I'm starting to be impacted`,
      `Significant... I'm struggling`,
      `Extreme... I'm completely overwhelmed`,
    ],
    label: {
      start: 'None',
      end: 'Extreme',
    },
  },
  current_energy: {
    id: 'current_energy',
    shortName: 'Energy',
    display: 'Current ability to cope with stress?',
    values: [
      'Not able to cope',
      'Slightly able to cope',
      'Somewhat able to cope',
      'Moderately able to cope',
      'Very able to cope',
      'Significantly able to cope',
      'Extremely able to cope',
    ],
    label: {
      start: 'Not able',
      end: 'Extremely able',
    },
  },
  current_focus: {
    id: 'current_focus',
    shortName: 'Focus',
    display: 'Current ability to focus?',
    values: [
      'Not at all focused',
      'Slightly focused ',
      'Somewhat focused',
      'Moderately focused',
      'Very focused',
      'Significantly focused',
      'Extremely focused',
    ],
    label: {
      start: 'Not focused',
      end: 'Extremely focused',
    },
  },
  challenge_anticipated: {
    id: 'challenge_anticipated',
    shortName: 'Challenge',
    display: 'How challenging do you anticipate your work will be today?',
    values: [
      'Not at all challenging',
      'Slightly challenging',
      'Somewhat challenging',
      'Moderately challenging',
      'Very challenging',
      'Significantly challenging',
      'Extremely challenging',
    ],
    label: {
      start: 'Not at all',
      end: 'Extremely challenging',
    },
  },
  preparedness: {
    id: 'preparedness',
    shortName: 'Preparedness',
    display: 'How prepared do you feel for your work today?',
    values: [
      'Not at all prepared',
      'Slightly prepared',
      'Somewhat prepared',
      'Moderately prepared',
      'Very prepared',
      'Significantly prepared',
      'Extremely prepared',
    ],
    label: {
      start: 'Not at all',
      end: 'Extremely prepared',
    },
  },
  make_key_decisions: {
    id: 'make_key_decisions',
    shortName: 'Decisions',
    display: 'How capable were you to make key clinical decisions today?',
    values: [
      'Not at all able',
      'Slightly able',
      'Somewhat able',
      'Moderately able',
      'Very able',
      'Significantly able',
      'Extremely able',
    ],
    label: {
      start: 'Not at all',
      end: 'Extremely able',
    },
  },
  clinical_performance: {
    id: 'clinical_performance',
    shortName: 'Performance',
    display: 'How satisfied are you with your clinical performance today?',
    values: [
      'Far below standards',
      'Below standards',
      'slightly below standards',
      'Meets standards',
      'Slightly exceeds standards',
      'Exceeds standards',
      'Far exceeds standards',
    ],
    label: {
      start: 'Below standards',
      end: 'Exceeded standards',
    },
  },
  recharging_needed: {
    id: 'recharging_needed',
    shortName: 'Recharge Needs',
    display: 'How much do you need to recharge today?',
    values: [
      'No recharging required',
      'Slight recharging required',
      'Minor recharging required',
      'Some recharging required',
      'Much recharging',
      'Significant recharging required',
      'Extreme recharging required',
    ],
    label: {
      start: 'Not at all',
      end: 'Max needed',
    },
  },
  recharging_ability: {
    id: 'recharging_ability',
    shortName: 'Recharge Ability',
    display: 'Current ability to recharge?',
    values: [
      'Not able to recharge',
      'Slightly able to recharge',
      'Somewhat able to recharge',
      'Moderately able to recharge',
      'Very able to recharge',
      'Significantly able to recharge',
      'Extremely able to recharge',
    ],
    label: {
      start: 'Not able',
      end: 'Extremely able',
    },
  },
} satisfies { [id in ACIQuestionId]: ACIQuestion };

type ACILocationConfig = {
  value: ACILocation;
  display_name: string;
  questions: ACIQuestionId[];
};

const commonQuestions = [
  'current_stress',
  'current_energy',
  'current_focus',
] as const;

export const ACILocations: ACILocationConfig[] = [
  {
    value: 'ramping_up',
    display_name: 'Starting My Day',
    questions: [...commonQuestions, 'challenge_anticipated', 'preparedness'],
  },
  {
    value: 'in_the_thick',
    display_name: 'In the Thick of It',
    questions: [...commonQuestions],
  },
  {
    value: 'winding_down',
    display_name: 'Winding Down',
    questions: [
      ...commonQuestions,
      'make_key_decisions',
      'clinical_performance',
    ],
  },
  {
    value: 'recharging',
    display_name: 'My Day Off',
    questions: [...commonQuestions, 'recharging_needed', 'recharging_ability'],
  },
];

/**
 * Returns a list of questions and response values for a given ACILocation
 */
export function getACIQuestions(value: ACILocation): ACIQuestion[] {
  return getACIQuestionIds(value).map((qId) => ACIQuestions[qId]);
}

export function getACIQuestionIds(value: ACILocation): ACIQuestionId[] {
  return (
    ACILocations.find((location) => location.value === value)?.questions ?? []
  );
}
