import { useForm, useWatch } from 'react-hook-form';
import Router from 'next/router';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { AlertBox, toast } from '@arena-labs/strive2-ui';

import { useTeleportMutation } from './admin-mutations';
import { RelocateData, relocateSchema } from './admin-schema';

export type AdminTeleportProps = {
  formId: string;
  onSuccess: () => void;
};

export function AdminTeleport({ formId, ...props }: AdminTeleportProps) {
  const teleport = useTeleportMutation();
  const [homepage] = useHomepage();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<RelocateData>({
    resolver: yupResolver(relocateSchema),
    defaultValues: {
      learning_state: homepage?.learning_state ?? 'onboarding',
      learning_session: (homepage?.pillar_data?.session ?? 0) + 1,
    },
  });

  const learningState = useWatch({
    control,
    name: 'learning_state',
  });

  const onSubmit = async ({
    learning_state,
    learning_session,
  }: RelocateData) => {
    try {
      if (learning_state === 'onboarding') {
        await teleport.mutateAsync({ learning_state: 'onboarding' });
      } else if (learning_state === 'in_progress' && learning_session) {
        await teleport.mutateAsync({ learning_state, learning_session });
      } else if (learning_state === 'complete') {
        await teleport.mutateAsync({ learning_state });
      } else {
        throw new Error('Invalid transport data');
      }
      Router.replace('/');
      props.onSuccess();
      toast({ title: 'Transporting user', status: 'success' });
    } catch (error) {
      toast({ title: `Error: ${error}`, status: 'error' });
    }
  };

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      spacing="4"
      align="stretch"
    >
      <Text fontStyle="italic">Where do you want to go?</Text>

      <RadioGroup
        defaultValue={homepage?.learning_state ?? 'onboarding'}
        colorScheme="brand"
      >
        <VStack spacing="2" flexWrap="wrap" align="start">
          <Radio value="onboarding" {...register('learning_state')}>
            Onboarding
          </Radio>
          <Radio value="in_progress" {...register('learning_state')}>
            Learning Sessions
          </Radio>
          <Radio value="complete" {...register('learning_state')}>
            Explorations
          </Radio>
        </VStack>
      </RadioGroup>

      {learningState === 'onboarding' ? (
        <AlertBox
          status="warning"
          title="Warning"
          description="This will delete all your previous onboarding data"
        />
      ) : learningState === 'in_progress' ? (
        <FormControl
          id="relocate-learning-session"
          isInvalid={Boolean(errors.learning_session)}
        >
          <FormLabel fontWeight="bold" mb="0">
            Learning Session
          </FormLabel>
          <Input
            type="text"
            inputMode="numeric"
            autoComplete="none"
            {...register('learning_session')}
          />
          <FormErrorMessage>
            {errors.learning_session?.message}
          </FormErrorMessage>
        </FormControl>
      ) : null}
    </VStack>
  );
}
