import { AuthResponse, ProfileFoundResponse } from '@arena-labs/shared-models';

import { backend } from '../clients';

/**
 * NOTE: These calls use a raw `axios` instance and not the `authClient`
 * so that no authorization headers are accidentally sent, and no token
 * refresh occurs if a request fails with a 401
 */

export async function createLoginCode(phone: string) {
  await backend.post<void>(`/auth/one-step/`, {
    phone_number: phone,
  });
}

export async function verifyLoginCode(phone: string, code: string) {
  const response = await backend.post<AuthResponse>(`auth/one-step/validate/`, {
    phone_number: phone,
    token: code,
  });
  return response.data;
}

export async function matchUserProfile(email: string, token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await backend.post<ProfileFoundResponse>(
    `/auth/sign_up/match/`,
    {
      email: email,
    },
    config,
  );
  return response.data;
}

export async function claimUserProfile(token: string) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await backend.get<AuthResponse>(
    `/auth/sign_up/claim/`,
    config,
  );
  return response.data;
}

export async function getFreshToken(refreshToken: string) {
  const response = await backend.post<AuthResponse>(`/auth/refresh/`, {
    refresh: refreshToken,
  });
  return response.data;
}

export async function createSignupCode(phone: string) {
  await backend.post<void>(`/auth/one-step/`, {
    phone_number: phone,
  });
  return null;
}
