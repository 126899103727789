import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { pillarPracticeSchema } from './pillars.schema';

export const practicesApi = makeApi([
  {
    alias: 'logPractice',
    description: 'Log a practice',
    method: 'post',
    path: '/content/practice/log/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          practice: z.string(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: 'skipPractice',
    description: 'Skip a practice',
    method: 'post',
    path: '/content/practice/skip/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({
          practice: z.string(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: 'practiceDetail',
    description: 'Get detailed data for a single Practice from the slug',
    method: 'get',
    path: '/content/practices/:slug/',
    parameters: [
      {
        type: 'Path',
        name: 'slug',
        schema: z.string(),
      },
    ],
    response: pillarPracticeSchema,
  },
  {
    alias: 'togglePracticeFavorite',
    description: "Toggle the 'favorite' boolean property of a Practice",
    method: 'post',
    path: '/content/practices/favorite/',
    parameters: [
      {
        type: 'Body',
        name: 'data',
        schema: z.object({ slug: z.string() }),
      },
    ],
    response: pillarPracticeSchema,
  },
]);
