import { z } from 'zod';
import { PersistOptions } from 'zustand/middleware';

import { deserialize, serialize } from './helpers';
import { getDataStoreProvider } from './providers';

type ZustandPersistOptions<
  State,
  PersistedStateSchema extends z.ZodTypeAny = z.ZodTypeAny,
  PersistedState = z.infer<PersistedStateSchema>,
> = {
  provider?: string;
  key: string;
  schema: PersistedStateSchema;
  staleTime?: number;
} & Omit<
  PersistOptions<State, PersistedState>,
  'serialize' | 'deserialize' | 'name' | 'getStorage'
>;

export function createZustandDataStore<
  State,
  PersistedStateSchema extends z.ZodTypeAny = z.ZodTypeAny,
  PersistedState = z.infer<PersistedStateSchema>,
>({
  key: name,
  provider,
  schema,
  staleTime,
  ...options
}: ZustandPersistOptions<
  State,
  PersistedStateSchema,
  PersistedState
>): PersistOptions<State, PersistedState> {
  const deserializer = z.object({
    state: schema,
    version: z.number().optional(),
  });

  return {
    ...options,
    name,
    getStorage: () => getZustandStorageAdapter(provider, { staleTime }),
    serialize,
    deserialize: (str: string) => {
      const value = deserialize(str);
      return deserializer.parse(value) as unknown as {
        state: PersistedState;
      };
    },
  };
}

export function getZustandStorageAdapter(
  provider = 'default',
  { staleTime = Infinity }: { staleTime?: number } = {},
) {
  if (typeof window === 'undefined') {
    return {
      async getItem() {
        return null;
      },
      removeItem() {
        return Promise.resolve();
      },
      setItem() {
        return Promise.resolve();
      },
    };
  }

  const storage = getDataStoreProvider(provider);
  return {
    async getItem(name: string) {
      const item = await storage.getItem(name);
      if (item && item.timestamp && item.timestamp + staleTime > Date.now()) {
        storage.removeItem(name);
        return null;
      }
      return item ? (item.value as string) : null;
    },
    removeItem(name: string) {
      return storage.removeItem(name);
    },
    setItem(name: string, value: string) {
      return storage.setItem(name, value);
    },
  };
}
