import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { JStyle } from '@strive/jstyle';
import { assert } from '@strive/utils';

import {
  StrivewareContext,
  StrivewareSelector,
} from '../logic/striveware-context';

export function useStrivewareBattery() {
  const queryClient = useQueryClient();

  const isConnected = StrivewareContext.useSelector(
    StrivewareSelector.isConnected,
  );
  useEffect(() => {
    if (!isConnected) {
      // Reset the query when the device is disconnected
      queryClient.resetQueries(['striveware', 'battery']);
    }
  }, [isConnected, queryClient]);

  const [refetchInterval, setRefetchInterval] = useState(60_000);

  const batteryQuery = useQuery({
    queryKey: ['striveware', 'battery'],
    queryFn: async () => JStyle.getBatteryLevel(),
    select: (res) => {
      assert(isConnected);
      return {
        batteryLevel: res.batteryLevel,
        batteryStatus: getBatteryStatus(res.batteryLevel),
      };
    },
    enabled: isConnected,
    refetchInterval,
  });

  // Adjust the refetch interval based on the battery level
  const batteryLevel = batteryQuery.data?.batteryLevel;
  const interval =
    typeof batteryLevel === 'number' && batteryLevel <= 20 ? 10_000 : 60_000;

  useEffect(() => {
    if (interval !== refetchInterval) {
      setRefetchInterval(interval);
    }
  }, [interval, refetchInterval]);

  return batteryQuery;
}

export function getBatteryStatus(batteryLevel: number) {
  return batteryLevel <= 20
    ? 'low'
    : batteryLevel <= 50
    ? 'medium'
    : ('high' as const);
}
