import * as Sentry from '@sentry/nextjs';
import type { AmplitudeClient } from 'amplitude-js';
import { produce } from 'immer';

import { UserInteractionEvent } from './events';
import { TrackingEvent, TrackingTag } from './tracking-constants';

export type TrackingEventContext = {
  tags?: TrackingTag[];
  [key: string]: unknown;
};

export type TrackingOptions = {
  isEnabled: boolean;
};

export class AnalyticsClient {
  public context: TrackingEventContext = {};

  public parentClient?: AnalyticsClient;

  private options: TrackingOptions = {
    isEnabled: false,
  };

  constructor(
    options: TrackingOptions,
    parentClient?: AnalyticsClient,
    context?: TrackingEventContext,
  ) {
    this.options = options;

    if (parentClient) {
      this.parentClient = parentClient;
    }
    if (context) {
      this.context = context;
    }
  }

  setUserId(userId: string | null) {
    this.ampClient?.setUserId(userId);
    return this;
  }

  setUserProperties(userProperties: unknown) {
    this.ampClient?.setUserProperties(userProperties);
    return this;
  }

  clearUser() {
    this.ampClient?.setUserId(null);
    this.ampClient?.clearUserProperties();
    return this;
  }

  createChild(context?: TrackingEventContext) {
    return new AnalyticsClient(this.options, this, context);
  }

  user<
    EventType extends keyof UserInteractionEvent,
    EventData extends UserInteractionEvent[EventType],
  >(event: EventType, data: EventData) {
    return this.log(`user.${event}`, data as Record<string, unknown>);
  }

  logEvent(event: TrackingEvent, data: TrackingEventContext = {}) {
    this.log(event, data);
  }

  log(event: string, data: Record<string, unknown> = {}) {
    const eventData = this.getContext(data);

    Sentry.addBreadcrumb({
      category: 'analytics',
      level: 'info',
      data: { event, eventData },
    });

    if (
      process.env.NODE_ENV === 'development' ||
      !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
    ) {
      const { tags: tags, ...dataWithoutTags } = eventData;
      console.groupCollapsed(`📊 Analytics: ${event}`, tags);
      console.log(`📊 %c${event}`, 'font-weight: bold', ' data:');
      console.table(dataWithoutTags);
      console.groupEnd();
    }

    // Log the event
    this.ampClient?.logEvent(event, eventData);
    return this;
  }

  get ampClient(): AmplitudeClient | undefined {
    const ampInstance =
      typeof window !== 'undefined'
        ? window.amplitude?.getInstance?.()
        : undefined;
    return ampInstance;
  }

  addContext(context: TrackingEventContext) {
    this.context = produce(this.context, (draft) => {
      for (const key of Object.keys(context)) {
        if (key === 'tags' && context.tags) {
          draft.tags = this.mergeTags(draft.tags ?? [], context.tags);
        } else {
          draft[key] = context[key];
        }
      }
    });
    return this;
  }

  getTags(event?: TrackingEventContext): TrackingTag[] {
    return this.mergeTags(
      this.parentClient?.getTags() ?? [],
      this.context.tags ?? [],
      event?.tags ?? [],
    );
  }

  getContext(event?: TrackingEventContext): TrackingEventContext {
    return {
      ...this.parentClient?.getContext(),
      ...this.context,
      ...event,
      tags: this.getTags(event),
    };
  }

  private mergeTags(...tagLists: Array<TrackingTag[]>) {
    // Merge tags & deduplicate
    const allTags: TrackingTag[] = ([] as TrackingTag[]).concat(...tagLists);
    return Array.from(new Set(allTags));
  }
}
