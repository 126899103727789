import type { NextApiRequest } from 'next';
import { v4 as uuidv4 } from 'uuid';

export function getAuthTokenFromRequest(req: NextApiRequest) {
  return req.headers.authorization?.replace(/Bearer \s*/i, '');
}

export function getAdditionalHeaders() {
  return {
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    'X-Trace-Id': uuidv4(),
  };
}
