import { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { useViewportHeightUnit } from '../utils/viewport-height';
import { Theme, theme as striveTheme } from './theme';

export interface ThemeProviderProps {
  theme?: Theme;
  children: React.ReactNode;
}

export function ThemeProvider({
  children,
  theme = striveTheme,
}: ThemeProviderProps) {
  const vh = useViewportHeightUnit();
  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `var(--dvh, ${vh}px)`);
  }, [vh]);

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
