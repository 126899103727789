import { useEffect, useMemo, useState } from 'react';
import { useCallbackRef, useUnmountEffect } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';
import { assign, createMachine, InterpreterFrom, StateFrom } from 'xstate';
import { log } from 'xstate/lib/actions';

import {
  AnalyticsClient,
  MediaTrackingEvent,
  TrackingEventContext,
  useAnalytics,
} from '@arena-labs/analytics';

export type MediaPlaybackContext = {
  playbackRate: number;
  currentTime: number;
  currentPercent: number;
  previousTime: number;
  previousPercent: number;
  maxPercent: number;
  pollInterval: number;
  completesProgram: boolean;
  getMediaEl: () => HTMLVideoElement | HTMLAudioElement | null | undefined;
};

export type MediaPlaybackEvents =
  | { type: 'play' }
  | { type: 'timeupdate' }
  | { type: 'pause' }
  | { type: 'ended' }
  | { type: 'seeking' }
  | { type: 'seeked' }
  | { type: 'tick' }
  | { type: 'changePlaybackRate'; rate: number };

export type MediaPlaybackState = StateFrom<typeof playbackMachine>;
export type MediaPlaybackSendAction = InterpreterFrom<
  typeof playbackMachine
>['send'];

const initialContext = {
  playbackRate: 1,
  currentTime: -1,
  currentPercent: -1,
  previousTime: -1,
  previousPercent: -1,
  maxPercent: -1,
  pollInterval: 1000,
  completesProgram: false,
  getMediaEl: () => undefined,
};

export const playbackMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFtIEsCGAFANhgnmAE4B0ADnvgEYYDGA1gMS0AWGAdjLgTQwEoYALmADaABgC6iUGQD2sNILSz20kAA9EAFgBMARhI6A7EYDMADi0A2U6b3mArHoA0IfInOmSYsQE4HjlpGOr5WehYAvhGuqBCY3ISkFDx09CTssoIAyoIYRMIQjMn44lJIIHIKSipqmgg6fiSmRjamWkG+1uZWru4Ipr46JL6+5ib2Ru2+Rv5RMejYlMTklLxpGdm5+ZCMSqgArmQQQqKSapWKyqrldQ2+TS227TNdPW7aWl5a5p4OfjpjGYOOYgWLxJZJVapFYENCcIoYfawU5lGTyS41G4eIy9RBOe5WMJ6MRWcy+Px-YHRUELBLLYprGH4OFQXZoBilc7o6rXUB1IzmIajGwOKxGHw6BwA3H9KUkSwNcxiHQq0w6UyEkFgxYEelQhjkRHIwrFTnlC482p46YkPTEoz2kk6T4OGXmYnywb6AJaMQBKxU+ZxHWJJmMshGnbIsD0FlmtFVK5WhB6FVu0xiEgOAJk0YNP1jLW0iFh6ERpE7MDsCCQeMVblJrEIAJGW32x1WZ2mV3vBC+O3yh22Xxq7sWHRF4N0yEpA3l40kaOx+Gms7mhuYvnW1t2sQOvdOl1usJNd0vMnfMVGSfg3Uz6hlyMQRdgGMsxhL2trhMY3kaRDdkKzrWJMYhKtY2YyvuJBWEE1h+AMfqkjeIZ6rOaRVjWhRLnG371omm7-ggYwyqMBgZs0OiEhqdiDFE1IZDW8DlNq05cgRf51AAtGmvZcVoJBBFYfjtA4QSqg6VgodOpYMOxv7JlRMoqpm3S6P8DijuY0klgy0IbDkeQFPJlpNiYMoDPcWipgMFgjtZko6XeslpMULImY2W4IOKma2MOVGknoozmDK3yZtmgoAk4eh-CSTmhnpc5Ph5hH8loMqdkKfiWGIdh-P4UnUqxun6q5T4kGgEA4GAKWcdopKCU4grunlZItFBHoDGJ7pksSUrXkVxbOYlZUVs+OGcLVinWcMYmfDFnZWP2pgda2JL2g6Yy5Z28VoQ+BqYZAU1mT4TR+YMhLusFbqWI1pJ-OqWjZg69EREAA */
  createMachine(
    {
      context: initialContext,
      tsTypes: {} as import('./media-playback.machine.typegen').Typegen0,
      schema: {
        context: {} as MediaPlaybackContext,
        events: {} as MediaPlaybackEvents,
      },
      id: 'mediaPlayer',
      type: 'parallel',
      predictableActionArguments: true,

      states: {
        playback: {
          description:
            'models the behavior of the play/pause/seeking states in a media player',
          initial: 'notStarted',
          states: {
            notStarted: {
              on: {
                play: {
                  target: 'playing',
                },
                timeupdate: {
                  target: 'playing',
                },
              },
            },
            playing: {
              entry: [
                'assignPlaybackProgress',
                'updateAnalyticsContext',
                'applyPlaybackRate',
              ],
              invoke: {
                src: 'playbackInterval',
              },
              on: {
                pause: {
                  target: 'paused',
                },
                tick: {
                  actions: ['assignPlaybackProgress', 'updateAnalyticsContext'],
                },
              },
            },
            paused: {
              initial: 'idle',
              states: {
                idle: {},
                seeking: {
                  on: {
                    play: {
                      target: '#mediaPlayer.playback.playing',
                    },
                    seeked: {
                      target: 'idle',
                    },
                  },
                },
              },
              on: {
                play: {
                  target: 'playing',
                },
                seeking: {
                  target: '.seeking',
                },
                ended: {
                  target: 'ended',
                },
              },
            },
            ended: {
              entry: ['assignPlaybackProgress', 'updateAnalyticsContext'],
              exit: 'resetPlaybackProgress',
              on: {
                seeking: {
                  target: '#mediaPlayer.playback.paused.seeking',
                },
              },
            },
          },
          on: {
            changePlaybackRate: {
              actions: [
                'updatePlaybackRate',
                'applyPlaybackRate',
                'trackPlaybackRate',
              ],
            },
          },
        },
      },
    },
    {
      actions: {
        updatePlaybackRate: assign({
          playbackRate: (context, event) => event.rate,
        }),
        applyPlaybackRate: (context) => {
          const mediaRef = context.getMediaEl();
          if (mediaRef) {
            mediaRef.playbackRate = context.playbackRate;
          }
        },
        resetPlaybackProgress: assign({
          previousTime: (context) => -1,
          previousPercent: (context) => -1,
          currentTime: (context) => -1,
          currentPercent: (context) => -1,
        }),
        assignPlaybackProgress: assign({
          previousTime: (context) => context.currentTime,
          previousPercent: (context) => context.currentPercent,
          currentTime: (context) => {
            return context.getMediaEl()?.currentTime ?? context.currentTime;
          },
          currentPercent: (context) =>
            getMediaPercent(context.getMediaEl()) ?? context.currentPercent,
          maxPercent: (context) =>
            Math.max(
              getMediaPercent(context.getMediaEl()) ?? 0,
              context.maxPercent,
            ),
        }),
        updateAnalyticsContext: log(
          '`updateAnalyticsContext` action not implemented',
        ),
      },
      services: {
        playbackInterval: (context) => (cb) => {
          const interval = setInterval(() => {
            cb('tick');
          }, context.pollInterval);

          return () => {
            clearInterval(interval);
          };
        },
      },
    },
  );

export type UseVideoPlaybackMachineOptions = {
  mediaType: 'audio' | 'video';
  onPlayerClosed?: (percent: number) => void;
};

export function useMediaPlaybackMachine(
  videoRef: React.RefObject<HTMLVideoElement | HTMLAudioElement>,
  options: UseVideoPlaybackMachineOptions,
): [MediaPlaybackState, MediaPlaybackSendAction, { playbackState?: string }] {
  const [analyticsContext, setAnalyticsContext] =
    useState<TrackingEventContext>({
      mediaType: options.mediaType,
    });
  const analytics = useAnalytics(analyticsContext);

  // Initialize the state machine
  const [mediaState, send] = useMachine(playbackMachine, {
    devTools: true,
    context: {
      getMediaEl: () => videoRef.current,
    },
    actions: {
      updateAnalyticsContext: (context) => {
        setAnalyticsContext((current) => ({
          ...current,
          mediaProgressTime: parseFloat(context.currentTime.toFixed(2)),
          mediaProgressPercent: context.currentPercent,
          mediaPlayerState: getMediaPlaybackStateName(mediaState),
          mediaMaxPercent: context.maxPercent,
          isCompletingProgram: context.completesProgram,
          playbackRate: context.playbackRate,
        }));
      },
      trackPlaybackRate: (context) => {
        analytics.logEvent(MediaTrackingEvent.PlaybackRate, {
          playbackRate: context.playbackRate,
        });
      },
    },
  });

  const stateName = getMediaPlaybackStateName(mediaState);

  // Proxy DOM media events to the state machine
  const video = videoRef.current;
  useEffect(() => {
    const proxyDomEvent = (event: Event) => {
      return send(event.type as MediaPlaybackEvents['type']);
    };

    video?.addEventListener('play', proxyDomEvent);
    video?.addEventListener('timeupdate', proxyDomEvent);
    video?.addEventListener('pause', proxyDomEvent);
    video?.addEventListener('ended', proxyDomEvent);
    video?.addEventListener('seeking', proxyDomEvent);
    video?.addEventListener('seeked', proxyDomEvent);

    return () => {
      video?.removeEventListener('play', proxyDomEvent);
      video?.removeEventListener('timeupdate', proxyDomEvent);
      video?.removeEventListener('pause', proxyDomEvent);
      video?.removeEventListener('ended', proxyDomEvent);
      video?.removeEventListener('seeking', proxyDomEvent);
      video?.removeEventListener('seeked', proxyDomEvent);
    };
  }, [send, video]);

  // Track playback progress
  useMediaPlaybackAnalytics(analytics, mediaState.context.currentPercent);

  // Run cleanup when we unmount
  useUnmountEffect(
    useCallbackRef(() => {
      options.onPlayerClosed?.(mediaState.context.maxPercent);
    }),
  );

  return [mediaState, send, { playbackState: stateName }];
}

function useMediaPlaybackAnalytics(
  analytics: AnalyticsClient,
  currentPercent: number,
  intervalPct = 10,
) {
  // On unmount, send the final amount played
  useUnmountEffect(
    useCallbackRef(() => {
      analytics.logEvent(MediaTrackingEvent.MediaPlayed);
    }),
  );

  // Callback to track the current playback progress (${intervalPct}% threshold)
  const sendProgressTracking = useCallbackRef(
    (mediaProgressThreshold: number) => {
      analytics.logEvent(MediaTrackingEvent.MediaProgress, {
        mediaProgressThreshold,
      });
    },
  );

  // A function which calls `sendProgressTracking` when the current progress
  // has passed a new ${intervalPct}% threshold
  const trackProgress = useMemo(() => {
    // Create a closure around `lastRecorded` so our function has some state
    let lastRecorded = 0;

    return (progress: number) => {
      const nextThreshold = lastRecorded + intervalPct;
      if (progress <= nextThreshold) {
        // Didn't pass next threshold yet, so don't track
        return;
      }

      const mediaProgressThreshold = progress - (progress % intervalPct);
      lastRecorded = mediaProgressThreshold;
      sendProgressTracking(mediaProgressThreshold);
    };
  }, [intervalPct, sendProgressTracking]);

  useEffect(() => {
    // Track it
    trackProgress(currentPercent);
  }, [trackProgress, currentPercent]);
}

// Get the name of the current playback state (e.g. paused / playing / seeking / etc)
export function getMediaPlaybackStateName(state: MediaPlaybackState) {
  if (typeof state.value === 'object') {
    const { playback } = state.value;
    return typeof playback === 'string'
      ? playback
      : Object.keys(state.value)[0];
  }
  return;
}

function getMediaPercent(mediaEl?: HTMLVideoElement | HTMLAudioElement | null) {
  const duration = mediaEl?.duration ?? 0;
  if (!duration) {
    return 0;
  }
  return mediaEl ? Math.round((100 * mediaEl.currentTime) / duration) : null;
}
