import { Text, TextProps, useStyleConfig } from '@chakra-ui/react';

export type StatusTextProps = TextProps & {
  status: 'success' | 'error' | 'warning' | 'info';
};

export function StatusText({ status, ...textProps }: StatusTextProps) {
  const errorStyles = useStyleConfig('FormError');
  return (
    <Text
      {...(errorStyles as { text: TextProps }).text}
      bg={`status.${status}.bg`}
      color={`status.${status}.fg`}
      {...textProps}
    />
  );
}
