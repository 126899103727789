import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Grid,
  Icon,
} from '@chakra-ui/react';

import { ArenaLogo, TEXT } from '@arena-labs/strive2-ui';
import { useSuspendMedia } from '@strive/av';

import { AppMenu } from '../app-menu';
import { useMenu } from './use-menu';

export function MenuDrawer() {
  const { isOpen, onClose } = useMenu();

  useSuspendMedia(isOpen);

  return (
    <Drawer
      placement={'left'}
      onClose={onClose}
      isOpen={isOpen}
      colorScheme="transparent"
    >
      <DrawerContent>
        <DrawerBody bg={'transparent'} m={0} p={0} h={'full'}>
          <Grid templateColumns={'1fr 1fr'} h={'full'}>
            <Box
              pl={4}
              pr={7}
              py={4}
              layerStyle={'4dp'}
              h={'full'}
              pt={'calc(env(safe-area-inset-top) + 24px)'}
            >
              <Flex alignItems={'center'} gap={3} mb={10}>
                <Icon as={ArenaLogo} w={'34px'} h={'38px'} color={'logo'} />
                <TEXT.H1 fontSize={'11px'} fontFamily={'brand'} color={'logo'}>
                  ARENA STRIVE
                </TEXT.H1>
              </Flex>

              <AppMenu />
            </Box>

            <Box h={'full'} onClick={onClose}></Box>
          </Grid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
