import React from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import {
  chakra,
  Flex,
  FlexProps,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import { fontSizes, textStyles } from '@strive/theme/tokens';

import { TEXT } from '../text';

type FontSizes = keyof {
  [K in keyof typeof fontSizes as K extends `p${string}` ? K : never]: true;
};

export type MarkdownProps = {
  fontSize?: FontSizes;
  children: string;
  options?: Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>;
  tags?: string[];
} & Omit<FlexProps, 'fontSize'>;

const defaultComponents = {
  h1: TEXT.H1,
  h2: TEXT.H2,
  h3: TEXT.H3,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  a: (props) => <Link {...props} variant="underline" />,
  blockquote: (props) => (
    <chakra.blockquote
      borderLeft="0.25em solid currentColor"
      paddingX="1em"
      {...props}
    />
  ),
} satisfies Components;

export function Markdown({
  fontSize = 'p1',
  children,
  options,
  tags = [],
  ...props
}: MarkdownProps) {
  const components = Object.assign(defaultComponents, options?.components);

  return (
    <Flex direction="column" gap="4" {...textStyles[fontSize]} {...props}>
      <ReactMarkdown
        rehypePlugins={[
          rehypeRaw,
          [
            rehypeSanitize,
            {
              tagNames: ['br'].concat(tags, defaultSchema.tagNames ?? []),
              attributes: {
                ...defaultSchema.attributes,
                '*': [...(defaultSchema.attributes?.['*'] || []), 'className'], // Allow 'class' for all elements
              },
            },
          ],
        ]}
        {...options}
        components={components}
      >
        {children}
      </ReactMarkdown>
    </Flex>
  );
}
