import { ButtonProps, chakra } from '@chakra-ui/react';

export type FilterButtonProps = ButtonProps & {
  active?: boolean;
  'aria-label': string;
};

export function FilterButton({ active, children, ...rest }: FilterButtonProps) {
  return (
    <chakra.button
      p="1"
      boxSize="6"
      borderRadius="full"
      color={active ? 'strive.primary' : 'strive.cardText'}
      aria-pressed={active}
      display="grid"
      placeItems="center"
      {...rest}
    >
      {children}
    </chakra.button>
  );
}
