import { useMemo } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import { throttle } from 'lodash-es';

import { useShallowCompareMemo } from './use-compare-fn-memo';

/**
 * A custom hook that creates a throttled callback function.
 */
export function useThrottleCallbackRef<TArgs extends unknown[], TReturn>(
  fn: (...args: TArgs) => TReturn,
  delay: number,
  options: Parameters<typeof throttle>[2] = {},
) {
  const fnRef = useCallbackRef(fn);
  const throttleOptions = useShallowCompareMemo(options);
  return useMemo(
    () => throttle(fnRef, delay, throttleOptions),
    [fnRef, delay, throttleOptions],
  );
}
