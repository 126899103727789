import { WaitForQuery } from '@arena-labs/strive2-ui';
import { useSuspendMedia } from '@strive/av';

import CompetitionHub from './competition/competition-hub';
import RewardHub from './rewards/reward-hub';
import StreaksHub from './streaks/streaks-hub';
import { useCurrentIncentive } from './use-current-incentive';
import { useIncentiveHub } from './use-incentive-hub';

export default function IncentivesHub() {
  const incentive = useCurrentIncentive();

  // Suspend any audio/video playback when the modal is open
  const { isOpen } = useIncentiveHub();
  useSuspendMedia(isOpen);

  return (
    <WaitForQuery query={incentive} error={null} loading={null}>
      {(incentive) =>
        incentive?.type === 'competition' ? (
          <CompetitionHub competition={incentive} />
        ) : incentive?.type === 'achievements' ? (
          <RewardHub acheivementsData={incentive} />
        ) : (
          <StreaksHub />
        )
      }
    </WaitForQuery>
  );
}
