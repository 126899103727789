import { useEffect } from 'react';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { useCallbackRef } from '@chakra-ui/react';
import { useActor } from '@xstate/react';
import { interpret, InterpreterFrom } from 'xstate';
import create from 'zustand';

import { pushNotificationsMachine } from './push-notifications.machine';

const isPushAvailable = Capacitor.isPluginAvailable('PushNotifications');

export const usePushNotificationsService = create<{
  service: InterpreterFrom<typeof pushNotificationsMachine>;
}>((set, get) => ({
  service: interpret(pushNotificationsMachine).start(),
}));

type UsePushNotificationsOptions = {
  onRegistration?: (token: Token) => void;
};

export function usePushNotifications(
  options: UsePushNotificationsOptions = {},
) {
  const { service } = usePushNotificationsService();
  const stateMachine = useActor(service);
  const [, send] = stateMachine;

  const onRegisterCallback = useCallbackRef(options.onRegistration);
  useEffect(() => {
    if (!isPushAvailable) {
      return undefined;
    }

    const listeners: PluginListenerHandle[] = [];
    listeners.push(
      PushNotifications.addListener('registration', (data) => {
        send({ type: 'REGISTERED', data });
        onRegisterCallback(data);
      }),
    );
    listeners.push(
      PushNotifications.addListener('registrationError', (err) => {
        console.warn('🔔 Push registration error', err);
        send({ type: 'REGISTER_FAILED' });
      }),
    );
    listeners.push(
      PushNotifications.addListener('pushNotificationReceived', (data) => {
        send({ type: 'MESSAGE_RECEIVED', data });
      }),
    );
    listeners.push(
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (data) => {
          send({ type: 'ACTION_PERFORMED', data });
        },
      ),
    );

    return () => {
      listeners.forEach((listener) => listener.remove());
    };
  }, [onRegisterCallback, send]);

  return stateMachine;
}
