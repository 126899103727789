import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  BoxProps,
  Flex,
} from '@chakra-ui/react';

type AlertBoxContent = string | React.ReactNode;

export type AlertBoxProps = {
  showIcon?: boolean | BoxProps['as'];
} & (
  | {
      title: AlertBoxContent;
      description?: AlertBoxContent;
    }
  | {
      title?: AlertBoxContent;
      description: AlertBoxContent;
    }
) &
  AlertProps;

export function AlertBox({
  showIcon,
  title,
  description,
  ...props
}: AlertBoxProps) {
  return (
    <Alert flexDirection="column" overflow="unset" gap="2" {...props}>
      {title ? (
        <Flex>
          {showIcon === true ? (
            <AlertIcon />
          ) : showIcon ? (
            <AlertIcon as={showIcon} />
          ) : null}
          <AlertTitle>{title}</AlertTitle>
        </Flex>
      ) : null}
      {description ? (
        <AlertDescription w="full">{description}</AlertDescription>
      ) : null}
    </Alert>
  );
}
