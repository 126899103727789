import { inspect } from '@xstate/inspect';

if (
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  window.location.search.includes('_DEBUG_')
) {
  inspect({
    iframe: false,
  });
}
