import { forwardRef, useEffect } from 'react';
import { useAsync } from 'react-use';
import { Box, BoxProps } from '@chakra-ui/react';
import { useReducedMotion } from 'framer-motion';
import { LottieOptions, useLottie } from 'lottie-react';

import { toPromise } from '@strive/utils';

export type LottieFileOptions = Omit<LottieOptions, 'animationData'> & {
  /**
   * The keyframe to show when the user prefers reduced motion
   * (0 to 1)
   * @default 1
   */
  keyframe?: number;
};

export type LottieFileProps = {
  /**
   * Lottie Data, or a Promise that resolves to Lottie Data
   */
  as: unknown;

  /**
   * Lottie options
   */
  options?: LottieFileOptions;
} & /**
 * Any other Box Props
 */ Omit<BoxProps, 'as'>;

/**
 * A Chakra UI `Box` that renders a Lottie animation.
 */
export const LottieFile = forwardRef<HTMLDivElement, LottieFileProps>(
  function LottieFile({ as, options, ...props }, ref) {
    const lottieFile = useLottieFile(as, options);
    return (
      <Box ref={ref} {...props}>
        {lottieFile.View}
      </Box>
    );
  },
);

/**
 * Hook that renders initializes a Lottie animation - lottie data can be async
 * Respects the user's preference for reduced motion.
 * Specify a keyframe (0-1) to show when the user prefers reduced motion.
 */
export function useLottieFile(
  data: unknown,
  { keyframe = 1, ...options }: LottieFileOptions = {},
  style?: React.CSSProperties,
) {
  const reducedAnimation = useReducedMotion();
  const lottieData = useAsync(() => toPromise(data));

  const lottie = useLottie(
    {
      animationData: lottieData.value,
      ...options,
    },
    style,
  );

  // If the user prefers reduced motion, show the keyframe
  // (default to last frame)
  useEffect(() => {
    if (reducedAnimation) {
      const frame = (lottie.getDuration() ?? 0) * keyframe;
      lottie.goToAndStop(frame * 1000, false);
    }
  }, [keyframe, lottie, reducedAnimation]);

  return lottie;
}
