import { useMemo } from 'react';

import { Pillar, VideoListItem } from '@arena-labs/shared-models';

export function usePillarVideos(pillar?: Pillar) {
  return useMemo(() => {
    if (!pillar) {
      return [];
    }

    const relatedVideos = new Map<string, VideoListItem>();
    for (const practice of pillar.practices) {
      for (const video of practice.related_videos) {
        relatedVideos.set(video.slug, video);
      }
    }

    return [...relatedVideos.values()];
  }, [pillar]);
}
