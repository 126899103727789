import * as React from 'react';

export function UserNullRecharge(props: React.SVGProps<SVGSVGElement>) {
  const gradientId1 = React.useId();
  const gradientId2 = React.useId();

  return (
    <svg viewBox="0 0 112 112" fill="none" {...props}>
      <path
        d="M80.994 6.816H14.337v80.61"
        stroke={`url(#${gradientId1})`}
        strokeWidth={5.337}
      />
      <path
        transform="matrix(-1 0 0 1 93 24.162)"
        stroke={`url(#${gradientId2})`}
        strokeWidth={5.337}
        d="M-2.669 2.669h61.32v75.272h-61.32z"
      />
      <path
        d="M67.488 50.034l-.52 19.153h-4.889l-.534-19.153h5.943zm-2.965 27.7a3.12 3.12 0 01-2.27-.934c-.633-.633-.944-1.39-.935-2.27-.01-.874.303-1.621.935-2.245a3.12 3.12 0 012.27-.935c.846 0 1.59.312 2.23.935.642.624.967 1.371.976 2.244a3.107 3.107 0 01-.468 1.616c-.293.481-.68.868-1.162 1.162a3.035 3.035 0 01-1.576.427z"
        fill="#D9D9D9"
      />
      <defs>
        <linearGradient
          id={gradientId1}
          x1={15.985}
          y1={9.367}
          x2={58.745}
          y2={82.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset={0.56} stopColor="#737373" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={59.691}
          y1={0.549}
          x2={0.072}
          y2={86.793}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.015} stopColor="#D9D9D9" />
          <stop offset={1} stopColor="#737373" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoUserNullRecharge = React.memo(UserNullRecharge);
export default MemoUserNullRecharge;
