import { TextProps } from '@chakra-ui/react';

import { ContentLabelSkill } from '@arena-labs/shared-models';

import { Tag } from './tag';

export interface SkillTagProps extends TextProps {
  skill: ContentLabelSkill;
}

export function SkillTag({ skill, ...props }: SkillTagProps) {
  const { foreground_color: fgColor, background_color: bgColor } = skill;
  return (
    <Tag color={fgColor} bg={bgColor} {...props}>
      {skill.name}
    </Tag>
  );
}
