/**
 * -apple-system — San Francisco in Safari (on Mac OS X and iOS); Neue Helvetica and Lucida Grande on older versions of Mac OS X.
 * system-ui — default UI font on a given platform.
 * BlinkMacSystemFont — equivalent of -apple-system, for Chrome on Mac OS X.
 * Roboto — Android (Ice Cream Sandwich (4.0)+) and Chrome OS.
 * Helvetica Neue - Pretty much universally available
 * sans-serif - Fallback - lets the OS provide a sans-serif font
 */
export const baseFont = [
  '-apple-system',
  'system-ui',
  'BlinkMacSystemFont',
  'Roboto',
  'Helvetica Neue',
  'sans-serif',
];
const systemFont = baseFont.join(', ');

export const fonts = {
  heading: systemFont,
  body: systemFont,
  secondary: 'RacingSansOne-Regular',
};

export const letterSpacings = {
  normal: '0',
  wide: '0.04em',
};

export const lineHeights = {
  normal: 1.5,
  short: 1.2,
  none: 1,
};

export const textStyles = {
  h0: {
    fontSize: `4rem`,
    fontWeight: 'bold',
    lineHeight: 'short',
    letterSpacing: 'wide',
  },
  h1: {
    fontSize: `2rem`,
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'wide',
  },
  h2: {
    fontSize: `1.5rem`,
    fontWeight: 'semibold',
    lineHeight: 'normal',
    letterSpacing: 'wide',
  },
  h3: {
    fontSize: `1.25rem`,
    fontWeight: 'medium',
    lineHeight: 'short',
    letterSpacing: 'wide',
  },
  p1: {
    fontSize: `1rem`,
    fontWeight: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  p1_semibold: {
    fontSize: `1rem`,
    fontWeight: 'semibold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  p1_bold: {
    fontSize: `1rem`,
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  p2: {
    fontSize: `0.8125rem`,
    fontWeight: 'medium',
    lineHeight: 'short',
    letterSpacing: 'normal',
  },
  p3: {
    fontSize: `0.625rem`,
    fontWeight: 'medium',
    lineHeight: 'short',
    letterSpacing: 'normal',
  },
};

export const fontSizes = {
  h0: textStyles.h0.fontSize,
  h1: textStyles.h1.fontSize,
  h2: textStyles.h2.fontSize,
  h3: textStyles.h3.fontSize,
  p1: textStyles.p1.fontSize,
  p2: textStyles.p2.fontSize,
  p3: textStyles.p3.fontSize,
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};
