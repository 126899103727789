import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { useCallbackRef } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { throttle } from 'lodash-es';
import {
  assign,
  createMachine,
  interpret,
  InterpreterFrom,
  StateFrom,
} from 'xstate';
import create from 'zustand';

/**
 * The `useCapgoUpdateMachine` maps Capacitor Updater events to a state machine,
 * and calls `onTransition` whenever the state changes.
 * Key states: idle, downloading, downloaded, update available
 */
export function useCapgoUpdateMachine(
  onTransition: (state: StateFrom<typeof capgoUpdateMachine>) => void,
) {
  const actor = useCapgoUpdateStore(({ actor }) => actor);
  const state = useSelector(actor, (state) => state);

  const handleTransition = useCallbackRef(() => onTransition(state));
  const stateValue = state.value;
  useEffect(() => {
    console.info(`Capgo update: ${stateValue}`);
    handleTransition();
  }, [handleTransition, stateValue]);

  return [state, actor.send, actor] as const;
}

export type CapgoUpdateActor = InterpreterFrom<typeof capgoUpdateMachine>;
export const capgoUpdateMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGMCGAHKB7AtAV3QlQBcwBiCLAOzADoBLKgNywGs61NcCjSFGWaYvWoBtAAwBdCZMSh0WWPWHU5IAB6IAjAA5xtAJwB2IzoCsRgCxatB80bMAaEAE9tAJgC+n552z5CEnJKAHcqABssVAgZNQUlFSo1TQQLZzcES3F3WnczADYzd3Esg0t3XTNvXwx-HiCKLDDI6IACZCwAW3RwsFJYpBB45REkwZS010QzcXzaHXNyo3EzAGYjGwN86pA-bkDSRuaoiFaAM1R6XpipOMUR1XHpo3Tpwtp1mZts40tLHR2ewCvHIVCwxFaqCYl3CqAARr0BvJ7olks9Xgh8u5VvN8pYzAYZisTCZAbV9iCyPVSJDoVd4YjboNhqinqkXlMEKtioYtBUtPlCXktgZvD4QGCIHA1EDqWA7glRmiEAYMTo5u4jKs-lpLAZsYKDKsyVxgUEGBBegqHmNQCl3FjaHzVoL8ss8g6tBisZZaOJuQV1qt9XivOLZQc6KEIidGFBray7YgHTlna73UV8l7OVktLQtZZVjoXctVmtReHyWbSLRoy0pRAE0q2SmnQathnPRiZkY-WtgxYdBs7GYqpXTXLa00Y20LldIE3HkmEK20x3spnsxkCmZaGZysZjPluatdSa6pHaHLaTCGfLmSjm8vV+23Ruu5z3HZeWsNm7TPipJikAA */
    id: 'capgo-update',

    tsTypes: {} as import('./capgo-update.machine.typegen').Typegen0,

    schema: {
      context: {} as {
        currentVersion: string;
        downloadPercent: number | null;
        nextBundle: BundleInfo | null;
      },
      events: {} as
        | { type: 'download'; percent: number; bundle: BundleInfo }
        | { type: 'download complete'; bundle: BundleInfo }
        | { type: 'download failed'; version: string }
        | { type: 'not available' }
        | { type: 'update available'; bundle: BundleInfo },
      services: {} as {
        getCurrentVersion: {
          data: { currentVersion: string };
        };
      },
    },

    context: {
      currentVersion: process.env.VERSION ?? '0.0.0',
      downloadPercent: null,
      nextBundle: null,
    },

    initial: 'idle',

    states: {
      idle: {},

      downloading: {
        exit: 'clearDownloadProgress',
      },

      downloaded: {},
      'download failed': {},
      'update available': {
        description: `Update is ready to be installed`,
      },
    },

    on: {
      download: {
        target: '.downloading',
        actions: 'assignDownload',
      },

      'download complete': '.downloaded',
      'download failed': '.download failed',
      'not available': '.idle',
      'update available': '.update available',
    },

    invoke: {
      src: 'getCurrentVersion',

      onDone: {
        target: '#capgo-update',
        internal: true,
        actions: 'assignCurrentVersion',
      },
    },

    description: `Models events from the [Capgo Updater Plugin](https://capgo.app/docs/plugin/api/)

Watches download progress so we can show UI at the appropriate time to trigger the restart with the new version`,
  },
  {
    actions: {
      assignDownload: assign({
        nextBundle: (_, event) => event.bundle,
        downloadPercent: (_, event) => event.percent,
      }),
      clearDownloadProgress: assign({
        downloadPercent: (_, __) => null,
      }),
      assignCurrentVersion: assign({
        currentVersion: (context, event) =>
          Capacitor.isNativePlatform()
            ? event.data.currentVersion
            : context.currentVersion,
      }),
    },
    services: {
      getCurrentVersion: async (context) => {
        const capgoCurrent = await CapacitorUpdater.current();
        const currentVersion =
          capgoCurrent?.bundle.version === 'builtin'
            ? capgoCurrent.native
            : capgoCurrent.bundle.version;
        return { currentVersion };
      },
    },
  },
);

export const useCapgoUpdateStore = create<{ actor: CapgoUpdateActor }>()(() => {
  const actor = interpret(capgoUpdateMachine, { devTools: true }).start();

  const downloadLog = throttle(console.info, 2000);
  CapacitorUpdater.addListener('download', (event) => {
    downloadLog(`Downloading ${event.bundle.version} - ${event.percent}%`);
    actor.send({
      type: 'download',
      bundle: event.bundle,
      percent: event.percent,
    });
  });
  CapacitorUpdater.addListener('downloadComplete', (event) => {
    actor.send({ type: 'download complete', bundle: event.bundle });
  });
  CapacitorUpdater.addListener('updateAvailable', (event) => {
    actor.send({ type: 'update available', bundle: event.bundle });
  });
  CapacitorUpdater.addListener('downloadFailed', (event) => {
    actor.send({ type: 'download failed', version: event.version });
  });
  CapacitorUpdater.addListener('appReloaded', () => {
    console.info(`App reloaded`);
  });

  return { actor };
});
