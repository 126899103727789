import { forwardRef } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  function PasswordInput(props, ref) {
    const [show, setShow] = useBoolean(false);

    return (
      <InputGroup size="md">
        <Input
          ref={ref}
          {...props}
          type={show ? 'text' : 'password'}
          fontFamily={show ? 'body' : 'Verdana'}
          mr="4rem"
        />
        <InputRightElement width="3.5rem">
          <Button
            type="button"
            h="1.75rem"
            size="xs"
            variant="outline"
            colorScheme="secondary"
            sx={{
              _hover: {
                bg: 'inherit',
              },
            }}
            onClick={setShow.toggle}
          >
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
  },
);
