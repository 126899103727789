import { z } from 'zod';

import { useDataStoreQuery } from '@strive/utils';

export function useMutedPreference() {
  const [preference, setPreference] = useDataStoreQuery({
    key: 'Media.Muted',
    schema: z.boolean(),
    fallback: true,
  });
  return [preference?.data, setPreference.mutateAsync] as const;
}
