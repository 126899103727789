import {
  Avatar,
  AvatarProps,
  useStyleConfig,
  useToken,
} from '@chakra-ui/react';
import BoringAvatar from 'boring-avatars';

export type UserIconProps = {
  size?: AvatarProps['size'];
  boxSize?: AvatarProps['boxSize'];
  first_name?: string | undefined;
  last_name?: string | undefined;
  useCustomAvatar?: boolean;
};

export function UserIcon({
  size,
  boxSize,
  first_name,
  last_name,
  useCustomAvatar,
}: UserIconProps) {
  // Convert Chakra Avatar 'size' to standard CSS unit
  const avatarStyles = useStyleConfig('Avatar', { size }) as {
    container?: { width?: string };
  };
  const width = useToken('space', avatarStyles.container?.width ?? '') ?? size;

  const name =
    first_name && last_name ? `${first_name} ${last_name}` : undefined;

  return name && useCustomAvatar ? (
    <BoringAvatar
      variant="beam"
      colors={['#00EEFF', '#3D3D3D', '#FF5F1F', '#FF45CC', '#191E22']}
      name={name}
      size={width}
    />
  ) : (
    <Avatar name={name} size={size} boxSize={boxSize} />
  );
}
