import { chakra } from '@chakra-ui/react';

export const DL = chakra('dl', {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: '6rem 1fr',
    columnGap: 4,
    rowGap: 1,
  },
});

export const DT = chakra('dt', {
  baseStyle: {
    gridColumnStart: 1,
    fontWeight: 'bold',
    textAlign: 'right',
  },
});

export const DD = chakra('dd', {
  baseStyle: { gridColumnStart: 2 },
});
