import { $API, IncentiveType, QueryOptions } from '@strive/api';

interface UseCurrentIncentiveOptions<T = IncentiveType | undefined>
  extends Omit<QueryOptions<IncentiveType[], unknown>, 'select'> {
  select?: (data: IncentiveType | undefined) => T;
}

export function useCurrentIncentive<T = IncentiveType>(
  options?: UseCurrentIncentiveOptions<T>,
) {
  const customSelect = options?.select;

  return $API.useGetIncentives(
    {},
    {
      ...options,
      select: (data) => {
        const firstItem = data.at(0);
        return (customSelect ? customSelect(firstItem) : firstItem) as T;
      },
    },
  );
}
