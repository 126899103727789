/**
 * This is a modified version of the `pluginToken` from `@zodios/plugins`
 * https://www.zodios.org/docs/client/plugins#authorization-with-token-plugin
 *
 * This version fixes a couple of cases where we could trigger multiple token
 * renewals at the same time.
 *
 * See https://github.com/ecyrbe/zodios-plugins/pull/134
 * We can eventually remove this when the PR is merged and released.
 */
import { ZodiosPlugin } from '@zodios/core';
import axios, { AxiosError } from 'axios';

export type TokenProvider = {
  getToken: () => Promise<string | undefined>;
  renewToken?: () => Promise<string | undefined>;
};

function isTokenRenewed(newToken: string | undefined, error: AxiosError) {
  return newToken && getTokenFromHeader(error) !== newToken;
}

function getTokenFromHeader(error: AxiosError) {
  if (error.config?.headers) {
    const oldCredentials = error.config.headers['Authorization'] as string;
    return oldCredentials?.replace('Bearer ', '');
  }
  return undefined;
}

export function authTokenPlugin(provider: TokenProvider): ZodiosPlugin {
  let asyncRenewPending = Promise.resolve<string | undefined>(undefined);
  let expiredToken: string | undefined;

  return {
    request: async (_, config) => {
      let token = await provider.getToken();
      if (token) {
        // Wait for any pending renew request
        if (token === expiredToken) {
          try {
            token = await asyncRenewPending;
          } catch (error) {
            throw new axios.Cancel('Renew token request failed');
          }
        }

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }
      return config;
    },
    error: provider.renewToken
      ? async (_, __, error) => {
          if (
            axios.isAxiosError(error) &&
            provider.renewToken &&
            error.config
          ) {
            if (error.response?.status === 401) {
              const thisToken = getTokenFromHeader(error);
              if (expiredToken !== thisToken) {
                expiredToken = thisToken;
                asyncRenewPending = provider.renewToken();
              }
              const newToken = await asyncRenewPending;

              if (isTokenRenewed(newToken, error)) {
                const retryConfig = { ...error.config };
                retryConfig.headers = {
                  ...retryConfig.headers,
                  Authorization: `Bearer ${newToken}`,
                };
                // retry with new token and without interceptors
                return axios(retryConfig);
              }
            }
          }
          throw error;
        }
      : undefined,
  };
}
