import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  chakra,
  Grid,
  Link as ChakraLink,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { isPlayableVideo } from '@arena-labs/shared-models';
import { useHomeScreenData } from '@arena-labs/strive2-coaching';
import {
  DocumentsIcon,
  FairyWandIcon,
  Icon,
  LifeBuoyIcon,
  MegaphoneIcon,
  NotificationIcon,
  PerfAmbassadorsIcon,
  SignOutIcon,
  SVGIconComponentType,
  TEXT,
  UserProfileIcon,
} from '@arena-labs/strive2-ui';
import { useAuthService, useUserProfile } from '@strive/api';

import { useAdminDashboard } from '../admin/admin-dashboard';
import { AdminModal } from '../admin/admin-modal';
import { useStriveChatActions } from '../chat/chat-provider';
import { TeamVideoModal } from '../home/team-video-modal';
import { ContactModal } from '../support/contact-modal';
import { useMenu } from './menu/use-menu';

export function AppMenu() {
  const helpDisclosure = useDisclosure();
  const adminDisclosure = useDisclosure();
  const teamVideoModal = useDisclosure();
  const appMenu = useMenu();

  const {
    isActive: isAdminActive,
    activateAdmin,
    deactivateAdmin,
    open: openAdmin,
  } = useAdminDashboard();

  const [homescreen, { refreshState }] = useHomeScreenData();
  const teamVideo = homescreen?.team_video_data;
  const { data: userData } = useUserProfile();

  const router = useRouter();
  const authSvc = useAuthService();
  const queryClient = useQueryClient();

  const { disconnect: signoutChat } = useStriveChatActions();
  const onLogout = () => {
    signoutChat();
    appMenu.onClose();
    authSvc.send('Log out');
    router.push('/log-in');
    queryClient.clear();
  };
  return (
    <>
      <VStack spacing="2" align="stretch">
        <AppMenuItem type="link" icon={UserProfileIcon} href="/user/profile">
          Profile
        </AppMenuItem>

        <AppMenuItem
          type="link"
          icon={NotificationIcon}
          href="/user/notifications"
        >
          Notifications
        </AppMenuItem>

        {isPlayableVideo(teamVideo) ? (
          <AppMenuItem
            type="button"
            icon={MegaphoneIcon}
            onClick={() => teamVideoModal.onOpen()}
          >
            What&apos;s new
          </AppMenuItem>
        ) : null}

        <AppMenuItem
          type="link"
          icon={PerfAmbassadorsIcon}
          href="/coaching/ambassadors"
        >
          Performance Ambassadors
        </AppMenuItem>

        <AppMenuItem
          type="button"
          icon={LifeBuoyIcon}
          onClick={helpDisclosure.onOpen}
        >
          Strive Support
        </AppMenuItem>

        <AppMenuItem type="link" icon={DocumentsIcon} href="/legal">
          Legal
        </AppMenuItem>

        <AppMenuItem
          type="button"
          icon={SignOutIcon}
          onClick={() => {
            onLogout();
          }}
        >
          Sign out
        </AppMenuItem>
      </VStack>

      {userData?.is_staff ? (
        <chakra.button
          py={4}
          w="full"
          px="3"
          rounded="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          my={3}
          border="1px solid"
          borderColor="alert.error"
          onClick={
            isAdminActive
              ? () => {
                  deactivateAdmin();
                  appMenu.onClose();
                }
              : () => {
                  activateAdmin();
                  openAdmin();
                  appMenu.onClose();
                }
          }
        >
          <Icon as={FairyWandIcon} boxSize="5" color="strive.primary" mr="2" />
          <span>{isAdminActive ? 'Exit Admin Mode' : 'Admin Mode'}</span>{' '}
        </chakra.button>
      ) : null}

      <ContactModal {...helpDisclosure} />

      {isPlayableVideo(teamVideo) && (
        <TeamVideoModal
          isOpen={teamVideoModal.isOpen}
          onClose={() => {
            refreshState();
            teamVideoModal.onClose();
          }}
        />
      )}
      <AdminModal {...adminDisclosure} />
    </>
  );
}

export type AppMenuItemProps = {
  icon: SVGIconComponentType;
  children: React.ReactNode;
} & (
  | { type: 'button'; onClick: () => void }
  | {
      type: 'link';
      href: string;
    }
);

export function AppMenuItem({ icon, children, ...props }: AppMenuItemProps) {
  const { onClose } = useMenu();

  return (
    <Grid
      templateColumns="24px 1fr"
      gap="2"
      p={4}
      justifyContent="start"
      alignItems={'center'}
      rounded="md"
      border="1px solid"
      borderColor="neutral.900"
    >
      <Icon as={icon} w={'20px'} h={'auto'} color="strive.primary" />
      {props.type === 'button' && (
        <chakra.button
          textAlign="left"
          color="white"
          onClick={() => {
            props.onClick();
            // onClose();
          }}
        >
          <TEXT.P1_SEMIBOLD whiteSpace={'nowrap'}>
            <span>{children}</span>{' '}
          </TEXT.P1_SEMIBOLD>
        </chakra.button>
      )}
      {props.type === 'link' && (
        <Link passHref legacyBehavior href={props.href}>
          <ChakraLink variant="unstyled" onClick={onClose}>
            <TEXT.P1_SEMIBOLD whiteSpace={'nowrap'}>
              {' '}
              {children}
            </TEXT.P1_SEMIBOLD>
          </ChakraLink>
        </Link>
      )}
    </Grid>
  );
}
export { useMenu };
