import { oembed } from '@loomhq/loom-embed';
import { captureException } from '@sentry/nextjs';
import { assign, createMachine } from 'xstate';

import { getLoomUrl } from './loom-url';

export const loomPlaybackMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBkD2qC2AFANgQwE8AjPAYwGsBZMgCwEsA7MAOgDMwAXUmyzvCPBzxtO3RlADEEVE2aMAbqnIt2XGmky8hAoQG0ADAF1EoAA6pYdDnRkmQAD0QAWAOz7mAZgAcAVicAmADYvAEZ-H0CATg8nABoQAkQPH3d-JxCfNP1k0MinHwBfAviNbHxiMipaRhVRHj4dYVUxBkkwACd21HbmU3wOVm6METVSrX5BPANjJBBzS2tbWccEDMjmSP1syMj-HbSPaPjEhHD3fP1MkMjAkK9LwuKQUtxCEgpqFpY+t8q5CBwYAkAEEAMIAFQAkgA1YHggCi0zs8ysNgYdhWGR8zECPi8vii2Q8gXyPmOiBcPhCzH8HhcgVutP8IX0XicRRK6DKvw+1VkPwqFGYAHc8KjWhJ7LAhBwWHhWLL2gAKADqwMh4IA+gBlcHAgBKWqhlHhAEoJC9yu8ql9ela-qLxVAkbMUYt0cskjtmClcSE7vpIpkXK5yQhKf5mC5vB5WVsMi4dhznlzXoKbfR+fahQLxJLpYI5QqOkrKJCAHKarDIYEATQAQmCANKa41mi2p7MZmp2nnkXsEcQuswWVFLUArFws5iXSJeXabPbErxhiNRmNx-QJpPJhioCBwOyWvufTNgZGj90YxAAWjSYbvThnWxfr9fLmTx-Tp57zXq2kmC8FjRa9TkiMNAg8ZgnAJel-RDfwvETR5OUwNNrR-WQ-3GRoRhaKAgLHD0JwpFwwxiLwfRSel5zyYNaU-TsTz5Wo1BwyZmGkJhCKvT1w30J9YycQIQ0iENMg8ci3GYe4tzuFxEPEkIPEYtCu0w74ux4kC+PvBJnAZGcPHCKllNZPYvFU7lvxYgdrX+QFtPHBwKX8VcqXXeljIEwNhKs9DKg0uyHTFaxWic4iXLAyNEJJQIginMIFPc6loy8-wfLyQJ-PU2yBXs3NwtdS8dJIhBAmyTxon8NJ7gyJwtxSzzIIyhqspy5jbXyv5SEwPpOEgCLQOiJ8kP9EJXAyhTWXc85NnnDKkJExCiiKIA */
    id: 'LoomPlaybackMachine',
    type: 'parallel',
    tsTypes: {} as import('./loom-playback.machine.typegen').Typegen0,
    schema: {
      context: {} as {
        loomId: string | null;
        wait: number;
        duration: number;
        completionPercent: number;
      },
      events: {} as {
        type: 'ACTIVATE';
      },
      services: {} as {
        fetchLoomMetadata: { data: { duration: number } };
      },
    },
    context: {
      loomId: null,
      wait: 0,
      duration: 60,
      completionPercent: 40,
    },
    states: {
      fetchMetadata: {
        initial: 'fetching',

        states: {
          fetching: {
            invoke: {
              id: 'fetchLoomMetadata',
              src: 'fetchLoomMetadata',
              onDone: {
                target: 'done',
                actions: 'assignDuration',
              },
              onError: {
                actions: (context, event) => {
                  captureException(event.data);
                },
              },
            },
          },
          done: {
            type: 'final',
          },
        },

        description: `Fetches metadata for the loom video using the "oembed" API

e.g. "GET https://www.loom.com/"

@see https://dev.loom.com/docs/embed-sdk/api#oembed`,
      },
      playback: {
        initial: 'idle',

        states: {
          idle: {
            on: {
              ACTIVATE: 'waiting',
            },
          },
          waiting: {
            after: {
              WAIT_START_TIME: 'playing',
            },
          },
          playing: {
            after: {
              MIN_PLAYBACK_TIME: 'completed',
            },
          },
          completed: {
            type: 'final',
          },
        },

        description: `Models the "playback" state as best as we can.

The loom video is embedded in an iframe and loom doesn't expose any events to inform us whether the video is playing or has been completed.

1. Waits for the "ACTIVATE" event to signify that the video is visible to the user.
2. After "WAIT_START_TIME" (context.wait seconds) move to "playing"
3. After "MIN_PLAYBACK_TIME" (context.completionPercent% of the video duration - defaults to 40%) move to "completed"`,
      },
    },
  },
  {
    actions: {
      assignDuration: assign({
        duration: (context, event) => event.data.duration as number,
      }),
    },
    services: {
      fetchLoomMetadata: async (ctx, event) => {
        if (!ctx.loomId) {
          throw new Error('No loomId provided');
        }
        const metadata = await oembed(getLoomUrl(ctx.loomId));
        return metadata;
      },
    },
    delays: {
      WAIT_START_TIME: (context) => ms(context.wait),
      MIN_PLAYBACK_TIME: (context) =>
        ms(context.duration) * (context.completionPercent / 100),
    },
  },
);

function ms(seconds: number) {
  return seconds * 1000;
}
