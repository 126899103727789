import { useLifecycles } from 'react-use';
import { Capacitor } from '@capacitor/core';

export type UseScreenOrientationProps = {
  lock?: boolean;
};
export function useScreenOrientation(options: UseScreenOrientationProps = {}) {
  const onMount = options.lock ? lock : unlock;
  const onUnmount = options.lock ? unlock : lock;
  useLifecycles(onMount, onUnmount);
}

function lock() {
  if (Capacitor.isPluginAvailable('ScreenOrientation')) {
    import('@capacitor/screen-orientation')
      .then(({ ScreenOrientation }) =>
        ScreenOrientation.lock({ orientation: 'portrait' }),
      )
      .catch(console.warn);
  }
}

function unlock() {
  if (Capacitor.isPluginAvailable('ScreenOrientation')) {
    import('@capacitor/screen-orientation')
      .then(({ ScreenOrientation }) => ScreenOrientation.unlock())
      .catch(console.warn);
  }
}
