import { useEffect, useRef, useState } from 'react';
import { Box, BoxProps, Fade, useCallbackRef } from '@chakra-ui/react';
import { VideoJsPlayer } from 'video.js';

import { VideoPlayerComponent } from './video-player-component';

export type VideoPlayerTimedComponentProps = UseVideoPlayerInTimeRangeOptions &
  Omit<BoxProps, 'onChange'>;

export function VideoPlayerTimedComponent({
  start,
  end,
  player,
  onChange,
  className,
  ...props
}: VideoPlayerTimedComponentProps) {
  const isVisible = useVideoPlayerInTimeRange({
    start,
    end,
    player,
    onChange,
  });
  return (
    <VideoPlayerComponent player={player} className={className}>
      <Fade in={isVisible} unmountOnExit>
        <Box {...props}></Box>
      </Fade>
    </VideoPlayerComponent>
  );
}

export type UseVideoPlayerInTimeRangeOptions = {
  id?: string;
  player?: VideoJsPlayer;
  start: number;
  end?: number;
  onChange?: (isActive: boolean) => void;
};

export function useVideoPlayerInTimeRange(
  options: UseVideoPlayerInTimeRangeOptions,
) {
  const { start, end, player } = options;
  const [isInRange, setIsInRange] = useState(false);

  // Normalize the start & end times...
  // Convert negative values (relative to the end of the video) to positive
  const times = useRef<[number, number | undefined]>([start, end]);
  useEffect(() => {
    let _start = start;
    let _end = end;

    if (!player || !player?.el()) {
      return;
    }

    function normalizeStartAndEnd() {
      if (!player) return;
      const duration = player.duration();
      if (start < 0) {
        _start = duration + _start;
      }
      if (!_end) {
        _end = duration + 1;
      } else if (_end < 0) {
        _end = duration + _end;
      }
      times.current = [_start, _end];
    }

    player.on('loadedmetadata', normalizeStartAndEnd);
    return () => player.off('loadedmetadata', normalizeStartAndEnd);
  }, [player, start, end, options.id]);

  // Update the visibility of the annotation based on the current time
  const handleVisibilityChange = useCallbackRef(options.onChange);
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!player) return;
      const [_start, _end] = times.current;
      const currentTime = player.currentTime();

      const shouldBeVisible =
        currentTime > 0 &&
        Math.max(_start, 0) <= currentTime &&
        currentTime <= Math.max(_end ?? 0, 0);

      if (shouldBeVisible !== isInRange) {
        setIsInRange(shouldBeVisible);
        handleVisibilityChange(shouldBeVisible);
      }
    };

    if (!player || !player.el()) {
      return;
    }

    player.ready(function () {
      player.on('timeupdate', handleTimeUpdate);
    });

    return () => player.off('timeupdate', handleTimeUpdate);
  }, [start, end, player, isInRange, handleVisibilityChange]);

  return isInRange;
}
