import { forwardRef, Ref } from 'react';
import { Grid, GridProps } from '@chakra-ui/react';

export type ZStackProps = GridProps & {
  variant?: 'grid' | 'position';
};
export const ZStack = forwardRef(function ZStack(
  { sx, variant = 'grid', ...props }: ZStackProps,
  ref: Ref<HTMLDivElement>,
) {
  const stackSx =
    variant === 'grid'
      ? {
          ...sx,
          '&> *': {
            gridArea: '1/1',
          },
        }
      : {
          position: 'relative',
          display: 'block',
          ...sx,
          '&> *': {
            position: 'absolute',
            inset: 0,
          },
        };
  return <Grid {...props} sx={stackSx} ref={ref} />;
});
