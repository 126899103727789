import { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  Image,
  Progress,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isSameDay } from 'date-fns';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { Card, InfoIcon, PageStack, pluralize } from '@arena-labs/strive2-ui';
import { $API, AchievementIncentiveGroup, IncentiveType } from '@strive/api';

import IncentiveTypes from '../../onboarding/incentives/incentive-types';
import { useIncentiveHub } from '../use-incentive-hub';
import { incentiveImages, supportStrings } from './reward-config';

export type RewardHubProps = {
  acheivementsData: IncentiveType<'achievements'>;
};
export default function RewardHub({ acheivementsData }: RewardHubProps) {
  const analytics = useAnalytics();
  const incentiveHub = useIncentiveHub();

  useEffect(() => {
    incentiveHub.isOpen &&
      analytics.logEvent(GeneralTrackingEvent.RewardHubOpen);
  }, [analytics, incentiveHub]);

  return (
    <Drawer
      isFullHeight
      isOpen={incentiveHub.isOpen}
      placement="top"
      onClose={() => {
        analytics.logEvent(GeneralTrackingEvent.RewardHubClosed);
        incentiveHub.onClose();
      }}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="#1D1D1D">
        <DrawerBody
          h="full"
          mb="2"
          mt="8"
          display="flex"
          flexDirection="column"
          overflowY="auto"
          p={0}
        >
          <Swiper
            style={{
              height: '100%',
              maxHeight: 'var(--height)',
              maxWidth: '100vw',
            }}
            slidesPerView={1}
            modules={[A11y]}
            spaceBetween={50}
          >
            <SwiperSlide>
              <Rewards
                acheivementsData={acheivementsData}
                onClose={() => {
                  incentiveHub.onClose();
                }}
              />
            </SwiperSlide>

            <SwiperSlide>
              <IncentiveTypes
                incentives={acheivementsData.groups}
                slideDirection="last"
              />
            </SwiperSlide>
          </Swiper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

type RewardsProps = {
  acheivementsData: IncentiveType<'achievements'>;
  onClose: () => void;
};

function Rewards({ acheivementsData, onClose }: RewardsProps) {
  const { data: streak } = $API.useGetStreakData();
  const analytics = useAnalytics();
  const swiper = useSwiper();
  //maybe should sort by date and take the oldest one of the array?
  const recentReward = acheivementsData.groups
    .map((incentiveGroup) => {
      return incentiveGroup.incentives.find((incentive) =>
        incentive.earned ? isSameDay(incentive.earned, new Date()) : null,
      );
    })
    .find((firstActive) => {
      return firstActive !== undefined;
    });

  const earnByDoingRewards = acheivementsData?.groups.find(
    (incentiveGroup) => incentiveGroup.type_text === 'earn_by_doing',
  );

  const incompletedEarnByDoing =
    earnByDoingRewards &&
    earnByDoingRewards.incentives.find((incentive) => !incentive.earned);

  return (
    <PageStack
      h="full"
      pb="6"
      spacing="4"
      align="stretch"
      gridArea="content-body"
      overflowY="auto"
      textAlign={'center'}
      position="relative"
    >
      <Box>
        <HStack>
          <Button p={0} bg="none" onClick={() => swiper.slideNext()}>
            <Icon color={'white'} as={InfoIcon} boxSize={6} />
          </Button>
          <Spacer />
          <Box>
            <DrawerCloseButton
              size={'xl'}
              position={'inherit'}
              pr="3"
              onClick={() => {
                analytics.logEvent(GeneralTrackingEvent.RewardHubClosed);
                onClose();
              }}
            />
          </Box>
        </HStack>
        <Heading textAlign={'center'} fontSize={'2xl'}>
          Reward Hub
        </Heading>
      </Box>

      {/* if there is a recent reward earned, show that instead of streak stuff */}
      <Box>
        <Center>
          <Image
            src={
              !incompletedEarnByDoing
                ? 'images/incentives/treasure-chest.png'
                : recentReward
                ? incentiveImages[recentReward.icon]
                : '/images/incentives/fire.png'
            }
            alt={recentReward ? recentReward.alt_text : ''}
            w="120px"
          />
        </Center>

        <Text fontSize={'xl'} pt={recentReward ? '4' : 0}>
          {!incompletedEarnByDoing
            ? `All yours!`
            : recentReward
            ? 'New Reward Collected!'
            : streak?.streak.current === 0
            ? 'Ready to set fire?'
            : `${streak?.streak.current} Day Streak!`}
        </Text>
        <Text fontSize="sm">
          {recentReward
            ? `${recentReward.reward} added to your treasure box!`
            : streak?.streak.current === 0
            ? 'Check in daily to hit 10 day streak reward!'
            : ` You are here ${streak?.streak.current} ${pluralize(
                streak?.streak.current ? streak?.streak.current : 0,
                'day',
              )} in a row. Keep it up!`}
        </Text>
      </Box>

      {acheivementsData.groups.map((cardGroup, idx) => {
        {
          /* only show 'extra_bonus' rewards if ANY of those have been earned, and then ONLY those rewards that have been 
      earned if there are more than one */
        }
        if (cardGroup.type_text === 'extra_bonus') {
          const earnedExtraIncentives = cardGroup.incentives.find(
            (incentive) => incentive.earned,
          );

          if (!earnedExtraIncentives) {
            return null;
          }
        }
        return <RewardHubCardGroup key={idx} cardGroup={cardGroup} />;
      })}
    </PageStack>
  );
}

type RewardHubGroupProps = {
  cardGroup: AchievementIncentiveGroup;
};

function RewardHubCardGroup({ cardGroup }: RewardHubGroupProps) {
  return (
    <VStack w="full" alignItems={'flex-start'} spacing={'14px'}>
      <Text fontSize={'h2'}>
        {supportStrings[cardGroup.type_id + '_heading']}
      </Text>

      {cardGroup.incentives.map((reward, idx) => {
        //only show EARNED incentives in the 'extra_bonus' category
        if (!reward.earned && cardGroup.type_text === 'extra_bonus') {
          return null;
        }
        return (
          <Card
            key={idx}
            bg={reward.earned ? '#1E5563' : 'strive.card'}
            w="full"
            color="white"
          >
            <HStack w="full">
              <Box px={2}>
                <Image
                  src={
                    reward.earned
                      ? incentiveImages[reward.icon]
                      : incentiveImages[reward.icon_alternative]
                  }
                  w="48px"
                  alt={reward.earned ? reward.alt_text : ''}
                />
              </Box>
              <Box w="full">
                <Text pl="3" w="full" fontSize="h2" textAlign="left">
                  {reward.title}
                </Text>
                {reward.count_data && (
                  <HStack mt="2" pl="3">
                    <Progress
                      rounded="full"
                      w={40}
                      size="sm"
                      value={
                        (Number(reward.count_data.current) /
                          Number(reward.count_data.target)) *
                        100
                      }
                    />
                    <Text fontSize={'sm'}>
                      {reward.count_data.current}
                      {'/'}
                      {reward.count_data.target}
                    </Text>
                  </HStack>
                )}
              </Box>
            </HStack>
          </Card>
        );
      })}
    </VStack>
  );
}
