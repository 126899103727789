import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Icon,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useAnimate, usePresence } from 'framer-motion';

import { PillarPractice } from '@arena-labs/shared-models';
import { ChevronLeftIcon, InfoIcon, TEXT } from '@arena-labs/strive2-ui';
import { StriveApiResponse } from '@strive/api';

import {
  AdditionalInsightCards,
  InsightsRechargeCard,
} from '../recharge-cards';

export function DailyInsights({
  data,
  onOpenContext,
  logPracticeCard,
}: {
  data: StriveApiResponse<'submitACI2'>;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
  onOpenContext: () => void;
}) {
  const [insightsExpanded, setInsightsExpanded] = useState(false);
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      console.log('no animation');
    } else {
      const exitAnimation = async () => {
        await animate(
          '#body',
          { opacity: 0 },
          {
            duration: 0.35,
          },
        );
        await animate(
          scope.current,
          { opacity: 0 },
          {
            duration: 0.35,
          },
        );

        safeToRemove && safeToRemove();
      };
      exitAnimation();
    }
  }, [isPresent, animate, safeToRemove, scope]);

  return (
    <Box ref={scope}>
      <Flex
        h={'full'}
        id={'body'}
        mx={6}
        opacity={1}
        direction={'column'}
        gap={4}
      >
        <TEXT.H3>
          {!data?.daily?.latest_hrv ? (
            ` Your HRV data is missing today.`
          ) : (
            <>
              <span>
                Your Inner State &amp; HRV indicate
                {data?.daily?.inner_state_trend === data?.daily?.hrv_trend
                  ? ' similar'
                  : ' opposing'}{' '}
                trends{' '}
              </span>
            </>
          )}
          <Button
            ml={1}
            onClick={() => onOpenContext()}
            variant={'unstyled'}
            h={6}
            p={0}
            color={'neutral.700'}
            size={'xs'}
          >
            <Icon as={InfoIcon} boxSize={6} />
          </Button>{' '}
        </TEXT.H3>

        <Flex
          border={'2px solid'}
          borderColor={'neutral.900'}
          direction={'column'}
          rounded={'button'}
          py={2}
          w={'full'}
          gap={3}
        >
          <TEXT.P1_SEMIBOLD mx={'auto'}> Last 24 Hours</TEXT.P1_SEMIBOLD>

          <Grid templateColumns={'1fr 1fr'} w={'full'} gap={3}>
            <InsightsRechargeCard
              selection={data?.daily?.inner_state_trend}
              isDataCard={false}
              expanded={insightsExpanded}
            />
            <InsightsRechargeCard
              selection={data?.daily?.hrv_trend}
              isDataCard={true}
              expanded={insightsExpanded}
            />
          </Grid>

          <Collapse in={insightsExpanded} style={{ width: '100%' }}>
            <Grid templateColumns={'1fr 1fr'} w={'full'} gap={3}>
              {data?.daily?.recommended_practice && (
                <AdditionalInsightCards
                  metric={'Practice'}
                  value={`${data.daily.practice_count} completed`}
                  context={`${data.daily.practice_previous_count} from ${format(
                    new Date(data.daily.practice_trend_from),
                    'MMM dd',
                  )}`}
                  contextIndicator={true}
                  trend={data.daily.practice_trend}
                />
              )}
              {data?.daily?.recommended_practice && (
                <AdditionalInsightCards
                  metric={'HRV'}
                  value={
                    data.daily.latest_hrv
                      ? `${data?.daily?.latest_hrv} ms`
                      : '-'
                  }
                  context={`14 day avg: ${data?.daily?.baseline_hrv} ms`}
                  trend={data.daily.hrv_trend}
                />
              )}
            </Grid>
          </Collapse>
          <Button
            variant={'unstyled'}
            w={'full'}
            p={0}
            h={'4'}
            onClick={() => setInsightsExpanded(!insightsExpanded)}
          >
            <Icon
              as={ChevronLeftIcon}
              transform={`rotate(${insightsExpanded ? '90' : '-90'}deg)`}
              transition={'all 0.5se ease'}
              boxSize={3}
              mx={'auto'}
            />
          </Button>
        </Flex>

        <Flex direction={'column'} gap={9} px={2} py={4}>
          <Box>
            <Text
              fontSize={'20px'}
              lineHeight={6}
              fontWeight={'bold'}
              letterSpacing={'0.04em'}
              mb={1}
            >
              {data?.daily?.llm_title}
            </Text>
            <Text
              fontSize={'13px'}
              letterSpacing={'-0.04em'}
              lineHeight={'24px'}
              fontWeight={500}
            >
              {data?.daily?.llm_description}
            </Text>
          </Box>
          {data?.daily?.recommended_practice && (
            <Box>
              <TEXT.P1_SEMIBOLD mb={4}>Try This Practice</TEXT.P1_SEMIBOLD>
              {logPracticeCard(data.daily.recommended_practice)}
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
