export type CoachingSectionType = keyof typeof CoachingSection;

export const CoachingSection = {
  PROTOCOLS: 'Protocols',
  SYLLABUS: 'Syllabus',
  AMBASSADORS: 'Ambassadors',
} as const;

// The section list appears in this order
export const CoachingSectionList = [
  'PROTOCOLS',
  'SYLLABUS',
  'AMBASSADORS',
] as const;
