import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { playlistDetailSchema, playlistSchema } from './playlist.schema';

export const playlistApi = makeApi([
  {
    alias: 'getPlaylists',
    description: 'Get The Video Playlists',
    method: 'get',
    path: '/content/playlists/',
    parameters: [
      {
        type: 'Query',
        name: 'type',
        schema: z.enum(['explorations']).optional(),
      },
    ],
    response: z.object({
      featured: playlistSchema.nullable(),
      groups: z.array(playlistSchema),
    }),
  },
  {
    alias: 'getPlaylist',
    description: 'Get a playlist',
    method: 'get',
    path: 'content/playlists/:slug/',
    parameters: [
      {
        type: 'Path',
        name: 'slug',
        schema: z.string(),
      },
    ],
    response: playlistDetailSchema,
  },
]);

export const contentAPI = makeApi([
  {
    alias: 'updatePostRoll',
    description: 'Update Post Roll Items',
    method: 'post',
    path: 'content/video_view/post_roll/',
    parameters: [
      {
        type: 'Body',
        name: 'Data',
        schema: z.object({
          loom_id: z.string(),
        }),
      },
    ],
    response: z.unknown(),
  },
  ...playlistApi,
]);
