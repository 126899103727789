import { useEffect } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import { Duration, intervalToDuration } from 'date-fns';

/**
 * An accurate, performant timer
 * @see https://youtu.be/MCi6AZMkxcU
 */
export function animationInterval(
  ms: number,
  signal: AbortSignal,
  callback: (time: number) => void,
) {
  // Prefer currentTime, as it'll better sync animtions queued in the
  // same frame, but if it isn't supported, performance.now() is fine.
  const start = Number(document.timeline.currentTime) || performance.now();

  function frame(time: number) {
    if (signal.aborted) return;
    callback(time);
    scheduleFrame(time);
  }

  function scheduleFrame(time: number) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / ms) * ms;
    const targetNext = start + roundedElapsed + ms;
    const delay = targetNext - performance.now();
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}

type UseCountdownOptions = {
  onTick?: (time: number, duration: Duration) => unknown;
  onEnd?: () => unknown;
  enabled?: boolean;
};
export function useCountdownToDate(
  date: Date | number | null,
  options: UseCountdownOptions = {},
) {
  const endTime = typeof date === 'number' ? date : date?.getTime() ?? 0;

  const handleTick = useCallbackRef((remainingMs: number) => {
    const duration = intervalToDuration({ start: 0, end: remainingMs });
    options.onTick?.(remainingMs, duration);
  });
  const handleEnd = useCallbackRef(options.onEnd);

  useEffect(() => {
    if (options.enabled === false) {
      return;
    }
    if (endTime < Date.now()) {
      handleEnd();
      return;
    }

    const controller = new AbortController();
    handleTick(Math.max(0, endTime - Date.now()));
    animationInterval(1000, controller.signal, (time) => {
      const remaining = endTime - Date.now();
      if (Math.floor(remaining) <= 0) {
        handleEnd();
        controller.abort();
      } else {
        handleTick(remaining);
      }
    });

    return () => controller.abort();
  }, [endTime, handleEnd, handleTick, options.enabled]);
}
